import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ReportsState,
  ReportsFilterKey,
  ReportsFilterList,
} from "Interfaces/Pages/ReportsInterface";
import { DEFAULT_FILTER_KEY, DEFAULT_FILTER_LIST } from "./constants";

const initialState: ReportsState = {
  filterKeys: DEFAULT_FILTER_KEY,
  filterList: DEFAULT_FILTER_LIST,
};

export const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setFilterKeys: (state, action: PayloadAction<ReportsFilterKey>) => {
      state.filterKeys = action.payload;
    },
    setFilterList: (state, action: PayloadAction<ReportsFilterList>) => {
      state.filterList.selectedTableData = action.payload.selectedTableData;
    },
    resetReportsState: () => initialState,
  },
});

export const { setFilterKeys, setFilterList, resetReportsState } =
  reportsSlice.actions;
export default reportsSlice.reducer;
