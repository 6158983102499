import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  useMemo,
} from "react";
import { useInfiniteQuery } from "react-query";

import { useNavigate, useLocation } from "react-router-dom";
import { Form } from "Components/Core";
import {
  SearchControl,
  TableComponent,
  HeaderComponent,
  SelectSports,
} from "Components/Shared";
import { resetState } from "Components/Shared/SurveyHistory/surveyHistoryReducer";
import { cancelBatch } from "Api/Pages/BatchesApi";
import { deleteCoach } from "Api/Pages/CoachesApi";
import { deletePlayer } from "Api/Pages/PlayersApi";
import { getList } from "Api/Components/DisplayListComponentApi";
import {
  DisplayListComponentPropType,
  Page,
} from "Interfaces/Components/Shared/DisplayListComponentInterface";
import { SortColumnType } from "Interfaces/Pages/BatchesInterface";
import { UserDetailsObjectType } from "Interfaces/Components/Shared/UserDetailsFormInterface";
import {
  ACTIVE,
  BUTTONT_TEXT,
  CONFIRMATION_MESSAGE,
  FILTERED_LIST,
  FORM_CONSTANT,
  getColumns,
  HEADER_TITLE,
  ON_ADD_NAVIGATE_ROUTE,
  ON_EDIT_NAVIGATE_ROUTE,
  ON_ROWCLICK,
  TOTAL_MATCH_PLAYED,
} from "./constants";
import { COACH_WARNING } from "Pages/Coaches/constants";
import { SORT_COLUMN, ASC, DESC } from "Pages/Batches/constants";
import { PER_PAGE } from "./constants";
import {
  BATCH,
  COACH,
  PLAYER,
  SPORT,
  CONFIRM,
  INITIAL_PAGE,
  removeComfirmMessage,
  TEXT_END,
} from "App/constants";
import { useAppDispatch, useAppSelector } from "App/hooks";
import { setConfirmProps } from "App/commonReducer";

const DisplayListComponent = (props: DisplayListComponentPropType) => {
  const { title, isMatchPlayed, matchPlayedUserType, id } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  let state = location.state as {
    currentPage: number;
  };
  const [filteredList, setFilteredList] = useState<any>([]);
  const [searchInput, setSearchInput] = useState<string>("");
  const [selectValue, setSelectValue] = useState<string>("");
  const [active, setActive] = useState<string>(ACTIVE);
  const [currentPage, setCurrentPage] = useState<number>(
    state?.currentPage || INITIAL_PAGE
  );

  const [totalMatchPlayed, setTotalMatchPlayed] = useState<number>();
  const [filterConfig, setFilterConfig] = useState<SortColumnType>(SORT_COLUMN);
  const { confirmProps } = useAppSelector((state) => state.commonReducer);
  const { Confirm, TextCenter, TextEnd, Ok } = FORM_CONSTANT;
  const {
    userDetails: { academy_id, permissions },
  } = useAppSelector((state) => state.loginReducer);

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery(
      [title, searchInput, selectValue, active],
      ({ pageParam = 1 }) =>
        getList({
          perPage: PER_PAGE,
          userType: title,
          academyId: academy_id,
          searchInput: searchInput,
          selectValue: selectValue,
          currentPage: INITIAL_PAGE,
          active,
          sort_column: filterConfig.latestClicked,
          order_by: filterConfig[filterConfig.latestClicked],
          matchPlayedUserType,
          id,
          pageParam,
        }),
      {
        // The result of the API call is stored as lastPage.
        //getNextPageParam function tells React Query how to calculate the nextPage to fetch when the fetchNextPage function is called
        //allPages-Contains an array of all previously fetched pages.
        getNextPageParam: (lastPage, allPages) => {
          const totalPages = Math.ceil(lastPage.total_count / 15);

          let nextPage = 1;
          if (allPages && allPages.length) {
            nextPage = allPages.length + 1;
          }
          return nextPage <= totalPages ? nextPage : undefined;
        },

        staleTime: 5 * 60 * 1000,
        cacheTime: 10 * 60 * 1000,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
      }
    );

  const getFilteredListFromPages = (pages: Page[] | undefined, key: string) => {
    if (!pages) return [];
    return pages.flatMap((page) => page[key] || []);
  };

  const newFilteredList = useMemo(() => {
    const filterKey = FILTERED_LIST[title];
    return getFilteredListFromPages(data?.pages, filterKey);
  }, [data?.pages, title]);

  useEffect(() => {
    setFilteredList(newFilteredList);

    // Set totalMatchPlayed from the latest page
    const lastPage = data?.pages?.[data.pages.length - 1];
    if (lastPage && lastPage.totalMatchPlayed !== undefined) {
      setTotalMatchPlayed(lastPage.totalMatchPlayed);
    }
  }, [newFilteredList, data?.pages]);

  // Intersection Observer to load more data when reaching the end of the page
  //pageEndRef: A reference to the last element in the DOM to detect when it’s in view.
  //IntersectionObserver: Monitors when the pageEndRef becomes visible within the viewport.
  const pageEndRef = useRef(null);
  useEffect(() => {
    const currentPageEnd = pageEndRef.current;
    const root = document.querySelector(".scroll-y");
    if (root) {
      root.classList.add("h-100", "overflow-auto"); // Bootstrap utility classes
    } else {
      console.error("Element with class 'scroll-y' not found.");
    }
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && hasNextPage && !isFetchingNextPage) {
          fetchNextPage(); // Fetch next page when the bottom is in view
        }
      },
      { root, rootMargin: "100px" } // Trigger when the bottom is within 100px from view
    );

    if (currentPageEnd) {
      observer.observe(currentPageEnd);
    }
    // Cleanup function
    return () => {
      if (currentPageEnd) {
        observer.unobserve(currentPageEnd);
      }
    };
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  localStorage.setItem("Current Page", currentPage.toString());
  const handleSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = event;
      setSearchInput(value);
      setCurrentPage(INITIAL_PAGE);
    },
    [setSearchInput, setCurrentPage]
  );
  const handleSelect = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const {
        target: { value },
      } = event;
      setSelectValue(value);
      setCurrentPage(INITIAL_PAGE);
    },
    [setSelectValue, setCurrentPage]
  );

  const handleSubmit = useCallback(
    (event: React.ChangeEvent<HTMLFormElement>) => {
      event.preventDefault();
    },
    []
  );
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { checked },
      } = event;
      checked ? setActive(ACTIVE) : setActive("");
    },
    [setActive]
  );
  const onApplyFilter = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const column = event.currentTarget.getAttribute("data-column");
      column &&
        setFilterConfig({
          ...filterConfig,
          latestClicked: column,
          [column]: filterConfig[column] === ASC ? DESC : ASC,
        });
    },
    [filterConfig]
  );
  const onCancelBatch = useCallback(
    (id: number) => {
      dispatch(
        setConfirmProps({
          ...confirmProps,
          buttonProps: [{ title: CONFIRM, onClick: () => cancelBatch(id) }],
          confirmBodyProps: {
            message: [`${removeComfirmMessage} ${BATCH}?`],
          },
          closeButtonClassName: TEXT_END,
          modalType: CONFIRM,
          showConfirm: true,
        })
      );
    },
    [confirmProps, dispatch]
  );
  const onConfirmDelete = useCallback(
    async (id: number) => {
      if (title === COACH) {
        const { assigned_batches_names } = await deleteCoach({
          academyId: academy_id,
          id,
        });
        if (assigned_batches_names) {
          dispatch(
            setConfirmProps({
              ...confirmProps,
              closeButtonClassName: TextCenter,
              modalType: CONFIRM,
              confirmBodyProps: {
                message: COACH_WARNING,
                list: assigned_batches_names,
              },
              buttonProps: [{ title: Ok, onClick: () => {} }],
              showConfirm: true,
            })
          );
        }
      } else if (title === PLAYER) {
        await deletePlayer({ academyId: academy_id, id });
      }
    },
    [confirmProps, Ok, TextCenter, academy_id, dispatch, title]
  );
  const onDelete = useCallback(
    (id: number) => {
      dispatch(
        setConfirmProps({
          ...confirmProps,
          closeButtonClassName: TextEnd,
          modalType: CONFIRM,
          confirmBodyProps: {
            message: [CONFIRMATION_MESSAGE[title]],
          },
          buttonProps: [{ title: Confirm, onClick: () => onConfirmDelete(id) }],
          showConfirm: true,
        })
      );
    },
    [TextEnd, title, Confirm, confirmProps, onConfirmDelete, dispatch]
  );
  const onAdd = useCallback(() => {
    navigate(ON_ADD_NAVIGATE_ROUTE[title], {
      state: { isEditUser: false, userType: title },
    });
  }, [navigate, title]);
  const onEdit = useCallback(
    (id: number) => {
      navigate(ON_EDIT_NAVIGATE_ROUTE[title](id), {
        state: { isEditUser: true, userType: title },
      });
    },
    [navigate, title]
  );
  const onRowClick = useCallback(
    (props: UserDetailsObjectType) => {
      dispatch(resetState());
      if (props.id) {
        navigate(ON_ROWCLICK[title](props.id), {
          state: { currentPage: currentPage, path: location.pathname },
        });
      }
    },
    [dispatch, navigate, title, currentPage, location]
  );

  const tableData = useMemo(() => {
    return (
      data?.pages?.flatMap((page) => page[FILTERED_LIST[title]] || []) || []
    );
  }, [data?.pages, title]);

  return (
    <div className="ss-page-content d-flex flex-column flex-1 scroll-y bg-white">
      <>
        {!isMatchPlayed && (
          <HeaderComponent
            title={HEADER_TITLE[title]}
            shouldShowNav={false}
            shouldShowBulkUpload={title === PLAYER ? true : false}
            buttonProps={{
              buttonText: BUTTONT_TEXT[title],
              onClick: onAdd,
            }}
          />
        )}
        <Form
          className="d-flex align-items-start justify-content-between mb-4 px-4"
          onSubmit={handleSubmit}
        >
          <div className="d-flex justify-content-center gap-3 flex-column">
            <SelectSports
              handleSelect={handleSelect}
              shouldShowAll={!isMatchPlayed}
              selected={selectValue}
              shouldPassMasterSportId={isMatchPlayed}
              title={SPORT}
            />
            {isMatchPlayed && (
              <p className="d-flex align-items-center gap-1 mb-0 mt-0">
                {TOTAL_MATCH_PLAYED}
                <span className="fw-bold fs-4">{totalMatchPlayed}</span>
              </p>
            )}
          </div>

          <SearchControl
            className="mt-4"
            placeholder="Search"
            label="Search"
            onChange={handleSearch}
          />
        </Form>
        <div className="flex-1 scroll-y px-4 mb-3">
          {title === BATCH && (
            <Form.Check
              type="switch"
              id="custom-switch"
              label="Active Only"
              className="custom-switch-check mb-3"
              defaultChecked={true}
              onChange={handleChange}
            />
          )}
          <div className="table-responsive">
            {filteredList && (
              <TableComponent
                columns={getColumns[title]({
                  onEdit: onEdit,
                  onDelete: onDelete,
                  permissions: permissions,
                  onApplyFilter: onApplyFilter,
                  onCancelBatch: onCancelBatch,
                  filterConfig: filterConfig,
                })}
                tableData={tableData}
                onRowClick={onRowClick}
                isClickAble={!isMatchPlayed}
              />
            )}
            {/* Empty div to serve as the trigger for IntersectionObserver */}
            <div ref={pageEndRef} style={{ height: "1px" }}></div>
          </div>
        </div>
      </>
      {/* )} */}
    </div>
  );
};
export default DisplayListComponent;
