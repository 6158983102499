import { useNavigate, useParams, useLocation } from "react-router-dom";

import AssetVideoDetail from "Components/Shared/AssetVideoDetail";

const PromotionalVideoDetail = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const id = Number(params.id);
  const state = location.state as {
    currentPage: number;
  };

  if (Number.isNaN(id)) {
    navigate("/page-not-found");
  }

  const handleBackNavigation = () => {
    navigate("/featured-videos", {
      state: { currentPage: state?.currentPage || 1 },
    });
  };

  return <AssetVideoDetail videoId={id} onBackClick={handleBackNavigation} />;
};

export default PromotionalVideoDetail;
