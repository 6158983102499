import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Button, Col } from "Components/Core";
import {
  AssetEvaluationCategory,
  AssetTagsProps,
} from "Interfaces/AssetTagsInterface";
import { useState } from "react";
import { getAssetEvaluationTagsFromData } from "../AssetSubmissionVideos/utils";

interface Props extends AssetTagsProps {
  categoryData: AssetEvaluationCategory[];
}

const SpecificSkillAndTags = (props: Props) => {
  const { formData, disabled = false, categoryData, dispatch } = props;

  const [tagTitle, setTagTitle] = useState("");

  const handleSubCategoryChange: React.ChangeEventHandler<HTMLSelectElement> = (
    e
  ) => {
    const value = e.target.value;

    if (value === "OTHER") {
      dispatch({
        type: "asset_evaluation_sub_category",
        payload: {
          is_sub_category_other: true,
          asset_evaluation_sub_category_id: null,
          asset_evaluation_sub_category_title: "",
          asset_evaluation_tags: [],
        },
      });
    } else if (value === "NONE") {
      dispatch({
        type: "asset_evaluation_sub_category",
        payload: {
          is_sub_category_other: false,
          asset_evaluation_sub_category_id: null,
          asset_evaluation_sub_category_title: null,
          asset_evaluation_tags: getAssetEvaluationTagsFromData(
            categoryData,
            formData.asset_evaluation_category_id,
            null
          ),
        },
      });
    } else {
      const subCategoryId = Number(value);

      dispatch({
        type: "asset_evaluation_sub_category",
        payload: {
          is_sub_category_other: false,
          asset_evaluation_sub_category_id: subCategoryId,
          asset_evaluation_sub_category_title: null,
          asset_evaluation_tags: getAssetEvaluationTagsFromData(
            categoryData,
            formData.asset_evaluation_category_id,
            subCategoryId
          ),
        },
      });
    }
  };

  const handleOtherSubCategoryChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (e) => {
    dispatch({
      type: "asset_evaluation_sub_category_title",
      payload: e.target.value,
    });
  };

  const handleCreateTag = () => {
    dispatch({
      type: "create_asset_evaluation_tag",
      payload: tagTitle,
    });
    setTagTitle("");
  };

  const handleRemoveTag = (index: number) => {
    dispatch({
      type: "remove_asset_evaluation_tag",
      payload: index,
    });
  };

  const category = categoryData.find(
    (cat) => cat.id === formData.asset_evaluation_category_id
  );

  const subCategories = category?.asset_evaluation_sub_categories || [];
  const subCategoryOptions = subCategories.map((subCategory) => (
    <option key={subCategory.id} value={subCategory.id}>
      {subCategory.title}
    </option>
  ));

  const tagButtons = formData.asset_evaluation_tags.map((tag, index) => (
    <Button
      key={tag.id}
      variant="outline-secondary"
      className="d-flex align-items-center gap-2"
      disabled={disabled}
      onClick={() => handleRemoveTag(index)}
    >
      {!disabled && <FontAwesomeIcon icon={faXmark} />}
      {tag.title}
    </Button>
  ));

  return (
    <>
      <Form.Group as={Col} lg={4}>
        <Form.Label>Specific Skills</Form.Label>
        <Form.Select
          disabled={disabled}
          value={
            formData.asset_evaluation_sub_category_id ||
            (formData.is_sub_category_other ? "OTHER" : "NONE")
          }
          onChange={handleSubCategoryChange}
        >
          <option value="NONE">No Specific Skills Selected</option>
          {subCategoryOptions}
          <option value="OTHER">Other</option>
        </Form.Select>
        {formData.is_sub_category_other && (
          <Form.Control
            placeholder="Other specific skill"
            value={formData.asset_evaluation_sub_category_title || ""}
            onChange={handleOtherSubCategoryChange}
          />
        )}
      </Form.Group>
      <Form.Group as={Col} lg={4}>
        <Form.Label>Select Multiple Tags</Form.Label>
        {disabled && tagButtons.length === 0 && (
          <p className="text-secondary">No tags added!</p>
        )}
        <div className="d-flex gap-2 py-2">{tagButtons}</div>
        {!disabled && (
          <div className="d-flex align-items-center gap-2">
            <Form.Control
              placeholder="Create Tag"
              value={tagTitle}
              onChange={(e) => setTagTitle(e.target.value)}
            />
            <Button variant="secondary" onClick={handleCreateTag}>
              Create
            </Button>
          </div>
        )}
      </Form.Group>
    </>
  );
};

export default SpecificSkillAndTags;
