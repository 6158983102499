import { useCallback, useMemo, useReducer, useState } from "react";
import { useForm, Controller } from "react-hook-form";

import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Col,
  Form,
  Row,
  ProgressBar,
} from "Components/Core";
import AssetTags from "Components/Shared/AssetTags";

import {
  isFileSizeValid,
  validateImageResolution,
} from "Api/Pages/cloudinaryAssetUpload";
import {
  assetTagsReducer,
  defaultAssetTagsData,
} from "Components/Shared/AssetTags/utils";

import {
  ASSET_TYPE_ENUM,
  IAssetDetails,
  ResolutionConfigType,
  SizeConfigType,
  UPLOAD_PURPOSE_ENUM,
} from "./types";

import "./styles.scss";
import { AssetTagsData } from "Interfaces/AssetTagsInterface";

interface IProps {
  isModalVisible: boolean;
  uploadProgressPercentage: number;
  assetFormats: Array<string>;
  title: string;
  assetType?: ASSET_TYPE_ENUM;
  sizeConfig: SizeConfigType;
  resolutionConfig: ResolutionConfigType;
  purpose: UPLOAD_PURPOSE_ENUM;
  sportsList?: Array<{
    id: number;
    image_link: string;
    name: string;
  }>;
  toggleModal: VoidFunction;
  onAssetSubmitCallback: (
    videoDetails: IAssetDetails,
    assetTagsData: AssetTagsData
  ) => void;
}

const UploadAsset = (props: IProps) => {
  const {
    isModalVisible,
    uploadProgressPercentage,
    assetFormats,
    sizeConfig,
    resolutionConfig,
    title = "Upload Asset",
    purpose,
    toggleModal,
    onAssetSubmitCallback,
  } = props;

  const {
    formState: { errors },
    control,
    handleSubmit,
    register,
  } = useForm<IAssetDetails>({
    defaultValues: {
      description: "",
      title: "",
      asset: null,
      sport: "",
    },
  });

  const initialAssetTagsData = useMemo(() => defaultAssetTagsData(), []);
  const [assetTagsData, assetTagsDispatch] = useReducer(
    assetTagsReducer,
    initialAssetTagsData
  );
  const [assetTagsError, setAssetTagsError] = useState<string | null>(null);

  const getAssetFormats = () => {
    if (!assetFormats || assetFormats.length === 0) {
      return "";
    }
    return assetFormats.reduce((acc, ele) => {
      acc += `.${ele},`;
      return acc;
    }, "");
  };

  const validAssetTagsData = (): string | null => {
    if (
      purpose === UPLOAD_PURPOSE_ENUM.drill &&
      !assetTagsData.master_sport_id
    ) {
      return "Sport is required";
    }

    return null;
  };

  const onSubmit = (data: any) => {
    if (purpose === UPLOAD_PURPOSE_ENUM.public) {
      return onAssetSubmitCallback({ ...data, purpose }, assetTagsData);
    }

    const assetError = validAssetTagsData();
    setAssetTagsError(assetError);

    if (!assetError) {
      onAssetSubmitCallback({ ...data, purpose }, assetTagsData);
    }
  };

  const handleFormSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    if (purpose !== UPLOAD_PURPOSE_ENUM.public) {
      setAssetTagsError(validAssetTagsData());
    }

    handleSubmit(onSubmit)(e);
  };

  const assetTagsDispatchWrapper = useCallback(
    (action: { type: string; payload: any }) => {
      setAssetTagsError(null);
      assetTagsDispatch(action);
    },
    []
  );

  return (
    <Modal centered size="lg" show={isModalVisible}>
      <ModalHeader>{title}</ModalHeader>

      <Form
        className="d-flex flex-column scroll-y "
        onSubmit={handleFormSubmit}
      >
        <ModalBody>
          <Row>
            <Col sm={8}>
              <Form.Group controlId="textarea">
                <Form.Label className="fs-xs">Title*</Form.Label>
                <Controller
                  name="title"
                  control={control}
                  rules={{ required: "Title is required" }}
                  render={({ field }) => (
                    <Form.Control
                      type="text"
                      className="border"
                      placeholder="Start typing title here"
                      {...field}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid" className="d-block">
                  {errors.title?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={8}>
              <Form.Group controlId="textarea">
                <Form.Label className="fs-xs">Description*</Form.Label>
                <Controller
                  name="description"
                  control={control}
                  rules={{
                    required: "Description is required",
                  }}
                  render={({ field }) => (
                    <Form.Control
                      as="textarea"
                      rows={5}
                      className="border"
                      placeholder="Tell us more about video"
                      {...field}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid" className="d-block">
                  {errors.description?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col sm={8}>
              <input
                type="file"
                className="form-control"
                accept={getAssetFormats()}
                {...register("asset", {
                  required: "Please select video",
                  validate: {
                    lessThan: (files) => {
                      if (files) {
                        const file = files[0];
                        return (
                          isFileSizeValid(file, sizeConfig) || "Max 500MBß"
                        );
                      }
                      return;
                    },
                    acceptedFormats: (files) => {
                      if (files) {
                        const file: any = files[0];
                        let fileType = "";
                        if (!assetFormats || assetFormats.length === 0) {
                          return;
                        }
                        if (file.type.includes("/")) {
                          const splitValues = file.type.split("/");
                          if (splitValues.length >= 1) {
                            fileType = splitValues[1].toUpperCase();
                          }
                        }
                        return (
                          assetFormats.includes(fileType.toLowerCase()) ||
                          `Only ${assetFormats.join(", ")}`
                        );
                      }
                    },
                    checkResolution: async (files) => {
                      if (files) {
                        const file: any = files[0];
                        if (file.type.includes(ASSET_TYPE_ENUM.image)) {
                          try {
                            const result: any = await validateImageResolution(
                              file,
                              {
                                width: resolutionConfig.imageWidth,
                                height: resolutionConfig.imageHeight,
                              }
                            );
                            return result;
                          } catch (_) {
                            console.error(
                              "Error in validating image resolutions"
                            );
                          }
                        } else {
                          return true; // no validation for other types
                        }
                      }
                    },
                  },
                })}
              />
              <Form.Control.Feedback type="invalid" className="d-block">
                {errors.asset?.message}
              </Form.Control.Feedback>
            </Col>
          </Row>

          {purpose !== UPLOAD_PURPOSE_ENUM.public && (
            <AssetTags
              formData={assetTagsData}
              dispatch={assetTagsDispatchWrapper}
              disabled={false}
            />
          )}

          {assetTagsError && (
            <Form.Control.Feedback type="invalid" className="d-block">
              {assetTagsError}
            </Form.Control.Feedback>
          )}

          {uploadProgressPercentage !== 0 ? (
            <>
              <div className="text-center">{uploadProgressPercentage}%</div>
              <ProgressBar variant="success" now={uploadProgressPercentage} />
            </>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outline-secondary"
            size="sm"
            className="px-4"
            onClick={toggleModal}
          >
            Cancel
          </Button>
          <Button
            variant="secondary"
            size="sm"
            className="px-4"
            type="submit"
            disabled={uploadProgressPercentage !== 0}
          >
            {uploadProgressPercentage === 0 ? "Save" : "Saving"}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default UploadAsset;
