import React, { useMemo, useState, useEffect } from "react";
import { useQuery } from "react-query";
import DatePicker from "react-datepicker";

import { fetchMatchPlayHistory } from "Api/Pages/MatchPlayApi";
import {
  MatchPlayApiResponse,
  MatchPlayProps,
} from "Interfaces/Pages/MatchPlayHistoryInterface";
import { matchPlayColumns } from "./constant";

import TableComponent from "../TableComponent";
import PaginationControl from "../PaginationControl";
import calenderIcon from "App/images/calendar-icon.svg";

const formatDateToDDMMYYYY = (date: Date): string => {
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

const MatchPlayHistory: React.FC<MatchPlayProps> = ({ userId }) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  const today = new Date();
  const threeMonthsAgo = new Date(today);
  threeMonthsAgo.setMonth(today.getMonth() - 3);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    threeMonthsAgo,
    today,
  ]);

  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);
  const [startDate, endDate] = dateRange.map((date) =>
    date ? formatDateToDDMMYYYY(date) : ""
  );
  const pageSize = 15;

  const { data, isLoading, error } = useQuery<MatchPlayApiResponse>(
    ["matchPlayHistory", currentPage, startDate, endDate, userId],
    async () => {
      if (userId === undefined) {
        return undefined;
      }

      return fetchMatchPlayHistory({
        userId,
        startDate: startDate,
        endDate: endDate,
        page: currentPage,
        perPage: pageSize,
      });
    },
    {
      enabled: userId !== undefined && !!startDate && !!endDate,
      keepPreviousData: true,
    }
  );

  const memoizedTableData = useMemo(() => {
    const filteredData = data?.engagement_requests;

    const paginatedData = filteredData || [];
    return paginatedData?.map((item) => ({
      ...item,
      of_type: item.of_type.charAt(0).toUpperCase() + item.of_type.slice(1),
      scheduled_at: item.scheduled_at
        .map((date) => new Date(date).toLocaleDateString())
        .join(", "),
      participant_names: item.participant_names.join(", "),
      is_user_winner: item.is_user_winner
        ? "Yes"
        : item.is_user_winner === false
        ? "No"
        : "N/A",
    }));
  }, [data]);

  useEffect(() => {
    if (data) {
      const totalRecords = data.total_count || 0;
      setTotalPages(Math.ceil(totalRecords / pageSize));
    }
  }, [data]);

  const handleDateRangeChange = (dates: [Date | null, Date | null]) => {
    setDateRange(dates);
    if (dates[0] && dates[1]) {
      setIsCalendarOpen(false);
    } else {
      setIsCalendarOpen(true);
    }

    setCurrentPage(1);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error fetching data</div>;
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="datepicker-container d-flex align-items-center">
          <DatePicker
            selectsRange
            startDate={dateRange[0]}
            endDate={dateRange[1]}
            onChange={handleDateRangeChange}
            placeholderText="Select Date Range"
            open={isCalendarOpen}
            onClickOutside={() => setIsCalendarOpen(false)}
            className="form-control date-range-input"
          />
          <img
            src={calenderIcon}
            alt="Calendar Icon"
            className="calendar-icon ms-2 cursor-pointer"
            onClick={() => setIsCalendarOpen(!isCalendarOpen)}
          />
        </div>
      </div>

      <div className="table-responsive">
        <TableComponent
          className="ss-table mb-0"
          columns={matchPlayColumns}
          tableData={memoizedTableData}
          hideColWidth={false}
          hideThead={false}
        />
      </div>

      <div className="pagination-wrap d-flex justify-content-end px-4">
        <PaginationControl
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
        />
      </div>
    </>
  );
};

export default MatchPlayHistory;
