import { useQuery } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";

import { faPlus } from "@fortawesome/free-solid-svg-icons";

import {
  deleteVideoDetails,
  getAssetList,
  getVideoConfig,
  postVideoDetails,
} from "Api/Pages/VideoListApi";
import AssetLandingPage from "Components/Shared/AssetLandingPage";

import { getAssetColumns } from "./constants";

import {
  ASSET_TYPE_ENUM,
  IAssetDetails,
  UPLOAD_PURPOSE_ENUM,
  VideoConfigInterface,
} from "Components/Shared/UploadAsset/types";
import { useEffect, useState } from "react";
import USE_QUERY_KEYS_CONSTANTS from "Api/useQueryKeyConstants";
import { getMasterSportsList } from "Api/Pages/SportsApi";
import { IMasterSportResponse } from "./types";
import uploadFile, {
  getResolutionConfig,
  getSizeConfig,
} from "Api/Pages/cloudinaryAssetUpload";
import { AssetType } from "Components/Shared/AssetLandingPage/types";
import { INITIAL_PAGE } from "App/constants";
import { useCanApproveSubmissionDrillVideos } from "./utils";
import { AssetTagsData } from "Interfaces/AssetTagsInterface";
import { isAllowedTo } from "Utils/helper";
import { useAppSelector } from "App/hooks";

const Drills = () => {
  const navigate = useNavigate();
  let location = useLocation();
  let state = location.state as {
    currentPage: number;
  };
  const {
    userDetails: { permissions, permission_rules },
  } = useAppSelector((state) => state.loginReducer);
  const [isUploadModalOpen, setUploadModalVisibility] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(
    state?.currentPage || INITIAL_PAGE
  );
  const canApproveSubmissionDrillVideos = useCanApproveSubmissionDrillVideos();

  // track progress for file upload
  const [uploadProgressPercentage, setUploadProgressPercentage] =
    useState<number>(0);

  const { data: drillsResponse, refetch: fetchDrillsVideos } = useQuery(
    USE_QUERY_KEYS_CONSTANTS.drillVideosList,
    () =>
      getAssetList({
        currentPage: currentPage,
        per_page: 10,
        upload_purpose: UPLOAD_PURPOSE_ENUM.drill,
        of_type: ASSET_TYPE_ENUM.video,
      }),
    { refetchOnWindowFocus: false, enabled: false }
  );

  const { data: sportsResponse, refetch: fetchMasterSportsList } =
    useQuery<IMasterSportResponse>(
      USE_QUERY_KEYS_CONSTANTS.masterSportsList,
      () => getMasterSportsList(),
      { refetchOnWindowFocus: false, enabled: false }
    );

  const { data: assetConfig } = useQuery<VideoConfigInterface>(
    USE_QUERY_KEYS_CONSTANTS.videoConfigs,
    () => getVideoConfig(),
    {
      staleTime: 1000 * 60, // 1 minute
    }
  );

  useEffect(() => {
    fetchMasterSportsList();
  }, [fetchMasterSportsList]);

  useEffect(() => {
    fetchDrillsVideos();
  }, [fetchDrillsVideos, currentPage]);

  // toggle video details upload modal
  const toggleVideoUploadModal = () => {
    setUploadModalVisibility((isOpen) => !isOpen);
  };

  const onDeleteRowClicked = async (selectedId: number) => {
    await deleteVideoDetails(selectedId);
    // On successful delete fetch drills videos
    fetchDrillsVideos();
  };
  const onRowClicked = (rowData: AssetType) => {
    navigate(`/drills/details/${rowData.id}`, {
      state: { ...rowData, currentPage: currentPage },
    });
  };

  const onVideoUploadProgressCallback = (
    progressEvent: any,
    totalFileSize: number
  ) => {
    setUploadProgressPercentage(
      Math.round((progressEvent.loaded / totalFileSize) * 100)
    );
  };

  const onVideoSaveCallback = (
    videoDetails: IAssetDetails,
    assetTagsData: AssetTagsData
  ) => {
    // file upload start flag set and show progress bar
    const { title, description, asset } = videoDetails;
    // upload file
    if (asset !== null && assetConfig) {
      const fileUpload = asset[0];
      try {
        uploadFile(
          fileUpload,
          {
            ofType: ASSET_TYPE_ENUM.video,
            cloudName: assetConfig?.cloudinary.cloud_name,
            apiKey: assetConfig?.cloudinary.api_key,
            purpose: UPLOAD_PURPOSE_ENUM.drill,
          },
          (progressEvent) =>
            onVideoUploadProgressCallback(progressEvent, fileUpload.size)
        ).then(async (response: any) => {
          toggleVideoUploadModal();
          setUploadProgressPercentage(0);

          await postVideoDetails({
            title,
            description,
            of_type: ASSET_TYPE_ENUM.video,
            upload_purpose: UPLOAD_PURPOSE_ENUM.drill,
            url: response.data.secure_url,
            ...assetTagsData,
          });

          // once complete save details to sport skill api server
          // after saving details to api server call listing api
          fetchDrillsVideos();
        });
      } catch (error) {
        setUploadProgressPercentage(0);
      }
    }
  };

  const handleVideoSubmissionNavigate = () => {
    navigate("/drills/submissions");
  };

  const canManageDestroy = permission_rules?.manage?.Asset;
  const shouldAllowDelete =
    (isAllowedTo("Asset", "destroy", permissions) &&
      permission_rules?.destroy?.Asset?.upload_purpose?.includes("drill")) ||
    canManageDestroy;

  return (
    <AssetLandingPage
      uploadModalProps={{
        purpose: UPLOAD_PURPOSE_ENUM.drill,
      }}
      title="Drills"
      subActionButtonProps={
        canApproveSubmissionDrillVideos
          ? {
              label: "Video Submissions",
              onClickHandler: handleVideoSubmissionNavigate,
            }
          : undefined
      }
      actionButtonProps={{
        label: "Upload Video",
        icon: faPlus,
        onClickHandler: toggleVideoUploadModal,
      }}
      tableProps={{
        columns: getAssetColumns(onDeleteRowClicked, shouldAllowDelete),
        tableData: drillsResponse?.data || [],
        noDataMessage: "Please upload drills",
        onRowClick: onRowClicked,
        clickable: true,
        paginationProps: {
          totalCount: drillsResponse?.total_count || 0,
          currentPage: currentPage,
          numberOfRowPerPage: 10,
          setPageNumber: setCurrentPage,
        },
      }}
      sportsList={sportsResponse || []}
      isUploadModalOpen={isUploadModalOpen}
      uploadProgressPercentage={uploadProgressPercentage}
      allowedFileFormats={assetConfig?.video.allowed_file_formats || []}
      sizeConfig={getSizeConfig(assetConfig)}
      resolutionConfig={getResolutionConfig(assetConfig)}
      toggleVideoUploadModal={toggleVideoUploadModal}
      onVideoSaveCallback={onVideoSaveCallback}
    />
  );
};

export default Drills;
