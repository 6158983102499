const USE_QUERY_KEYS_CONSTANTS = {
  masterSportsList: "masterSportsList",
  drillVideosList: "drillVideosList",
  publicAssetList: "publicAssetList",
  promotionalVideosList: "promotionalVideosList",
  videoConfigs: "videoConfigs",
  playerVideosList: "playerVideosList",
  playerSurveyAdherenceDetails: "playerSurveyAdherenceDetails",
};

export default USE_QUERY_KEYS_CONSTANTS;
