import { ProgressBar } from "Components/Core";
import { ProgressBarComponentProps, PROGRESS_BAR } from "./constants";

const ProgressBarComponent = (props: ProgressBarComponentProps) => {
  const { surveys } = props;
  const progressBarKeys = Object.keys(surveys);
  return (
    <ProgressBar>
      {progressBarKeys.map((keys, idx) => (
        <ProgressBar
          className={PROGRESS_BAR[keys]}
          now={surveys[keys]}
          key={idx}
        />
      ))}
    </ProgressBar>
  );
};
export default ProgressBarComponent;
