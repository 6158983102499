import { CHART_DATA_WEIGHTAGE } from "../constants";
import { IPlayerSurveyDetails, SurveyType } from "./types";

export const getBatches = (data: Array<IPlayerSurveyDetails>) => {
  return data.map((ele) => ({
    batchName: ele.batch_name,
    batchId: ele.batch_id,
  }));
};

export const getAverageValue = (surveyData: { [key: string]: SurveyType }) => {
  let totalCount: number = 0;
  let key: string = "";
  for (key in surveyData) {
    totalCount += CHART_DATA_WEIGHTAGE[key] * (surveyData[key].value || 0);
  }

  // need to revise formula
  const result = totalCount / Object.keys(surveyData).length;
  return result;
};

export const getIndex = (row: number, n: number, col: number) => {
  return row * n + col;
};
