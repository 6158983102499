export const ADD_USER_ERROR_MESSAGE = {
  NAME_IS_EMPTY: "Name is required",
  NAME_LIMIT_EXCEEDED: "Character Limit of 50 has been exceeded",
  CONTACT_NUMBER_IS_EMPTY: "Contact number is required",
  CONTACT_NUMBER_IS_INVALID: "Invalid Contact number",
  EMAIL_IS_EMPTY: "Email is required",
  EMAIL_LIMIT_EXCEEDED: "Character limit of 100 has been exceeded",
  EMAIL_IS_INVALID: "Inavlid Email Format",
  DETAILS_LIMIT_EXCEEDED: "Character Limit of 200 has been exceeded",
};

export const ADD_SURVEY_QUESTION_ERROR_MESSAGE = {
  QUESTION_IS_EMPTY: "Question is required",
  QUESTION_LIMIT_EXCEEDED: "Character Limit of 150 has been exceeded",
  ANSWER_A_IS_EMPTY: "Answer is required",
  ANSWER_A_LIMIT_EXCEEDED: "Character Limit of 100 has been exceeded",
  ANSWER_B_IS_EMPTY: "Answer is required",
  ANSWER_B_LIMIT_EXCEEDED: "Character Limit of 100 has been exceeded",
  ANSWER_C_IS_EMPTY: "Answer is required",
  ANSWER_C_LIMIT_EXCEEDED: "Character Limit of 100 has been exceeded",
  ANSWER_D_IS_EMPTY: "Answer is required",
  ANSWER_D_LIMIT_EXCEEDED: "Character Limit of 100 has been exceeded",
};
export const LOGIN_ERROR_MESSAGE = {
  EMAIL_IS_REQUIRED: "Email is required",
  PASSWORD_IS_REQUIRED: "Password is required",
  INVALID_EMAIL_FORMAT: "Email is not valid",
};

export const SPORT_ERROR_MESSAGE = {
  SPORT_ERROR: "Sport already selected",
};

export const addUserErrorValues = {
  nameError: "",
  contact_number_error: "",
  emailError: "",
  detailsError: "",
};

export const addSurveyErrorValues = {
  questionError: "",
  answerError: [
    {
      answerError: "",
    },
    {
      answerError: "",
    },
    {
      answerError: "",
    },
    {
      answerError: "",
    },
  ],
};

export const addBatchErrorValues = {
  NameMsg: "Enter batch name",
  LevelMsg: "Select level",
  FeeStructureMsg: "Enter fee structure",
  SportMsg: "Select sport",
  StartTimeMsg: "Add start time",
  CoachMsg: "Select coach",
  EndTimeMsg: "Add end time",
};

export const addBatchErrorInitialValues = {
  NameMsg: "",
  LevelMsg: "",
  FeeStructureMsg: "",
  SportMsg: "",
  StartTimeMsg: "",
  CoachMsg: "",
  EndTimeMsg: "",
};

export const ADD_BATCH_ERROR_MESSAGE = {
  DETAILS_LIMIT_EXCEEDED: "Character Limit of 200 has been exceeded",
};
