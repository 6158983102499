import { Form } from "Components/Core";
import { TableComponent, SearchControl } from "Components/Shared";
import { AcademiesListComponentProps } from "Interfaces/Pages/AcademyInterface";
import { getColumns } from "Pages/Academies/constants";
import { useAppSelector } from "App/hooks";

const AcademiesListComponent: React.FC<AcademiesListComponentProps> = ({
  academiesList,
  onDeactivateAcademy,
  onEditAcademy,
  onEditAdmin,
  onAddAdmin,
  onRowClick,
  onHandleSearch,
}) => {
  const {
    userDetails: { permissions },
  } = useAppSelector((state) => state.loginReducer);

  return (
    <>
      <div className="ss-page-body d-flex flex-column flex-1 scroll-y">
        <Form className="d-flex align-items-center justify-content-between mb-4 px-4">
          <h6 className="academies-list-title fw-medium mb-0">
            Lists of Academies
          </h6>
          <SearchControl
            placeholder="Search"
            label="Search"
            onChange={onHandleSearch}
          />
        </Form>
        <div className="flex-1 scroll-y px-4">
          <div className="table-responsive">
            <TableComponent
              columns={
                onEditAcademy &&
                onDeactivateAcademy &&
                onEditAdmin &&
                onAddAdmin &&
                onRowClick
                  ? getColumns({
                      onEditAcademy: onEditAcademy,
                      onDelete: onDeactivateAcademy,
                      permissions,
                      onAddAdmin: onAddAdmin,
                      onEditAdmin: onEditAdmin,
                    })
                  : ""
              }
              tableData={academiesList}
              onRowClick={onRowClick}
              hideThead={false}
              isClickAble={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AcademiesListComponent;
