import React, { useEffect, useState } from "react";
import { Card } from "Components/Core";
import { IMAGES_TO_BE_PRELOADED } from "App/constants";
import { AuthProps } from "Interfaces/Components/Shared/AuthInterface";
import { useAppDispatch } from "App/hooks";
import { setShowLoader } from "App/commonReducer";
import { preloadImage } from "Utils/utils";
import logo from "App/images/logo@2x.png";
import "./auth.scss";

const Auth: React.FC<AuthProps> = ({ heading, children }) => {
  const dispatch = useAppDispatch();
  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);

  useEffect(() => {
    Promise.all(IMAGES_TO_BE_PRELOADED.map((image) => preloadImage(image)))
      .then(() => {
        setIsImageLoaded(true);
        dispatch(setShowLoader(false));
      })
      .finally(() => setIsImageLoaded(true));
    dispatch(setShowLoader(true));
  }, [dispatch]);

  return (
    <>
      {isImageLoaded ? (
        <div className="page-auth d-flex flex-1 p-5">
          <div className="page-auth-section">
            <div className="auth-logo">
              <img src={logo} alt="logo" />
            </div>
          </div>
          <div className="page-auth-section d-flex align-items-center justify-content-center">
            <Card className="card-auth border-0 bg-transparent flex-1">
              <Card.Body className="p-0">
                <Card.Title className="fw-normal mb-5 text-white">
                  {heading}
                </Card.Title>
                {children}
              </Card.Body>
            </Card>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Auth;
