import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Modal, Button, Form, Col } from "Components/Core";
import ButtonIcon from "../ButtonIcon";
import SearchSelect from "../SearchSelect";
import { useAppSelector } from "App/hooks";
import {
  getAllPlayers,
  getPlayerReport,
  sendEmailPlayerReports,
} from "Api/Pages/PlayersApi";
import { download } from "Api/Pages/cloudinaryAssetUpload";
import { PlayerReportModalPropsType } from "Interfaces/Components/Shared/HeaderInterface";
import { PlayerInfo } from "Interfaces/AppInterface";
import { ReportModalType } from "./constants";
import "./reportModal.scss";

const PlayerReportModal = (props: PlayerReportModalPropsType) => {
  const { show, reportType, onCloseModal } = props;

  const [allPlayers, setAllPlayers] = useState<PlayerInfo[]>([]);
  const [players, setPlayers] = useState<PlayerInfo[]>([]);
  const [selectedPlayer, setSelectedPlayer] = useState<PlayerInfo | null>(null);
  const [emailAdmin, setEmailAdmin] = useState<boolean>(false);

  const [selectPlayerError, setSelectPlayerError] = useState("");
  const [dateRangeError, setDateRangeError] = useState("");
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);

  const { academy_id: academyId } = useAppSelector(
    (state) => state.loginReducer.userDetails
  );

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);

  useEffect(() => {
    getAllPlayers(academyId).then((infoList) => {
      setAllPlayers(infoList);
    });
  }, [academyId]);

  const handleCloseModal = () => {
    setPlayers([]);
    setDateRange([null, null]);
    onCloseModal();
    setSelectPlayerError("");
    setDateRangeError("");
    setIsAllSelected(false);
    setEmailAdmin(false);
  };

  const handleAddPlayer = (playerInfo: PlayerInfo) => {
    let playerAdded =
      players.find((info) => info.email === playerInfo.email) !== undefined;

    if (!playerAdded) {
      players.push(playerInfo);
      setPlayers([...players]);
    }
  };

  const handleRemovePlayer = (playerInfo: PlayerInfo) => {
    const updatedPlayers = players.filter(
      (info) => info.email !== playerInfo.email
    );
    setPlayers(updatedPlayers);
    setIsAllSelected(false);
  };

  const handleDateRangeChange = (value: [Date, Date]) => {
    setDateRangeError("");
    setDateRange(value);
  };

  const handleSelectPlayerChange = (playerInfo: PlayerInfo) => {
    setSelectPlayerError("");

    if (reportType === ReportModalType.EMAIL) {
      handleAddPlayer(playerInfo);
    } else if (reportType === ReportModalType.DOWNLOAD) {
      setSelectedPlayer(playerInfo);
    }
  };

  const handleEmailToChange = () => {
    setEmailAdmin((v) => !v);
  };

  const handleSelectAllPlayers: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    setSelectPlayerError("");
    setIsAllSelected(e.target.checked);
    if (e.target.checked) {
      setPlayers([...allPlayers]);
    } else {
      setPlayers([]);
    }
  };

  const handleDownloadReport = async () => {
    const [startDate, endDate] = dateRange;

    if (selectedPlayer === null) {
      setSelectPlayerError("Player is required!");
    } else if (startDate === null || endDate === null) {
      setDateRangeError("Date Range is required!");
    } else {
      handleCloseModal();

      const data = await getPlayerReport({
        academyId,
        playerId: selectedPlayer.id,
        startDate,
        endDate,
      });

      download(data, `${selectedPlayer.id}-player-report.pdf`);
    }
  };

  const handleEmailReport = async () => {
    const [startDate, endDate] = dateRange;
    if (players.length === 0) {
      setSelectPlayerError("Select atleast 1 player!");
    } else if (startDate === null || endDate === null) {
      setDateRangeError("Date Range is required!");
    } else {
      const playerIds = players
        .filter((player) => player.email !== null)
        .map((player) => player.id);

      handleCloseModal();
      await sendEmailPlayerReports({
        playerIds,
        academyId,
        startDate,
        endDate,
        emailAdmin,
      });
    }
  };

  const playerOptions = allPlayers
    .filter((info) => info.email != null)
    .map((info) => ({
      value: info,
      label: info.email,
    }));

  const playerCardList = players
    .filter((playerInfo) => playerInfo.email != null)
    .map((playerInfo) => (
      <div key={playerInfo.email} className="card">
        <div className="card-header d-flex flex-row gap-2 align-items-center justify-content-between p-0 px-2">
          {playerInfo.email}
          <ButtonIcon
            className="p-0"
            onClick={() => handleRemovePlayer(playerInfo)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </ButtonIcon>
        </div>
      </div>
    ));

  let titlePrefix = "";

  switch (reportType) {
    case ReportModalType.DOWNLOAD:
      titlePrefix = "Download ";
      break;
    case ReportModalType.EMAIL:
      titlePrefix = "Email ";
      break;
    default:
      break;
  }

  return (
    <Modal className="ss-modal right_modal" show={show}>
      <Modal.Header className="fw-bold fs-sm border-0">
        <h2 className="fs-md mb-0 fw-medium">{titlePrefix}Player Report</h2>
        <ButtonIcon className="fs-5" onClick={handleCloseModal}>
          <FontAwesomeIcon icon={faTimes} />
        </ButtonIcon>
      </Modal.Header>

      <hr className="m-0" />

      <Modal.Body className="d-flex flex-column gap-4">
        <Form className="d-flex flex-column gap-4">
          <Form.Group
            as={Col}
            className="d-flex flex-column gap-2"
            controlId="Players"
          >
            <Form.Label className="fs-sm fw-bold">Select Player</Form.Label>
            {reportType === ReportModalType.DOWNLOAD && (
              <SearchSelect
                optionsList={playerOptions}
                setSelected={handleSelectPlayerChange}
                placeholder="Select Player"
                selected={{ value: "", label: "" }}
              />
            )}
            {reportType === ReportModalType.EMAIL && (
              <>
                <div className="d-flex flex-column gap-2 overflow-auto selected-player-card-container">
                  {playerCardList}
                </div>
                <SearchSelect
                  value=""
                  optionsList={playerOptions}
                  setSelected={handleSelectPlayerChange}
                  selected={{ value: "", label: "" }}
                  placeholder={"Add Player"}
                  errorMessage={""}
                />
              </>
            )}
          </Form.Group>
          {reportType === ReportModalType.EMAIL && (
            <Form.Group as={Col} controlId="SelectAll">
              <div className="d-flex flex-row gap-2">
                <Form.Check
                  checked={isAllSelected}
                  onChange={handleSelectAllPlayers}
                />
                <span>Select All Players</span>
              </div>
              <span className="text-danger">{selectPlayerError}</span>
            </Form.Group>
          )}
          <Form.Group as={Col} controlId="DateRange">
            <Form.Label className="fs-sm fw-bold">Date Range</Form.Label>
            <div className="d-flex align-items-center">
              <div className="reports-datepicker-wrap">
                <DatePicker
                  selectsRange
                  placeholderText="Select Date Range"
                  className="reports-datepicker"
                  startDate={dateRange[0]}
                  endDate={dateRange[1]}
                  dateFormat="MM/dd/yyyy"
                  onChange={handleDateRangeChange}
                />
              </div>
            </div>
            <span className="text-danger">{dateRangeError}</span>
          </Form.Group>
        </Form>

        {reportType === ReportModalType.EMAIL && (
          <div className="d-flex flex-row gap-5 form-check p-0">
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="exampleRadios"
                id="exampleRadios1"
                value="option1"
                checked={!emailAdmin}
                onChange={handleEmailToChange}
              />
              <label className="form-check-label" htmlFor="exampleRadios1">
                Email Players
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="exampleRadios"
                id="exampleRadios2"
                value="option2"
                checked={emailAdmin}
                onChange={handleEmailToChange}
              />
              <label className="form-check-label" htmlFor="exampleRadios2">
                Email Admin
              </label>
            </div>
          </div>
        )}

        <div>
          {reportType === ReportModalType.DOWNLOAD && (
            <Button variant="secondary" onClick={handleDownloadReport}>
              Download Report
            </Button>
          )}
          {reportType === ReportModalType.EMAIL && (
            <Button variant="secondary" onClick={handleEmailReport}>
              Email Report
            </Button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PlayerReportModal;
