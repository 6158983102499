import axios from "axios";
import {
  IVideosParams,
  IPostVideosParams,
  IPlayerAssetGetParams,
  GetVideoSubmissionsParams,
  PatchSubmissionVideoActionParam,
  PatchSubmissionVideoParam,
} from "Interfaces/AppInterface";
import { BASE_URL } from "App/constants";
import { AssetVideoDetailData } from "Interfaces/Components/Shared/AssetVideoDetailInterface";
import { AssetSubmissionVideo } from "Interfaces/Components/Shared/AssetSubmissionVideos";
export const getVideoConfig = async () => {
  const { data } = await axios.get(
    `${BASE_URL}assets/config`
    // `/mocks/assets.json?page=${currentPage}&source=${source}&per_page=${per_page}`
  );
  return data;
};

export const getAssetSignature = async (
  timestamp: number,
  public_id: string
) => {
  const { data } = await axios.post(`${BASE_URL}assets/signature`, {
    signature_params: {
      timestamp,
      public_id,
    },
  });
  return data;
};

export const getAssetList = async (param: IVideosParams) => {
  const { currentPage, per_page, upload_purpose } = param;
  let assetRequestURL = `${BASE_URL}assets?page=${currentPage}&per_page=${per_page}`;
  if (upload_purpose) {
    assetRequestURL = `${assetRequestURL}&upload_purpose=${upload_purpose}`;
  }
  const { data } = await axios.get(assetRequestURL);
  // const { data } = await axios.get(
  //   `/mocks/drills.json?page=${currentPage}&per_page=${per_page}`
  // );

  return data;
};

export const postVideoDetails = async (params: IPostVideosParams) => {
  const { data } = await axios.post(`${BASE_URL}assets`, params);
  // const { data } = await axios.get(
  //   `/mocks/assets.json?params=${JSON.stringify(param)}`
  // );
  return data;
};

export const deleteVideoDetails = async (id: number) => {
  const { data } = await axios.delete(`${BASE_URL}assets/${id}`);

  return data;
};

export const getVideoComments = async (asset_id: number) => {
  const { data } = await axios.get(
    // `${BASE_URL}assets/${asset_id}/comments`
    `/mocks/assetComments.json?`
  );

  return data;
};

export const getPlayerUploadedAssets = async (
  params: IPlayerAssetGetParams
) => {
  const { academy_id, player_id, page, per_page } = params;
  const { data } = await axios.get(
    `${BASE_URL}academies/${academy_id}/players/${player_id}/assets?page=${page}&per_page=${per_page}`
    // `/mocks/player_asset.json?params=${JSON.stringify(params)}`
  );

  return data;
};

export const downloadPlayerUploadedPdf = async (
  params: IPlayerAssetGetParams
) => {
  const { academy_id, player_id } = params;
  const { data } = await axios.get(
    `${BASE_URL}academies/${academy_id}/players/${player_id}/assets.pdf`,
    { responseType: "arraybuffer" }
    // `/mocks/player_asset.json?params=${JSON.stringify(params)}`
  );

  return data;
};

export const getVideoDetail = async (id: number) => {
  const { data } = await axios.get(`${BASE_URL}assets/${id}`);
  return data as AssetVideoDetailData;
};

export const getVideoSubmissionList = async (
  params: GetVideoSubmissionsParams
) => {
  const { data } = await axios.get(`${BASE_URL}approval_assets`, {
    params: {
      page: params.page,
      per_page: params.perPage,
      upload_purpose: params.uploadPurpose,
      request_status: params.requestStatus,
    },
  });

  const responseData = data as {
    data: AssetSubmissionVideo[];
    total_count: number;
  };

  return {
    videos: responseData.data,
    totalCount: responseData.total_count,
  };
};

export const patchSubmissionVideoAction = async (
  params: PatchSubmissionVideoActionParam
) => {
  const { data } = await axios.patch(
    `${BASE_URL}approval_assets/${params.videoId}/update_request_status`,
    { request_status: params.action }
  );

  return data;
};

export const getSubmissionVideoDetails = async (videoId: number) => {
  const { data } = await axios.get(`${BASE_URL}approval_assets/${videoId}`);
  return data as AssetSubmissionVideo;
};

export const putSubmissionVideo = async (params: PatchSubmissionVideoParam) => {
  const tags = params.data.asset_evaluation_tags.map((tag) => ({
    ...tag,
    id: tag.is_other ? null : tag.id,
  }));

  const { data } = await axios.put(
    `${BASE_URL}approval_assets/${params.videoId}`,
    { ...params.data, asset_evaluation_tags: tags }
  );

  return data;
};
