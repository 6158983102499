import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { downloadReport } from "Api/Pages/ReportsApi";
import { DownloadParams } from "Interfaces/AppInterface";
import { ROLES } from "./constants";

export const isUserLoggedIn = () => {
  return localStorage.getItem("access-token");
};

export const titleCaseConversion = (str: string) => {
  return str ? str.substring(0, 1).toUpperCase() + str.substring(1) : "";
};

export const isEmptyOrSpaces = (str: string) => {
  return str === null || str.match(/^ *$/) !== null;
};

export const getTimeRanges = (
  interval: number,
  language = window.navigator.language
) => {
  const ranges: { label: string; value: string }[] = [];
  const date = new Date();
  const format: any = {
    hour: "numeric",
    minute: "numeric",
  };

  for (let minutes = 0; minutes < 24 * 60; minutes = minutes + interval) {
    date.setHours(0);
    date.setMinutes(minutes);

    let hourString = date.getHours().toString();
    let minuteString = date.getMinutes().toString();

    if (hourString.length < 2) hourString = "0" + hourString;
    if (minuteString.length < 2) minuteString = "0" + minuteString;

    ranges.push({
      label: date.toLocaleTimeString(language, format),
      value: `${hourString}:${minuteString}`,
    });
  }

  return ranges;
};

export const setAuthorisationInfo = (response: any) => {
  const {
    headers,
    data: {
      data: { academy_id, id, permission_rules },
    },
  } = response;
  const { create } = permission_rules;

  window.localStorage.setItem("access-token", headers["access-token"]);
  window.localStorage.setItem("client", headers.client);
  window.localStorage.setItem("uid", headers.uid);
  window.localStorage.setItem("expiry", headers.expiry);
  window.localStorage.setItem("academyId", academy_id);
  window.localStorage.setItem("userId", id);

  const entityData = create.AppNotification?.entity;
  if (entityData && entityData.academies?.id) {
    // If academies and id are present, user is an admin
    window.localStorage.setItem("Entity", JSON.stringify(entityData));
    window.localStorage.setItem("role", ROLES.ADMIN);
  } else {
    // If entity is empty, user is a superadmin
    window.localStorage.setItem("role", ROLES.SUPERADMIN);
  }
};

export const clearSession = () => {
  ["access-token", "client", "uid", "expiry"].forEach((keyItem: string) => {
    localStorage.removeItem(keyItem);
  });
};

export const DATE_FORMAT = "MM/DD/YYYY";

export const DEBOUNCE_WAIT_TIME = 1000;

export const debounce = (
  callback: Function,
  wait: number | undefined = DEBOUNCE_WAIT_TIME
) => {
  let timeout: any;
  return ((...args: any) => {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      callback.apply(context, args);
    }, wait);
  })();
};

export const convertDateInto_Y_M_D_format = function (date: Date | string) {
  const d = new Date(date);
  var yyyy = d.getFullYear().toString();
  var mm = (d.getMonth() + 1).toString(); // getMonth() is zero-based
  var dd = d.getDate().toString();

  return (
    yyyy + "-" + (mm[1] ? mm : "0" + mm[0]) + "-" + (dd[1] ? dd : "0" + dd[0])
  );
};

export const getAcademyId = (academyId?: number) =>
  academyId || localStorage.getItem("academyId");

export const checkOldAndCurrentData = (oldData: any, currentData: any) =>
  JSON.stringify(oldData) === JSON.stringify(currentData);

export const preloadImage = (src: string) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve(img);
    };
    img.onerror = img.onabort = () => reject(src);
    img.src = src;
  });
};

export const validatePhoneNumber = (
  countryCode: string,
  contactNumber: string
) => {
  const phoneNumber = countryCode + contactNumber;
  return isPossiblePhoneNumber(phoneNumber) && isValidPhoneNumber(phoneNumber);
};

export const handleDownload = async ({
  academyId,
  selectedSportValue,
  startDate,
  endDate,
  searchInput,
  numOfDays,
  dropDownValue,
}: DownloadParams) => {
  const downloadLink = await downloadReport({
    academyId: academyId,
    selectedSportValue: selectedSportValue,
    start_date: startDate,
    end_date: endDate,
    searchInput: searchInput,
    numOfDays: numOfDays,
    dropDownValue: dropDownValue,
  });

  const element = document.createElement("a");
  element.setAttribute("href", downloadLink);
  element.setAttribute("download", `${dropDownValue}report.csv`);
  element.setAttribute("rel", "noopener noreferrer");
  element.click();
};

export const formatDateToDDMMYYYY = (date: Date): string => {
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};
