import { Button, Row, Col, Form } from "Components/Core";
import { HeaderComponent } from "Components/Shared";
import "./dashboard.scss";
const DashboardDetails = () => {
  return (
    <div className="ss-page-content d-flex flex-column flex-1 scroll-y bg-white">
      <HeaderComponent title={"Academy Details"} />
      <div className="ss-page-body d-flex flex-column flex-1 scroll-y p-4">
        <Form className="d-flex flex-column gap-4">
          <div className="academy-logo-box px-2">
            <img src="" alt="Academy Logo" />
          </div>
          <Row>
            <Col lg={4}>
              <label className="fs-xs">Academy Name</label>
              <p className="fs-sm fw-bold mb-0">ABCD Academy</p>
            </Col>
            <Col lg={4}>
              <label className="fs-xs">Academy Contact Number</label>
              <p className="fs-sm fw-bold mb-0">+91 00000 00000</p>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <label className="fs-xs">Academy Email ID</label>
              <p className="fs-sm fw-bold mb-0">Samplename@gmail.com</p>
            </Col>
            <Col lg={4}>
              <label className="fs-xs">Website</label>
              <p className="fs-sm fw-bold mb-0">www.academyname.in</p>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <label className="fs-xs">Address line 1</label>
              <p className="fs-sm fw-bold mb-0">Address line 1</p>
            </Col>
            <Col lg={4}>
              <label className="fs-xs">Address line 2</label>
              <p className="fs-sm fw-bold mb-0">Address line 2</p>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <label className="fs-xs">state</label>
              <p className="fs-sm fw-bold mb-0">State Name</p>
            </Col>
            <Col lg={4}>
              <label className="fs-xs">City</label>
              <p className="fs-sm fw-bold mb-0">City Name</p>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <label className="fs-xs">PIN Code</label>
              <p className="fs-sm fw-bold mb-0">0000000</p>
            </Col>
            <Col lg={4}>
              <label className="fs-xs">More Details About Coach</label>
              <p className="fs-sm fw-bold mb-0">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
                varius ligula
              </p>
            </Col>
          </Row>
          <hr className="ss-page-seprator my-0" />
          <p className="fs-sm fw-bold mb-0">Academy Admin Details</p>
        </Form>
      </div>
      <footer className="ss-page-footer d-flex align-items-center px-4 py-3">
        <Button variant="secondary" size="sm" className="px-4">
          Edit Academy Info
        </Button>
      </footer>
    </div>
  );
};

export default DashboardDetails;
