import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getSubmissionVideoDetails } from "Api/Pages/VideoListApi";
import { useAppDispatch } from "App/hooks";
import { setToastMessage } from "App/commonReducer";
import { SUCCESS } from "App/constants";
import VideoDetailsForm from "./VideoDetailsForm";
import ViewVideoDetails from "./ViewVideoDetails";

interface AssetSubmissionVideoDetailProps {
  uploadPurpose: string;
}

const AssetSubmissionVideoDetail = (props: AssetSubmissionVideoDetailProps) => {
  const { uploadPurpose } = props;

  const navigate = useNavigate();
  const params = useParams();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  const id = Number(params.id);

  if (Number.isNaN(id)) {
    navigate("/page-not-found");
  }

  const [isEditMode, setIsEditMode] = useState(false);
  const { data, isLoading, isRefetching } = useQuery({
    queryKey: ["asset-submission-video-detail", id],
    queryFn: () => getSubmissionVideoDetails(id),
  });

  useEffect(() => {
    if (data && data.request_status !== "pending") {
      if (data.request_status === "approved") {
        dispatch(setToastMessage([["Video already approved!"], SUCCESS]));
      }

      if (data.request_status === "rejected") {
        dispatch(setToastMessage([["Video already rejected!"], SUCCESS]));
      }

      if (uploadPurpose === "featured") {
        navigate("/featured-videos");
      } else {
        navigate("/drills");
      }
    }
  }, [data, dispatch, navigate, uploadPurpose]);

  const handleBackNavigation = () => {
    if (uploadPurpose === "featured") {
      navigate("/featured-videos/submissions");
    } else {
      navigate("/drills/submissions");
    }

    queryClient.invalidateQueries(["asset-submission-video-detail", data?.id]);
    queryClient.invalidateQueries(["asset-video-submissions"]);
  };

  return (
    <div className="ss-page-content d-flex flex-column flex-1 scroll-y bg-white">
      <header className="ss-page-header d-flex align-items-center justify-content-start gap-2 px-4 ">
        <FontAwesomeIcon
          icon={faChevronLeft}
          type="button"
          onClick={handleBackNavigation}
        />
        <h2 className="fs-md mb-0 fw-medium">Submission Video Details</h2>
      </header>
      <hr className="ss-page-seprator mx-4 my-0" />

      {data &&
        (isEditMode ? (
          <VideoDetailsForm
            data={data}
            handleCloseEdit={() => setIsEditMode(false)}
          />
        ) : (
          <ViewVideoDetails
            data={data}
            showAssetTags={!(isLoading || isRefetching)}
            uploadPurpose={uploadPurpose}
            handleEdit={() => setIsEditMode(true)}
          />
        ))}
    </div>
  );
};

export default AssetSubmissionVideoDetail;
