import {
  BulkUploadButtonTitleInterafce,
  BulkUploadRouteInterface,
  ConfirmBodyPropsType,
  UserListInterface,
} from "Interfaces/AppInterface";
import { SurveyHistoryUserInterface } from "Interfaces/Components/Shared/SurveyHistoryInterface";
import logo from "App/images/logo@2x.png";
import bgImage from "App/images/login-bg@2x.png";

export const ACADEMY_BASE_URL = `${process.env.REACT_APP_API_HOST}api/v1/admin/academies/`;

export const BASE_URL = `${process.env.REACT_APP_API_HOST}api/v1/admin/`;
export const CONSTANT_URL = `${process.env.REACT_APP_API_HOST}api/v1/constants`;

export const LOGIN_URL = `${process.env.REACT_APP_API_HOST}api/v1/admin_auth/sign_in`;

export const FORGET_PASSWORD_URL = `${process.env.REACT_APP_API_HOST}api/v1/admin_auth/password`;

export const LOGOUT_URL = `${process.env.REACT_APP_API_HOST}api/v1/admin_auth/sign_out`;

export const REDIRECT_URL = `${window.location.origin}/reset-password`;

export const ADD_PLAYER = "Create New Player";
export const EDIT_PLAYER = "Edit Player Details";
export const ADD_COACH = "Create New Coach";
export const EDIT_COACH = "Edit Coach Details";
export const ADD_BATCH = "Create New Batch";
export const EDIT_BATCH = "Edit Batch";
export const DASHBOARD = "Dashboard";
export const BATCH = "Batch";
export const COACH = "Coach";
export const ACADEMY = "Academy";
export const ADMIN = "Admin";
export const PLAYER = "Player";
export const DRILLS = "Drills";
export const QUESTION = "Question";
export const SURVEY_QUESTION = "Survey Question";
export const SPORT = "Sport";
export const SELECT_SPORT = "Select Sports";
export const CANCEL = "Cancel";
export const CLEAR = "Clear";
export const DELETE = "Delete";
export const EDIT = "Edit";
export const CREATE = "Create";
export const OK = "Ok";
export const ADD = "Add";
export const SAVE = "Save";
export const CONFIRM = "Confirm";
export const TEXT_END = "text-end";
export const INITIAL_PAGE = 1;
export const RECORDS_PER_PAGE = 10;
export const MATCH_PLAYED = "MatchPlayed";
export const EMPTY_LABEL = "----";

export const BatchItemMap: any = {
  Coach: "batch_coaches",
  Drills: "batch_drills",
  Player: "batch_players",
  "Survey Question": "batch_survey_questions",
};
export const ADD_SPORT = "Create New Sport";

export const removeComfirmMessage = "Do you want to remove this";
export const deactivateConfirmMessage = "Do you want to de-activate this";
export const editComfirmMessage = "Do you want to edit this";
export const saveComfirmMessage = "Do you want to save this";

export const CONFIRM_BODY_PROPS_DEFAULT_VALUE: ConfirmBodyPropsType = {
  message: [],
  list: [],
};

export const BATCH_USER: any = {
  [COACH]: "coaches",
  [PLAYER]: "players",
  [DRILLS]: "drills",
};

export const SURVEY_HISTORY_USER: SurveyHistoryUserInterface = {
  Player: "players",
  Coach: "coaches",
};

export const DEFAULT_CONFIRM_PROPS = {
  showConfirm: false,
  modalType: "",
  confirmBodyProps: CONFIRM_BODY_PROPS_DEFAULT_VALUE,
  buttonProps: [
    {
      title: "",
      onClick: () => {},
    },
  ],
  modalClassName: "ss-deactivate-modal",
};

export const SELECTED_TAB_OBJECT = {
  [PLAYER]: "personalDetails",
  [COACH]: "personalDetails",
};

export const BULK_UPLOAD_BUTTON_TITLE: BulkUploadButtonTitleInterafce = {
  Players: " Bulk Upload Players",
};

export const BULK_UPLOAD_ROUTE: BulkUploadRouteInterface = {
  Players: "/players/player_bulk_upload",
};

export const ACCEPTED_EXTENSION_FOR_BULK_UPLOAD =
  ".csv,.xlsx,.xlsm,.xlsb,.xltx,.xltm,.xls,.xlt,.xls,.xml,.xlam,.xla,.xlw,.xlr";

export const SUCCESS = "success";

export const USER_LIST: UserListInterface = {
  [COACH]: "coaches",
  [PLAYER]: "players",
  [BATCH]: "batches",
};
export const COACH_SESSION_TYPES = [
  { name: "Post-Session", field: "post_session" },
];

export const PLAYER_SESSION_TYPES = [
  { name: "Morning", field: "morning" },
  { name: "Pre-Session", field: "pre_session" },
  { name: "Post-Session", field: "post_session" },
];

export const MAX_DISPLAY_PAGES_COUNT = 5;
export const TRIGGER_EVENT_MORNING = "morning";

export const IMAGES_TO_BE_PRELOADED: string[] = [logo, bgImage];

export const GUIDELINES: string = "Guidelines";
export const PAGE_NOT_FOUND_ERROR = 404;
