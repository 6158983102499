export const MESSAGES = {
  NOTIFICATION_SUCCESS: "Your notification has been sent!",
  SESSION_EXPIRED:
    "Your session has timed out. Please log in to keep using the application.",
  NO_ACCESS:
    "It looks like you don't have access for this action. Reach out to support for help.",
  NOT_FOUND:
    "Oops! We couldn’t find what you were looking for. Please try again.",
  DEFAULT_ERROR:
    "An error occurred while adding the notification. Please try again.",
  UNEXPECTED_ERROR:
    "An error occurred unexpectedly. Try again, or contact support for assistance",
};
