import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Tab, Tabs } from "Components/Core";

import {
  //DisplayListComponent,
  HeaderComponent,
  SurveyHistory,
} from "Components/Shared";
import PersonalDetails from "Components/Shared/UserDetailsComponent/PersonalDetails";
import { getUserData } from "Api/Pages/BatchesApi";
import {
  //MATCH_PLAYED_TITLE,
  MATCH_PLAY_HISORY,
  MATCH_PLAY_HISTORY_TITLE,
  PERSONAL_DETAILS,
  PERSONAL_DETAILS_TITLE,
  MY_SCHEDULE,
  MY_SCHEDULE_TITLE,
  SURVEY_HISTORY,
  SURVEY_HISTORY_TITLE,
  UserTypeMap,
  MORNING,
  VIDEOS,
  COMMENTS,
  COMMENT_TABLE_HEADERS,
  BATCH_PERFORMANCE,
} from "Components/Shared/UserDetailsComponent/constants";
import { UserDetailsComponentPropsType } from "Interfaces/Components/Shared/UserDetailsComponentInterface";

//import {PlayerDataType} from "Interfaces/Components/Shared/ScheduleInterface"
import {
  BATCH,
  COACH,
  COACH_SESSION_TYPES,
  EDIT,
  INITIAL_PAGE,
  // MATCH_PLAYED,
  PLAYER,
  PLAYER_SESSION_TYPES,
} from "App/constants";
import { useAppDispatch, useAppSelector } from "App/hooks";
import { setSelectedTab } from "App/commonReducer";
import VideoListComponent from "../VideoListComponent";
import VirtualTableComponent from "../VirtualTableComponent";
import Schedule from "../Schedule";
import MatchPlayHistory from "../MatchPlayHistory";
import BatchPerformance from "Pages/BatchPerformance";

const UserDetailsComponent: React.FC<UserDetailsComponentPropsType> = ({
  batchUserId,
  batchUserType,
  hideHeader,
}) => {
  const userTypeArray = window.location.href.split("/");
  let userType: string = batchUserType;
  if (!batchUserType) {
    if (userTypeArray.includes("batches")) userType = BATCH;
    else if (userTypeArray.includes("players")) userType = PLAYER;
    else if (userTypeArray.includes("coaches")) userType = COACH;
  }

  const { id } = useParams();
  const [userData, setUserData] = useState<any>({});
  const [currentPage, setCurrentPage] = useState<number>(INITIAL_PAGE);

  const tabs: string[] =
    userType === PLAYER
      ? PLAYER_SESSION_TYPES.map((data) => data.name)
      : COACH_SESSION_TYPES.map((data) => data.name);
  const {
      userDetails: { academy_id },
    } = useAppSelector((state) => state.loginReducer),
    navigate = useNavigate(),
    dispatch = useAppDispatch(),
    { selectedTab } = useAppSelector((state) => state.commonReducer);
  const userId = batchUserId ? batchUserId : id;

  const fetchPlayerData = useCallback(
    async (id: number) => {
      const data = await getUserData({
        academyId: academy_id,
        id,
        userType: userType,
      });
      data && setUserData(data);
    },
    [setUserData, academy_id, userType]
  );

  useEffect(() => {
    if (userId) {
      fetchPlayerData(+userId);
    }
  }, [userId, fetchPlayerData]);

  return (
    <div className="ss-page-content d-flex flex-column flex-1 scroll-y bg-white">
      {!hideHeader && <HeaderComponent title={`${userType} Details`} />}
      <div className="ss-page-body d-flex flex-column flex-1 scroll-y">
        <Tabs
          activeKey={selectedTab[userType]}
          mountOnEnter={true}
          unmountOnExit={true}
          onSelect={(eventKey: string | null) =>
            eventKey &&
            dispatch(setSelectedTab({ ...selectedTab, [userType]: eventKey }))
          }
          className="ss-tabs mx-4"
        >
          <Tab
            eventKey={PERSONAL_DETAILS}
            title={PERSONAL_DETAILS_TITLE}
            className="ss-tab-content p-4"
          >
            <PersonalDetails {...userData} userType={userType} />
          </Tab>
          <Tab
            eventKey={MY_SCHEDULE}
            title={MY_SCHEDULE_TITLE}
            className="ss-tab-content p-4"
          >
            <Schedule
              Userdata={userData}
              userId={userData?.user_id}
              UserType={userType}
            ></Schedule>
          </Tab>
          <Tab
            eventKey={MATCH_PLAY_HISORY}
            title={MATCH_PLAY_HISTORY_TITLE}
            className="ss-tab-content p-4"
          >
            <MatchPlayHistory
              Userdata={userData}
              userId={userData?.user_id}
              UserType={userType}
            ></MatchPlayHistory>
          </Tab>

          <Tab
            eventKey={SURVEY_HISTORY}
            title={SURVEY_HISTORY_TITLE}
            className="ss-tab-content p-4 h-100"
          >
            <SurveyHistory
              title={userType}
              TABS={
                !userData?.academy_batches?.length
                  ? userType === PLAYER
                    ? [MORNING]
                    : [COACH_SESSION_TYPES[0].name]
                  : tabs
              }
              batches={userData?.academy_batches}
              userId={batchUserId}
            />
          </Tab>
          {userType === PLAYER && (
            <Tab
              eventKey={BATCH_PERFORMANCE}
              title={BATCH_PERFORMANCE}
              className="ss-tab-content p-4"
            >
              <BatchPerformance
                Userdata={userData}
                Id={userData?.id}
                UserType={userType}
              ></BatchPerformance>
            </Tab>
          )}
          {/* <Tab
            eventKey={MATCH_PLAYED}
            title={MATCH_PLAYED_TITLE}
            className="ss-tab-content p-4"
          >
            <DisplayListComponent
              title={MATCH_PLAYED}
              matchPlayedUserType={userType}
              isMatchPlayed={true}
              id={batchUserId ? batchUserId : id}
            />
          </Tab> */}
          {userType === PLAYER && (
            <Tab
              eventKey={VIDEOS}
              title={VIDEOS}
              className="ss-tab-content p-4"
            >
              <VideoListComponent playerID={id} />
            </Tab>
          )}
          {userType === PLAYER && (
            <Tab
              eventKey={COMMENTS}
              title={COMMENTS}
              className="ss-tab-content p-4"
            >
              <VirtualTableComponent
                columns={COMMENT_TABLE_HEADERS}
                tableData={userData?.comments || []}
                onRowClick={() => {}}
                hideThead={false}
                hideColWidth={false}
                isClickAble={false}
                paginationProps={{
                  totalCount: userData?.comments?.length || 0,
                  currentPage: currentPage,
                  numberOfRowPerPage: 10,
                  setPageNumber: setCurrentPage,
                }}
              />
            </Tab>
          )}
        </Tabs>
      </div>
      {!hideHeader && selectedTab[userType] === PERSONAL_DETAILS && (
        <footer className="ss-page-footer d-flex align-items-center px-4 py-3 gap-3">
          {
            <Button
              variant="secondary"
              size="sm"
              className="px-4"
              onClick={() => {
                navigate(`/${UserTypeMap[userType]}/${id}/edit`, {
                  state: { isEditUser: true, userType: userType },
                });
              }}
            >
              {EDIT}
            </Button>
          }
        </footer>
      )}
    </div>
  );
};
export default UserDetailsComponent;
