import axios from "axios";
import { BASE_URL } from "App/constants";
import {
  QuestionParamObjectType,
  AddQuestionParam,
  UpdateQuestionParam,
} from "Interfaces/AppInterface";

export const getQuestionsList: Function = async ({
  page,
  masterSportId,
  applicableFor,
  triggerEvent,
  questionId,
}: QuestionParamObjectType) => {
  const queryParams = `?page=${page}&master_sport_id=${masterSportId}&applicable_for=${applicableFor}&trigger_event=${triggerEvent}`;
  const { data } = await axios.get(
    `${BASE_URL}survey_questions${questionId ? `/${questionId}` : queryParams}`
  );
  if (data) {
    return data;
  }
  return [];
};

export const AddQuestion = async ({
  academyId,
  param,
  isEditUser,
}: AddQuestionParam) => {
  await axios.post(`${BASE_URL}survey_questions`, {
    survey_question: { ...param },
  });

  return [];
};
export const UpdateQuestion = async ({
  questionId,
  param,
  isEditUser,
}: UpdateQuestionParam) => {
  return await axios
    .put(
      `${BASE_URL}survey_questions/${questionId}${
        isEditUser ? "" : "/deactivate"
      }`,
      isEditUser && {
        survey_question: { ...param },
      }
    )
    .catch((error) => error.response);
};
