import { useCallback, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import { Button, Row, Col, Form, InputGroup } from "Components/Core";
import {
  MultiSelect,
  SelectSports,
  HeaderComponent,
  DisplayErrorMessage,
  SearchSelect,
} from "Components/Shared";
import { getAllCoach } from "Api/Pages/CoachesApi";
import { addBatch } from "Api/Pages/BatchesApi";
import { AddBatchErrorObjectType } from "Interfaces/ValidationInterface";
import {
  BatchFormComponentPropsType,
  BatchUserObjectType,
} from "Interfaces/Pages/BatchesInterface";
import { CoachObjectType } from "Interfaces/Pages/CoachesInterface";
import {
  BATCH_FORM_CONSTANTS,
  DaysItems,
  getEndTimeValue,
  TIME_RANGES,
  BatchLevels,
} from "Pages/Batches/constants";
import { addBatchErrorInitialValues } from "App/Validations/constants";
import { ADD, CANCEL, CLEAR, SAVE } from "App/constants";
import { history } from "App";
import { validateAddBatch } from "App/Validations/utils";
import {
  checkOldAndCurrentData,
  DATE_FORMAT,
  titleCaseConversion,
} from "Utils/utils";
import { useAppDispatch } from "App/hooks";
import { setToastMessage } from "App/commonReducer";
import calenderIcon from "App/images/calendar-icon.svg";
import clockIcon from "App/images/clock-icon.svg";
import "Pages/Batches/batch.scss";
import "./batchForm.scss";

const BatchFormComponent: React.FC<BatchFormComponentPropsType> = ({
  title,
  isEditBatch,
  batchData,
}) => {
  const [batchDetails, setBatchDetails] = useState<any>({
      ...batchData,
    }),
    [coachList, setCoachList] = useState<CoachObjectType[]>([]),
    [selectedDays, setSelectedDays] = useState<string[]>([...batchData.days]),
    [searchInput, setSearchInput] = useState<string>(""),
    [selectedCoaches, setSelectedCoaches] = useState<BatchUserObjectType[]>([
      { coach_id: "", _destroy: false },
    ]),
    dispatch = useAppDispatch(),
    navigate = useNavigate(),
    {
      name,
      details,
      end_date,
      end_time,
      fee_structure,
      level,
      sport_id,
      start_date,
      start_time,
    } = batchDetails,
    [formError, setFormError] = useState<AddBatchErrorObjectType>({
      ...addBatchErrorInitialValues,
    }),
    {
      NameMsg,
      LevelMsg,
      FeeStructureMsg,
      SportMsg,
      StartTimeMsg,
      CoachMsg,
      EndTimeMsg,
    } = formError,
    {
      NAME,
      ACTIVE,
      ADD_AMOUNT,
      ENTER_DETAILS,
      SELECT_ATLEAST_ONE_DAY,
      BATCHES_ROUTE,
      END_TIME,
      START_TIME,
      SELECT_END_DATE,
      SELECT_START_DATE,
      END_DATE_SHOULD_GRETER_THAN_START_DATE,
      SELECT_LEVEL,
    } = BATCH_FORM_CONSTANTS,
    updateField = (field: string, value: any) => {
      batchDetails[field] = value;
      setBatchDetails({ ...batchDetails });
    },
    fetchCoachList = useCallback(async () => {
      const list: CoachObjectType[] = await getAllCoach(sport_id, searchInput);
      setCoachList(list);
      !isEditBatch && setSelectedCoaches([{ coach_id: "", _destroy: false }]);
    }, [isEditBatch, sport_id, searchInput]),
    checkAndAddToastMessage = () => {
      const toastMessage: string[] = [];
      if (!start_date) {
        toastMessage.push(SELECT_START_DATE);
      }
      if (!end_date) {
        toastMessage.push(SELECT_END_DATE);
      }
      if (!selectedDays.length) {
        toastMessage.push(SELECT_ATLEAST_ONE_DAY);
      }
      if (toastMessage.length) {
        dispatch(setToastMessage(toastMessage));
        return true;
      }
      return false;
    },
    onAddBatch = async () => {
      batchDetails.days = selectedDays;
      if (!isEditBatch) {
        batchDetails.batch_coaches = [...selectedCoaches];
      }

      const formError = await validateAddBatch({
        NameMsg: name,
        LevelMsg: level,
        SportMsg: `${sport_id}`,
        FeeStructureMsg: fee_structure,
        StartTimeMsg: start_time.toString(),
        EndTimeMsg: end_time.toString(),
        CoachMsg: `${selectedCoaches[0]?.coach_id}`,
      });

      if (`${Object.values(formError)}`.replaceAll(",", "") !== "") {
        setFormError(formError);
        return false;
      } else if (new Date(start_date) > new Date(end_date)) {
        dispatch(setToastMessage([END_DATE_SHOULD_GRETER_THAN_START_DATE]));
        setFormError(formError);
        return false;
      } else if (checkAndAddToastMessage()) {
        setFormError(formError);
        return false;
      }

      await addBatch({ param: batchDetails, isEditBatch });
      navigate(BATCHES_ROUTE);
    };

  const onAddCoach = useCallback(
      (selected: CoachObjectType) => {
        selectedCoaches[0] = { coach_id: selected.id, _destroy: false };
        setSelectedCoaches([...selectedCoaches]);
      },
      [selectedCoaches, setSelectedCoaches]
    ),
    onDaySelect = (dayItem: string) => {
      if (selectedDays.includes(dayItem)) {
        selectedDays.splice(selectedDays.indexOf(dayItem), 1);
      } else {
        selectedDays.push(dayItem);
      }
      setSelectedDays([...selectedDays]);
    },
    onClearBatch = () => {
      if (isEditBatch) {
        history.back();
      } else {
        setBatchDetails({ ...batchData });
        setSelectedCoaches([{ coach_id: "", _destroy: false }]);
      }
    };

  useEffect(() => {
    !isEditBatch && sport_id && fetchCoachList();
  }, [fetchCoachList, sport_id, isEditBatch]);

  useEffect(() => {
    isEditBatch && setSelectedCoaches([batchData.batch_coaches]);
  }, [isEditBatch, batchData]);

  return (
    <div className="ss-page-content d-flex flex-column flex-1 scroll-y bg-white">
      <HeaderComponent title={title} />
      <div className="ss-page-body d-flex flex-column flex-1 scroll-y">
        <Form className="d-flex flex-column flex-1 scroll-y p-4 gap-4">
          <div className="d-grid gap-4 px-4">
            {isEditBatch && (
              <Row>
                <Col lg={5}>
                  <Form.Group controlId="name">
                    <Form.Label className="fs-xs">Name</Form.Label>
                    <Form.Control
                      placeholder={NAME}
                      value={name}
                      onChange={(e) => updateField("name", e.target.value)}
                      isInvalid={NameMsg !== ""}
                    />
                    <DisplayErrorMessage errorField={NameMsg} />
                  </Form.Group>
                </Col>
              </Row>
            )}
            <Row>
              <Col lg={5}>
                <Row>
                  <Col lg={6}>
                    <Form.Group controlId="selectLevel">
                      <Form.Label className="fs-xs">Level *</Form.Label>
                      <Form.Select
                        className="bg-white"
                        disabled={!!isEditBatch}
                        onChange={(
                          event: React.ChangeEvent<HTMLSelectElement>
                        ) => {
                          const {
                            target: { value },
                          } = event;
                          updateField("level", value);
                        }}
                        isInvalid={LevelMsg !== ""}
                      >
                        <option
                          disabled
                          value=""
                          key=""
                          selected={level === ""}
                        >
                          {SELECT_LEVEL}
                        </option>
                        {BatchLevels.map((levelItem: string) => (
                          <option
                            key={levelItem}
                            value={levelItem}
                            selected={levelItem === level}
                          >
                            {titleCaseConversion(levelItem)}
                          </option>
                        ))}
                      </Form.Select>
                      <DisplayErrorMessage errorField={LevelMsg} />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group controlId="feeStructure">
                      <Form.Label className="fs-xs">Fee Structure *</Form.Label>
                      <Form.Control
                        placeholder={ADD_AMOUNT}
                        value={fee_structure}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          const {
                            target: { value },
                          } = event;
                          updateField("fee_structure", value);
                        }}
                        isInvalid={FeeStructureMsg !== ""}
                      />
                      <DisplayErrorMessage errorField={FeeStructureMsg} />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col lg={5}>
                <SelectSports
                  selected={sport_id}
                  shouldShowAll={false}
                  isDisableDropdown={!!isEditBatch}
                  placeHolder="Select Sport"
                  handleSelect={(event: any) => {
                    updateField("sport_id", event.target.value);
                  }}
                  className="flex-1 batch-select-wrap"
                  isRequired={true}
                  SportMsg={SportMsg}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={5}>
                <Form.Label className="fs-xs">Batch Period *</Form.Label>
                <div className="d-flex align-items-center gap-2">
                  <div className="flex-1">
                    <DatePicker
                      className="form-control flex-1"
                      selected={start_date ? new Date(start_date) : null}
                      placeholderText={DATE_FORMAT}
                      selectsStart
                      minDate={new Date()}
                      onChange={(date: any) => {
                        updateField("start_date", date);
                      }}
                    />
                  </div>
                  <img src={calenderIcon} alt="calender" />
                  <span>-</span>
                  <div className="flex-1">
                    <DatePicker
                      className="form-control flex-1"
                      placeholderText={DATE_FORMAT}
                      selected={end_date ? new Date(end_date) : null}
                      selectsEnd
                      minDate={new Date(start_date)}
                      onChange={(date: any) => {
                        updateField("end_date", date);
                      }}
                    />
                  </div>
                  <img src={calenderIcon} alt="calender" />
                </div>
              </Col>
              <Col lg={5}>
                <Form.Label className="fs-xs">Batch Time *</Form.Label>
                <Form.Group controlId="endTime" className="flex-1">
                  <div className="d-flex align-items-center gap-2">
                    <InputGroup className="batch-time-wrap">
                      <Form.Select
                        className="ss-select-control px-2 w-50"
                        value={start_time}
                        onChange={(
                          event: React.ChangeEvent<HTMLSelectElement>
                        ) => {
                          const {
                            target: { value },
                          } = event;
                          updateField("start_time", value);
                          updateField("end_time", getEndTimeValue(value));
                        }}
                        isInvalid={StartTimeMsg !== ""}
                      >
                        {TIME_RANGES.map((time, ind) => {
                          return (
                            <option
                              value={time.value}
                              key={`${time}${ind}`}
                              selected={start_time === time}
                            >
                              {time.label}
                            </option>
                          );
                        })}
                        <option
                          value=""
                          key=""
                          selected={start_time === ""}
                          disabled
                        >
                          {START_TIME}
                        </option>
                      </Form.Select>
                      <img src={clockIcon} alt="clock" className="ms-2" />
                      <DisplayErrorMessage errorField={StartTimeMsg} />
                    </InputGroup>
                    <span>-</span>
                    <InputGroup className="batch-time-wrap">
                      <Form.Select
                        className="ss-select-control px-2 w-50"
                        value={end_time}
                        onChange={(
                          event: React.ChangeEvent<HTMLSelectElement>
                        ) => {
                          const {
                            target: { value },
                          } = event;
                          updateField("end_time", value);
                        }}
                        isInvalid={EndTimeMsg !== ""}
                      >
                        {TIME_RANGES.map((time, ind) => {
                          return (
                            <option
                              value={time.value}
                              key={`${time}${ind}`}
                              selected={end_time === time}
                            >
                              {time.label}
                            </option>
                          );
                        })}
                        <option
                          value=""
                          key=""
                          selected={end_time === ""}
                          disabled
                        >
                          {END_TIME}
                        </option>
                      </Form.Select>
                      <img src={clockIcon} alt="clock" className="ms-2" />
                      <DisplayErrorMessage errorField={EndTimeMsg} />
                    </InputGroup>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label className="fs-xs">Select Days *</Form.Label>
                <div className="d-flex gap-2 flex-wrap">
                  {DaysItems.map((dayItem, i) => (
                    <MultiSelect
                      multiSelectText={dayItem}
                      className={selectedDays.includes(dayItem) ? ACTIVE : ""}
                      key={i}
                      onClick={() => onDaySelect(dayItem)}
                    />
                  ))}
                </div>
              </Col>
              <DisplayErrorMessage errorField={EndTimeMsg} />
            </Row>
          </div>
          {!isEditBatch && (
            <div className="sports-details-wrap d-grid gap-4 p-4">
              <Row>
                <Col lg={4}>
                  <div className="d-flex gap-2">
                    <Form.Group className="flex-1">
                      <Form.Label className="fs-xs">Select Coach *</Form.Label>
                      <SearchSelect
                        optionsList={coachList.map((data: CoachObjectType) => ({
                          value: data,
                          label: data.name,
                        }))}
                        setSearchString={setSearchInput}
                        setSelected={onAddCoach}
                        selected={{
                          value: batchData?.batch_coaches[0],
                          label: batchData?.batch_coaches[0]?.name,
                        }}
                        placeholder="Select Coach"
                        errorMessage={CoachMsg}
                        disabled={!sport_id}
                      />
                    </Form.Group>
                  </div>
                </Col>
              </Row>
            </div>
          )}
          <div className="px-4">
            <Row>
              <Col lg={5}>
                <Form.Group controlId="textarea">
                  <Form.Label className="fs-xs">
                    More Details About Batch
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    className="border"
                    placeholder={ENTER_DETAILS}
                    value={details || ""}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const {
                        target: { value },
                      } = e;
                      updateField("details", value);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
      <footer className="ss-page-footer d-flex align-items-center px-4 py-3 gap-3">
        <Button
          variant="outline-secondary"
          size="sm"
          className="px-4"
          onClick={onClearBatch}
        >
          {isEditBatch ? CANCEL : CLEAR}
        </Button>
        <Button
          variant="secondary"
          size="sm"
          className="px-4"
          onClick={onAddBatch}
          disabled={
            isEditBatch &&
            checkOldAndCurrentData(batchData, batchDetails) &&
            checkOldAndCurrentData(selectedDays, batchData.days)
          }
        >
          {isEditBatch ? SAVE : ADD}
        </Button>
      </footer>
    </div>
  );
};

export default BatchFormComponent;
