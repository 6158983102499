import axios from "axios";
import {
  ASSET_TYPE_ENUM,
  UPLOAD_PURPOSE_ENUM,
  ResolutionConfigType,
  SizeConfigType,
  VideoConfigInterface,
} from "Components/Shared/UploadAsset/types";
import { formatBytes } from "Utils/helper";
import { getAssetSignature } from "./VideoListApi";

const cloudinaryConfig = {
  HOST: "https://api.cloudinary.com",
  VERSION: "v1_1",
  ACTION: "upload",
};

const getCloudinaryRequestURL = (
  cloudName: string,
  ofType: ASSET_TYPE_ENUM
) => {
  return `${cloudinaryConfig.HOST}/${cloudinaryConfig.VERSION}/${cloudName}/${ofType}/${cloudinaryConfig.ACTION}`;
};

const uploadFile = async (
  file: any,
  cloudinaryConfig: {
    ofType: ASSET_TYPE_ENUM;
    cloudName: string;
    apiKey: string;
    purpose: UPLOAD_PURPOSE_ENUM;
  },
  uploadProgress: (progressEvent: any) => void // will update progress percentage
) => {
  try {
    const dt = new Date();
    let timestamp = Math.round(dt.getTime() / 1000);
    let public_id = `${cloudinaryConfig.purpose}/${dt.getFullYear()}/${
      dt.getMonth() + 1
    }/${dt.getDate()}/${timestamp}`;

    // Generating signature from server to upload asset over cloudinary
    const { signature } = await getAssetSignature(timestamp, public_id);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("api_key", cloudinaryConfig.apiKey);
    formData.append("timestamp", String(timestamp));
    formData.append("public_id", public_id);

    formData.append("signature", signature);

    const config = {
      onUploadProgress: uploadProgress,
    };
    const response = await axios.post(
      getCloudinaryRequestURL(
        cloudinaryConfig.cloudName,
        cloudinaryConfig.ofType
      ),
      formData,
      config
    );
    return response;
  } catch (error) {
    console.error("Failed to upload asset over cloudinary : ", error);
  }
};

export const download = (data: any, filename: string) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(link.href);
};

export const getFileType = (fileType: string) => {
  if (fileType.startsWith("image/")) {
    return ASSET_TYPE_ENUM.image;
  }
  if (fileType.startsWith("video/")) {
    return ASSET_TYPE_ENUM.video;
  }
  return "";
};

export const isFileSizeValid = (file: any, sizeConfig: SizeConfigType) => {
  if (file.type.includes(ASSET_TYPE_ENUM.image)) {
    if (file.size < sizeConfig.imageMaxSize) {
      return true; // valid file
    }
    return `Max allowed image size is : ${formatBytes(
      sizeConfig.imageMaxSize
    )} `;
  }

  if (file.type.includes(ASSET_TYPE_ENUM.video)) {
    if (file.size < sizeConfig.videoMaxSize) {
      return true; // valid file
    }
    return `Max allowed video size is : ${formatBytes(
      sizeConfig.videoMaxSize
    )} `;
  }
};

export const getSizeConfig = (
  assetConfig: VideoConfigInterface | undefined
) => {
  return {
    videoMaxSize: assetConfig?.video.max_size_bytes || 0,
    videoMinSize: assetConfig?.video.min_size_bytes || 0,
    imageMaxSize: assetConfig?.image.max_size_bytes || 0,
    imageMinSize: assetConfig?.image.min_size_bytes || 0,
  };
};

export const getResolutionConfig = (
  assetConfig: VideoConfigInterface | undefined
): ResolutionConfigType => {
  return {
    imageWidth: assetConfig?.image.resolution.width_pixels || 0,
    imageHeight: assetConfig?.image.resolution.width_pixels || 0,
    videoWidth: assetConfig?.video.resolution.width_pixels || 0,
    videoHeight: assetConfig?.video.resolution.width_pixels || 0,
  };
};

export const validateImageResolution = (
  file: File,
  acceptedResolution: {
    height: number;
    width: number;
  }
) => {
  return new Promise((resolve, reject) => {
    try {
      const img = new Image();
      const objectUrl = window.URL.createObjectURL(file);
      img.onload = function () {
        window.URL.revokeObjectURL(objectUrl);
        if (
          //@ts-ignore for this
          this.height < acceptedResolution.height ||
          //@ts-ignore for this
          this.width < acceptedResolution.width
        ) {
          resolve(true);
        }

        resolve(
          `Max resolution is allowed upto ${acceptedResolution.width} * ${acceptedResolution.height}`
        );
      };
      img.src = objectUrl;
    } catch (_) {
      reject(false);
    }
  });
};

export default uploadFile;
