import React from "react";
import { TileProps } from "Interfaces/Components/Shared/TileInterface";
import "./tile.scss";

const Tile: React.FC<TileProps> = ({ tileCount, tileTitle, onClick }) => {
  return (
    <div className="tile px-3 py-4" onClick={onClick}>
      <h6 className="fs-md fw-bold">{tileCount}</h6>
      <p className="fs-sm mb-0 text-truncate">{tileTitle}</p>
    </div>
  );
};

export default Tile;
