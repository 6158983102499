import {
  SurveyBatchProps,
  SurveyHistoryColumnsType,
  SurveyHistoryComponentFilterKey,
  SurveyHistoryComponentFilterList,
  SurveyHistoryObjectInterface,
} from "Interfaces/Components/Shared/SurveyHistoryInterface";

export const COLUMNS: SurveyHistoryColumnsType[] = [
  {
    dataField: "questions",
    title: "Questions",
    formatter: (props: any) => {
      return props?.survey_question?.title;
    },
  },
  {
    dataField: "answer",
    title: "Answer",
  },
];

export const COACH_SURVEY: string = "Coach Survey";
export const PLAYER_SURVEY: string = "Player Survey";
export const BATCH_NAME: string = "Batch Name";
export const TRIGGER_EVENT: string = "Trigger Event:";
export const DATE: string = "Date:";
export const Coach: string = "coach";

export const SURVEY_BATCH_OBJECT: SurveyBatchProps = {
  name: "",
  value: "",
  status: "",
};

export const SURVEY_HISTORY_LIST_OBJECT: SurveyHistoryObjectInterface = {
  id: 0,
  date: "",
  is_attended: false,
};

export const DEFAULT_FILTER_KEY: SurveyHistoryComponentFilterKey = {
  showModal: false,
  currentPage: 1,
  totalPages: 1,
  selectedBatchId: "",
  triggerEvent: "",
};
export const DEFAULT_FILTER_LIST: SurveyHistoryComponentFilterList = {
  assignedBatches: [],
  surveyHistoryList: [],
  surveyHistoryDetails: [],
};

export const NO_DATA_FOUND: string = "No Data Found";
