import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "Components/Core";
import { CheckboxItem, HeaderComponent } from "Components/Shared";
import { addSportList, getMasterSportsList } from "Api/Pages/SportsApi";
import { MasterSportObjectType } from "Interfaces/Pages/SportsInterface";
import { useAppSelector } from "App/hooks";
import { ADD, CLEAR } from "App/constants";

const AddSports = () => {
  const [masterSportsList, setMasterSportsList] = useState<
      MasterSportObjectType[]
    >([]),
    {
      userDetails: { academy_id },
    } = useAppSelector((state) => state.loginReducer),
    [isListEmpty, setIsListEmpty] = useState(false),
    navigate = useNavigate();
  const selectedSports = localStorage.getItem("selected_sports");
  const fetchSportsList = useCallback(async () => {
      const data = await getMasterSportsList();
      if (data) {
        // eslint-disable-next-line
        const sportsList = data.filter((elem: any) => {
          if (
            selectedSports &&
            !JSON.parse(selectedSports).includes(elem.name)
          ) {
            return { ...elem, isChecked: false };
          }
        });
        if (sportsList && sportsList.length) {
          setMasterSportsList(sportsList);
          setIsListEmpty(false);
        }
      }
    }, [selectedSports]),
    onSelect = (index: number) => {
      masterSportsList[index].isChecked = !masterSportsList[index].isChecked;
      setMasterSportsList([...masterSportsList]);
    },
    selectedSportIds = () => {
      return masterSportsList
        .filter((sport) => sport.isChecked)
        ?.map((sport: MasterSportObjectType) => sport.id);
    },
    onAddClick = async () => {
      await addSportList({ ids: selectedSportIds(), academyId: academy_id });
      navigate("/sports");
    },
    onClearSelection = () => {
      masterSportsList.forEach((el) => (el.isChecked = false));
      setMasterSportsList([...masterSportsList]);
    },
    getSportElements = () => {
      const sportElements = masterSportsList?.filter(
        // eslint-disable-next-line
        ({ image_link, name, isChecked }, i) => {
          if (!(selectedSports && JSON.parse(selectedSports).includes(name)))
            return { image_link, name, isChecked };
        }
      );
      if (sportElements.length) {
        return sportElements.map(({ image_link, name, isChecked }, i) => {
          return (
            <CheckboxItem
              key={name + i}
              name={name}
              src={image_link}
              checkboxName={name}
              isChecked={isChecked}
              onClick={() => onSelect(i)}
            />
          );
        });
      } else {
        !isListEmpty && setIsListEmpty(true);
        return (
          <p className="mb-0 fw-bold">All the sports are already added.</p>
        );
      }
    };

  useEffect(() => {
    fetchSportsList();
  }, [fetchSportsList]);

  return (
    <div className="ss-page-content d-flex flex-column flex-1 scroll-y bg-white">
      <HeaderComponent title={"Create New Sport"} />
      <div className="ss-page-body d-flex flex-column flex-1 scroll-y p-4">
        <div className="sports-list d-flex flex-wrap gap-3">
          {getSportElements()}
        </div>
      </div>

      {!isListEmpty && (
        <footer className="ss-page-footer d-flex align-items-center px-4 py-3 gap-3">
          <Button
            variant="outline-secondary"
            size="sm"
            className="px-4"
            onClick={onClearSelection}
            disabled={!selectedSportIds().length}
          >
            {CLEAR}
          </Button>
          <Button
            variant="secondary"
            size="sm"
            className="px-4"
            disabled={!selectedSportIds().length}
            onClick={onAddClick}
          >
            {ADD}
          </Button>
        </footer>
      )}
    </div>
  );
};

export default AddSports;
