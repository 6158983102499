import { Button } from "Components/Core";
import { PatchSubmissionVideoActionParam } from "Interfaces/AppInterface";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { EMPTY_LABEL } from "App/constants";
import { AssetSubmissionVideo } from "Interfaces/Components/Shared/AssetSubmissionVideos";

export const getSubmissionVideoColumns = (
  performAction: (params: PatchSubmissionVideoActionParam) => void,
  isLoading: boolean
) => [
  {
    dataField: "video",
    title: "Video",
    colWidth: "20%",
    formatter: (data: AssetSubmissionVideo) => {
      const { thumbnail_url } = data;

      return (
        <div className="thumbnail-box position-relative">
          <img src={thumbnail_url} alt={`video thumbnail ${data.title}`} />
          <FontAwesomeIcon
            icon={faPlay}
            color="white"
            className="position-absolute fs-5 icon-media"
          />
        </div>
      );
    },
  },
  {
    dataField: "title",
    title: "Name",
    colWidth: "30%",
  },
  {
    dataField: "description",
    title: "Description",
    colWidth: "30%",
    formatter: (data: AssetSubmissionVideo) => {
      return data.description ? data.description : EMPTY_LABEL;
    },
  },
  {
    dataField: "master_sport_name",
    title: "Sport",
    colWidth: "10%",
  },
  {
    dataField: "uploaded_by",
    title: "Uploaded by",
    colWidth: "10%",
  },
  {
    title: "Action",
    colWidth: "10%",
    formatter: (data: AssetSubmissionVideo) => {
      return (
        <div className="d-flex gap-2">
          <Button
            variant="success"
            disabled={isLoading}
            onClick={(e) => {
              e.stopPropagation();
              performAction({ videoId: data.id, action: "approved" });
            }}
          >
            Approve
          </Button>
          <Button
            variant="danger"
            disabled={isLoading}
            onClick={(e) => {
              e.stopPropagation();
              performAction({ videoId: data.id, action: "rejected" });
            }}
          >
            Reject
          </Button>
        </div>
      );
    },
  },
];
