export const BatchPerformancecolumns = [
  { title: "Name", dataField: "name", colWidth: "30%" },
  { title: "Attendance", dataField: "attendance", colWidth: "10%" },
  { title: "Batch Coach Name", dataField: "batch_coach_name", colWidth: "20%" },
  {
    title: "Best Rating(%)",
    dataField: "best_rating_percentage",
    colWidth: "10%",
  },
  {
    title: "Least Rating(%)",
    dataField: "least_rating_percentage",
    colWidth: "10%",
  },
  { title: "Performance", dataField: "performance", colWidth: "10%" },
  { title: "Survey Activity", dataField: "survey_activity", colWidth: "10%" },
];
