import React from "react";
import { SkillTylePropsType } from "Interfaces/Components/Shared/SkillsTileInterface";

const SkillsTile: React.FC<SkillTylePropsType> = (props) => {
  const { skill_coins } = props;
  return (
    <>
      <p className="fs-xs mb-2">Skill Coin</p>
      <h2 className="mb-0 fw-bold fs-large">{skill_coins}</h2>
    </>
  );
};

export default SkillsTile;
