import AccordionBody from "react-bootstrap/esm/AccordionBody";
import AccordionHeader from "react-bootstrap/esm/AccordionHeader";
import AccordionItem from "react-bootstrap/esm/AccordionItem";
import { Row, Col, Form } from "Components/Core";
import { PlayerDemographicFormPropsType } from "Interfaces/Components/Shared/UserDetailsFormInterface";
import { DisplayErrorMessage } from "../ErrorMessage/DisplayErrorMessage";
import SearchSelect from "../SearchSelect";

const PlayerDemographicForm = (props: PlayerDemographicFormPropsType) => {
  const {
    userDetails,
    fieldError,
    handleOnChange,
    educationalQualificationList,
    setUserDetails,
  } = props;

  const {
    fathers_name,
    mothers_name,
    aadhar_number,
    educational_qualification,
    nationality,
    state,
    address,
  } = userDetails;

  const { aadhar_number_error } = fieldError;

  const onEducationalQualificationChange = (qualificationSelected: string) => {
    userDetails.educational_qualification = qualificationSelected;
    setUserDetails({ ...userDetails });
  };

  const isInvalid = !!aadhar_number_error;

  return (
    <AccordionItem eventKey="demographic-details">
      <AccordionHeader>
        <span className={"fw-bold" + (isInvalid ? " text-danger" : "")}>
          Demographic details
        </span>
      </AccordionHeader>
      <AccordionBody>
        <div className="d-grid gap-4">
          <Row>
            <Form.Group controlId="fathers_name" as={Col} lg={4}>
              <Form.Label className="fs-xs">Fathers Name</Form.Label>
              <Form.Control
                placeholder="Fathers Name"
                name="fathers_name"
                value={fathers_name}
                onChange={handleOnChange}
              />
            </Form.Group>
            <Form.Group controlId="mothers_name" as={Col} lg={4}>
              <Form.Label className="fs-xs">Mothers Name</Form.Label>
              <Form.Control
                placeholder="Mothers Name"
                name="mothers_name"
                value={mothers_name}
                onChange={handleOnChange}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group controlId="aadhar_number" as={Col} lg={4}>
              <Form.Label className="fs-xs">Aadhar Number</Form.Label>
              <Form.Control
                type="number"
                placeholder="Aadhar Number"
                name="aadhar_number"
                value={aadhar_number}
                onChange={handleOnChange}
                isInvalid={!!aadhar_number_error}
              />
              <DisplayErrorMessage errorField={aadhar_number_error} />
            </Form.Group>
            <Form.Group controlId="educational_qualification" as={Col} lg={4}>
              <Form.Label className="fs-xs">
                Educational Qualification
              </Form.Label>
              <SearchSelect
                optionsList={educationalQualificationList.map(
                  (qualification: string) => ({
                    value: qualification,
                    label: qualification,
                  })
                )}
                setSelected={onEducationalQualificationChange}
                selected={{
                  value: educational_qualification,
                  label: educational_qualification,
                }}
                placeholder={
                  educational_qualification || "Educational Qualification"
                }
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group controlId="nationality" as={Col} lg={4}>
              <Form.Label className="fs-xs">Nationality</Form.Label>
              <Form.Control
                placeholder="Nationality"
                name="nationality"
                value={nationality}
                onChange={handleOnChange}
              />
            </Form.Group>
            <Form.Group controlId="State" as={Col} lg={4}>
              <Form.Label className="fs-xs">State</Form.Label>
              <Form.Control
                placeholder="State"
                name="state"
                value={state}
                onChange={handleOnChange}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group controlId="address" as={Col} lg={8}>
              <Form.Label className="fs-xs">Address</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                placeholder="Address"
                name="address"
                value={address}
                onChange={handleOnChange}
              />
            </Form.Group>
          </Row>
        </div>
      </AccordionBody>
    </AccordionItem>
  );
};

export default PlayerDemographicForm;
