import { Button, CloseButton, Modal } from "Components/Core";
import { ConfirmBoxComponentPropsType } from "Interfaces/Components/Shared/ConfirmBoxComponentInterface";
import { CONFIRM, DEFAULT_CONFIRM_PROPS, GUIDELINES } from "App/constants";
import { useAppDispatch } from "App/hooks";
import { setConfirmProps } from "App/commonReducer";
import { GUIDELINES_BUTTON_CSS } from "../Header/constants";
import React from "react";
import ConfirmBoxBody from "./ConfirmBoxBody";
import GuidelinesBoxBody from "./GuidelinesBoxBody";

const ConfirmBoxComponent: React.FC<ConfirmBoxComponentPropsType> = (
  confirmProps
) => {
  const {
      showConfirm,
      confirmBodyProps,
      buttonProps,
      modalType,
      modalClassName,
      closeButtonClassName,
    } = confirmProps,
    dispatch = useAppDispatch();

  const handleClose = () => {
      dispatch(setConfirmProps(DEFAULT_CONFIRM_PROPS));
    },
    handleConfirm = (onClick: Function | undefined) => {
      onClick && onClick();
      dispatch(setConfirmProps(DEFAULT_CONFIRM_PROPS));
    };

  return (
    <Modal
      size="sm"
      centered
      show={showConfirm}
      onHide={handleClose}
      scrollable
      className={modalClassName ? modalClassName : ""}
    >
      {modalType === CONFIRM ? (
        <div className={`${closeButtonClassName} p-3`}>
          <CloseButton onClick={() => handleClose()} />
        </div>
      ) : (
        <div className="px-3 py-4">
          <h2 className="fw-bold text-center mb-0">{GUIDELINES}</h2>
        </div>
      )}
      <Modal.Body className="d-grid gap-2 py-0">
        <div>
          {modalType === CONFIRM ? (
            <ConfirmBoxBody
              message={confirmBodyProps?.message}
              list={confirmBodyProps?.list}
            />
          ) : (
            <GuidelinesBoxBody list={confirmBodyProps?.list} />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-center gap-3 px-3 py-4">
        {buttonProps &&
          buttonProps.map((buttonProps, index) => {
            const { title, onClick } = buttonProps;
            return (
              <Button
                onClick={() => handleConfirm(onClick)}
                key={index}
                className={`px-3 m-0 ${
                  modalType === GUIDELINES ? GUIDELINES_BUTTON_CSS[title] : ""
                } `}
              >
                {title}
              </Button>
            );
          })}
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmBoxComponent;
