import axios from "axios";
import { PAGE_NOT_FOUND_ERROR, SUCCESS } from "App/constants";
import {
  setErrorCode,
  setShowLoader,
  setToastMessage,
} from "App/commonReducer";
import {
  EXCLUDE_ERROR_CODES,
  NO_INTERNET_MSG,
  REDIRECT_TO_HOME_ERROR_CODES,
} from "Utils/helper";

const axiosInterceptor = {
  config: (store: any, history: any) => {
    // Request interceptor
    axios.interceptors.request.use(
      function (config: any) {
        const isLogedIn = localStorage.getItem("access-token");
        if (isLogedIn && config.url.includes(process.env.REACT_APP_API_HOST)) {
          config.headers["access-token"] = localStorage.getItem("access-token");
          config.headers["client"] = localStorage.getItem("client");
          config.headers["uid"] = localStorage.getItem("uid");
        }
        store.dispatch(setShowLoader(true));
        return config;
      },
      function (error) {
        store.dispatch(setShowLoader(false));
        return Promise.reject(error);
      }
    );

    // Response interceptor
    axios.interceptors.response.use(
      function (response) {
        store.dispatch(setShowLoader(false));
        const {
          data: { message },
        } = response;
        if (message && message.length) {
          store.dispatch(setToastMessage([[message], SUCCESS]));
        }
        return response;
      },
      function (error) {
        const errorObject = JSON.parse(JSON.stringify(error));

        //shown toast msg if there is no internet connection
        if (errorObject.status === null) {
          store.dispatch(setShowLoader(false));
          store.dispatch(setToastMessage([NO_INTERNET_MSG]));
        }

        const {
          response: {
            data: { errors },
            statusText,
            status,
          },
        } = error || {};
        store.dispatch(setShowLoader(false));
        if (EXCLUDE_ERROR_CODES.includes(status)) {
          return Promise.reject(error);
        } else if (
          (errors && errors.length) ||
          status === PAGE_NOT_FOUND_ERROR
        ) {
          REDIRECT_TO_HOME_ERROR_CODES.includes(status) && localStorage.clear();
          status !== PAGE_NOT_FOUND_ERROR &&
            store.dispatch(setToastMessage(errors));
          store.dispatch(setErrorCode(status));
        } else if (statusText) {
          store.dispatch(setToastMessage([statusText]));
        }
        return Promise.reject(error);
      }
    );
  },
};

export default axiosInterceptor;
