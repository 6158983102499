import React, { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ValidationError } from "yup";
import { Form, Button } from "Components/Core";
import { Auth, DisplayErrorMessage } from "Components/Shared";
import { forgetPassword } from "Api/Pages/ForgetPasswordApi";
import {
  BACK,
  EMAIL,
  FORM_CONSTANT,
  SEND_LINK,
} from "Pages/ForgotPassword/constant";
import { REDIRECT_URL } from "App/constants";
import { addUserEmailSchema } from "App/Validations/allSchema";

const ForgotPassword = () => {
  const navigate = useNavigate(),
    [email, setEmail] = useState<string>(""),
    [emailError, setEmailError] = useState<string>(""),
    { LoginPath } = FORM_CONSTANT;

  const handleOnSubmit = useCallback(async () => {
    addUserEmailSchema
      .validate({ email: email }, { abortEarly: false })
      .then(async () => {
        setEmailError("");
        await forgetPassword({
          redirect_url: REDIRECT_URL,
          email: email,
        });
        navigate(LoginPath);
      })
      .catch((error) => {
        const { inner } = error;
        inner.forEach((element: ValidationError) => {
          const { message } = element;
          setEmailError(message);
        });
      });
  }, [email, navigate, LoginPath]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const {
      target: { value },
    } = event;
    setEmail(value);
  };

  return (
    <Auth heading="Forgot Password">
      <Form className="forgot-password-form">
        <Form.Group className="mb-3">
          <Form.Label className="text-white">{EMAIL}</Form.Label>
          <Form.Control
            type="text"
            onChange={handleOnChange}
            placeholder="Enter Email"
            className="border-primary py-2"
            value={email}
            isInvalid={emailError !== ""}
          />
          <DisplayErrorMessage errorField={emailError} />
        </Form.Group>
        <Form.Group className="d-grid pt-3 mb-3">
          <Button
            variant="secondary"
            size="sm"
            type="button"
            onClick={handleOnSubmit}
          >
            {SEND_LINK}
          </Button>
        </Form.Group>
        <Form.Group className="text-center">
          <Link to="/" className="d-inline-block mx-auto fs-sm text-white">
            {BACK}
          </Link>
        </Form.Group>
      </Form>
    </Auth>
  );
};

export default ForgotPassword;
