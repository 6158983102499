import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  PaginationControl,
  HeaderComponent,
  AcademiesListComponent,
} from "Components/Shared";
import { getAcademyList } from "Api/Pages/AcademyApi";
import { AcademyDetailsObjectType } from "Interfaces/Pages/AcademyInterface";
import {
  ACADEMY_PORTAL,
  CREATE_NEW_ACADEMY,
  ROUTE_ACADEMY_CREATE,
  ROUTE_ACADEMY_UPDATE,
  ROUTE_ADMIN_CREATE,
  ROUTE_ADMIN_UPDATE,
  ROUTE_SHOW_ACADEMY,
} from "Pages/Academies/constants";
import { setConfirmProps } from "App/commonReducer";
import {
  CONFIRM,
  RECORDS_PER_PAGE,
  editComfirmMessage,
  TEXT_END,
  INITIAL_PAGE,
} from "App/constants";
import { isAllowedTo } from "Utils/helper";
import { useAppDispatch, useAppSelector } from "App/hooks";
import { setFilterKeys } from "Components/Shared/Academy/academyListComponentReducer";

const Academy = () => {
  const navigate = useNavigate(),
    [filteredList, setFilteredList] = useState<AcademyDetailsObjectType[]>([]),
    [searchInput, setSearchInput] = useState(""),
    { confirmProps } = useAppSelector((state) => state.commonReducer),
    dispatch = useAppDispatch(),
    {
      userDetails: { permissions },
    } = useAppSelector((state) => state.loginReducer),
    { filterKeys } = useAppSelector(
      (state) => state.academyListComponentReducer
    ),
    { currentPage, totalPages } = filterKeys;

  const fetchAcademiesList = useCallback(async () => {
    const { academies, total_count } = await getAcademyList({
      page: currentPage,
      search: searchInput,
    });
    dispatch(
      setFilterKeys({
        currentPage,
        totalPages: Math.ceil(total_count / RECORDS_PER_PAGE),
      })
    );
    setFilteredList(academies);
  }, [currentPage, dispatch, searchInput]);

  useEffect(() => {
    fetchAcademiesList();
  }, [fetchAcademiesList]);

  const onAddAcademy = () => {
    navigate(ROUTE_ACADEMY_CREATE);
  };

  const onEditAcademy = (id: number) => {
      dispatch(
        setConfirmProps({
          ...confirmProps,
          buttonProps: [
            { title: CONFIRM, onClick: () => onConfirmEditAcademy(id) },
          ],
          confirmBodyProps: {
            message: [`${editComfirmMessage} Academy?`],
          },
          closeButtonClassName: TEXT_END,
          modalType: CONFIRM,
          showConfirm: true,
        })
      );
    },
    onConfirmEditAcademy = (id: number) => {
      navigate(ROUTE_ACADEMY_UPDATE(id));
    };

  const onDeactivateAcademy = (id: number) => {
    navigate(ROUTE_ACADEMY_UPDATE(id));
  };

  const setCurrentPage = useCallback(
    (current: number) => {
      dispatch(setFilterKeys({ currentPage: current, totalPages: totalPages }));
    },
    [dispatch, totalPages]
  );

  const handleSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = event;
      setSearchInput(value);
      setCurrentPage(INITIAL_PAGE);
    },
    [setSearchInput, setCurrentPage]
  );

  const onAddAdmin = (academyId: number) => {
    navigate(ROUTE_ADMIN_CREATE(academyId));
  };

  const onEditAdmin = (academy_id: number, id: number) => {
      dispatch(
        setConfirmProps({
          ...confirmProps,
          buttonProps: [
            {
              title: CONFIRM,
              onClick: () => onConfirmEditAdmin(academy_id, id),
            },
          ],
          confirmBodyProps: {
            message: [`${editComfirmMessage} Admin?`],
          },
          closeButtonClassName: TEXT_END,
          modalType: CONFIRM,
          showConfirm: true,
        })
      );
    },
    onConfirmEditAdmin = (academy_id: number, id: number) => {
      navigate(ROUTE_ADMIN_UPDATE(academy_id, id));
    };

  const onRowClick = (academy: any) => {
    navigate(ROUTE_SHOW_ACADEMY(academy.id));
  };

  return (
    <div className="ss-page-content d-flex flex-column flex-1 scroll-y bg-white">
      <HeaderComponent
        title={ACADEMY_PORTAL}
        shouldShowNav={false}
        buttonProps={
          isAllowedTo("Academy", "create", permissions)
            ? {
                buttonText: CREATE_NEW_ACADEMY,
                onClick: onAddAcademy,
              }
            : undefined
        }
      />
      <div className="ss-page-body d-flex flex-column flex-1 scroll-y py-4">
        <AcademiesListComponent
          academiesList={filteredList}
          onEditAcademy={onEditAcademy}
          onDeactivateAcademy={onDeactivateAcademy}
          onEditAdmin={onEditAdmin}
          onAddAdmin={onAddAdmin}
          onRowClick={onRowClick}
          onHandleSearch={handleSearch}
        />
      </div>
      <div className="pagination-wrap d-flex justify-content-end px-4">
        <PaginationControl
          currentPage={currentPage}
          setCurrentPage={(current: number) => setCurrentPage(current)}
          totalPages={totalPages}
        />
      </div>
    </div>
  );
};

export default Academy;
