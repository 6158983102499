import { useQuery } from "react-query";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Col, Row } from "Components/Core";
import FileViewPort from "Components/Shared/DrillsDetailsComponent/FileViewPort";
import { ASSET_TYPE_ENUM } from "Components/Shared/UploadAsset/types";
import { getVideoDetail } from "Api/Pages/VideoListApi";

interface AssetVideoDetailProps {
  videoId: number;
  onBackClick: () => void;
}

const AssetVideoDetail = (props: AssetVideoDetailProps) => {
  const { videoId, onBackClick } = props;

  const { data } = useQuery({
    queryKey: ["asset-details", videoId],
    queryFn: () => getVideoDetail(videoId),
  });

  if (!data) {
    return null;
  }

  const {
    description,
    title,
    master_sport_name,
    of_type,
    url,
    thumbnail_url,
    graph_attribute,
    asset_evaluation_category,
    asset_evaluation_sub_category,
    asset_evaluation_relations,
    uploaded_by,
  } = data;

  const tags = asset_evaluation_relations
    .map((obj) => obj.evaluable_title)
    .join(" > ");

  return (
    <div className="ss-page-content d-flex flex-column flex-1 scroll-y bg-white">
      <header className="ss-page-header d-flex align-items-center justify-content-start gap-2 px-4 ">
        <FontAwesomeIcon
          icon={faChevronLeft}
          type="button"
          onClick={onBackClick}
        />
        <h2 className="fs-md mb-0 fw-medium">Video Details</h2>
      </header>
      <hr className="ss-page-seprator mx-4 my-0" />
      <div className="d-flex flex-column flex-1 scroll-y p-4 gap-4">
        <FileViewPort
          fileType={
            of_type === ASSET_TYPE_ENUM.image
              ? `${of_type}/jpeg`
              : `${of_type}/mp4`
          }
          url={url}
          preview={thumbnail_url}
          videoWrapperClasses="w-50 h-50"
        />
        <div>
          <label className="fs-xs">Title</label>
          <p className="fs-sm fw-bold mb-0">{title}</p>
        </div>
        <div>
          <label className="fs-xs">Description</label>
          <p className="fs-sm fw-bold mb-0">{description || "---"}</p>
        </div>
        <div>
          <label className="fs-xs">Uploaded By</label>
          <p className="fs-sm fw-bold mb-0">{uploaded_by || "---"}</p>
        </div>
        <Row>
          {master_sport_name && (
            <Col lg={4}>
              <label className="fs-xs">Sport</label>
              <p className="fs-sm fw-bold mb-0">{master_sport_name}</p>
            </Col>
          )}
          {graph_attribute && (
            <Col lg={4}>
              <label className="fs-xs">Parameter</label>
              <p className="fs-sm fw-bold mb-0">{graph_attribute}</p>
            </Col>
          )}
        </Row>
        <Row>
          {asset_evaluation_category?.title && (
            <Col lg={4}>
              <label className="fs-xs">Specific Parameter</label>
              <p className="fs-sm fw-bold mb-0">
                {asset_evaluation_category.title}
              </p>
            </Col>
          )}
          {asset_evaluation_sub_category?.title && (
            <Col lg={4}>
              <label className="fs-xs">Specific Skill</label>
              <p className="fs-sm fw-bold mb-0">
                {asset_evaluation_sub_category.title}
              </p>
            </Col>
          )}
        </Row>
        {tags.length !== 0 && (
          <div>
            <label className="fs-xs">Multiple Tags</label>
            <div className="d-flex gap-2"># {tags}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AssetVideoDetail;
