import { AcademyFilterKeysType } from "Interfaces/Pages/AcademyInterface";
import {
  AcademyDetailsObjectType,
  AcademyDetailsFormErrorObjectType,
} from "Interfaces/Pages/AcademyInterface";

export const DEFAULT_FILTER_KEY: AcademyFilterKeysType = {
  currentPage: 1,
  totalPages: 1,
};

export const AcademyDefaultValue: AcademyDetailsObjectType = {
  name: "",
  contact_number: "",
  email: "",
  pincode: 0,
  address1: "",
  city: "",
  website: "",
  timezone: "",
  details: "",
  address2: "",
  admin: {},
};

export const AcademyDetailsFormErrorValue: AcademyDetailsFormErrorObjectType = {
  name_error: "",
  email_error: "",
  contact_number_error: "",
  country_code_error: "",
  pincode_error: "",
  address1_error: "",
  timezone_error: "",
};
