import axios from "axios";
import { BASE_URL, CONSTANT_URL } from "App/constants";

export const getCountryCodeAndNames = async (academyId: number) => {
  const { data } = await axios.get(`${BASE_URL}country_codes`);
  if (data) {
    const countries = data.country_codes as {
      name: string;
      country_code: string;
    }[];

    const countryNameList = countries.map((country) => country.name);
    const countryCodeList = countries.map((country) => country.country_code);
    return { countryNameList, countryCodeList };
  }
  return { countryNameList: [], countryCodeList: [] };
};

export const getGenders = async () => {
  const { data } = await axios.get(`${CONSTANT_URL}`, {
    params: {
      key: "gender",
    },
  });

  return data?.gender ?? ([] as string[]);
};

export const getCities = async (countryCode: string | null) => {
  if (countryCode === "") return [];
  const { data } = await axios.get(`${CONSTANT_URL}`, {
    params: {
      key: "city",
      country_code: countryCode,
    },
  });

  return data?.city ?? ([] as string[]);
};

export const getEducationalQualifications = async () => {
  const { data } = await axios.get(`${CONSTANT_URL}`, {
    params: {
      key: "educational_qualification",
    },
  });

  return data.educational_qualification;
};

export const getBloodGroups = async () => {
  const { data } = await axios.get(`${CONSTANT_URL}`, {
    params: {
      key: "blood_group",
    },
  });

  return data.blood_group;
};
