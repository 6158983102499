import { useState } from "react";
import { FixedSizeGrid as Grid } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

import { useLocation, Location } from "react-router-dom";
import { useQuery } from "react-query";

import PlayerSurveyHeader from "./components/PlayerSurverHeader";
// import PlayerSurveyActionRow from "./components/PlayerSurveyActionRow";
import PlayerSurveyDetailsCard from "./components/PlayerSurveyDetailsCard";
import { ButtonIcon } from "Components/Shared";

import {
  getPlayerSurveyAdherenceDetails,
  getPlayerSurveyAdherencePDF,
} from "Api/Pages/ReportsApi";
import { download } from "Api/Pages/cloudinaryAssetUpload";
import { useAppSelector } from "App/hooks";

import { IBatchOption, ICell, IPlayerSurveyDetails } from "./types";
import {
  //  getBatches,
  getIndex,
} from "./helper";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";

import USE_QUERY_KEYS_CONSTANTS from "Api/useQueryKeyConstants";
import { DOWNLOAD } from "../constants";
import { PlayerSurveyProps } from "Interfaces/Pages/ReportsInterface";

import "./styles.scss";

const PlayerSurveyAdherenceDetailsPage = () => {
  const location: Location = useLocation();
  const { player_name, batch_name, batch_id, player_id } =
    location.state as PlayerSurveyProps;

  // state variables
  // set default branch name from  list
  const [
    selectedBatch,
    // setSelectedBatch
  ] = useState<IBatchOption>({
    batchName: batch_name || "Select Batch",
    batchId: batch_id,
  });

  const {
    userDetails: { academy_id },
  } = useAppSelector((state) => state.loginReducer);

  // api call to fetch player survey details
  const {
    data: result,
    // refetch: fetchPlayerSurveyAdherenceDetails
  } = useQuery<IPlayerSurveyDetails>(
    [
      USE_QUERY_KEYS_CONSTANTS.playerSurveyAdherenceDetails,
      selectedBatch.batchId,
    ],
    () =>
      getPlayerSurveyAdherenceDetails({
        playerId: player_id,
        academyId: academy_id,
        batchId: selectedBatch.batchId,
      })
    // { refetchOnWindowFocus: false, enabled: false }
  );

  const { questions = [] } = result || {};

  const COLUMNS = 3;
  // const COLUMNS_WIDTH = 310;

  const Cell = ({ style, columnIndex, rowIndex }: ICell) => {
    const index = getIndex(rowIndex, COLUMNS, columnIndex);
    if (questions[index]?.data) {
      const { title, trigger_event, data, filled_surveys, total_surveys } =
        questions[index];
      return (
        <div style={style}>
          <PlayerSurveyDetailsCard
            title={title}
            triggerEvent={trigger_event}
            surveyData={data}
            filledSurvey={filled_surveys}
            totalSurvey={total_surveys}
          />
        </div>
      );
    }
    return null;
  };

  const onDownloadClick = async () => {
    try {
      const data = await getPlayerSurveyAdherencePDF({
        playerId: player_id,
        academyId: academy_id,
        batchId: selectedBatch.batchId,
      });
      download(data, `${player_name}-${selectedBatch.batchName}.pdf`);
    } catch (error) {
      console.error("failed to download error: ", error);
    }
  };

  return (
    <div
      className="ss-page-content d-flex flex-column flex-1 scroll-y bg-white"
      id="test"
    >
      <PlayerSurveyHeader player_name={player_name} />
      <hr className="ss-page-seprator mx-4 my-0" />
      <div className="ss-page-body d-flex flex-column flex-1 scroll-y py-4">
        {/* <PlayerSurveyActionRow
          selectedBatch={selectedBatch}
          setSelectedBatch={setSelectedBatch}
          batchesOptions={getBatches([])}
        /> */}
        <div className="d-flex flex-row pe-4 mb-2">
          <span className="ms-4 mb-4">
            Batch: <strong> {selectedBatch.batchName}</strong>
          </span>
          <ButtonIcon
            className="btn-outline-secondary fs-sm ms-auto"
            onClick={onDownloadClick}
            isdisabled={questions.length === 0}
          >
            <FontAwesomeIcon icon={faArrowDown} className="me-2" />
            {DOWNLOAD}
          </ButtonIcon>
        </div>
        <div className="text-center">
          {questions.length === 0 && "No data present"}
        </div>

        <div className="flex-1 scroll-y px-4 mb-3" id="pdf-content">
          <AutoSizer>
            {({ height, width }) => (
              <Grid
                columnCount={COLUMNS}
                columnWidth={width / COLUMNS}
                height={height}
                rowCount={Math.ceil(questions.length / COLUMNS) || 0}
                rowHeight={320}
                width={width}
              >
                {Cell}
              </Grid>
            )}
          </AutoSizer>
        </div>
      </div>
    </div>
  );
};

export default PlayerSurveyAdherenceDetailsPage;
