import axios from "axios";
import { BASE_URL } from "App/constants";

export const getTimeZone = async () => {
  const { data } = await axios.get(`${BASE_URL}timezones`);
  if (data) {
    const timeZoneArr = data.timezones.map((timezone: any, key: string) => {
      return timezone;
    });
    return timeZoneArr;
  }
  return [];
};
