import React from "react";

import { Card } from "react-bootstrap";
import { formatDistanceToNow } from "date-fns";

import "./comment.css";

import { CommentType } from "Interfaces/Components/Shared/ScheduleInterface";

const CommentBox: React.FC<CommentType> = ({
  commented_by,
  created_at,
  text,
}) => {
  return (
    <Card className="comment-box mb-3">
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center">
          <Card.Title className="mb-0 fw-bold fs-6">{commented_by}</Card.Title>
          <span className="text-muted ms-2 " text-end>
            {formatDistanceToNow(new Date(created_at), { addSuffix: true })}
          </span>
        </div>
        <Card.Text className="mt-2 fs-6">{text}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default CommentBox;
