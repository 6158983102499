import axios from "axios";
import { BASE_URL } from "App/constants";

export const fetchMatchPlayHistory = async ({
  userId,
  startDate,
  endDate,
  page = 1,
  perPage = 15,
}: {
  userId: number;
  startDate: Date | string | null;
  endDate: Date | string | null;
  page?: number;
  perPage?: number;
}) => {
  try {
    const { data } = await axios.get(`${BASE_URL}engagement_requests`, {
      params: {
        start_date: startDate,
        end_date: endDate,
        user_id: `${userId}`,
        page,
        per_page: perPage,
      },
    });

    return data || [];
  } catch (error) {
    return [];
  }
};
