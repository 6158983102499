import React from "react";
import { IconProps } from "Interfaces/Components/Shared/IconInterface";

const Icon: React.FC<IconProps> = (props) => {
  const { className, name } = props;

  return <i className={`icon-${name} ${className}`} />;
};

Icon.defaultProps = {
  className: "",
};

export default Icon;
