import { ButtonIcon } from "Components/Shared";
import {
  faPencil,
  faUserEdit,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import {
  AcademyObjectType,
  AcademyItemTabsObjectType,
  AddAcademyType,
  ColumnPropsType,
} from "Interfaces/Pages/AcademyInterface";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./academy.scss";

import { isAllowedTo } from "Utils/helper";
export const ACADEMY_PORTAL: string = "Academy Portal";
export const CREATE_NEW_ACADEMY: string = "Create New Academy";
export const ROUTE_ACADEMY_CREATE: string = "/academies/new";

export const ROUTE_ACADEMY_UPDATE = (id: number) => {
  return `/academies/${id}/edit`;
};

export const ROUTE_SHOW_ACADEMY = (id: number) => {
  return `/academies/${id}`;
};
export const ROUTE_ADMIN_CREATE = (academy_id: number) => {
  return `/academies/${academy_id}/admin/new`;
};

export const ROUTE_ADMIN_UPDATE = (academy_id: number, id: number) => {
  return `/academies/${academy_id}/admin/${id}/edit`;
};

export const ADD_ACADEMY_FORM_FIELD: AddAcademyType = {
  title: "Create Academy",
  button1: "Clear",
  button2: "Add",
};

export const AcademyItemTabs: AcademyItemTabsObjectType[] = [
  {
    eventKey: "Academy",
    title: "Academy",
  },
  {
    eventKey: "Admin",
    title: "Admin",
  },
];

export const getColumns = ({
  permissions,
  onEditAcademy,
  onEditAdmin,
  onAddAdmin,
}: ColumnPropsType) => [
  {
    dataField: "name",
    title: "Name",
  },
  {
    dataField: "email",
    title: "Email",
  },
  {
    dataField: "contact_number",
    title: "Contact Number",
  },
  {
    dataField: "city",
    title: "City",
  },
  {
    dataField: "admin",
    title: "Admin",
    formatter: (props: AcademyObjectType) => {
      return (
        <>
          {props.admin === null ? (
            <div className="admin-name" key={props.id}>
              -
            </div>
          ) : (
            <div className="admin-name" key={props.id}>
              {props.admin.name}
            </div>
          )}
        </>
      );
    },
  },
  {
    dataField: "",
    title: "",
    formatter: (props: AcademyObjectType) => {
      return (
        <>
          {props.admin === null ? (
            <ButtonIcon
              onClick={(event) => {
                event.stopPropagation();
                onAddAdmin && onAddAdmin(props.id);
              }}
            >
              <FontAwesomeIcon icon={faUserPlus} />
            </ButtonIcon>
          ) : (
            <ButtonIcon
              onClick={(event) => {
                event.stopPropagation();
                onEditAdmin && onEditAdmin(props.id, props.admin.id);
              }}
            >
              <FontAwesomeIcon icon={faUserEdit} />
            </ButtonIcon>
          )}
        </>
      );
    },
  },
  {
    dataField: "action",
    title: "Action",
    formatter: (props: AcademyObjectType) => {
      return (
        <>
          <div className="academy-actions" key={props.id}>
            {isAllowedTo("Academy", "edit", permissions) && (
              <>
                <ButtonIcon
                  onClick={(event) => {
                    event.stopPropagation();
                    onEditAcademy && onEditAcademy(props.id);
                  }}
                >
                  <FontAwesomeIcon icon={faPencil} />
                </ButtonIcon>
                {/* <ButtonIcon
                  onClick={(event) => {
                    event.stopPropagation();
                    onEditAcademy && onEditAcademy(props.id);
                  }}
                >
                  <FontAwesomeIcon icon={faXmark} />
                </ButtonIcon> */}
              </>
            )}
          </div>
        </>
      );
    },
  },
];
