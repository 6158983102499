import { faFilePdf, faPlay } from "@fortawesome/free-solid-svg-icons";
import {
  DrillsDetailsObjectType,
  FileTypeMapType,
  FormConstantDrillType,
} from "Interfaces/Pages/DrillsInterface";

export const FILE_TYPE_MAP: FileTypeMapType = {
  "video/mp4": faPlay,
  "application/pdf": faFilePdf,
};

export const DrillDetails: DrillsDetailsObjectType = {
  id: 0,
  description: "",
  file_type: "",
  file_url: "",
  master_sport: "",
  title: "",
  preview: "",
};

export const FORM_CONSTANT: FormConstantDrillType = {
  DrillDetail: "Drill Details",
  ImageType: [
    "image/jpeg",
    "image/png",
    "image/apng",
    "image/avif",
    "image/gif",
    "image/svg",
    "image/webp",
    "image/emf",
    "image/bmp",
    "image/tiff",
  ],
  VideoType: [
    "video/mp4",
    "video/3gp",
    "video/flv",
    "video/webm",
    "video/mkv",
    "video/gif",
    "video/ogg",
    "video/ogv",
    "video/mov",
    "video/wmv",
    "video/avi",
  ],
  PdfType: [
    "application/pdf",
    "application/pdf",
    "application/doc",
    "application/docx",
    "application/docm",
    "application/dot",
    "application/dotx",
    "application/xps",
    "application/odt",
    "application/txt",
    "application/wps",
    "application/csv",
    "application/xls",
  ],
  Blank: "blank",
  Preload: "none",
  Height: "100%",
  Width: "100%",
  Image: "Image",
  NoDownload: "nodownload",
};

export const ROUTE_DRILLS_DETAILS = (id: number) => {
  return `/old-drills/${id}/details`;
};
