import { AdminDetailsObjectTypes } from "Interfaces/Pages/AcademyInterface";
import { AdminDetailsFormErrorObjectType } from "Interfaces/Pages/AdminInterface";

export const AdminDefaultValue: AdminDetailsObjectTypes = {
  id: "",
  name: "",
  email: "",
  password: "",
  contact_number: "",
};

export const AdminDetailsDefaultFormErrorValue: AdminDetailsFormErrorObjectType =
  {
    name_error: "",
    email_error: "",
    contact_number_error: "",
    password_error: "",
  };
