import { PermissionsObjType } from "Interfaces/UtilsInterface";

/*resource=> Batch, Player, etc
action=> index, create, update, show, etc

*/
const canAccess = (
  resource: string,
  action: string,
  permissions: PermissionsObjType
) => {
  const { can } = permissions;
  if (can[action]) {
    const accessableResources = Object.keys(can[action]);
    return accessableResources && accessableResources.includes(resource);
  }
  return false;
};

const cannotAccess = (
  resource: string,
  action: string,
  permissions: PermissionsObjType
) => {
  const { cannot } = permissions;
  if (cannot[action]) {
    const notAccessableResources = Object.keys(cannot[action]);
    return notAccessableResources && notAccessableResources.includes(resource);
  }
  return false;
};

export const isAllowedTo = (
  resource: string, //Asset
  action: string,
  permissions: PermissionsObjType
) => {
  if (!permissions) {
    return false;
  }

  if (
    canAccess(resource, action, permissions) &&
    !cannotAccess(resource, action, permissions)
  ) {
    return true;
  }

  return false;
};

export const REDIRECT_TO_HOME_ERROR_CODES = [403, 401];
export const EXCLUDE_ERROR_CODES = [423];
export const NO_INTERNET_MSG = "Please check internet connection";

export const formatDate = (dt: string) => {
  var options: any = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  var date = new Date(dt);

  return date.toLocaleDateString("en-US", options); // Saturday, September 17, 2016
};

export function formatTimestamp(timestamp: string) {
  const date: any = new Date(timestamp);
  const now: any = new Date();
  const diff = now - date;
  const minute = 60 * 1000;
  const hour = 60 * minute;
  const day = 24 * hour;
  const month = 30 * day;
  const year = 365 * day;

  if (diff < minute) {
    return "just now";
  }
  if (diff < hour) {
    const minutesAgo = Math.round(diff / minute);
    return `${minutesAgo} minute${minutesAgo === 1 ? "" : "s"} ago`;
  }
  if (diff < day) {
    const hoursAgo = Math.round(diff / hour);
    return `${hoursAgo} hour${hoursAgo === 1 ? "" : "s"} ago`;
  }
  if (diff < month) {
    const daysAgo = Math.round(diff / day);
    return `${daysAgo} day${daysAgo === 1 ? "" : "s"} ago`;
  }
  if (diff < year) {
    const monthsAgo = Math.round(diff / month);
    return `${monthsAgo} month${monthsAgo === 1 ? "" : "s"} ago`;
  }
  {
    const yearsAgo = Math.round(diff / year);
    if (yearsAgo > 10) {
      return "10+ years ago";
    }
    return `${yearsAgo} year${yearsAgo === 1 ? "" : "s"} ago`;
  }
}

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};
