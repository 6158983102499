import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BatchFormComponent } from "Components/Shared";
import { getBatchData } from "Api/Pages/BatchesApi";
import { EDIT_BATCH } from "App/constants";
import { BatchDetailsObjectType } from "Interfaces/Pages/BatchesInterface";

const EditBatch = () => {
  const [batchData, setBatchData] = useState<BatchDetailsObjectType>(),
    navigate = useNavigate(),
    fetchBatchData = useCallback(
      async (id: number) => {
        const data = await getBatchData({ id });
        if (data) {
          // data.name && delete data.name;
          setBatchData(data);
        }
      },
      [setBatchData]
    );
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchBatchData(+id);
    } else {
      navigate("/batches");
    }
  }, [fetchBatchData, navigate, id]);

  return batchData ? (
    <BatchFormComponent
      title={EDIT_BATCH}
      isEditBatch={true}
      batchData={batchData}
    />
  ) : (
    ""
  );
};

export default EditBatch;
