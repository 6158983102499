import AccordionBody from "react-bootstrap/esm/AccordionBody";
import AccordionHeader from "react-bootstrap/esm/AccordionHeader";
import AccordionItem from "react-bootstrap/esm/AccordionItem";
import { Row, Col, Form } from "Components/Core";
import { PlayerPersonalInformationFormPropsType } from "Interfaces/Components/Shared/UserDetailsFormInterface";

const PlayerPersonalInformationForm = (
  props: PlayerPersonalInformationFormPropsType
) => {
  const { userDetails, handleOnChange } = props;
  const {
    previous_serious_injuries,
    medical_history,
    other_hobbies_and_interest,
  } = userDetails;

  return (
    <AccordionItem eventKey="additional-information">
      <AccordionHeader>
        <span className="fw-bold">Additional details</span>
      </AccordionHeader>
      <AccordionBody>
        <div className="players-details-wrap d-grid gap-4">
          <Row>
            <Form.Group controlId="previous_serious_injuries" as={Col} lg={4}>
              <Form.Label className="fs-xs">
                Previous Serious Injuries
              </Form.Label>
              <Form.Control
                placeholder="Previous Serious Injuries"
                name="previous_serious_injuries"
                value={previous_serious_injuries}
                onChange={handleOnChange}
              />
            </Form.Group>
            <Form.Group controlId="medical_history" as={Col} lg={4}>
              <Form.Label className="fs-xs">Medical History</Form.Label>
              <Form.Control
                placeholder="Medical History"
                name="medical_history"
                value={medical_history}
                onChange={handleOnChange}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group controlId="other_hobbies_and_interest" as={Col} lg={4}>
              <Form.Label className="fs-xs">
                Other Hobbies and Interests
              </Form.Label>
              <Form.Control
                placeholder="Other Hobbies and Interests"
                name="other_hobbies_and_interest"
                value={other_hobbies_and_interest}
                onChange={handleOnChange}
              />
            </Form.Group>
          </Row>
        </div>
      </AccordionBody>
    </AccordionItem>
  );
};

export default PlayerPersonalInformationForm;
