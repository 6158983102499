import axios from "axios";
import { LoginDetails } from "Interfaces/Pages/LoginInterface";
import { LOGIN_URL } from "App/constants";
import { setAuthorisationInfo } from "Utils/utils";

export const login = async (data: LoginDetails) => {
  const response = await axios.post(LOGIN_URL, data);
  setAuthorisationInfo(response);
  return response.data;
};
