import axios from "axios";
import { BASE_URL } from "App/constants";
import { FetchUserScheduleParams } from "Interfaces/Components/Shared/ScheduleInterface";
export const fetchUserSchedules = async ({
  userId,
  page,
  filterType,
  startDate,
  endDate,
}: FetchUserScheduleParams) => {
  const { data } = await axios.get(
    `${BASE_URL}users/${userId}/user_schedules`,
    {
      params: {
        page,
        of_type: filterType && filterType.toLowerCase(),

        start_date: startDate
          ? startDate.toISOString().split("T")[0]
          : undefined,
        end_date: endDate ? endDate.toISOString().split("T")[0] : undefined,
      },
    }
  );
  if (data) {
    return data;
  }
  return [];
};

export const fetchComments = async (id: number) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/user_schedules/${id}/comments`
    );
    return response.data || []; // Access the `data` property that holds the array of comments
  } catch (error) {
    throw error;
  }
};
