import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { history } from "App";

import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  player_name: string;
}

const PlayerSurveyHeader = (props: IProps) => {
  const { player_name } = props;

  return (
    <header className="ss-page-header d-flex align-items-xl-center justify-content-between p-4 flex-column flex-xl-row">
      <div className="d-flex align-items-center gap-2">
        <FontAwesomeIcon
          icon={faChevronLeft}
          type="button"
          onClick={history.back}
        />
        <h2 className="fs-md mb-0 fw-medium">{player_name}</h2>
      </div>
    </header>
  );
};

export default PlayerSurveyHeader;
