import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  SurveyHistoryComponentFilterKey,
  SurveyHistoryState,
} from "Interfaces/Components/Shared/SurveyHistoryInterface";
import { DEFAULT_FILTER_KEY, DEFAULT_FILTER_LIST } from "./constants";

const initialState: SurveyHistoryState = {
  filterKeys: DEFAULT_FILTER_KEY,
  filterList: DEFAULT_FILTER_LIST,
};

export const surveyHistorySlice = createSlice({
  name: "surveyHistory",
  initialState,
  reducers: {
    setFilterKeys: (
      state,
      action: PayloadAction<SurveyHistoryComponentFilterKey>
    ) => {
      state.filterKeys = action.payload;
    },
    setAssignedBatch: (state, action: PayloadAction<any>) => {
      state.filterList.assignedBatches = action.payload.assignedBatches;
    },
    setSurveyHistoryList: (state, action: PayloadAction<any>) => {
      state.filterList.surveyHistoryList = action.payload.surveyHistoryList;
    },
    setSurveyHistoryDetails: (state, action: PayloadAction<any>) => {
      state.filterList.surveyHistoryDetails =
        action.payload.surveyHistoryDetails;
    },
    resetState: () => initialState,
  },
});

export const {
  setFilterKeys,
  setAssignedBatch,
  setSurveyHistoryList,
  setSurveyHistoryDetails,
  resetState,
} = surveyHistorySlice.actions;
export default surveyHistorySlice.reducer;
