import React, { useMemo } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { fetchUserSchedules } from "Api/Pages/ScheduleApi";
import {
  ScheduleDataType,
  ScheduleApiResponse,
} from "Interfaces/Components/Shared/ScheduleInterface";
import TableComponent from "../TableComponent";
import { columns } from "./constant";
import PaginationControl from "../PaginationControl";
import calenderIcon from "App/images/calendar-icon.svg";

interface ScheduleProps {
  userId: number | undefined;
  Userdata: any;
  UserType: string;
}

const Schedule: React.FC<ScheduleProps> = ({ userId, Userdata, UserType }) => {
  const navigate = useNavigate();
  const [filterType, setFilterType] = useState<string>("All");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]); // State to manage date range
  const [startDate, endDate] = dateRange;
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false); // Toggle calendar visibility
  const pageSize = 10;

  // Fetch data from API
  const { data, isLoading, error } = useQuery<ScheduleApiResponse>(
    ["userSchedules", currentPage, filterType, startDate, endDate, userId],
    async () => {
      if (userId === undefined) {
        return undefined;
      }
      return fetchUserSchedules({
        page: currentPage,
        filterType: filterType === "All" ? null : filterType,
        startDate,
        endDate,
        userId,
      });
    },
    {
      keepPreviousData: true, // keep previous data while fetching new data
    }
  );

  const handleRowClick = (rowData: ScheduleDataType) => {
    // `rowData.id` is the unique identifier for the schedule
    if (UserType === "Player") {
      navigate(`/players/${Userdata.id}/schedule-detail/${rowData.id}`, {
        state: rowData, // Pass the rowData as schedule in state
      });
    } else {
      navigate(`/coaches/${Userdata.id}/schedule-detail/${rowData.id}`, {
        state: rowData, // Pass the rowData as schedule in state
      });
    }
  };

  // Filter Data

  const memoizedTableData = useMemo(() => {
    const filteredData = data?.data;
    // Paginate Data
    const paginatedData = filteredData || [];
    return paginatedData?.map((item) => ({
      ...item,
      // Capitalizing `of_type` within the map function itself
      of_type: item.of_type.charAt(0).toUpperCase() + item.of_type.slice(1),
    }));
  }, [data]);
  // Upadte the totalPages after Filtering
  useEffect(() => {
    if (data) {
      const totalRecords = data?.total_count || 0; // Use the total record count, not just data.length
      setTotalPages(Math.ceil(totalRecords / pageSize));
    }

    // Set total pages based on the number of items in playerdata
  }, [data]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error fetching data</div>;
  }
  // Filtering by Type

  const handleDateRangeChange = (dates: [Date | null, Date | null]) => {
    setDateRange(dates);
    setCurrentPage(1);
  };

  const handleFilterChange = (newFilter: string) => {
    setFilterType(newFilter);
    setCurrentPage(1); // Reset to first page when filter changes
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        {/* Tab Filters using Bootstrap 5 Buttons */}
        <div className="btn-group mb-3" role="group" aria-label="Filter Tabs">
          <button
            type="button"
            className={`btn btn-outline-primary ${
              filterType === "All" ? "active" : ""
            }`}
            onClick={() => handleFilterChange("All")}
          >
            All
          </button>
          <button
            type="button"
            className={`btn btn-warning ${
              filterType === "Leave" ? "active" : ""
            }`}
            onClick={() => handleFilterChange("Leave")}
          >
            Leave
          </button>
          <button
            type="button"
            className={`btn btn-danger ${
              filterType === "Injury" ? "active" : ""
            }`}
            onClick={() => handleFilterChange("Injury")}
          >
            Injury
          </button>
          <button
            type="button"
            className={`btn btn-success ${
              filterType === "Activity" ? "active" : ""
            }`}
            onClick={() => handleFilterChange("Activity")}
          >
            Activity
          </button>
          <button
            type="button"
            className={`btn btn-info ${
              filterType === "Travel" ? "active" : ""
            }`}
            onClick={() => handleFilterChange("Travel")}
          >
            Travel
          </button>
        </div>

        {/* DatePicker Component with Calendar Icon */}
        <div className="datepicker-container d-flex align-items-center">
          {/* DatePicker Input Trigger */}
          <DatePicker
            selectsRange
            startDate={startDate}
            endDate={endDate}
            onChange={handleDateRangeChange}
            placeholderText="Select Date Range"
            open={isCalendarOpen} // Control calendar visibility
            onClickOutside={() => setIsCalendarOpen(false)} // Close on outside click
            onSelect={() => setIsCalendarOpen(false)} // Close after selecting date
            className="form-control date-range-input" // Custom styling for date input
          />

          {/* Calendar Icon - Triggers DatePicker */}
          <img
            src={calenderIcon}
            alt="Calendar Icon"
            className="calendar-icon ms-2 cursor-pointer"
            onClick={() => setIsCalendarOpen(!isCalendarOpen)} // Toggle DatePicker on icon click
          />
        </div>
      </div>

      {/* Table Component Code */}
      <div className="table-responsive">
        {data && (
          <TableComponent
            className="ss-table mb-0"
            columns={columns}
            tableData={memoizedTableData}
            hideColWidth={false}
            hideThead={false}
            isClickAble={true}
            onRowClick={handleRowClick}
          />
        )}
      </div>
      <div className="pagination-wrap d-flex justify-content-end px-4">
        <PaginationControl
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
        />
      </div>
    </>
  );
};

export default Schedule;
