import { useState } from "react";
import { faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Form, Row } from "Components/Core";
import { ButtonIcon, MultiSelect, SelectSports } from "Components/Shared";
import { DisplayErrorMessage } from "Components/Shared/ErrorMessage/DisplayErrorMessage";
import {
  FORM_CONSTANT,
  Rank,
  SubRank,
  UserLevelMap,
  UserSportDefaultValueMap,
} from "Components/Shared/UserDetailsForm/constant";
import { UserSportFormPropsType } from "Interfaces/Components/Shared/UserDetailsFormInterface";
import { COACH, PLAYER, SELECT_SPORT, SPORT } from "App/constants";

const UserSportForm = ({
  sportsDetailList,
  OnAddNewSport,
  errors,
  isEdit,
  userType,
}: UserSportFormPropsType) => {
  const [newSportIndex, setnewSportIndex] = useState<number[]>([]);
  const addMoreSport = () => {
    setnewSportIndex([...newSportIndex, sportsDetailList.length]);
    OnAddNewSport([
      ...sportsDetailList,
      { ...UserSportDefaultValueMap[userType ? userType : PLAYER] },
    ]);
  };

  const removeSport = (index: number) => {
    if (isEdit && !newSportIndex.includes(index)) {
      sportsDetailList[index]._destroy = true;
      OnAddNewSport([...sportsDetailList]);
    } else {
      OnAddNewSport([
        ...sportsDetailList.splice(0, sportsDetailList.length - 1),
      ]);
    }
  };

  return (
    <>
      {sportsDetailList &&
        sportsDetailList.map((sport, index) => {
          const { sport_id, level, rank, sub_rank, matches_played, _destroy } =
            sport;
          if (_destroy) return "";
          return (
            <div className="sports-details-wrap d-grid gap-4 p-4" key={index}>
              <Row>
                <Col lg={4}>
                  <div className="d-flex align-items-center flex-1 justify-content-between">
                    <SelectSports
                      isRequired={!!errors[index]?.sport_id_error}
                      SportMsg={errors[index]?.sport_id_error}
                      isDisableDropdown={
                        isEdit && !newSportIndex.includes(index)
                      }
                      handleSelect={(
                        event: React.ChangeEvent<HTMLSelectElement>
                      ) => {
                        sportsDetailList[index].sport_id = event.target.value;
                        OnAddNewSport([...sportsDetailList]);
                      }}
                      shouldShowAll={false}
                      placeHolder={SELECT_SPORT}
                      selected={sport_id}
                      title={
                        userType === COACH
                          ? `${SPORT}*`
                          : `${SPORT} ${index + 1}*`
                      }
                    />
                  </div>
                </Col>
                <Col as={Col} lg={{ span: 4, offset: 4 }}>
                  <div className="d-flex align-items-center flex-1 justify-content-between">
                    <Form.Group controlId="selectLevel" className="flex-1">
                      <Form.Label className="fs-sm">Level*</Form.Label>
                      <Form.Select
                        required
                        className="ss-select-control bg-white"
                        defaultValue={level}
                        value={level}
                        isInvalid={!!errors[index]?.level_error}
                        onChange={(
                          event: React.ChangeEvent<HTMLSelectElement>
                        ) => {
                          const {
                            target: { value },
                          } = event;
                          sportsDetailList[index].level = value;
                          OnAddNewSport([...sportsDetailList]);
                        }}
                      >
                        <option value="" key="" disabled selected>
                          Select Level
                        </option>
                        {userType &&
                          UserLevelMap[userType].map((level, index) => {
                            const { name, value } = level;
                            return (
                              <option key={index} value={value}>
                                {name}
                              </option>
                            );
                          })}
                      </Form.Select>
                      <DisplayErrorMessage
                        errorField={errors[index]?.level_error}
                      />
                    </Form.Group>
                    {((sportsDetailList.length > 1 && !isEdit) ||
                      newSportIndex.includes(index)) && (
                      <FontAwesomeIcon
                        className="ms-4 fs-5"
                        type="button"
                        icon={faXmark}
                        onClick={() => removeSport(index)}
                      />
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <Form.Label className="fs-xs me-2">
                    {userType === COACH && sport.rank === ""
                      ? FORM_CONSTANT.Rank
                      : FORM_CONSTANT.ReqRank}
                  </Form.Label>
                  <div className="d-flex gap-2 flex-column">
                    <div className="d-flex gap-3">
                      {Rank.map((item, i) => (
                        <div key={index + "" + i}>
                          <MultiSelect
                            multiSelectText={item}
                            className={rank === item ? "active" : ""}
                            key={`rank${index}_${i}`}
                            onClick={() => {
                              sportsDetailList[index].rank = item;
                              OnAddNewSport([...sportsDetailList]);
                            }}
                          />
                        </div>
                      ))}
                    </div>
                    <p className="fs-xs mb-0 text-danger">
                      {errors[index]?.rank_error}
                    </p>
                  </div>
                </Col>
                <Col lg={4}>
                  <Form.Label className="fs-xs me-2">
                    {userType === COACH && sport.sub_rank === ""
                      ? FORM_CONSTANT.SubRank
                      : FORM_CONSTANT.ReqSubRank}
                  </Form.Label>
                  <div className="d-flex gap-2 flex-column">
                    <div className="d-flex gap-3">
                      {SubRank.map((item, i) => (
                        <MultiSelect
                          multiSelectText={item}
                          className={sub_rank === item ? "active" : ""}
                          key={index + i}
                          onClick={() => {
                            sportsDetailList[index].sub_rank = item;
                            OnAddNewSport([...sportsDetailList]);
                          }}
                        />
                      ))}
                    </div>
                    <p className="fs-xs mb-0 text-danger">
                      {errors[index]?.sub_rank_error}
                    </p>
                  </div>
                </Col>
                <Form.Group controlId="matches" as={Col} lg={4}>
                  <Form.Label className="fs-xs">Matches Played</Form.Label>
                  <Form.Control
                    value={matches_played}
                    type="number"
                    placeholder="Enter Number"
                    className="bg-white"
                    min={0}
                    onChange={(event) => {
                      sportsDetailList[index].matches_played =
                        event.target.value;
                      OnAddNewSport([...sportsDetailList]);
                    }}
                  />
                </Form.Group>
              </Row>
            </div>
          );
        })}
      <div className="px-4">
        <ButtonIcon
          className="btn-outline-secondary fs-sm"
          onClick={addMoreSport}
        >
          <FontAwesomeIcon icon={faPlus} className="me-2" />
          Add Another Sport
        </ButtonIcon>
      </div>
    </>
  );
};

export default UserSportForm;
