import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "Components/Core";
import {
  SearchControl,
  SelectSports,
  DrillsListComponent,
  PaginationControl,
  HeaderComponent,
} from "Components/Shared";
import { getDrillsList } from "Api/Pages/DrillsApi";
import { DrillsDetailsObjectType } from "Interfaces/Pages/DrillsInterface";
import { ROUTE_DRILLS_DETAILS } from "Pages/OLD-Drills/constants";
import { DRILLS, INITIAL_PAGE, RECORDS_PER_PAGE, SPORT } from "App/constants";
import "./drills.scss";

const Drills = () => {
  const [drillsList, setDrillsList] = useState<DrillsDetailsObjectType[]>([]),
    [searchQuery, setSearchQuery] = useState<string>(""),
    [totalPages, setTotalPages] = useState<number>(INITIAL_PAGE),
    [selectedSport, setSelectedSport] = useState<string>(""),
    [currentPage, setCurrentPage] = useState<number>(INITIAL_PAGE);

  const navigate = useNavigate(),
    handleSearch = (evant: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = evant;
      setSearchQuery(value);
    },
    fetchDrillsList = useCallback(async () => {
      const { drills, total_count } = await getDrillsList({
        currentPage,
        searchQuery,
        selectedSport,
      });
      setDrillsList(drills);
      setTotalPages(Math.ceil(total_count / RECORDS_PER_PAGE));
    }, [selectedSport, searchQuery, currentPage]),
    onDrillClick = (data: DrillsDetailsObjectType) => {
      navigate(ROUTE_DRILLS_DETAILS(data.id));
    };

  useEffect(() => {
    fetchDrillsList();
  }, [fetchDrillsList]);

  return (
    <div className="ss-page-content d-flex flex-column flex-1 scroll-y bg-white">
      <HeaderComponent title={DRILLS} shouldShowNav={false} />
      <div className="ss-page-body d-flex flex-column flex-1 scroll-y py-4">
        <Form
          className="d-flex align-items-end justify-content-between mb-4 px-4"
          onSubmit={(event: React.ChangeEvent<HTMLFormElement>) =>
            event.preventDefault()
          }
        >
          <SelectSports
            handleSelect={(event: React.ChangeEvent<HTMLSelectElement>) => {
              setSelectedSport(event.target.value);
            }}
            selected={selectedSport}
            shouldShowAll={true}
            shouldPassMasterSportId={true}
            title={SPORT}
          />
          <SearchControl
            placeholder="Search"
            label="Search"
            onChange={handleSearch}
          />
        </Form>
        <DrillsListComponent
          drillsList={drillsList}
          onDrillClick={onDrillClick}
          isAssignList={false}
        />
        <div className="pagination-wrap d-flex justify-content-end px-4">
          <div className="pagination-wrap d-flex justify-content-end px-4">
            <PaginationControl
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Drills;
