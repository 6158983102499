import React, { useEffect } from "react";
import { AxiosResponse } from "axios";
import {
  Button,
  Modal,
  Form as BootstrapForm,
  Row,
  Col,
  ToggleButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { addNotification } from "Api/Pages/NotificationsApi";
import {
  NotificationData,
  NotificationFormProps,
} from "Interfaces/Pages/NotificationsInterface";
import { DEFAULT_ENTITY, PLACEHOLDERS } from "Pages/Notifications/constants";

const NotificationForm: React.FC<NotificationFormProps> = ({
  showModal,
  onClose,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm<NotificationData>({
    defaultValues: {
      title: "",
      message: "",
      entityType: DEFAULT_ENTITY,
    },
  });

  useEffect(() => {
    if (!showModal) {
      // Clear the form fields when the modal is closed
      reset();
      setValue("entityType", DEFAULT_ENTITY); // Reset to default entity
    }
  }, [showModal, reset, setValue]);

  let academyId: number;
  try {
    const value = window.localStorage.getItem("academyId");
    academyId = value ? parseInt(value, 10) : 0; // or use a fallback default
    if (isNaN(academyId)) throw new Error("Invalid academyId format");
  } catch (error) {
    console.error("Error parsing academyId:", error);
    academyId = 0; // or handle as needed
  }

  const submitNotification = useMutation<
    AxiosResponse,
    Error,
    NotificationData
  >((notificationData) => addNotification(academyId, notificationData), {
    onSuccess: () => {
      onClose();
    },
    onError: (error) => {
      console.error("Error adding notification:", error);
    },
  });

  const onSubmit = (data: NotificationData): void => {
    // Transform data to match backend format
    const transformedData = {
      ...data,
      entity_type: data.entityType, // Add `entity_type` for backend
    };

    submitNotification.mutate(transformedData);
  };

  return (
    <>
      {/* Modal for adding notifications */}
      <Modal show={showModal} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BootstrapForm>
            {/* Title Field */}
            <BootstrapForm.Group controlId="formTitle">
              <BootstrapForm.Label>Title</BootstrapForm.Label>
              <BootstrapForm.Control
                type="text"
                placeholder={PLACEHOLDERS.title}
                {...register("title", { required: "Title is required" })}
              />
              {errors.title && (
                <p className="text-danger">{errors.title.message}</p>
              )}
            </BootstrapForm.Group>

            {/* Description Field */}
            <BootstrapForm.Group controlId="formDescription" className="mt-3">
              <BootstrapForm.Label>Description</BootstrapForm.Label>
              <BootstrapForm.Control
                as="textarea"
                rows={3}
                placeholder={PLACEHOLDERS.description}
                {...register("message", {
                  required: "Description is required",
                })}
              />
              {errors.message && (
                <p className="text-danger">{errors.message.message}</p>
              )}
            </BootstrapForm.Group>

            {/* Entity Toggle Switch */}
            <BootstrapForm.Group as={Row} className="mt-4">
              <Col sm={12} className="d-flex justify-content-center">
                <ToggleButtonGroup
                  type="radio"
                  name="entityType"
                  value={watch("entityType")}
                  onChange={(value) => setValue("entityType", value)}
                >
                  <ToggleButton
                    id="tbg-radio-1"
                    value="Player"
                    variant="outline-primary"
                  >
                    Player
                  </ToggleButton>
                  <ToggleButton
                    id="tbg-radio-2"
                    value="Coach"
                    variant="outline-primary"
                  >
                    Coach
                  </ToggleButton>
                </ToggleButtonGroup>
              </Col>
            </BootstrapForm.Group>
          </BootstrapForm>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit(onSubmit)}>
            Send All
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NotificationForm;
