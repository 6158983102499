import { AcademyDetailsPropsObjectTypes } from "Interfaces/Pages/AcademyInterface";
import { Row, Col } from "Components/Core";

import AdminDetails from "../AdminDetailsComponent";

const AcademyDetails = ({
  eventKey,
  academyDetails,
}: AcademyDetailsPropsObjectTypes) => {
  const {
    name,
    email,
    contact_number,
    country_code,
    address1,
    address2,
    city,
    website,
    timezone,
    details,
    admin,
  } = academyDetails;

  return (
    <>
      {eventKey === "Admin" ? (
        <AdminDetails admin={admin} academyId={academyDetails.id} />
      ) : (
        <div className="d-grid gap-4 px-4 pb-3">
          <Row>
            <Col lg={4}>
              <label className="fs-xs">Name</label>
              <p className="fs-sm fw-bold mb-0">{name}</p>
            </Col>
            <Col lg={4}>
              <label className="fs-xs"> Contact Number</label>
              <p className="fs-sm fw-bold mb-0">
                {country_code
                  ? `${country_code} ${contact_number}`
                  : contact_number}
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <label className="fs-xs">Email ID</label>
              <p className="fs-sm fw-bold mb-0">{email || "-"}</p>
            </Col>
            <Col lg={4}>
              <label className="fs-xs">More Details About Academy</label>
              <p className="fs-sm fw-bold mb-0">{details}</p>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <label className="fs-xs">Website</label>
              <p className="fs-sm fw-bold mb-0">{website}</p>
            </Col>
            <Col lg={4}>
              <label className="fs-xs"> Contact Number</label>
              <p className="fs-sm fw-bold mb-0">
                {country_code
                  ? `${country_code} ${contact_number || "-"}`
                  : contact_number || "-"}
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <label className="fs-xs">Address 1</label>
              <p className="fs-sm fw-bold mb-0">{address1}</p>
            </Col>
            <Col lg={4}>
              <label className="fs-xs">Address 2</label>
              <p className="fs-sm fw-bold mb-0">{address2}</p>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <label className="fs-xs">City</label>
              <p className="fs-sm fw-bold mb-0">{city}</p>
            </Col>
            <Col lg={4}>
              <label className="fs-xs">Timezone</label>
              <p className="fs-sm fw-bold mb-0">{timezone}</p>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default AcademyDetails;
