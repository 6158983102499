import { Row, Col } from "Components/Core";
import { ButtonIcon } from "Components/Shared";
import { useNavigate } from "react-router-dom";

import { AdminItemPropsObjectsTypes } from "Interfaces/Pages/AdminInterface";
import PlayersLogo from "App/images/players-icon.svg";
import { ROUTE_ADMIN_CREATE } from "Pages/Academies/constants";
import "../../../Pages/Academies/academy.scss";

const AdminDetails = ({ admin, academyId }: AdminItemPropsObjectsTypes) => {
  const navigate = useNavigate(),
    { name, email, contact_number } = admin || {};

  const onAddAdmin = (id: number) => {
    navigate(ROUTE_ADMIN_CREATE(id));
  };

  return (
    <>
      {!admin ? (
        <>
          <div className="d-flex">
            <ButtonIcon
              className="btn-outline-secondary fs-sm btn btn-link add-admin-btn"
              onClick={(event) => {
                event.stopPropagation();
                onAddAdmin && onAddAdmin(academyId);
              }}
            >
              Add Admin
            </ButtonIcon>
          </div>
        </>
      ) : (
        <div className="d-grid gap-4 px-4 pb-3">
          <Row>
            <Col lg={4}>
              <div className="user-profile border rounded-circle d-flex align-items-center justify-content-center">
                <img src={PlayersLogo} alt="Image1" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <label className="fs-xs">Name</label>
              <p className="fs-sm fw-bold mb-0">{name}</p>
            </Col>
            <Col lg={4}>
              <label className="fs-xs"> Contact Number</label>
              <p className="fs-sm fw-bold mb-0">{contact_number || "-"}</p>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <label className="fs-xs">Email ID</label>
              <p className="fs-sm fw-bold mb-0">{email || "-"}</p>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default AdminDetails;
