import React from "react";
import { ToggleSwitchProps } from "Interfaces/Components/Shared/ToggleSwitchInterface";
import "./toggleSwitch.scss";

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  textLeft,
  textRight,
  onClick,
  checked,
  className,
}) => {
  return (
    <label
      className={`ss-toggle-switch nolabel position-relative ${className}`}
    >
      <input type="checkbox" checked={checked} className="position-absolute" />
      <a href="w" className="position-absolute toggle-anchor">
        ""
      </a>
      <span
        className="text-field position-absolute text-nowrap text-left"
        onClick={onClick}
      >
        <span className="left-span fs-sm position-absolute text-center">
          {textLeft}
        </span>
        <span className="right-span fs-sm position-absolute text-center">
          {textRight}
        </span>
      </span>
    </label>
  );
};

export default ToggleSwitch;
