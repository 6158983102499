import axios from "axios";
import {
  ForgetPasswordPayloadObjectType,
  SportParam,
  AddSportParam,
  DeleteSportParam,
  GetPlayerListParam,
  DeletePlayerParam,
  DeleteCoachParam,
  GetCoachListParam,
  GetBatchListParam,
  GetBatchCoachParam,
  AddPlayerParam,
  GetUserDataParam,
  AddQuestionParam,
  AddBatchParam,
  getBatchDataParam,
  GetMasterParam,
  AssignBatchUserParam,
  GetBatchUserParam,
  UpdateQuestionParam,
  QuestionParamObjectType,
  DrillListApiObjectType,
  GetSurveyHistoryParam,
  BulkUploadParam,
} from "Interfaces/AppInterface";
import { ResetPasswordFormObjectType } from "Interfaces/Pages/ForgotPasswordInterface";
import { LoginDetails } from "Interfaces/Pages/LoginInterface";
import { GetListParam } from "Interfaces/Components/Shared/DisplayListComponentInterface";
import {
  ACADEMY_BASE_URL,
  BASE_URL,
  LOGIN_URL,
  LOGOUT_URL,
  SURVEY_HISTORY_USER,
  SURVEY_QUESTION,
  DRILLS,
  BatchItemMap,
  FORGET_PASSWORD_URL,
  USER_LIST,
  BULK_UPLOAD_ROUTE,
  BATCH,
  BATCH_USER,
} from "App/constants";
import { USER_ATTRIBUTES } from "Pages/Players/constants";
import { REPORTS_ROUTE } from "Pages/Reports/constants";
import {
  convertDateInto_Y_M_D_format,
  setAuthorisationInfo,
} from "Utils/utils";
import { GetReportParam } from "Interfaces/Pages/ReportsInterface";

export const login = async (data: LoginDetails) => {
  const response = await axios.post(LOGIN_URL, data);
  setAuthorisationInfo(response);
  return response.data;
};

export const forgetPassword = async (data: ForgetPasswordPayloadObjectType) => {
  const response = await axios.post(FORGET_PASSWORD_URL, data);
  return response.data;
};

export const resetPassword = async (payload: ResetPasswordFormObjectType) => {
  const response = await axios
    .put(FORGET_PASSWORD_URL, payload)
    .then((response) => response)
    .catch((error) => error.response);
  return response.data;
};

const getAcademyId = (academyId?: number) =>
  academyId || localStorage.getItem("academyId");

export const getSportsList = async (academyId: number) => {
  const { data } = await axios.get(
    `${ACADEMY_BASE_URL}${getAcademyId(academyId)}/sports`
  );
  if (data) {
    const selectedSports = data?.sports?.map((sport: any) => sport.name);
    localStorage.setItem("selected_sports", JSON.stringify(selectedSports));
    return data?.sports;
  }
  return [];
};

export const getSportDetail = async ({ academyId, sportId }: SportParam) => {
  const { data } = await axios.get(
    `${ACADEMY_BASE_URL}${getAcademyId(academyId)}/sports/${sportId}`
  );
  if (data) {
    return data;
  }
  return [];
};

export const getMasterSportsList = async () => {
  const { data } = await axios.get(`${BASE_URL}master_sports`);
  if (data) {
    return data;
  }
  return [];
};

export const addSportList = async ({ academyId, ids }: AddSportParam) => {
  await axios.post(
    `${ACADEMY_BASE_URL}${getAcademyId(academyId)}/sports/bulk_create`,
    {
      sports: { ids },
    }
  );

  return [];
};

export const deleteSport = async ({ academyId, sportId }: DeleteSportParam) => {
  await axios.put(
    `${ACADEMY_BASE_URL}${getAcademyId(academyId)}/sports/${sportId}/deactivate`
  );
};

export const getPlayerList = async ({
  academyId,
  searchInput,
  selectValue,
  currentPage,
}: GetPlayerListParam) => {
  const queryParams = `page=${currentPage}&sport_id=${selectValue}&search=${searchInput}&order_by=name&order_type=asc`;
  const { data } = await axios.get(
    `${ACADEMY_BASE_URL}${getAcademyId(academyId)}/players?${queryParams}`
  );
  if (data) {
    return data;
  }
  return [];
};

export const deletePlayer = async ({ academyId, id }: DeletePlayerParam) => {
  const { data } = await axios.put(
    `${ACADEMY_BASE_URL}${getAcademyId(academyId)}/players/${id}/deactivate`,
    {
      player: {
        is_active: "false",
      },
    }
  );
  if (data) return data;
};
export const deleteCoach = async ({ academyId, id }: DeleteCoachParam) => {
  const { data } = await axios
    .put(
      `${ACADEMY_BASE_URL}${getAcademyId(academyId)}/coaches/${id}/deactivate`,
      {
        coach: {
          is_active: "false",
        },
      }
    )
    .catch((error) => error.response);

  return data;
};
export const getCoachList = async ({
  academyId,
  searchInput,
  selectValue,
  currentPage,
}: GetCoachListParam) => {
  const queryParams = `page=${currentPage}&sport_id=${selectValue}&search=${searchInput}&order_by=name&order_type=asc`;
  const { data } = await axios.get(
    `${ACADEMY_BASE_URL}${getAcademyId(academyId)}/coaches?${queryParams}`
  );
  if (data) {
    return data;
  }
  return [];
};
export const getBatchList = async ({
  academyId,
  searchInput,
  selectValue,
  currentPage,
  active,
  sort_column,
  order_by,
}: GetBatchListParam) => {
  const queryParams = `page=${currentPage}&sport_id=${selectValue}&search=${searchInput}&type=${active}&sort_column=${sort_column}&order_by=${order_by}`;
  const { data } = await axios.get(
    `${ACADEMY_BASE_URL}${getAcademyId(academyId)}/batches?${queryParams}`
  );
  if (data) {
    return data;
  }
  return [];
};
export const getBatchCoachList = async ({
  academyId,
  id,
}: GetBatchCoachParam) => {
  const { data } = await axios.get(
    `${ACADEMY_BASE_URL}${getAcademyId(academyId)}/batches/${id}/coaches`
  );
  if (data?.coaches?.length) {
    return data;
  }
  return [];
};
export const getBatchPlayerList = async ({
  academyId,
  id,
}: GetBatchCoachParam) => {
  const { data } = await axios.get(
    `${ACADEMY_BASE_URL}${getAcademyId(academyId)}/batches/${id}/players`
  );
  if (data?.players?.length) {
    return data;
  }
  return [];
};
export const getBatchInfo = async (academyId: number, id: number) => {
  const { data } = await axios.get(
    `${ACADEMY_BASE_URL}${getAcademyId(academyId)}/batches/${id}`
  );
  if (data) {
    return data;
  }
  return [];
};

export const cancelBatch = async (batchId: number) => {
  await axios.put(
    `${ACADEMY_BASE_URL}${getAcademyId()}/batches/${batchId}/cancel_batch`
  );
};

export const addPlayer = async ({
  academyId,
  param,
  isEditUser,
}: AddPlayerParam) => {
  const user_attributes: any = {};
  USER_ATTRIBUTES.forEach((ele: any) => {
    user_attributes[ele] = param[ele];
    delete param[ele];
  });

  delete param.image;
  if (isEditUser) {
    param.player_sports &&
      param.player_sports.length &&
      param.player_sports.forEach((sportData: any) => {
        delete sportData.sport;
      });
    await axios.put(
      `${ACADEMY_BASE_URL}${getAcademyId(academyId)}/players/${param.id}`,
      {
        player: {
          ...param,
          user_attributes,
        },
      }
    );
    return;
  }
  await axios.post(`${ACADEMY_BASE_URL}${getAcademyId(academyId)}/players`, {
    player: {
      ...param,
      user_attributes,
    },
  });

  return [];
};

export const getPlayerData = async ({ academyId, id }: GetUserDataParam) => {
  const data: any = await axios.get(
    `${ACADEMY_BASE_URL}${getAcademyId(academyId)}/players/${id}`
  );
  if (data) {
    return data.data;
  }
  return [];
};

export const addCoach = async ({
  academyId,
  param,
  isEditUser,
}: AddPlayerParam) => {
  const user_attributes: any = {};
  USER_ATTRIBUTES.forEach((ele: any) => {
    user_attributes[ele] = param[ele];
    delete param[ele];
  });
  delete param.image;
  if (isEditUser) {
    param.coach_sports &&
      param.coach_sports.length &&
      param.coach_sports.forEach((sportData: any) => {
        delete sportData.sport;
      });
    await axios.put(
      `${ACADEMY_BASE_URL}${getAcademyId(academyId)}/coaches/${param.id}`,
      {
        coach: {
          ...param,
          user_attributes,
        },
      }
    );
    return;
  }
  await axios.post(`${ACADEMY_BASE_URL}${getAcademyId(academyId)}/coaches`, {
    coach: {
      ...param,
      user_attributes,
    },
  });

  return [];
};
export const getQuestionsList: Function = async ({
  page,
  masterSportId,
  applicableFor,
  triggerEvent,
  questionId,
}: QuestionParamObjectType) => {
  const queryParams = `?page=${page}&master_sport_id=${masterSportId}&applicable_for=${applicableFor}&trigger_event=${triggerEvent}`;
  const { data } = await axios.get(
    `${BASE_URL}survey_questions${questionId ? `/${questionId}` : queryParams}`
  );
  if (data) {
    return data;
  }
  return [];
};

export const AddQuestion = async ({
  academyId,
  param,
  isEditUser,
}: AddQuestionParam) => {
  await axios.post(`${BASE_URL}survey_questions`, {
    survey_question: { ...param },
  });

  return [];
};
export const UpdateQuestion = async ({
  questionId,
  param,
  isEditUser,
}: UpdateQuestionParam) => {
  return await axios
    .put(
      `${BASE_URL}survey_questions/${questionId}${
        isEditUser ? "" : "/deactivate"
      }`,
      isEditUser && {
        survey_question: { ...param },
      }
    )
    .catch((error) => error.response);
};

export const getDrillsList = async (param: DrillListApiObjectType) => {
  const { currentPage, searchQuery, selectedSport } = param;
  const { data } = await axios.get(
    `${BASE_URL}drills?page=${currentPage}&master_sport_id=${selectedSport}&search_query=${searchQuery}`
  );
  return data;
};

export const getDrillDetails = async (id: string) => {
  const { data } = await axios.get(`${BASE_URL}drills/${id}`);
  if (data) {
    return data;
  } else return [];
};

export const getCountryCode = async (academyId: number) => {
  const { data } = await axios.get(`${BASE_URL}country_codes`);
  if (data) {
    const contryCodeArr = data.country_codes.map(
      (country: any, key: string) => {
        return country.country_code;
      }
    );
    return contryCodeArr;
  }
  return [];
};

export const logout = async () => {
  await axios.delete(`${LOGOUT_URL}`);
};

export const getAllCoach = async (sportId: string, searchInput: string) => {
  const data = await axios.get(
    `${ACADEMY_BASE_URL}${getAcademyId()}/coaches?sport_id=${sportId}&search=${searchInput}`
  );
  if (data) {
    return data.data.coaches;
  }
  return [];
};

export const addBatch = async ({ param, isEditBatch }: AddBatchParam) => {
  if (isEditBatch) {
    await axios.put(
      `${ACADEMY_BASE_URL}${getAcademyId()}/batches/${param.id}`,
      {
        batch: {
          ...param,
        },
      }
    );
    return [];
  }

  await axios.post(`${ACADEMY_BASE_URL}${getAcademyId()}/batches`, {
    batch: {
      ...param,
    },
  });

  return [];
};

export const getBatchData = async ({ id }: getBatchDataParam) => {
  const data: any = await axios.get(
    `${ACADEMY_BASE_URL}${getAcademyId()}/batches/${id}`
  );
  if (data) {
    data.data.batch_coaches = data.data.batch_coaches.map(
      ({ id, coach_id, coach: { name } }: any) => ({
        id,
        coach_id,
        _destroy: false,
        name,
      })
    );

    return data.data;
  }
  return [];
};

export const getMasterList = async ({
  academyId,
  user,
  batchId,
  searchInput,
  applicable_for,
  sportId,
  page,
  trigger_event,
}: GetMasterParam) => {
  const param: string = `page=${page}&reject_batch_id=${batchId}&order_by=name&order_type=asc&search=${searchInput}`;
  if (user === SURVEY_QUESTION) {
    const { data } = await axios.get(
      `${BASE_URL}survey_questions?&master_sport_id=${sportId}&applicable_for=${applicable_for}&trigger_event=${trigger_event}&${param}`
    );
    return data;
  } else if (user === DRILLS) {
    const { data } = await axios.get(
      `${BASE_URL}drills?master_sport_id=${sportId}&${param}`
    );
    return data;
  } else {
    const { data } = await axios.get(
      `${ACADEMY_BASE_URL}${getAcademyId(academyId)}/${[
        BATCH_USER[user],
      ]}?sport_id=${sportId}&${param}`
    );
    return data;
  }
};

export const assignBatchUser = async ({
  user,
  academyId,
  id,
  batchItemSelectedIds,
}: AssignBatchUserParam) => {
  const { data } = await axios.put(
    `${ACADEMY_BASE_URL}${getAcademyId(academyId)}/batches/${id}`,
    {
      batch: {
        [BatchItemMap[user]]: { ...batchItemSelectedIds },
      },
    }
  );
  if (data) {
    return data;
  }
};

export const getUserData = async ({
  academyId,
  id,
  userType,
}: GetUserDataParam) => {
  const data: any = await axios.get(
    `${ACADEMY_BASE_URL}${getAcademyId(academyId)}/${[
      BATCH_USER[userType],
    ]}/${id}`
  );
  if (data) {
    return data.data;
  }
  return [];
};

export const getBatchItemList = async ({
  academyId,
  batchId,
  userType,
  page,
  applicable_for,
  trigger_event,
}: GetBatchUserParam) => {
  let response = null;
  if (userType === SURVEY_QUESTION) {
    const queryParams: string = `?page=${page}&applicable_for=${applicable_for}&trigger_event=${trigger_event}`;
    response = await axios.get(
      `${ACADEMY_BASE_URL}${getAcademyId(
        academyId
      )}/batches/${batchId}/survey_questions${queryParams}`
    );
  } else if (userType === DRILLS) {
    response = await axios.get(
      `${ACADEMY_BASE_URL}${getAcademyId(
        academyId
      )}/batches/${batchId}/drills?page=${page}`
    );
  } else {
    response = await axios.get(
      `${ACADEMY_BASE_URL}${getAcademyId(academyId)}/batches/${batchId}/${[
        BATCH_USER[userType],
      ]}?page=${page}`
    );
  }
  const { data } = response;
  return data;
};

export const getReportList = async ({
  academyId,
  dropDownValue,
  numOfDays,
  selectedSportValue,
  searchInput,
  currentPage,
  start_date,
  end_date,
}: GetReportParam) => {
  let queryParams = `?sport_id=${selectedSportValue}&page=${currentPage}&search=${searchInput}`;
  if (start_date && end_date) {
    queryParams += `&start_date=${convertDateInto_Y_M_D_format(
      start_date
    )}&end_date=${convertDateInto_Y_M_D_format(end_date)}`;
  } else {
    queryParams += `&days=${numOfDays}`;
  }

  const { data } = await axios.get(
    `${ACADEMY_BASE_URL}${getAcademyId(academyId)}/reports/${[
      REPORTS_ROUTE[dropDownValue],
    ]}${queryParams}`
  );
  if (data) {
    return data;
  } else {
    return [];
  }
};

export const getSurveyHistory = async ({
  title,
  academyId,
  id,
  currentPage,
  batchId,
  triggerEvent,
}: GetSurveyHistoryParam) => {
  const { data } = await axios.get(
    `${ACADEMY_BASE_URL}${getAcademyId(academyId)}/${[
      SURVEY_HISTORY_USER[title],
    ]}/${id}/survey_history?trigger_event=${triggerEvent}&page=${currentPage}&batch_id=${batchId}`
  );
  if (data) {
    return data;
  }
  return [];
};

export const getSurveyHistoryDetails = async (
  id: number,
  academyId: number
) => {
  const { data } = await axios.get(
    `${ACADEMY_BASE_URL}${getAcademyId(academyId)}/surveys/${id}`
  );
  if (data) {
    return data;
  }
  return [];
};

export const getList = async ({
  userType,
  academyId,
  searchInput,
  selectValue,
  currentPage,
  active,
  sort_column,
  order_by,
}: GetListParam) => {
  let queryParams = `page=${currentPage}&sport_id=${selectValue}&search=${searchInput}`;
  if (userType === BATCH)
    queryParams += `&type=${active}&sort_column=${sort_column}&order_by=${order_by}`;
  else queryParams += `&order_by=name&order_type=asc`;

  const { data } = await axios.get(
    `${ACADEMY_BASE_URL}${getAcademyId(academyId)}/${[
      USER_LIST[userType],
    ]}?${queryParams}`
  );
  if (data) {
    return data;
  }
  return [];
};

export const bulkUpload = async ({ title, formData }: BulkUploadParam) => {
  await axios.post(`${BASE_URL}${[BULK_UPLOAD_ROUTE[title]]}`, formData, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
};
