import { NotificationsObjectType } from "Interfaces/Pages/NotificationsInterface";
import { PermissionsObjType } from "Interfaces/UtilsInterface";

export const NotificationDetailDefaultValue: NotificationsObjectType = {
  id: 0,
  entity_id: 0,
  entity_type: 0,
  is_read: false,
  message: "",
  notification_type: "",
  seen_at: null,
  sent_at: null,
  title: "",
  metadata: {},
};

export const getColumns = (permissions: PermissionsObjType) => [
  {
    dataField: "title",
    title: "Title",
    colWidth: "10%",
  },
  {
    dataField: "notification_type",
    title: "Notification Type",
    colWidth: "10%",
  },
  {
    dataField: "message",
    title: "message",
    colWidth: "30%",
  },
  {
    dataField: "is_read",
    title: "Status",
    colWidth: "10%",
    formatter: (data: NotificationsObjectType) => {
      return data.is_read === true ? (
        ""
      ) : (
        <span style={{ color: "red" }}>New</span>
      );
    },
  },
  {
    dataField: "sent_at",
    title: "Send At",
    colWidth: "10%",
  },
];

export const NOTIFICATIONS: string = "Notifications";
export enum NotificationType {
  PLAYER_PROFILE_UPDATE = "player_profile_update",
  VIDEO_APPROVAL_REQUEST = "video_approval_request",
}

export const DEFAULT_ENTITY = "Player";

export const PLACEHOLDERS = {
  title: "Enter Notification Title....",
  description: "Enter Notification Description...",
};
