import React from "react";
import { Form } from "Components/Core";
import { CheckBoxProps } from "Interfaces/Components/Shared/CheckBoxItemInterface";
import "./checkboxItem.scss";

const CheckboxItem: React.FC<CheckBoxProps> = (props) => {
  const { className, onClick, name, src, checkboxName, isChecked, onChange } =
    props;

  return (
    <div
      className={`checkbox-item-box d-flex flex-column justify-content-between border px-3 py-3 position-relative ${className}`}
      onClick={(e) => onClick && onClick(e)}
    >
      <div className="img-box-wrap d-flex align-items-center justify-content-center overflow-hidden">
        <img src={src} alt="sports" className="rounded-circle" />
      </div>
      <p className="fs-xs mb-0 text-truncate text-center">{name}</p>
      <Form.Check
        className="checkbox-field"
        name={checkboxName}
        type="checkbox"
      >
        <Form.Check.Input
          type="checkbox"
          checked={isChecked}
          onChange={(e) => onChange && onChange(e)}
        />
      </Form.Check>
    </div>
  );
};

CheckboxItem.defaultProps = {
  className: "",
  isChecked: false,
};

export default CheckboxItem;
