import {
  faArrowDown,
  faArrowUp,
  faPencil,
  faTimes,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "Components/Core";
import { ButtonIcon } from "Components/Shared";
import { FILE_TYPE_MAP } from "Pages/OLD-Drills/constants";
import { FORM_CONSTANT } from "Pages/SurveyQuestions/constants";
import { BATCH } from "App/constants";
import {
  BatchCoachColumnPropsType,
  BatchCoachObjectType,
  BatchColumnPropsType,
  BatchDetailsObjectType,
  BatchDrillColumnPropsType,
  BatchDrillObjectType,
  BatchFormConstantsType,
  BatchItemTabsObjectType,
  BatchPlayerColumnPropsType,
  BatchPlayerObjectType,
  BatchQuestionColumnPropsType,
  BatchSurveyQuestionObjectType,
  ItemMapType,
  SessionCountObjectType,
  SortColumnType,
} from "Interfaces/Pages/BatchesInterface";
import { SportDetailDefaultValue } from "Pages/Sports/constants";
import { isAllowedTo } from "Utils/helper";
import { getTimeRanges, titleCaseConversion } from "Utils/utils";

export const START_DATE: string = "Start Date";
export const END_DATE: string = "End Date";

export const getBatchQuestionColumns = ({
  onUpdateQuestion,
  permissions,
}: BatchQuestionColumnPropsType) => [
  {
    dataField: "title",
    title: "Question",
    formatter: (props: BatchSurveyQuestionObjectType) => {
      return props && props.survey_question?.title;
    },
  },
  {
    dataField: "master_sport_id",
    title: "Type",
    formatter: (props: BatchSurveyQuestionObjectType) => {
      return props.survey_question?.master_sport_id
        ? FORM_CONSTANT.specific
        : FORM_CONSTANT.generic;
    },
  },
  isAllowedTo(BATCH, "update", permissions) && {
    colWidth: "20rem",
    formatter: (props: BatchSurveyQuestionObjectType) => (
      <div className="d-flex" key={props.id}>
        <ButtonIcon
          className="fs-xs"
          onClick={(event) => {
            event.stopPropagation();
            onUpdateQuestion({
              id: props.survey_question?.id,
              batchItemId: props?.id,
              isRemove: true,
            });
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </ButtonIcon>
      </div>
    ),
  },
];

export const getBatchDrillColumns = ({
  onRemoveDrill,
  permissions,
}: BatchDrillColumnPropsType) => [
  {
    colWidth: "10rem",
    formatter: (props: BatchDrillObjectType) => {
      return (
        <div className="thumbnail-box position-relative">
          <img
            src={props.drill?.preview}
            width="100%"
            height="100%"
            alt={props.drill?.file_type}
          />
          {props.drill?.file_type && FILE_TYPE_MAP[props.drill?.file_type] && (
            <FontAwesomeIcon
              icon={FILE_TYPE_MAP[props.drill?.file_type]}
              className="position-absolute fs-5 icon-media"
            />
          )}
        </div>
      );
    },
  },
  {
    formatter: (props: BatchDrillObjectType) => {
      return (
        <div>
          <label className="fs-sm fw-bold">{props.drill?.title}</label>
          <p className="fs-xs mb-0">{props.drill?.description}</p>
        </div>
      );
    },
  },
  isAllowedTo(BATCH, "update", permissions) && {
    colWidth: "20rem",
    formatter: (props: BatchDrillObjectType) => (
      <div className="d-flex" key={props.id}>
        <ButtonIcon
          className="fs-xs"
          onClick={(event) => {
            event.stopPropagation();
            onRemoveDrill({
              id: props.drill?.id,
              batchItemId: props?.id,
              isRemove: true,
            });
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </ButtonIcon>
      </div>
    ),
  },
];

export const getBatchCoachColumns = ({
  onReplaceCoach,
  permissions,
}: BatchCoachColumnPropsType) => [
  {
    dataField: "name",
    title: "Coach Name",
    formatter: (props: BatchCoachObjectType) => {
      return props && props.coach?.name;
    },
  },
  {
    dataField: "contact_number",
    title: "Contact No.",
    formatter: (props: BatchCoachObjectType) => {
      return props && props.coach?.contact_number;
    },
  },
  {
    dataField: "email",
    title: "Email ID",
    formatter: (props: BatchCoachObjectType) => {
      return props && props.coach?.email;
    },
  },
  isAllowedTo(BATCH, "update", permissions) && {
    dataField: "action",
    title: "Action",
    formatter: (props: BatchCoachObjectType) => (
      <div className="d-flex" key={props.id}>
        <Button
          variant="outline-secondary"
          size="sm"
          onClick={(event) => {
            event.stopPropagation();
            onReplaceCoach({
              id: props?.coach_id,
              batchItemId: props?.id,
              isReplace: true,
            });
          }}
        >
          {REPLACE_COACH}
        </Button>
      </div>
    ),
  },
];

export const getBatchPlayerColumns = ({
  onReplacePlayer,
  permissions,
}: BatchPlayerColumnPropsType) => [
  {
    dataField: "name",
    title: "Player Name",
    formatter: (props: BatchPlayerObjectType) => {
      return props && props.player?.name;
    },
  },
  {
    dataField: "contact_number",
    title: "Contact No.",
    formatter: (props: BatchPlayerObjectType) => {
      return props && props.player?.contact_number;
    },
  },
  {
    dataField: "email",
    title: "Email ID",
    formatter: (props: BatchPlayerObjectType) => {
      return props && props.player?.email;
    },
  },
  isAllowedTo(BATCH, "update", permissions) && {
    dataField: "action",
    title: "Action",
    formatter: (props: BatchPlayerObjectType) => (
      <div className="d-flex" key={props.id}>
        <ButtonIcon
          className="fs-xs"
          onClick={(event) => {
            event.stopPropagation();
            onReplacePlayer({
              id: props?.player_id,
              batchItemId: props?.id,
              isRemove: true,
            });
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </ButtonIcon>
      </div>
    ),
  },
];

export const getBatchColumns = ({
  onEditBatch,
  permissions,
  onApplyFilter,
  onCancelBatch,
  filterConfig,
}: BatchColumnPropsType) => [
  {
    dataField: "name",
    title: "Batch Name",
  },
  {
    dataField: "sport",
    title: "Sport",
    formatter: (props: BatchDetailsObjectType) => {
      return props.sport && props.sport.name;
    },
  },
  {
    dataField: "batch_coach_name",
    title: "Coaches",
  },
  {
    dataField: "batchTiming",
    title: "Batch Timing",
    formatter: (props: BatchDetailsObjectType) => {
      const { start_time, end_time } = props;
      return `${start_time} to ${end_time}`;
    },
  },
  {
    dataField: "start_date",
    title: (
      <button
        type="button"
        className="btn p-0 m-0 fw-bold"
        data-column="start_date"
        onClick={onApplyFilter}
      >
        {START_DATE}
        <FontAwesomeIcon
          className="ms-2"
          icon={filterConfig.start_date === ASC ? faArrowUp : faArrowDown}
        />
      </button>
    ),
  },
  {
    dataField: "end_date",
    title: (
      <button
        type="button"
        className="btn p-0 m-0 fw-bold"
        data-column="end_date"
        onClick={onApplyFilter}
      >
        {END_DATE}
        <FontAwesomeIcon
          className="ms-2"
          icon={filterConfig.end_date === ASC ? faArrowUp : faArrowDown}
        />
      </button>
    ),
  },
  {
    dataField: "level",
    title: "Level",
    formatter: (props: BatchDetailsObjectType) => {
      return titleCaseConversion(props.level);
    },
  },
  {
    dataField: "players_count",
    title: "Players",
  },
  {
    dataField: "status",
    title: "Status",
    formatter: (props: BatchDetailsObjectType) => {
      return titleCaseConversion(props.status);
    },
  },
  isAllowedTo(BATCH, "update", permissions) && {
    dataField: "actions",
    title: "Actions",
    formatter: (props: BatchDetailsObjectType) => (
      <div className="d-flex" key={props.id}>
        <ButtonIcon
          onClick={(event) => {
            event.stopPropagation();
            onEditBatch(props.id);
          }}
        >
          <FontAwesomeIcon icon={faPencil} />
        </ButtonIcon>
        <ButtonIcon
          onClick={(event) => {
            event.stopPropagation();
            onCancelBatch(props.id);
          }}
          isdisabled={props.status !== "active"}
        >
          <FontAwesomeIcon icon={faXmark} />
        </ButtonIcon>
      </div>
    ),
  },
];

export const DaysItems: string[] = [
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "Sun",
];

export const BatchLevels: string[] = ["beginner", "intermediate", "advance"];

export const GAP_IN_MINUTES: number = 30;

export const TIME_RANGES: { label: string; value: string }[] =
  getTimeRanges(GAP_IN_MINUTES);

export const getEndTimeValue = (startTime: string) => {
  const endDateNextIndex = 60 / GAP_IN_MINUTES;

  const startTimeIndex = TIME_RANGES.findIndex(
    (time) => time.value === startTime
  );

  const endTime =
    TIME_RANGES[(startTimeIndex + endDateNextIndex) % TIME_RANGES.length];

  return endTime.value;
};

export const BatchItemIdMap: Record<string, string> = {
  Player: "player_id",
  Coach: "coach_id",
  Drills: "drill_id",
  "Survey Question": "survey_question_id",
};

export const ItemMap: ItemMapType = {
  Coach: "coach",
  Player: "player",
  Drills: "drill",
  "Survey Question": "survey_question",
};

export const GlobalItemMap: Record<string, string> = {
  Coach: "coaches",
  Player: "players",
  Drills: "drills",
  "Survey Question": "survey_questions",
};
export const BatchItemTabs: BatchItemTabsObjectType[] = [
  {
    eventKey: "Coach",
    title: "Coaches",
    count: "coaches_count",
  },
  {
    eventKey: "Player",
    title: "Players",
    count: "players_count",
  },
  {
    eventKey: "Drills",
    title: "Drills",
    count: "drills_count",
  },
  {
    eventKey: "Survey Question",
    title: "Survey Questions",
    // count: "survey_questions_count", as it may use in future
  },
];

export const BatchSessionMap: Record<string, string> = {
  "Pre-Session": "pre_session",
  "Post-Session": "post_session",
};

export const UserSessionMap: Record<string, string[]> = {
  player: ["Pre-Session", "Post-Session"],
  coach: ["Post-Session"],
};

export const SessionCountDefaultValue: SessionCountObjectType = {
  morning: 0,
  post_session: 0,
  pre_session: 0,
};

export const BatchDetailsDefaultValue: BatchDetailsObjectType = {
  details: "",
  end_date: "",
  start_time: "",
  end_time: "",
  fee_structure: "",
  is_active: true,
  level: "",
  sport_id: "",
  days: [],
  start_date: "",
  name: "-",
  players_count: 0,
  coaches_count: 0,
  drills_count: 0,
  survey_questions_count: 0,
  sport: SportDetailDefaultValue,
  grouped_survey_questions_count_for_batch: {
    coach: SessionCountDefaultValue,
    player: SessionCountDefaultValue,
  },
  batch_coaches: [],
  batch_players: [],
  batch_drills: [],
  batch_survey_questions: [],
};

export const BATCH_FORM_CONSTANTS: BatchFormConstantsType = {
  NAME: "Name",
  ACTIVE: "active",
  ADD_AMOUNT: "Add Amount",
  ENTER_DETAILS: "Enter Details",
  SELECT_ATLEAST_ONE_DAY: "Select at least one day",
  SELECT_END_DATE: "select end date",
  SELECT_START_DATE: "select start date",
  END_DATE_SHOULD_GRETER_THAN_START_DATE:
    "End date should be greter than start date",
  BATCHES_ROUTE: "/batches",
  END_TIME: "End Time",
  START_TIME: "Start Time",
  SELECT_COACH: "Select Coach",
  SELECT_LEVEL: " Select Level",
  CREATE_MORE_COACH: "Create More Coach",
};

export const ASC: string = "asc";
export const DESC: string = "desc";
export const REPLACE_COACH: string = "Replace Coach";
export const SORT_COLUMN: SortColumnType = {
  latestClicked: "",
  start_date: "",
  end_date: "",
};
