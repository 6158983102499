import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DEFAULT_FILTER_KEY } from "./constants";
import {
  SurveyQuestionFilterKeysType,
  SurveyQuestionListState,
} from "Interfaces/Components/Shared/SurveyQuestionListInterface";

const initialState: SurveyQuestionListState = {
  filterKeys: DEFAULT_FILTER_KEY,
};

export const surveyQuestionListSlice = createSlice({
  name: "surveyQuestionList",
  initialState,
  reducers: {
    setFilterKeys: (
      state,
      action: PayloadAction<SurveyQuestionFilterKeysType>
    ) => {
      state.filterKeys = action.payload;
    },
    resetState: () => initialState,
  },
});

export const { setFilterKeys, resetState } = surveyQuestionListSlice.actions;
export default surveyQuestionListSlice.reducer;
