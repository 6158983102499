import { useNavigate } from "react-router-dom";
import { Button } from "Components/Core";
import { Tile } from "Components/Shared";
import { TileItems } from "./constants";
import { clearSession } from "Utils/utils";

const Dashboard = () => {
  const navigate = useNavigate();
  const onDashboardDetails = () => {
    navigate("/dashboard");
  };
  const onEditAcademyDetails = () => {
    navigate("/academy");
  };
  return (
    <div className="ss-page-content d-flex flex-column flex-1 scroll-y bg-white">
      <header className="ss-page-header d-flex align-items-center px-4">
        <h2 className="fs-md mb-0 fw-medium">Dashboard</h2>

        <Button
          onClick={() => {
            clearSession();
            navigate("/");
          }}
          style={{ right: "5%", position: "absolute" }}
          variant="outline-secondary"
          size="sm"
        >
          Log out
        </Button>
      </header>
      <hr className="ss-page-seprator mx-4 my-0" />
      <div className="ss-page-body flex-1 scroll-y p-4">
        <div className="d-flex justify-content-between mb-3">
          <h6 className="fw-bold">ABCD Academy</h6>
          <Button
            variant="outline-secondary"
            size="sm"
            onClick={onDashboardDetails}
          >
            View Details
          </Button>
        </div>
        <p className="fs-sm mb-3">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis varius
          ligula est, in efficitur eros fringilla ac. Nam ultrices erat vel nibh
          lobortis, vitae dapibus justo al
        </p>
        <div className="d-flex gap-3 py-3">
          {TileItems.map((tileItem, i) => (
            <Tile
              key={`tile_${i}`}
              tileCount={tileItem.count}
              tileTitle={tileItem.title}
            />
          ))}
        </div>
      </div>
      <footer className="ss-page-footer d-flex align-items-center px-4 py-3">
        <Button variant="secondary" size="sm" onClick={onEditAcademyDetails}>
          Edit Academy Info
        </Button>
      </footer>
    </div>
  );
};

export default Dashboard;
