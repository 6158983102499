import {
  LoginDetails,
  LoginErrorDetails,
} from "Interfaces/Pages/LoginInterface";

export const loginFormValues: LoginDetails = {
  email: "",
  password: "",
};

export const loginErrorValues: LoginErrorDetails = {
  emailError: "",
  passwordError: "",
};

export const PASSWORD: string = "Password";
export const FORGOT_PASSWORD: string = "Forgot Password?";
export const LOGIN: string = "Login";
