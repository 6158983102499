import { Button } from "react-bootstrap";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FileViewPortPropsType } from "Interfaces/Pages/DrillsInterface";
import { FORM_CONSTANT } from "Pages/OLD-Drills/constants";

const FileViewPort = ({
  url,
  fileType,
  preview,
  videoWrapperClasses = "media-wrap w-50 border",
}: FileViewPortPropsType) => {
  const {
    Blank,
    Width,
    Height,
    Preload,
    PdfType,
    VideoType,
    ImageType,
    NoDownload,
    Image,
  } = FORM_CONSTANT;
  return (
    <>
      {fileType && ImageType.includes(fileType) && (
        <div className="media-wrap w-50 border">
          <img
            src={url}
            className="object-fit-contain"
            width={Width}
            height={Height}
            alt={Image}
          />
        </div>
      )}
      {fileType && PdfType.includes(fileType) && (
        <div className="pdf-wrap d-flex align-items-end gap-2 text-secondary">
          <div>
            <FontAwesomeIcon icon={faFilePdf} size="6x" />
          </div>
          <a href={url} target={Blank}>
            <Button className="px-3 fs-xs">Open</Button>
          </a>
        </div>
      )}
      {fileType && VideoType.includes(fileType) && (
        <div className={videoWrapperClasses}>
          <video
            key={url}
            width={Width}
            height={Height}
            controls
            playsInline
            poster={preview}
            preload={Preload}
            controlsList={NoDownload}
          >
            <source src={url} type={fileType} />
            Your browser does not support the video...
          </video>
        </div>
      )}
    </>
  );
};

export default FileViewPort;
