import React from "react";
import { MultiSelectProps } from "Interfaces/Components/Shared/MultiSelectInterface";
import "./multiSelect.scss";

const MultiSelect: React.FC<MultiSelectProps> = ({
  multiSelectText,
  className,
  onClick,
}) => {
  return (
    <div className={`ss-multiselect px-3 py-1 ${className}`} onClick={onClick}>
      <span className="fs-sm">{multiSelectText}</span>
    </div>
  );
};

MultiSelect.defaultProps = {
  className: "",
};

export default MultiSelect;
