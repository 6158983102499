import React, { useCallback, useEffect, useState } from "react";
import { ValidationError } from "yup";
import { Button, Col, Form, Row } from "Components/Core";
import {
  MultiSelect,
  ToggleSwitch,
  SelectSports,
  HeaderComponent,
  DisplayErrorMessage,
} from "Components/Shared";
import {
  SurveyQuestionProps,
  QuestionErrorObjectType,
  OptionErrorObjectType,
  SessionObjectType,
  OptionObjectType,
} from "Interfaces/Pages/SurveyQuestionsInterface";
import { GENERIC, SPECIFIC } from "./constants";
import {
  FORM_CONSTANT,
  OptionErrorDefaultValue,
  OPTIONS_ARR,
  PREFERENCES,
  PREFERENCE_ARR,
  QuestionErrorDefaultValue,
  ROLE,
  ROLE_All,
  ROLE_PLAYER,
  COACH_SESSION_TYPES,
  PLAYER_SESSION_TYPES,
} from "Pages/SurveyQuestions/constants";
import { SELECT_SPORT } from "App/constants";
import { useAppDispatch } from "App/hooks";
import { setToastMessage } from "App/commonReducer";
import { addQuestionSchema, optionsSchema } from "App/Validations/allSchema";

const SurveyQuestionFormComponent: React.FC<SurveyQuestionProps> = ({
  formField,
  questionDetails,
  setQuestionDetails,
  onClearButtonClick,
  OnAddButtonClick,
  isEditSurveyQuestion,
}) => {
  const [questionsError, setQuestionsError] = useState<QuestionErrorObjectType>(
      { ...QuestionErrorDefaultValue }
    ),
    [optionError, setOptionError] = useState<OptionErrorObjectType[]>([
      { ...OptionErrorDefaultValue },
    ]),
    { applicable_for, survey_question_options, trigger_event } =
      questionDetails,
    [isGenericQuestion, setIsGenericQuestion] = useState(false),
    dispatch = useAppDispatch();

  const validateForm = useCallback(async () => {
    if (!isGenericQuestion) questionDetails.master_sport_id = "";
    addQuestionSchema
      .validate(
        { ...questionDetails, isGenericQuestion },
        { abortEarly: false }
      )
      .then(() => {
        setQuestionsError({ ...QuestionErrorDefaultValue });
      })
      .catch((error) => {
        const errorObject: QuestionErrorObjectType = {
          ...QuestionErrorDefaultValue,
        };
        error.inner.forEach((element: ValidationError) => {
          const { path, message } = element;

          errorObject[`${path}_error`] = message;
        });
        setQuestionsError(errorObject);
      });
    survey_question_options?.forEach(async (option, index) => {
      const errorObject: OptionErrorObjectType = { ...OptionErrorDefaultValue };
      optionsSchema
        .validate({ ...option }, { abortEarly: false })
        .then(() => {
          optionError[index] = errorObject;
          setOptionError([...optionError]);
        })
        .catch((error) => {
          error.inner.forEach((element: ValidationError) => {
            const { path, message } = element;
            errorObject[`${path}_error`] = message;
          });
          optionError[index] = errorObject;
          setOptionError([...optionError]);
        });
    });
    const valid = await addQuestionSchema.isValid({ ...questionDetails });
    return valid;
  }, [
    optionError,
    questionDetails,
    survey_question_options,
    isGenericQuestion,
  ]);

  const handleOnSubmit = useCallback(async () => {
    const isValid = await validateForm();
    if (isValid) {
      OnAddButtonClick();
    } else {
      const toastMessage: string[] = [];
      optionError.forEach((data, index) => {
        data?.preference_error &&
          toastMessage.push(
            `Option ${OPTIONS_ARR[index]}: ${data?.preference_error}`
          );
      });
      !!toastMessage.length && dispatch(setToastMessage(toastMessage));
    }
  }, [validateForm, OnAddButtonClick, dispatch, optionError]);

  const TRIGGER_EVENTS = [ROLE.COACH, ROLE.BOTH].includes(applicable_for)
    ? COACH_SESSION_TYPES
    : PLAYER_SESSION_TYPES;
  const ROLES = [
    PLAYER_SESSION_TYPES[2].field,
    PLAYER_SESSION_TYPES[0].field,
  ].includes(trigger_event)
    ? ROLE_PLAYER
    : ROLE_All;

  useEffect(() => {
    setIsGenericQuestion(!!questionDetails.master_sport_id);
  }, [questionDetails.master_sport_id]);

  return (
    <div className="ss-page-content d-flex flex-column scroll-y flex-1 bg-white">
      <HeaderComponent title={formField.title} />

      <hr className="ss-page-seprator mx-4 my-0" />
      <div className="ss-page-body d-flex flex-column flex-1 scroll-y mb-2">
        <Form className="d-flex flex-column flex-1 scroll-y p-4 gap-4">
          <div>
            {!isEditSurveyQuestion ? (
              <ToggleSwitch
                textLeft={FORM_CONSTANT.generic}
                textRight={FORM_CONSTANT.specific}
                checked={isGenericQuestion}
                onClick={() => {
                  setIsGenericQuestion(!isGenericQuestion);
                  if (isGenericQuestion) {
                    questionDetails.master_sport_id = null;
                    setQuestionDetails({ ...questionDetails });
                  }
                }}
              />
            ) : (
              <span className="status-badge fs-sm py-2 px-3">
                {!isGenericQuestion ? GENERIC : SPECIFIC}
              </span>
            )}
          </div>
          <Row>
            {isGenericQuestion && (
              <Col lg={3}>
                <SelectSports
                  handleSelect={(
                    event: React.ChangeEvent<HTMLSelectElement>
                  ) => {
                    questionDetails.master_sport_id = event.target.value;
                    setQuestionDetails({ ...questionDetails });
                  }}
                  selected={questionDetails.master_sport_id || ""}
                  shouldShowAll={false}
                  placeHolder={SELECT_SPORT}
                  shouldPassMasterSportId={true}
                  isRequired={!!questionsError.master_sport_id_error}
                  SportMsg={questionsError.master_sport_id_error}
                />
              </Col>
            )}
            <Form.Group controlId={FORM_CONSTANT.session} as={Col} lg={3}>
              <Form.Label className="fs-sm">Trigger Event*</Form.Label>
              <Form.Select
                className="ss-select-control"
                aria-label={FORM_CONSTANT.sessionCatagaries}
                value={questionDetails.trigger_event}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  questionDetails.trigger_event = event.target.value;
                  setQuestionDetails({
                    ...questionDetails,
                  });
                }}
                defaultValue={questionDetails.trigger_event}
                isInvalid={!!questionsError?.trigger_event_error}
              >
                <option value="" key="" disabled selected>
                  Select Event
                </option>
                {TRIGGER_EVENTS.map(({ name, field }: SessionObjectType) => {
                  return (
                    <option value={field} key={field}>
                      {name}
                    </option>
                  );
                })}
              </Form.Select>
              <DisplayErrorMessage
                errorField={questionsError?.trigger_event_error}
              />
            </Form.Group>
            <Form.Group controlId={FORM_CONSTANT.role} as={Col} lg={3}>
              <Form.Label className="fs-sm">Role*</Form.Label>
              <Form.Select
                className="ss-select-control"
                aria-label={FORM_CONSTANT.sessionCatagaries}
                value={questionDetails.applicable_for}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  questionDetails.applicable_for = event.target.value;
                  setQuestionDetails({
                    ...questionDetails,
                  });
                }}
                defaultValue={questionDetails.applicable_for}
                isInvalid={!!questionsError?.applicable_for_error}
              >
                <option value="" key="" disabled selected>
                  Select Role
                </option>
                {ROLES.map(({ name, field }: SessionObjectType) => {
                  return (
                    <option value={field} key={field}>
                      {name}
                    </option>
                  );
                })}
              </Form.Select>
              <DisplayErrorMessage
                errorField={questionsError?.applicable_for_error}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group controlId={FORM_CONSTANT.question} as={Col} lg={9}>
              <Form.Label className="fs-xs">Question*</Form.Label>
              <Form.Control
                required
                type={FORM_CONSTANT.text}
                placeholder={FORM_CONSTANT.placeholderQuestion}
                value={questionDetails.title}
                onChange={(event) => {
                  questionDetails.title = event.target.value;
                  setQuestionDetails({ ...questionDetails });
                }}
                isInvalid={!!questionsError?.title_error}
              />
              <DisplayErrorMessage errorField={questionsError?.title_error} />
            </Form.Group>
          </Row>
          <Row>
            <Col lg={9}>
              {survey_question_options?.map((option, index) => {
                return (
                  <div
                    className="d-flex align-items-end gap-3 rank-preference-wrap"
                    key={`option${OPTIONS_ARR[index]}_${index}`}
                  >
                    <Form.Group className="flex-1">
                      <Form.Label className="fs-xs">
                        Answer Option {OPTIONS_ARR[index]}*
                      </Form.Label>
                      <Form.Control
                        required
                        type={FORM_CONSTANT.text}
                        placeholder={FORM_CONSTANT.placeholderOption}
                        value={option.title}
                        onChange={(event) => {
                          survey_question_options[index].title =
                            event.target.value;
                          setQuestionDetails({
                            ...questionDetails,
                            survey_question_options: [
                              ...survey_question_options,
                            ],
                          });
                        }}
                        isInvalid={!!optionError[index]?.title_error}
                      />
                      {
                        <DisplayErrorMessage
                          errorField={optionError[index]?.title_error}
                        />
                      }
                    </Form.Group>
                    <div className="flex-1">
                      {index === 0 && (
                        <Row>
                          {PREFERENCES.map((number, i) => (
                            <Col
                              xs={3}
                              lg={3}
                              key={`preference1_${OPTIONS_ARR[index]}_${i}`}
                            >
                              <p className="fs-xs mb-0 text-center">{number}</p>
                            </Col>
                          ))}
                        </Row>
                      )}
                      <Row>
                        {PREFERENCE_ARR.map((number, i) => (
                          <Col
                            xs={3}
                            lg={3}
                            key={`preference_${OPTIONS_ARR[index]}_${i}`}
                          >
                            <MultiSelect
                              multiSelectText={number}
                              className={`text-center ${
                                number === option.preference
                                  ? FORM_CONSTANT.active
                                  : ""
                              }`}
                              onClick={() => {
                                const option = survey_question_options.map(
                                  (ele: OptionObjectType) => ele.preference
                                );
                                if (
                                  survey_question_options[index].preference ===
                                  number
                                ) {
                                  survey_question_options[index].preference =
                                    "";
                                } else if (
                                  survey_question_options[index].preference ===
                                    "" &&
                                  !option.includes(number)
                                ) {
                                  survey_question_options[index].preference =
                                    number;
                                }
                                setQuestionDetails({
                                  ...questionDetails,
                                  survey_question_options: [
                                    ...survey_question_options,
                                  ],
                                });
                              }}
                            />
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </div>
                );
              })}
              <div className="d-flex align-items-end gap-3 rank-preference-wrap">
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label="Active"
                  checked={questionDetails.is_active}
                  className="custom-switch-check mb-3"
                  onChange={() => {
                    setQuestionDetails({
                      ...questionDetails,
                      is_active: !questionDetails.is_active,
                    });
                  }}
                />
              </div>
            </Col>
          </Row>
        </Form>
      </div>
      <footer className="ss-page-footer d-flex align-items-center px-4 py-3 gap-3">
        <Button
          variant={FORM_CONSTANT.outlineSecondary}
          size="sm"
          className="px-4"
          onClick={onClearButtonClick}
        >
          {formField.button1}
        </Button>
        <Button
          variant={FORM_CONSTANT.secondary}
          size="sm"
          className="px-4"
          onClick={handleOnSubmit}
        >
          {formField.button2}
        </Button>
      </footer>
    </div>
  );
};

export default SurveyQuestionFormComponent;
