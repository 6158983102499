import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { useMemo } from "react";
import DatePicker from "react-datepicker";
import { useAppSelector } from "App/hooks";
import { getAcademyId } from "Utils/utils";
import { fetchBatchPerformance } from "Api/Pages/BatchPerformanceApi";
import { PaginationControl, TableComponent } from "Components/Shared";
import calenderIcon from "App/images/calendar-icon.svg";

import {
  BatchPerformanceProps,
  BatchPerformanceApiResponse,
} from "Interfaces/Components/Shared/BatchPerformanceInterface";
import { BatchPerformancecolumns } from "./constant";
import { formatDateToDDMMYYYY } from "Utils/utils";

const BatchPerformance: React.FC<BatchPerformanceProps> = ({ Id }) => {
  const {
    userDetails: { academy_id },
  } = useAppSelector((state) => state.loginReducer);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const today = new Date();
  const threeMonthsAgo = new Date(today);
  threeMonthsAgo.setMonth(today.getMonth() - 3);

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    threeMonthsAgo,
    today,
  ]);
  const [startDate, endDate] = dateRange.map((date) =>
    date ? formatDateToDDMMYYYY(date) : ""
  );
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false); // Toggle calendar visibility
  const pageSize = 10;

  const { data, isLoading, error } = useQuery<BatchPerformanceApiResponse>(
    [
      "userSchedules",
      currentPage,
      startDate,
      endDate,
      academy_id,
      Id,
      pageSize,
    ],
    async () => {
      if (Id === undefined) {
        return undefined;
      }
      return fetchBatchPerformance({
        page: currentPage,
        startDate,
        endDate,
        academy_id: getAcademyId(academy_id) as number | undefined,
        Id,
        pageSize,
      });
    },
    {
      enabled: Id !== undefined && !!startDate && !!endDate,
      keepPreviousData: true,
    }
  );

  const memoizedTableData = useMemo(() => {
    const filteredData = data?.batches_data;

    const paginatedData = filteredData || [];

    return paginatedData?.map((item) => ({
      ...item,
    }));
  }, [data]);

  useEffect(() => {
    if (data) {
      const totalRecords = data?.total_count ? Number(data.total_count) : 0; // Use the total record count, not just data.length
      setTotalPages(Math.ceil(totalRecords / pageSize));
    }

    // Set total pages based on the number of items in playerdata
  }, [data]);
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error fetching data</div>;
  }

  const handleDateRangeChange = (dates: [Date | null, Date | null]) => {
    setDateRange(dates);
    if (dates[0] && dates[1]) {
      setIsCalendarOpen(false);
    } else {
      setIsCalendarOpen(true);
    }

    setCurrentPage(1);
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        {/* Tab Filters using Bootstrap 5 Buttons */}

        {/* DatePicker Component with Calendar Icon */}
        <div className="datepicker-container d-flex align-items-center">
          {/* DatePicker Input Trigger */}
          <DatePicker
            selectsRange
            startDate={dateRange[0]}
            endDate={dateRange[1]}
            onChange={handleDateRangeChange}
            placeholderText="Select Date Range"
            open={isCalendarOpen} // Control calendar visibility
            onClickOutside={() => setIsCalendarOpen(false)} // Close on outside click
            className="form-control date-range-input" // Custom styling for date input
          />

          {/* Calendar Icon - Triggers DatePicker */}
          <img
            src={calenderIcon}
            alt="Calendar Icon"
            className="calendar-icon ms-2 cursor-pointer"
            onClick={() => setIsCalendarOpen(!isCalendarOpen)} // Toggle DatePicker on icon click
          />
        </div>
      </div>

      {/* Table Component Code */}
      <div className="table-responsive">
        {data && (
          <TableComponent
            className="ss-table mb-0"
            columns={BatchPerformancecolumns}
            tableData={memoizedTableData}
            hideColWidth={false}
            hideThead={false}
          />
        )}
      </div>
      <div className="pagination-wrap d-flex justify-content-end px-4">
        <PaginationControl
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
        />
      </div>
    </>
  );
};

export default BatchPerformance;
