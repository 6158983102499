import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import { Button } from "Components/Core";
import VirtualTableComponent from "Components/Shared/VirtualTableComponent";
import {
  getVideoSubmissionList,
  patchSubmissionVideoAction,
} from "Api/Pages/VideoListApi";
import { useState } from "react";
import { AssetSubmissionVideo } from "Interfaces/Components/Shared/AssetSubmissionVideos";
import { getSubmissionVideoColumns } from "./constants";

interface AssetSubmissionVideosProps {
  uploadPurpose: string;
}

const AssetSubmissionVideos = (props: AssetSubmissionVideosProps) => {
  const { uploadPurpose } = props;

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [currentPage, setCurrentPage] = useState(1);

  const { data } = useQuery({
    queryKey: ["asset-video-submissions", currentPage],
    queryFn: () =>
      getVideoSubmissionList({
        page: currentPage,
        perPage: 10,
        uploadPurpose: uploadPurpose,
        requestStatus: "pending",
      }),
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: patchSubmissionVideoAction,
    onSuccess: () => {
      queryClient.invalidateQueries(["asset-video-submissions"]);
    },
  });

  const handleApprovedVideosNavigation = () => {
    if (uploadPurpose === "featured") {
      navigate("/featured-videos");
    } else {
      navigate("/drills");
    }
  };

  const handleSubmissionDetailNavigation = (data: AssetSubmissionVideo) => {
    if (uploadPurpose === "featured") {
      navigate(`/featured-videos/submissions/${data.id}`);
    } else {
      navigate(`/drills/submissions/${data.id}`);
    }
  };

  return (
    <div className="ss-page-content d-flex flex-column flex-1 scroll-y bg-white">
      <header className="ss-page-header d-flex align-items-center justify-content-between px-4 ">
        <h2 className="fs-md mb-0 fw-medium">
          {uploadPurpose === "featured" ? "Featured" : "Drill"} Video
          Submissions
        </h2>
        <div className="d-flex align-items-center gap-2">
          <Button
            variant="outline-secondary"
            className="fs-sm"
            onClick={handleApprovedVideosNavigation}
          >
            Approved Videos
          </Button>
        </div>
      </header>
      <hr className="ss-page-seprator mx-4 my-0" />
      {data && (
        <div className="ss-page-body d-flex flex-column flex-1 scroll-y py-4 px-4">
          <VirtualTableComponent
            columns={getSubmissionVideoColumns(mutate, isLoading)}
            tableData={data.videos}
            noDataMessage="No Videos found"
            paginationProps={{
              currentPage,
              setPageNumber: setCurrentPage,
              totalCount: data.totalCount,
              numberOfRowPerPage: 10,
            }}
            onRowClick={handleSubmissionDetailNavigation}
          />
        </div>
      )}
    </div>
  );
};

export default AssetSubmissionVideos;
