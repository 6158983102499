import {
  ResetPasswordFormObjectType,
  ResetPasswordErrorObjectType,
  ForgetPasswordFormConstant,
} from "Interfaces/Pages/ForgotPasswordInterface";

export const ResetPasswordDefaultValue: ResetPasswordFormObjectType = {
  password: "",
  password_confirmation: "",
  reset_password_token: "",
};

export const ResetPasswordErrorValue: ResetPasswordErrorObjectType = {
  password_error: "",
  password_confirmation_error: "",
  reset_password_token_error: "",
};

export const FORM_CONSTANT: ForgetPasswordFormConstant = {
  Ok: "Ok",
  TextEnd: "text-end",
  ResetPasswordToken: "reset_password_token",
  ResetPassword: "Reset Password",
  Password: "password",
  ConfirmPassword: "password_confirmation",
  Error: "_error",
  LoginPath: "/",
  ResetPasswordPath: "reset-password",
};

export const EMAIL: string = "Email";
export const SEND_LINK: string = "Send Link";
export const BACK: string = "Back";
export const NEW_PASSWORD: string = "New Password";
export const CONFIRM_NEW_PASSWORD: string = "Confirm New Password";
export const RESET: string = "Reset";
