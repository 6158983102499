import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { faChevronLeft, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tabs, Tab } from "Components/Core";
import { ButtonIcon, PaginationControl } from "Components/Shared";
import AssignComponent from "Pages/Batches/AssignComponent";
import UserList from "Pages/Batches/UserList";
import {
  assignBatchUser,
  getBatchInfo,
  getBatchItemList,
} from "Api/Pages/BatchesApi";
import {
  BatchDetailsObjectType,
  BatchItemListObjectType,
  BatchItemClickPropsType,
  BatchItemTabsObjectType,
} from "Interfaces/Pages/BatchesInterface";
import {
  BatchItemIdMap,
  BatchDetailsDefaultValue,
  BatchSessionMap,
  UserSessionMap,
  BatchItemTabs,
} from "Pages/Batches/constants";
import {
  BatchItemMap,
  COACH,
  CONFIRM,
  INITIAL_PAGE,
  TEXT_END,
  RECORDS_PER_PAGE,
  removeComfirmMessage,
  SURVEY_QUESTION,
} from "App/constants";
import { history } from "App";
import { useAppDispatch, useAppSelector } from "App/hooks";
import { setConfirmProps } from "App/commonReducer";
import "./batch.scss";

const BatchDetails = () => {
  const { id } = useParams(),
    dispatch = useAppDispatch(),
    [currentPage, setCurrentPage] = useState<number>(INITIAL_PAGE),
    [totalPages, setTotalPages] = useState<number>(INITIAL_PAGE),
    { confirmProps } = useAppSelector((state) => state.commonReducer),
    [assignButtonClicked, setAssignButtonClicked] = useState<boolean>(false),
    [isReplace, setIsReplace] = useState<boolean>(false),
    [isRemove, setIsRemove] = useState<boolean>(false),
    [removeItemIds, setRemoveItemIds] = useState<Object>({}),
    [eventKey, setEventKey] = useState<string>(COACH),
    {
      userDetails: { academy_id },
    } = useAppSelector((state) => state.loginReducer),
    [batchDetails, setBatchDetails] = useState<BatchDetailsObjectType>(
      BatchDetailsDefaultValue
    ),
    [assinedBatchItem, setAssinedBatchItem] = useState<
      BatchItemListObjectType[]
    >([]),
    {
      name,
      formatted_start_date,
      formatted_end_date,
      sport,
      grouped_survey_questions_count_for_batch,
    } = batchDetails,
    { filterKeys } = useAppSelector(
      (state) => state.surveyQuestionListComponentReducer
    ),
    { selectedToggleSwitch, selectedUserSession } = filterKeys;

  const fetchBatchInfo = useCallback(async () => {
    if (id) {
      const details = await getBatchInfo(academy_id, +id);
      details && setBatchDetails(details);
    }
  }, [academy_id, id]);

  const fetchBatchItemList = useCallback(async () => {
    const itemList =
      id &&
      (await getBatchItemList({
        academyId: academy_id,
        batchId: id,
        userType: eventKey,
        page: currentPage,
        applicable_for: selectedToggleSwitch,
        trigger_event: BatchSessionMap[selectedUserSession],
      }));
    const { total_count } = itemList;
    setTotalPages(Math.ceil(total_count / RECORDS_PER_PAGE));
    setAssinedBatchItem(itemList[BatchItemMap[eventKey]]);
  }, [
    eventKey,
    academy_id,
    id,
    selectedToggleSwitch,
    selectedUserSession,
    currentPage,
    setTotalPages,
  ]);

  const onItemClick = useCallback(
    (props: BatchItemClickPropsType) => {
      setRemoveItemIds({
        id: props?.batchItemId,
        [BatchItemIdMap[eventKey]]: props?.id,
      });
      props?.isReplace && setIsReplace(props?.isReplace);
      props?.isRemove && setIsRemove(props?.isRemove);
      props?.isReplace && setAssignButtonClicked(true);
    },
    [eventKey]
  );

  const updateBatch = useCallback(
    async (batchItemSelectedIds: number[]) => {
      const removeItemParam: Object = {
          ...removeItemIds,
          _destroy: true,
        },
        addItemParam: Object[] =
          batchItemSelectedIds &&
          batchItemSelectedIds.map((id: number) => ({
            [BatchItemIdMap[eventKey]]: id,
            _destroy: false,
          }));

      const details =
        id &&
        (await assignBatchUser({
          user: eventKey,
          academyId: academy_id,
          id: id,
          batchItemSelectedIds:
            (isReplace && [removeItemParam, ...addItemParam]) ||
            (isRemove && [removeItemParam]) ||
            addItemParam,
        }));
      setIsRemove(false);
      details && setBatchDetails(details);
      fetchBatchItemList();
    },
    [
      academy_id,
      eventKey,
      id,
      fetchBatchItemList,
      isRemove,
      removeItemIds,
      isReplace,
    ]
  );
  const UserProps = {
    userType: eventKey,
    batchItemList: assinedBatchItem,
    startDate: formatted_start_date,
    endDate: formatted_end_date,
    onUpdateBtachItem: onItemClick,
    setAssignButtonClicked: setAssignButtonClicked,
    questionCount: grouped_survey_questions_count_for_batch,
    tabs: UserSessionMap[selectedToggleSwitch],
  };

  useEffect(() => {
    fetchBatchInfo();
  }, [fetchBatchInfo]);

  useEffect(() => {
    if (isRemove && !confirmProps.showConfirm) {
      dispatch(
        setConfirmProps({
          ...confirmProps,
          closeButtonClassName: TEXT_END,
          modalType: CONFIRM,
          showConfirm: true,
          confirmBodyProps: {
            message: [`${removeComfirmMessage} ${eventKey}?`],
          },
          buttonProps: [
            {
              title: CONFIRM,
              onClick: updateBatch,
            },
          ],
        })
      );
      setIsRemove(false);
    }
  }, [isRemove, confirmProps, updateBatch, dispatch, removeItemIds, eventKey]);

  useEffect(() => {
    setAssinedBatchItem([]);
    fetchBatchItemList();
  }, [fetchBatchItemList, currentPage]);

  return (
    <>
      <div className="ss-page-content d-flex flex-column flex-1 scroll-y bg-white">
        <header className="ss-page-header d-flex align-items-center justify-content-between px-3">
          <div className="d-flex align-items-center justify-content-between flex-1">
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faChevronLeft}
                onClick={history.back}
                role="button"
              />
              <h2 className="fs-md mb-0 fw-medium px-2">{name}</h2>
              <span className="fs-xs ms-4">Batch Period:</span>
              <p className="fs-sm fw-bold mb-0 ms-1">{`${formatted_start_date} to ${formatted_end_date}`}</p>
            </div>
            {eventKey !== SURVEY_QUESTION && (
              <ButtonIcon
                className="btn-outline-secondary fs-sm"
                onClick={() => setAssignButtonClicked(true)}
              >
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                Assign {eventKey}
              </ButtonIcon>
            )}
          </div>
        </header>
        <hr className="ss-page-seprator mx-4 my-0" />
        <div className="ss-page-body d-flex flex-column flex-1 scroll-y py-4">
          <Tabs
            activeKey={eventKey}
            onSelect={(key: string | null) => {
              key !== null && setEventKey(key);
              setCurrentPage(INITIAL_PAGE);
            }}
            className="ss-tabs"
          >
            {BatchItemTabs.map(
              (tab: BatchItemTabsObjectType, index: number) => {
                const { eventKey, title, count } = tab;
                return (
                  <Tab
                    key={index}
                    eventKey={eventKey}
                    title={
                      title + `${count ? ` (${batchDetails[count]})` : ""}`
                    }
                    className="ss-tab-content py-3 px-0 flex-1"
                  >
                    <UserList {...UserProps} />
                  </Tab>
                );
              }
            )}

            <div className="pagination-wrap d-flex justify-content-end">
              <PaginationControl
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
              />
            </div>
          </Tabs>
        </div>
      </div>
      {assignButtonClicked && id && (
        <AssignComponent
          userType={eventKey}
          assignButtonClicked={assignButtonClicked}
          setAssignButtonClicked={setAssignButtonClicked}
          selectedList={assinedBatchItem}
          assignBatchItem={updateBatch}
          applicableFor={selectedToggleSwitch}
          selectedSession={selectedUserSession}
          setIsReplace={setIsReplace}
          isReplace={isReplace}
          batchSport={sport}
          batchId={id}
        />
      )}
    </>
  );
};

export default BatchDetails;
