import { useState, useEffect } from "react";
import { faChevronLeft, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal } from "Components/Core";
import {
  ButtonIcon,
  TableComponent,
  SurveyQuestionListComponent,
  UserDetailsComponent,
} from "Components/Shared";
import SurveyQuestionDetails from "Pages/SurveyQuestions/SurveyQuestionDetails";
import DrillsDetails from "Pages/OLD-Drills/DrillsDetails";
import {
  BatchItemClickPropsType,
  BatchItemObjectType,
  UserPropsObjectType,
} from "Interfaces/Pages/BatchesInterface";
import {
  getBatchCoachColumns,
  getBatchPlayerColumns,
  getBatchDrillColumns,
  ItemMap,
} from "Pages/Batches/constants";
import { COACH, DRILLS, PLAYER, SURVEY_QUESTION } from "App/constants";
import { useAppSelector } from "App/hooks";
import { useAppDispatch } from "App/hooks";
import { resetState } from "Components/Shared/SurveyQuestionListComponent/surveyQuestionListComponentReducer";

const UserList = ({
  userType,
  batchItemList,
  startDate,
  endDate,
  onUpdateBtachItem,
  setAssignButtonClicked,
  questionCount,
  tabs,
}: UserPropsObjectType) => {
  const [show, setShow] = useState<boolean>(false),
    {
      userDetails: { permissions },
    } = useAppSelector((state) => state.loginReducer),
    [itemProps, setItemProps] = useState<BatchItemClickPropsType>();
  const dispatch = useAppDispatch();

  const handleShow = (item: BatchItemObjectType) => {
    setItemProps({
      id: item[ItemMap[userType]].id,
      userType,
      batchItemId: item?.id,
    });
    setShow(true);
  };

  useEffect(() => {
    userType === SURVEY_QUESTION && dispatch(resetState());
  }, [dispatch, userType]);

  return (
    <>
      {batchItemList &&
        (userType === SURVEY_QUESTION ? (
          <SurveyQuestionListComponent
            showSportsTab={false}
            showAssignButton={true}
            questionsList={batchItemList}
            questionCount={questionCount}
            tabs={tabs}
            onDetailQuestion={handleShow}
            onUpdateQuestion={onUpdateBtachItem}
            setAssignButtonClicked={setAssignButtonClicked}
          />
        ) : (
          <div className="flex-1 scroll-y px-4">
            <div className="table-responsive">
              <TableComponent
                columns={
                  (userType === COACH &&
                    getBatchCoachColumns({
                      onReplaceCoach: onUpdateBtachItem,
                      permissions,
                    })) ||
                  (userType === PLAYER &&
                    getBatchPlayerColumns({
                      onReplacePlayer: onUpdateBtachItem,
                      permissions,
                    })) ||
                  (userType === DRILLS &&
                    getBatchDrillColumns({
                      onRemoveDrill: onUpdateBtachItem,
                      permissions,
                    }))
                }
                tableData={batchItemList}
                onRowClick={handleShow}
                hideThead={userType === DRILLS}
                isClickAble={true}
              />
            </div>
          </div>
        ))}
      <Modal
        className="ss-modal right_modal ss-large-modal"
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Header>
          <div className="d-flex align-items-center">
            <FontAwesomeIcon
              type="button"
              icon={faChevronLeft}
              onClick={() => setShow(false)}
            />
            <h2 className="fs-md mb-0 fw-medium px-2">{`${userType} Details`}</h2>
            <span className="fs-xs ms-4">Batch Period:</span>
            <p className="fs-sm fw-bold mb-0 ms-1">{`${startDate} to ${endDate}`}</p>
          </div>
          <ButtonIcon className="fs-sm" onClick={() => setShow(false)}>
            <FontAwesomeIcon icon={faTimes} />
          </ButtonIcon>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column scroll-y p-0">
          {userType === DRILLS && (
            <DrillsDetails batchDrillId={itemProps?.id} hideHeader={true} />
          )}
          {userType === SURVEY_QUESTION && (
            <SurveyQuestionDetails
              batchQuestionId={itemProps?.id}
              hideHeader={true}
            />
          )}
          {[PLAYER, COACH].includes(userType) && (
            <UserDetailsComponent
              batchUserId={itemProps?.id}
              batchUserType={userType}
              hideHeader={true}
            />
          )}
        </Modal.Body>
        {userType !== COACH && (
          <Modal.Footer className="justify-content-start">
            <Button
              variant="outline-secondary"
              size="sm"
              className="mb-0"
              onClick={() => {
                onUpdateBtachItem({
                  id: itemProps?.id,
                  batchItemId: itemProps?.batchItemId,
                  isRemove: true,
                });
                setShow(false);
              }}
            >
              Remove From This Batch
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};
export default UserList;
