export const TileItems = [
  {
    count: 10,
    title: "Batches",
  },
  {
    count: 100,
    title: "Players",
  },
  {
    count: 17,
    title: "Coaches",
  },
  {
    count: 40,
    title: "Drills",
  },
  {
    count: 8,
    title: "Sports",
  },
];
