import { BASE_URL } from "App/constants";
import axios from "axios";
import { FetchBatchPerformanceParams } from "Interfaces/Components/Shared/BatchPerformanceInterface";
import { getAcademyId } from "Utils/utils";

export const fetchBatchPerformance = async ({
  academy_id,
  Id,
  startDate,
  endDate,
  page,
  pageSize,
}: FetchBatchPerformanceParams) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}academies/${getAcademyId(
        academy_id
      )}/players/${Id}/batch_performances`,

      {
        params: {
          page,
          start_date: startDate,
          end_date: endDate,
          pageSize: pageSize,
        },
      }
    );

    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const errorMessage =
        error.response?.data?.error || "An unexpected Error has occured";
      throw new Error(errorMessage);
    }
  }
};
