import { useEffect, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { UseScrollerPropsType } from "Interfaces/HooksInterface";
import { INITIAL_PAGE } from "App/constants";
import type { RootState, AppDispatch } from "App/store";
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useScroller = ({
  pageEnd,
  loading,
  searchInput,
}: UseScrollerPropsType) => {
  const [pageNumber, setPageNumber] = useState<number>(INITIAL_PAGE);

  const loadMore = () => {
    setPageNumber((pre) => pre + 1);
  };

  useEffect(() => {
    if (loading) {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            loadMore();
            observer.unobserve(pageEnd.current);
          }
        },
        { threshold: 1 }
      );
      observer.observe(pageEnd.current);
    }
  }, [loading, pageEnd]);

  useEffect(() => {
    setPageNumber(INITIAL_PAGE);
  }, [searchInput]);

  return { pageNumber };
};
