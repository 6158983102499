import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonIcon } from "Components/Shared";
import { SportObjectType } from "Interfaces/Pages/SportsInterface";
import { PermissionsObjType } from "Interfaces/UtilsInterface";
import { isAllowedTo } from "Utils/helper";

export const SportDetailDefaultValue: SportObjectType = {
  name: "",
  image_link: "",
  id: 0,
  is_active: false,
  master_sport_id: 0,
};

export const getColumns = (
  onDeactivateSport: Function,
  permissions: PermissionsObjType
) => [
  {
    dataField: "icon",
    title: "Image",
    colWidth: "10%",
    formatter: (props: SportObjectType) => (
      <div
        className="players-profile-box rounded-circle border d-flex overflow-hidden"
        key={props.id}
      >
        <img src={props.image_link} alt="Icon" className="rounded-circle" />
      </div>
    ),
  },
  {
    dataField: "name",
    title: "Sports Name",
    colWidth: "90%",
  },
  {
    dataField: "action",
    title: "Action",
    formatter: (props: SportObjectType) => {
      return (
        isAllowedTo("Sport", "deactivate", permissions) && (
          <div className="d-flex" key={props.id}>
            <ButtonIcon
              onClick={(event) => {
                event.stopPropagation();
                onDeactivateSport(props.id);
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </ButtonIcon>
          </div>
        )
      );
    },
  },
];

export const SPORTS: string = "Sports";
