import { GuidelinesButtonCssType } from "Interfaces/Components/Shared/HeaderInterface";
export const BULKUPLOAD_GUIDELINES = [
  "File Format must be .csv only",
  "All columns from the Template sheet are mandatory except for Email and Mobile Number",
  "The row and column names should be exactly the same as given in the Template sheet",
  "If Email is provided, the Mobile Number and Country Code fields can be blank. If Mobile Number is provided, the Email field can be blank",
  "Enter valid mobile numbers only",
  "The Email should not contain any special character which invalidates an email",
  "The Email should not start with capital letters",
];

export const GUIDELINES_BUTTON_CSS: GuidelinesButtonCssType = {
  Continue: "btn-secondary",
  Cancel: "guideline-cancel-button",
};

export const DOWNLOAD_TEMPLATE: string = "Download Template";
export const DOWNLOAD_REPORT: string = "Download Report";
export const EMAIL_REPORT: string = "Email Report";

export enum ReportModalType {
  DOWNLOAD = "Download",
  EMAIL = "Email",
}
