import React, { useCallback, useEffect, useRef, useState } from "react";
import { ValidationError } from "yup";
import { useParams } from "react-router-dom";
import { Button, Row, Col, Form, InputGroup } from "Components/Core";
import { addAdmin } from "Api/Pages/AdminApi";
import { getAdminData } from "Api/Pages/AdminApi";
import { getErrorText } from "Components/Shared/UserDetailsForm/constant";

import {
  AdminDefaultValue,
  AdminDetailsDefaultFormErrorValue,
} from "./constants";

import {
  AdminProps,
  AdminDetailsObjectTypes,
} from "Interfaces/Pages/AcademyInterface";
import { AdminDetailsFormErrorObjectType } from "../../../Interfaces/Pages/AdminInterface";

import { HeaderComponent } from "Components/Shared";
import { DisplayErrorMessage } from "Components/Shared/ErrorMessage/DisplayErrorMessage";
import { useAppDispatch, useAppSelector } from "App/hooks";
import { setConfirmProps } from "App/commonReducer";

import { history } from "App";
import { adminDetailsSchema } from "App/Validations/allSchema";
import { checkOldAndCurrentData } from "Utils/utils";
import "../AcademyFormComponent/academyForm.scss";
import { CONFIRM, saveComfirmMessage, TEXT_END } from "App/constants";

const AdminFormComponent: React.FC<AdminProps> = ({ isEditAdmin }) => {
  const { academy_id, id } = useParams();
  const dispatch = useAppDispatch();
  const { confirmProps } = useAppSelector((state) => state.commonReducer);

  const [adminDetails, setAdminDetails] = useState<AdminDetailsObjectTypes>({
    ...AdminDefaultValue,
  });
  const userData = useRef();

  const [fieldError, setFieldError] = useState<AdminDetailsFormErrorObjectType>(
    AdminDetailsDefaultFormErrorValue
  );
  const { name, email, contact_number, password } = adminDetails;
  const { name_error, email_error, contact_number_error } = fieldError;

  const validateForm = useCallback(async () => {
    adminDetailsSchema
      .validate({ ...adminDetails, isEditAdmin }, { abortEarly: false })
      .then(() => setFieldError({ ...AdminDetailsDefaultFormErrorValue }))
      .catch((error) => {
        const errorObject: AdminDetailsFormErrorObjectType = {
          ...AdminDetailsDefaultFormErrorValue,
        };

        error.inner.forEach((element: ValidationError) => {
          const { path, message } = element;
          errorObject[`${path}_error`] = message;
        });
        setFieldError(errorObject);
      });

    const response = await adminDetailsSchema.isValid(adminDetails);
    return response;
  }, [adminDetails, isEditAdmin]);

  const handleOnSubmit = useCallback(async () => {
    const isValid = await validateForm();

    if (isValid && academy_id) {
      if (adminDetails.contact_number === "")
        adminDetails.contact_number = null;
      if (adminDetails.email === "") adminDetails.email = null;
      await addAdmin({
        academy_id,
        params: { ...adminDetails },
        isEditAdmin,
      });
      history.back();
    }
  }, [adminDetails, academy_id, validateForm, isEditAdmin]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const {
      target: { name, value },
    } = event;
    setAdminDetails({ ...adminDetails, [name]: value });
  };

  const onClearForm = () => {
    setAdminDetails({ ...AdminDefaultValue });
    setFieldError(AdminDetailsDefaultFormErrorValue);
  };

  const fetchAdminData = useCallback(async () => {
    if (academy_id && id) {
      const details = await getAdminData(academy_id, +id);
      details && setAdminDetails(details[0]);
    }
  }, [academy_id, id]);

  const saveEditChanges = () => {
    dispatch(
      setConfirmProps({
        ...confirmProps,
        buttonProps: [{ title: CONFIRM, onClick: () => handleOnSubmit() }],
        confirmBodyProps: {
          message: [`${saveComfirmMessage} admin changes?`],
        },
        closeButtonClassName: TEXT_END,
        modalType: CONFIRM,
        showConfirm: true,
      })
    );
  };

  useEffect(() => {
    fetchAdminData();
  }, [fetchAdminData]);

  return (
    <div className="ss-page-content d-flex flex-column flex-1 scroll-y bg-white">
      <HeaderComponent
        title={isEditAdmin ? "Edit Admin" : "Create New Admin"}
      />

      <div className="ss-page-body d-flex flex-column flex-1 scroll-y">
        <Form className="d-flex flex-column flex-1 scroll-y p-4 gap-4">
          <div className="players-details-wrap d-grid gap-4 px-4">
            <Row>
              <Form.Group controlId="name" as={Col} lg={4}>
                <Form.Label className="fs-xs">Admin Name *</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Academy Name"
                  value={name}
                  name="name"
                  onChange={handleOnChange}
                  isInvalid={!!name_error}
                />
                <DisplayErrorMessage errorField={name_error} />
              </Form.Group>
              <Form.Group controlId="email" as={Col} lg={4}>
                <Form.Label className="fs-xs">Email ID</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter Email ID"
                  name="email"
                  value={email || ""}
                  onChange={handleOnChange}
                  isInvalid={!!email_error}
                />
                <DisplayErrorMessage errorField={email_error} />
              </Form.Group>
              <Form.Group controlId="contact_number" as={Col} lg={4}>
                <div className="d-flex flex-column contact-number-field ">
                  <Form.Label className="fs-xs">Contact Number</Form.Label>
                  <div className="d-flex flex-1 gap-2">
                    <div className="d-flex flex-1 flex-column">
                      <InputGroup>
                        <Form.Control
                          placeholder="Enter Contact Number"
                          name="contact_number"
                          value={contact_number || ""}
                          onChange={handleOnChange}
                          isInvalid={!!contact_number_error}
                        />
                      </InputGroup>
                      {!!contact_number_error && (
                        <p className="required-text mb-0 ms-2">
                          {getErrorText(contact_number_error, "")}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </Form.Group>
              {!isEditAdmin && (
                <Form.Group controlId="password" as={Col} lg={4}>
                  <Form.Label className="fs-xs">Password *</Form.Label>
                  <Form.Control
                    name="password"
                    placeholder="Enter Password"
                    value={password}
                    onChange={handleOnChange}
                  />
                </Form.Group>
              )}
            </Row>
          </div>
        </Form>
      </div>
      <footer className="ss-page-footer d-flex align-items-center px-4 py-3 gap-3">
        <Button
          variant="outline-secondary"
          size="sm"
          className="px-4"
          onClick={onClearForm}
        >
          Clear
        </Button>
        <Button
          onClick={isEditAdmin ? saveEditChanges : handleOnSubmit}
          variant="secondary"
          size="sm"
          className="px-4"
          disabled={checkOldAndCurrentData(userData.current, adminDetails)}
        >
          {isEditAdmin ? "Save Admin" : "Create Admin"}
        </Button>
      </footer>
    </div>
  );
};

export default AdminFormComponent;
