import axios from "axios";

import { BASE_URL } from "App/constants";
import { AssetEvaluationCategory } from "Interfaces/AssetTagsInterface";

export const getGraphAttributes = async (masterSportId: number) => {
  const { data } = await axios.get(`${BASE_URL}graph_attributes`, {
    params: {
      master_sport_id: masterSportId,
    },
  });
  return data as { id: number; title: string }[];
};

export const getCategoriesAndTags = async (
  graphAttributeId: number,
  assetId: number | null
) => {
  const params: { approval_asset_id?: number } = {};

  if (assetId) {
    params.approval_asset_id = assetId;
  }

  const { data } = await axios.get(
    `${BASE_URL}graph_attributes/${graphAttributeId}/asset_evaluation_categories`,
    { params }
  );

  return data as AssetEvaluationCategory[];
};
