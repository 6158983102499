import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import { faPlus } from "@fortawesome/free-solid-svg-icons";

import {
  deleteVideoDetails,
  getAssetList,
  getVideoConfig,
  postVideoDetails,
} from "Api/Pages/VideoListApi";
import AssetLandingPage from "Components/Shared/AssetLandingPage";

import { getAssetColumns } from "./constants";

import {
  ASSET_TYPE_ENUM,
  IAssetDetails,
  UPLOAD_PURPOSE_ENUM,
  VideoConfigInterface,
} from "Components/Shared/UploadAsset/types";
import { useEffect, useState } from "react";
import USE_QUERY_KEYS_CONSTANTS from "Api/useQueryKeyConstants";
import uploadFile, {
  getFileType,
  getResolutionConfig,
  getSizeConfig,
} from "Api/Pages/cloudinaryAssetUpload";
import { AssetType } from "Components/Shared/AssetLandingPage/types";
import { INITIAL_PAGE } from "App/constants";

const PublicAsset = () => {
  const navigate = useNavigate();
  const [isUploadModalOpen, setUploadModalVisibility] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(INITIAL_PAGE);

  // track progress for file upload
  const [uploadProgressPercentage, setUploadProgressPercentage] =
    useState<number>(0);

  const { data: publicAssetResponse, refetch: fetchPublicAssets } = useQuery(
    USE_QUERY_KEYS_CONSTANTS.publicAssetList,
    () =>
      getAssetList({
        currentPage: currentPage,
        per_page: 10,
        upload_purpose: UPLOAD_PURPOSE_ENUM.public,
      }),
    { refetchOnWindowFocus: false, enabled: false }
  );

  const { data: assetConfig } = useQuery<VideoConfigInterface>(
    USE_QUERY_KEYS_CONSTANTS.videoConfigs,
    () => getVideoConfig(),
    {
      staleTime: 1000 * 60, // 1 minute
    }
  );

  useEffect(() => {
    fetchPublicAssets();
  }, [fetchPublicAssets, currentPage]);

  // toggle video details upload modal
  const toggleVideoUploadModal = () => {
    setUploadModalVisibility((isOpen) => !isOpen);
  };

  const onDeleteRowClicked = async (selectedId: number) => {
    await deleteVideoDetails(selectedId);
    // On successful delete fetch public assets
    fetchPublicAssets();
  };
  const onRowClicked = (rowData: AssetType) => {
    navigate(`/public-assets/details/${rowData.id}`, { state: rowData });
  };

  const onAssetUploadProgressCallback = (
    progressEvent: any,
    totalFileSize: number
  ) => {
    setUploadProgressPercentage(
      Math.round((progressEvent.loaded / totalFileSize) * 100)
    );
  };

  const onVideoSaveCallback = (videoDetails: IAssetDetails) => {
    // file upload start flag set and show progress bar
    const { title, description, asset } = videoDetails;
    // upload file
    if (asset !== null && asset.length > 0 && assetConfig?.cloudinary) {
      const fileUpload = asset[0];
      let fileType = getFileType(fileUpload.type) as ASSET_TYPE_ENUM;

      try {
        uploadFile(
          fileUpload,
          {
            ofType: fileType,
            cloudName: assetConfig?.cloudinary.cloud_name,
            apiKey: assetConfig?.cloudinary.api_key,
            purpose: UPLOAD_PURPOSE_ENUM.public,
          },
          (progressEvent) =>
            onAssetUploadProgressCallback(progressEvent, fileUpload.size)
        )
          .then(async (response: any) => {
            await postVideoDetails({
              title,
              description,
              of_type: fileType,
              upload_purpose: UPLOAD_PURPOSE_ENUM.public,
              url: response.data.secure_url,
            });
            // once complete save details to sport skill api server
            // after saving details to api server call listing api
            toggleVideoUploadModal();
            setUploadProgressPercentage(0);
            fetchPublicAssets();
          })
          .catch(() => {
            setUploadProgressPercentage(0);
          });
      } catch (error) {
        setUploadProgressPercentage(0);
      }
    }
  };

  const allowedFileFormats = () => {
    if (assetConfig?.video && assetConfig?.image) {
      return [
        ...assetConfig.video.allowed_file_formats,
        ...assetConfig.image.allowed_file_formats,
      ];
    }
    return [];
  };

  return (
    <AssetLandingPage
      uploadModalProps={{
        purpose: UPLOAD_PURPOSE_ENUM.public,
      }}
      title="Promotional Assets"
      actionButtonProps={{
        label: "Upload Asset",
        icon: faPlus,
        onClickHandler: toggleVideoUploadModal,
      }}
      tableProps={{
        columns: getAssetColumns(onDeleteRowClicked),
        tableData: publicAssetResponse?.data || [],
        noDataMessage: "Please upload assets",
        onRowClick: onRowClicked,
        clickable: true,
        paginationProps: {
          totalCount: publicAssetResponse?.total_count || 0,
          currentPage: currentPage,
          numberOfRowPerPage: 10,
          setPageNumber: setCurrentPage,
        },
      }}
      isUploadModalOpen={isUploadModalOpen}
      uploadProgressPercentage={uploadProgressPercentage}
      sizeConfig={getSizeConfig(assetConfig)}
      resolutionConfig={getResolutionConfig(assetConfig)}
      allowedFileFormats={allowedFileFormats()}
      toggleVideoUploadModal={toggleVideoUploadModal}
      onVideoSaveCallback={onVideoSaveCallback}
    />
  );
};

export default PublicAsset;
