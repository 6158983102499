import { ACADEMY_BASE_URL, BASE_URL } from "App/constants";
import axios from "axios";
import {
  SportParam,
  AddSportParam,
  DeleteSportParam,
} from "Interfaces/AppInterface";
import { getAcademyId } from "Utils/utils";

export const getSportsList = async (academyId: number) => {
  const { data } = await axios.get(
    `${ACADEMY_BASE_URL}${getAcademyId(academyId)}/sports`
  );
  if (data) {
    const selectedSports = data?.sports?.map((sport: any) => sport.name);
    localStorage.setItem("selected_sports", JSON.stringify(selectedSports));
    return data?.sports;
  }
  return [];
};

export const getSportDetail = async ({ academyId, sportId }: SportParam) => {
  const { data } = await axios.get(
    `${ACADEMY_BASE_URL}${getAcademyId(academyId)}/sports/${sportId}`
  );
  if (data) {
    return data;
  }
  return [];
};

export const getMasterSportsList = async () => {
  const { data } = await axios.get(`${BASE_URL}master_sports`);
  if (data) {
    return data;
  }
  return [];
};

export const addSportList = async ({ academyId, ids }: AddSportParam) => {
  await axios.post(
    `${ACADEMY_BASE_URL}${getAcademyId(academyId)}/sports/bulk_create`,
    {
      sports: { ids },
    }
  );

  return [];
};

export const deleteSport = async ({ academyId, sportId }: DeleteSportParam) => {
  await axios.put(
    `${ACADEMY_BASE_URL}${getAcademyId(academyId)}/sports/${sportId}/deactivate`
  );
};
