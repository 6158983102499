import { useQuery } from "react-query";

import { Button, Form } from "Components/Core";
import { getGraphAttributes } from "Api/Components/AssetTagsApi";
import { AssetTagsProps } from "Interfaces/AssetTagsInterface";
import SpecificParameter from "./SpecificParameter";

const Parameter = (props: AssetTagsProps) => {
  const { formData, disabled = false, dispatch } = props;

  const { data } = useQuery({
    queryKey: ["graph-attributes", formData.master_sport_id],
    queryFn: () =>
      formData.master_sport_id
        ? getGraphAttributes(formData.master_sport_id)
        : [],
  });

  if (!data) {
    return null;
  }

  const handleParameterChange = (id: number) => {
    dispatch({ type: "graph_attribute_id", payload: id });
  };

  const parameterButtons = data.map((attribute) => (
    <Button
      key={attribute.id}
      disabled={disabled}
      variant={
        formData.graph_attribute_id === attribute.id
          ? "secondary"
          : "outline-secondary"
      }
      onClick={() => handleParameterChange(attribute.id)}
    >
      {attribute.title}
    </Button>
  ));

  return (
    <>
      <Form.Group>
        <Form.Label>Parameter</Form.Label>
        {parameterButtons.length ? (
          <div className="d-flex gap-2 p-1">{parameterButtons}</div>
        ) : (
          <p className="text-secondary">No Parameters!</p>
        )}
      </Form.Group>
      {formData.graph_attribute_id && (
        <SpecificParameter
          formData={formData}
          disabled={disabled}
          dispatch={dispatch}
        />
      )}
    </>
  );
};

export default Parameter;
