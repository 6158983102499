import { BASE_URL, SUCCESS } from "App/constants";
import axios, { AxiosResponse } from "axios";
import { NotificationData } from "Interfaces/Pages/NotificationsInterface";
import { setToastMessage } from "App/commonReducer";
import { store } from "App/store";
import { MESSAGES } from "App/toastNotifications";
import { ROLES } from "Utils/constants";

export const getNotificationsList = async (
  academyId: number,
  currentPage: number,
  searchInput: string
) => {
  const { data } = await axios.get(
    `${BASE_URL}/notifications?page=${currentPage}&search=${searchInput}`
  );
  return data ?? [];
};

export const updateNotifications = async (
  academyId: number,
  unreadNotificationListIds: number[]
) => {
  const { data } = await axios.put(`${BASE_URL}/notifications/update_status`, {
    notificatons_ids: unreadNotificationListIds,
  });

  if (data) return data;
};

export const fetchNotificationCount = async (academy_id: number) => {
  const { data } = await axios.get(`${BASE_URL}/notifications/unread_count`);

  return data?.unread_notification_count ?? 0;
};

export const addNotification = async (
  academyId: number,
  notificationData: NotificationData
): Promise<AxiosResponse> => {
  try {
    const role = window.localStorage.getItem("role");
    // Determine the URL based on the user's role
    const url =
      role === ROLES.ADMIN
        ? `${BASE_URL}academies/${academyId}/app_notifications`
        : role === ROLES.SUPERADMIN
        ? `${BASE_URL}app_notifications`
        : `${BASE_URL}notifications`;

    // Log a warning if the role is unexpected
    if (role !== "admin" && role !== "superAdmin") {
      console.warn(
        `Unexpected role: ${role}. Using default notifications endpoint.`
      );
    }
    // Make the POST request

    const response = await axios.post(url, {
      app_notification: notificationData,
    });

    if (response.status === 201) {
      store.dispatch(
        setToastMessage([[MESSAGES.NOTIFICATION_SUCCESS], SUCCESS])
      );
    }

    return response.data;
  } catch (error) {
    // Handle Axios errors specifically
    if (axios.isAxiosError(error)) {
      const statusCode = error.response?.status;

      switch (statusCode) {
        case 401:
          store.dispatch(setToastMessage(MESSAGES.SESSION_EXPIRED));
          break;
        case 403:
          store.dispatch(setToastMessage(MESSAGES.NO_ACCESS));
          break;
        case 404:
          store.dispatch(setToastMessage(MESSAGES.NOT_FOUND));
          break;
        default:
          store.dispatch(setToastMessage(MESSAGES.DEFAULT_ERROR));
      }
    } else {
      // Handle unexpected non-Axios errors
      console.error("Unexpected error:", error);
      store.dispatch(setToastMessage(MESSAGES.UNEXPECTED_ERROR));
    }

    throw error;
  }
};
