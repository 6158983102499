import React, { useCallback, useEffect, useRef, useState } from "react";
import { ValidationError } from "yup";
import { useParams } from "react-router-dom";
import { Button, Row, Col, Form, InputGroup } from "Components/Core";
import { addAcademy } from "Api/Pages/AcademyApi";
import { getTimeZone } from "Api/TimeZoneApi";
import { getErrorText } from "Components/Shared/UserDetailsForm/constant";
import { getAcademyInfo } from "Api/Pages/AcademyApi";
import { CONFIRM, saveComfirmMessage, TEXT_END } from "App/constants";
import {
  AcademyDefaultValue,
  AcademyDetailsFormErrorValue,
} from "../Academy/constants";

import {
  AcademyDetailsObjectType,
  AcademyDetailsFormErrorObjectType,
  AcademyProps,
} from "Interfaces/Pages/AcademyInterface";
import { useAppDispatch, useAppSelector } from "App/hooks";
import { setConfirmProps } from "App/commonReducer";

import { HeaderComponent, SearchSelect } from "Components/Shared";
import { DisplayErrorMessage } from "Components/Shared/ErrorMessage/DisplayErrorMessage";

import { history } from "App";
import { academyDetailsSchema } from "App/Validations/allSchema";
import { checkOldAndCurrentData } from "Utils/utils";
import "./academyForm.scss";

const AcademyFormComponent: React.FC<AcademyProps> = ({ isEditAcademy }) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const [academyDetails, setAcademyDetails] =
    useState<AcademyDetailsObjectType>({
      ...AcademyDefaultValue,
    });
  const userData = useRef();
  const { confirmProps } = useAppSelector((state) => state.commonReducer);
  const [timeZoneList, setTimeZonesList] = useState<string[]>([]);

  const [fieldError, setFieldError] =
    useState<AcademyDetailsFormErrorObjectType>(AcademyDetailsFormErrorValue);
  const {
    name,
    email,
    contact_number,
    pincode,
    address1,
    address2,
    city,
    website,
    timezone,
    details,
  } = academyDetails;
  const {
    name_error,
    email_error,
    contact_number_error,
    pincode_error,
    timezone_error,
  } = fieldError;
  console.log("==>", fieldError, timezone_error);

  const validateForm = useCallback(async () => {
    academyDetailsSchema
      .validate(academyDetails, { abortEarly: false })
      .then(() => setFieldError({ ...AcademyDetailsFormErrorValue }))
      .catch((error) => {
        const errorObject: AcademyDetailsFormErrorObjectType = {
          ...AcademyDetailsFormErrorValue,
        };
        error.inner.forEach((element: ValidationError) => {
          const { path, message } = element;
          errorObject[`${path}_error`] = message;
        });
        setFieldError(errorObject);
      });

    const response = await academyDetailsSchema.isValid(academyDetails);
    return response;
  }, [academyDetails]);

  const handleOnSubmit = useCallback(async () => {
    const isValid = await validateForm();
    if (isValid) {
      if (academyDetails.email === "") academyDetails.email = null;
      if (academyDetails.contact_number === "")
        academyDetails.contact_number = null;
      await addAcademy({
        params: { ...academyDetails, id },
        isEditAcademy,
      });
      history.back();
    }
  }, [academyDetails, validateForm, isEditAcademy, id]);

  const saveEditChanges = () => {
    dispatch(
      setConfirmProps({
        ...confirmProps,
        buttonProps: [{ title: CONFIRM, onClick: () => handleOnSubmit() }],
        confirmBodyProps: {
          message: [`${saveComfirmMessage} academy changes?`],
        },
        closeButtonClassName: TEXT_END,
        modalType: CONFIRM,
        showConfirm: true,
      })
    );
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const {
      target: { name, value },
    } = event;
    setAcademyDetails({ ...academyDetails, [name]: value });
  };

  const fetchTimeZones = useCallback(async () => {
    const data = await getTimeZone();
    setTimeZonesList(data);
  }, []);

  const onClearForm = () => {
    setAcademyDetails({ ...AcademyDefaultValue });
    setFieldError(AcademyDetailsFormErrorValue);
  };

  const onSelectTimeZone = (timeZoneSelected: string) => {
    academyDetails.timezone = timeZoneSelected;
    setAcademyDetails({ ...academyDetails });
  };

  const fetchAcademyInfo = useCallback(async () => {
    if (id) {
      const details = await getAcademyInfo(+id);
      details && setAcademyDetails(details);
    }
  }, [id]);

  useEffect(() => {
    fetchAcademyInfo();
  }, [fetchAcademyInfo]);

  useEffect(() => {
    fetchTimeZones();
  }, [fetchTimeZones]);

  return (
    <div className="ss-page-content d-flex flex-column flex-1 scroll-y bg-white">
      <HeaderComponent
        title={isEditAcademy ? "Edit Academy" : "Create New Academy"}
      />

      <div className="ss-page-body d-flex flex-column flex-1 scroll-y">
        <Form className="d-flex flex-column flex-1 scroll-y p-4 gap-4">
          <div className="players-details-wrap d-grid gap-4 px-4">
            <Row>
              <Form.Group controlId="name" as={Col} lg={4}>
                <Form.Label className="fs-xs">Academy Name *</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Academy Name"
                  value={name}
                  name="name"
                  onChange={handleOnChange}
                  isInvalid={!!name_error}
                />
                <DisplayErrorMessage errorField={name_error} />
              </Form.Group>
              <Form.Group controlId="email" as={Col} lg={4}>
                <Form.Label className="fs-xs">Email ID *</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter Email ID"
                  name="email"
                  value={email || ""}
                  onChange={handleOnChange}
                  isInvalid={!!email_error}
                />
                <DisplayErrorMessage errorField={email_error} />
              </Form.Group>
              <Form.Group controlId="contact_number" as={Col} lg={4}>
                <div className="d-flex flex-column contact-number-field ">
                  <Form.Label className="fs-xs">Contact Number *</Form.Label>
                  <div className="d-flex flex-1 gap-2">
                    <div className="d-flex flex-1 flex-column">
                      <InputGroup>
                        <Form.Control
                          placeholder="Enter Contact Number"
                          name="contact_number"
                          value={contact_number || ""}
                          onChange={handleOnChange}
                          isInvalid={!!contact_number_error}
                        />
                      </InputGroup>
                      {!!contact_number_error && (
                        <p className="required-text mb-0 ms-2">
                          {getErrorText(contact_number_error, "")}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group controlId="number" as={Col} lg={4}>
                <Form.Label className="fs-xs">PIN Code *</Form.Label>
                <Form.Control
                  required
                  type="number"
                  placeholder="Enter PIN Code"
                  name="pincode"
                  value={pincode}
                  onChange={handleOnChange}
                  isInvalid={!!pincode_error}
                />
                <DisplayErrorMessage errorField={pincode_error} />
              </Form.Group>
              <Form.Group controlId="website" as={Col} lg={4}>
                <Form.Label className="fs-xs">Web Site</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Website"
                  value={website}
                  name="website"
                  onChange={handleOnChange}
                />
              </Form.Group>
              <Form.Group controlId="timezone" as={Col} lg={4}>
                <div className="d-flex flex-column timezone-field">
                  <Form.Label className="fs-xs">Time zone *</Form.Label>
                  <div className="d-flex flex-column">
                    <SearchSelect
                      optionsList={timeZoneList.map((timezone: string) => ({
                        value: timezone,
                        label: timezone,
                      }))}
                      setSelected={onSelectTimeZone}
                      selected={{
                        value: timezone,
                        label: timezone,
                      }}
                      placeholder={timezone || "Timezone"}
                      errorMessage={timezone_error}
                    />
                  </div>
                </div>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group controlId="textarea" as={Col} lg={4}>
                <Form.Label className="fs-xs">Address Line 1</Form.Label>
                <Form.Control
                  type="text"
                  className="border"
                  placeholder="Enter Address Line 1"
                  name="address1"
                  value={address1}
                  onChange={handleOnChange}
                />
              </Form.Group>
              <Form.Group controlId="textarea" as={Col} lg={4}>
                <Form.Label className="fs-xs">Address Line 2</Form.Label>
                <Form.Control
                  type="text"
                  className="border"
                  placeholder="Enter Address Line 2"
                  name="address2"
                  value={address2}
                  onChange={handleOnChange}
                />
              </Form.Group>
              <Form.Group controlId="textarea" as={Col} lg={4}>
                <Form.Label className="fs-xs">
                  More Details About Academy
                </Form.Label>
                <Form.Control
                  type="text"
                  className="border"
                  placeholder="Enter your Address here..."
                  name="details"
                  value={details}
                  onChange={handleOnChange}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group controlId="city" as={Col} lg={4}>
                <Form.Label className="fs-xs">City</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter City Name"
                  value={city}
                  name="city"
                  onChange={handleOnChange}
                />
              </Form.Group>
            </Row>
          </div>
        </Form>
      </div>
      <footer className="ss-page-footer d-flex align-items-center px-4 py-3 gap-3">
        <Button
          variant="outline-secondary"
          size="sm"
          className="px-4"
          onClick={onClearForm}
        >
          Clear
        </Button>
        <Button
          onClick={isEditAcademy ? saveEditChanges : handleOnSubmit}
          variant="secondary"
          size="sm"
          className="px-4"
          disabled={checkOldAndCurrentData(userData.current, academyDetails)}
        >
          {isEditAcademy ? "Save" : "Create"}
        </Button>
      </footer>
    </div>
  );
};

export default AcademyFormComponent;
