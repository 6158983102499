import { faTrashCan, faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonIcon } from "Components/Shared";
import {
  ColumnPropsType,
  QuestionDetailsObjectType,
  QuestionErrorObjectType,
  OptionErrorObjectType,
  AnswerErrorType,
  SurveyQuestionFormConstant,
  AddSurveyQuestionType,
  EditSurveyQuestionType,
  RoleType,
  TriggerEventType,
  SessionFieldMapType,
  SessionObjectType,
} from "Interfaces/Pages/SurveyQuestionsInterface";
import { isAllowedTo } from "Utils/helper";

export const getColumns = ({
  onEdit,
  onDelete,
  permissions,
}: ColumnPropsType) => [
  {
    dataField: "title",
    title: "Question",
  },
  {
    dataField: "master_sport_id",
    title: "Type",
    colWidth: "6.25rem",
    formatter: (props: QuestionDetailsObjectType) => {
      return props.master_sport_id
        ? FORM_CONSTANT.specific
        : FORM_CONSTANT.generic;
    },
  },
  isAllowedTo(FORM_CONSTANT.SurveyQuestion, "update", permissions) && {
    dataField: "is_active",
    title: "Status",
    colWidth: "100rem",
    formatter: (props: QuestionDetailsObjectType) => {
      return props.is_active
        ? FORM_CONSTANT.QuestionActive
        : FORM_CONSTANT.QuestionInActive;
    },
  },
  isAllowedTo(FORM_CONSTANT.SurveyQuestion, "update", permissions) && {
    dataField: "actions",
    title: "Actions",
    colWidth: "6.25rem",
    formatter: (props: QuestionDetailsObjectType) => (
      <div className="d-flex" key={props.id}>
        <ButtonIcon
          onClick={(event) => {
            event.stopPropagation();
            onEdit && onEdit(props.id);
          }}
        >
          <FontAwesomeIcon icon={faPencil} />
        </ButtonIcon>
        <ButtonIcon
          onClick={(event) => {
            event.stopPropagation();
            onDelete && onDelete(props.id);
          }}
          isdisabled={!props.is_active}
        >
          <FontAwesomeIcon icon={faTrashCan} />
        </ButtonIcon>
      </div>
    ),
  },
];

export const ANSWER_ERROR: AnswerErrorType[] = [
  {
    answerError: "",
  },
  {
    answerError: "",
  },
  {
    answerError: "",
  },
  {
    answerError: "",
  },
];

export const ANSWER: string[] = ["A", "B", "C", "D"];

export const QuestionDetails: QuestionDetailsObjectType = {
  title: "",
  trigger_event: "",
  is_active: false,
  master_sport_id: "",
  applicable_for: "",
  master_sport: {
    name: "",
  },
  survey_question_options: [
    {
      title: "",
      preference: "",
    },
    {
      title: "",
      preference: "",
    },
    {
      title: "",
      preference: "",
    },
    {
      title: "",
      preference: "",
    },
  ],
};

export const FORM_CONSTANT: SurveyQuestionFormConstant = {
  text: "text",
  placeholderQuestion: "Type question here",
  placeholderOption: "Type option here",
  generic: "Generic",
  specific: "Specific",
  session: "session",
  sessionCatagaries: "Session Category",
  role: "role",
  question: "question",
  invalid: "invalid",
  active: "active",
  activeSwitch: "active-switch",
  inactiveSwitch: "inactive-switch",
  option: "option",
  outlineSecondary: "outline-secondary",
  sm: "sm",
  secondary: "secondary",
  SurveyQuestionDwtails: "Survey Questions Details",
  QuestionActive: "Active",
  QuestionInActive: "In-Active",
  SurveyQuestion: "SurveyQuestion",
  Update: "update",
};

export const PREFERENCE_ARR: string[] = ["1", "2", "3", "4"];

export const OPTIONS_ARR: string[] = ["A", "B", "C", "D"];

export const QuestionErrorDefaultValue: QuestionErrorObjectType = {
  title_error: "",
  applicable_for_error: "",
  trigger_event_error: "",
  master_sport_id_error: "",
};

export const OptionErrorDefaultValue: OptionErrorObjectType = {
  title_error: "",
  preference_error: "",
};

export const PREFERENCES: string[] = [
  "Most Preferred",
  "2nd Most Preferred",
  "3rd Most Preferred",
  "Least Preferred",
];

export const ADD_SURVEY_QUESTION_FORM_FIELD: AddSurveyQuestionType = {
  title: "Create Survey Question",
  button1: "Clear",
  button2: "Add",
};
export const EDIT_SURVEY_QUESTION_FORM_FIELD: EditSurveyQuestionType = {
  title: "Edit Survey Question",
  button1: "Cancel",
  button2: "Save",
};
export const ROUTE_SURVEY_QUESTION: string = "/survey-questions";
export const ROUTE_SURVEY_QUESTION_CREATE: string = "/survey-questions/new";
export const ROUTE_SURVEY_QUESTION_UPDATE = (id: number) => {
  return `/survey-questions/${id}/edit`;
};
export const ROUTE_SURVEY_QUESTION_DETAILS = (id: number) => {
  return `/survey-questions/${id}`;
};
export const TRIGGER_EVENT: TriggerEventType = {
  PRE_SESSION: "pre_session",
  POST_SESSION: "post_session",
  MORNING: "morning",
};
export const ROLE: RoleType = {
  PLAYER: "player",
  COACH: "coach",
  BOTH: "both",
  PLAYER_NAME: "Player",
  COACH_NAME: "Coach",
};
export const SURVEY_QUESTIONS: string = "Survey Questions";
export const CREATE_NEW_QUESTION: string = "Create New Question";
export const ASSIGN_SURVEY_QUESTION: string = "Assign Survey Question";

export const SESSION_FIELD_MAP: SessionFieldMapType = {
  Onboarding: "onboarding",
  Morning: "morning",
  "Pre-Session": "pre_session",
  "Post-Session": "post_session",
  onboarding: "Onboarding",
  morning: "Morning",
  pre_session: "Pre-Session",
  post_session: "Post-Session",
  player: "Player",
  coach: "Coach",
  both: "Both",
};

export const UserSessionMap: Record<string, string[]> = {
  player: ["Pre-Session", "Post-Session", "Morning"],
  coach: ["Post-Session"],
};

export const ROLE_All: SessionObjectType[] = [
  { name: "Both", field: "both" },
  { name: "Player", field: "player" },
  { name: "Coach", field: "coach" },
];
export const ROLE_PLAYER: SessionObjectType[] = [
  { name: "Player", field: "player" },
];

export const PLAYER_SESSION_TYPES: SessionObjectType[] = [
  { name: "Pre-Session", field: "pre_session" },
  { name: "Post-Session", field: "post_session" },
  { name: "Morning", field: "morning" },
];

export const COACH_SESSION_TYPES: SessionObjectType[] = [
  { name: "Post-Session", field: "post_session" },
];

export const TRIGGER_EVENT_TITLE: string = "Trigger Event";
export const ROLE_TITLE: string = "Role";
export const ANSWER_OPTION = "Answer Option";
