import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Form } from "Components/Core";
import { ButtonIcon, SearchControl, TableComponent } from "Components/Shared";
import {
  deleteSport,
  getMasterSportsList,
  getSportsList,
} from "Api/Pages/SportsApi";
import { SportObjectType } from "Interfaces/Pages/SportsInterface";
import { getColumns, SPORTS } from "Pages/Sports/constants";
import {
  SPORT,
  ADD_SPORT,
  CONFIRM,
  deactivateConfirmMessage,
  TEXT_END,
} from "App/constants";
import { isAllowedTo } from "Utils/helper";
import { useAppDispatch, useAppSelector } from "App/hooks";
import { setConfirmProps } from "App/commonReducer";

const Sports = () => {
  const dispatch = useAppDispatch(),
    navigate = useNavigate(),
    { confirmProps } = useAppSelector((state) => state.commonReducer),
    [sportsList, setSportsList] = useState<SportObjectType[]>([]),
    [filteredList, setFilteredList] = useState<SportObjectType[]>([]),
    {
      userDetails: { academy_id, permissions },
    } = useAppSelector((state) => state.loginReducer);

  const onAddSports = () => {
      navigate("/sports/new");
    },
    handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
      const list = sportsList.filter((el: SportObjectType) =>
        el.name
          .replaceAll(" ", "")
          .toLocaleLowerCase()
          .includes(event.target.value.replaceAll(" ", "").toLocaleLowerCase())
      );
      setFilteredList(list);
    },
    onDeactivateSport = async (sportId: number) => {
      dispatch(
        setConfirmProps({
          ...confirmProps,
          closeButtonClassName: TEXT_END,
          modalType: CONFIRM,
          confirmBodyProps: {
            message: [`${deactivateConfirmMessage} ${SPORT}?`],
          },
          buttonProps: [
            { title: CONFIRM, onClick: () => onConfirmDeactivate(sportId) },
          ],
          showConfirm: true,
        })
      );
    },
    fetchSportsList = useCallback(async () => {
      const data = academy_id
        ? await getSportsList(academy_id)
        : await getMasterSportsList();
      setSportsList(data);
      setFilteredList(data);
    }, [academy_id]),
    onConfirmDeactivate = useCallback(
      async (id: number) => {
        await deleteSport({ academyId: academy_id, sportId: id });
        fetchSportsList();
      },
      [academy_id, fetchSportsList]
    );

  useEffect(() => {
    fetchSportsList();
  }, [academy_id, fetchSportsList]);

  return (
    <div className="ss-page-content d-flex flex-column flex-1 scroll-y bg-white">
      <header className="ss-page-header d-flex align-items-center justify-content-between px-4">
        <h2 className="fs-md mb-0 fw-medium">{SPORTS}</h2>
        <div className="d-flex align-items-center gap-2">
          {isAllowedTo("Sport", "bulk_create", permissions) && (
            <ButtonIcon
              className="btn-outline-secondary fs-sm"
              onClick={onAddSports}
            >
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              {ADD_SPORT}
            </ButtonIcon>
          )}
        </div>
      </header>
      <hr className="ss-page-seprator mx-4 my-0" />
      <div className="ss-page-body d-flex flex-column flex-1 scroll-y py-4">
        <Form className="d-flex justify-content-end mb-4 px-4">
          <SearchControl
            placeholder="Search"
            label="Search"
            onChange={handleSearch}
          />
        </Form>
        <div className="flex-1 scroll-y px-4">
          <div className="table-responsive">
            <TableComponent
              columns={getColumns(onDeactivateSport, permissions)}
              tableData={filteredList}
              isClickAble={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sports;
