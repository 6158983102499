import { useCallback } from "react";
import Select, { SingleValue } from "react-select";
import { invalidStyles } from "Components/Shared/SearchSelect/constant";
import {
  OptionObjectType,
  SearchSelectPropsType,
} from "Interfaces/Components/Shared/SearchSelectInterface";

const SearchSelect: React.FC<SearchSelectPropsType> = ({
  optionsList,
  setSearchString,
  setSelected,
  selected,
  placeholder,
  errorMessage,
  disabled,
  value,
}) => {
  const onInputChange = useCallback(
    (newValue: string) => {
      setSearchString && setSearchString(newValue);
    },
    [setSearchString]
  );

  const onSelect = useCallback(
    (event: SingleValue<OptionObjectType>) => {
      setSelected(event?.value);
    },
    [setSelected]
  );

  return (
    <>
      <Select
        isSearchable={true}
        options={optionsList}
        defaultValue={selected && selected.value}
        onInputChange={onInputChange}
        onChange={onSelect}
        placeholder={placeholder}
        styles={errorMessage ? invalidStyles : undefined}
        isDisabled={disabled}
        value={value}
        className="select-search-control"
        classNamePrefix="select-search-control"
      />
      {errorMessage && (
        <p className="required-text mb-0 ms-2">{errorMessage}</p>
      )}
    </>
  );
};

export default SearchSelect;
