import React from "react";
import { Pagination } from "react-bootstrap";
import { usePagination } from "Components/Shared/PaginationControl/usePagination";
import { PaginationControlProps } from "Interfaces/Components/Shared/PaginationControlInterface";
import { MAX_DISPLAY_PAGES_COUNT } from "App/constants";

const PaginationControl: React.FC<PaginationControlProps> = ({
  currentPage,
  setCurrentPage,
  totalPages,
}) => {
  const { paginationRange, prevDisabled, nextDisabled } = usePagination({
    currentPage,
    totalPages,
    pageSize: MAX_DISPLAY_PAGES_COUNT,
  });

  const goToNextPage = () => {
    setCurrentPage(currentPage + 1);
  };
  const goToPreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };
  const changePage = (pagenumber: number) => {
    setCurrentPage(pagenumber);
  };

  return totalPages > 1 ? (
    <Pagination className="mb-0 ms-3">
      <Pagination.Prev
        className="fs-xs"
        onClick={goToPreviousPage}
        disabled={prevDisabled}
      >
        Previous
      </Pagination.Prev>
      {paginationRange.map((val: number) => (
        <Pagination.Item
          className="fs-xs"
          key={val}
          onClick={() => changePage(val)}
          active={currentPage === val ? true : false}
        >
          {val}
        </Pagination.Item>
      ))}
      <Pagination.Next
        className="fs-xs"
        onClick={goToNextPage}
        disabled={nextDisabled}
      >
        Next
      </Pagination.Next>
    </Pagination>
  ) : null;
};
export default PaginationControl;
