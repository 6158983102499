import axios from "axios";
import { BASE_URL } from "App/constants";
import { AddAdminParam } from "Interfaces/AppInterface";

export const getAdminData: Function = async (
  academy_id: number,
  id: number
) => {
  const { data } = await axios.get(
    `${BASE_URL}academies/${academy_id}/admins/${id}`
  );
  if (data) {
    return data;
  }
  return [];
};

export const addAdmin = async ({
  academy_id,
  params,
  isEditAdmin,
}: AddAdminParam) => {
  if (isEditAdmin) {
    await axios.put(
      `${BASE_URL}academies/${academy_id}/admins/${params["id"]}`,
      {
        admin: {
          ...params,
        },
      }
    );
    return [];
  }
  await axios.post(`${BASE_URL}academies/${academy_id}/admins`, {
    admin: {
      ...params,
    },
  });
  return [];
};
