import { useQuery } from "react-query";

import { Form, Button } from "Components/Core";
import Parameter from "./Parmeter";
import { getMasterSportsList, getSportsList } from "App/api";
import { useAppSelector } from "App/hooks";
import { AssetTagsProps } from "Interfaces/AssetTagsInterface";

const AssetTags = (props: AssetTagsProps) => {
  const { formData, disabled = false, dispatch } = props;

  const {
    userDetails: { academy_id },
  } = useAppSelector((state) => state.loginReducer);

  const { data } = useQuery({
    queryKey: ["asset-tags-sport-list", academy_id],
    queryFn: () =>
      academy_id ? getSportsList(academy_id) : getMasterSportsList(),
  });

  if (!data) {
    return null;
  }

  const handleChangeSport = (id: number) => {
    dispatch({ type: "master_sport_id", payload: id });
  };

  const sportList = data as {
    id: number;
    master_sport_id: number;
    name: string;
  }[];
  const sportButtons = sportList.map((sport) => (
    <Button
      key={sport.master_sport_id}
      variant={
        formData.master_sport_id ===
        (academy_id ? sport.master_sport_id : sport.id)
          ? "secondary"
          : "outline-secondary"
      }
      disabled={disabled}
      onClick={() =>
        handleChangeSport(academy_id ? sport.master_sport_id : sport.id)
      }
    >
      {sport.name}
    </Button>
  ));

  return (
    <>
      <Form.Group>
        <Form.Label>Sport</Form.Label>
        {sportButtons.length ? (
          <div className="d-flex gap-2 p-1 flex-wrap">{sportButtons}</div>
        ) : (
          <p className="text-secondary">No Sports!</p>
        )}
      </Form.Group>
      {formData.master_sport_id && (
        <Parameter
          formData={formData}
          disabled={disabled}
          dispatch={dispatch}
        />
      )}
    </>
  );
};

export default AssetTags;
