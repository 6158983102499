import axios from "axios";
import { ForgetPasswordPayloadObjectType } from "Interfaces/AppInterface";
import { ResetPasswordFormObjectType } from "Interfaces/Pages/ForgotPasswordInterface";
import { FORGET_PASSWORD_URL } from "App/constants";

export const forgetPassword = async (data: ForgetPasswordPayloadObjectType) => {
  const response = await axios.post(FORGET_PASSWORD_URL, data);
  return response.data;
};

export const resetPassword = async (payload: ResetPasswordFormObjectType) => {
  const response = await axios
    .put(FORGET_PASSWORD_URL, payload)
    .then((response) => response)
    .catch((error) => error.response);
  return response.data;
};
