import {
  UserSportErrorObjectType,
  UserDetailFormErrorObjectType,
  UserSportObjectType,
  LevelObjectType,
  UserDetailsObjectType,
} from "Interfaces/Components/Shared/UserDetailsFormInterface";

export const UserSportErrorValue: UserSportErrorObjectType = {
  sport_id_error: "",
  rank_error: "",
  sub_rank_error: "",
  level_error: "",
};

export const UserDetailFormErrorValue: UserDetailFormErrorObjectType = {
  name_error: "",
  email_error: "",
  contact_number_error: "",
  country_code_error: "",
  sport_error: [],
  aadhar_number_error: "",
};
export const UserSport: Record<string, string> = {
  Coach: "coach_sports",
  Player: "player_sports",
};

export const UserSportDefaultValueMap: Record<string, UserSportObjectType> = {
  Player: {
    sport_id: "",
    level: "",
    matches_played: "",
    rank: "D",
    sub_rank: "4",
    _destroy: false,
  },
  Coach: {
    sport_id: "",
    level: "",
    matches_played: "",
    rank: "",
    sub_rank: "",
    _destroy: false,
  },
};

export const UserLevelMap: Record<string, LevelObjectType[]> = {
  Coach: [
    { name: "Level-1", value: "level_1" },
    { name: "Level-2", value: "level_2" },
    { name: "Level-3", value: "level_3" },
  ],
  Player: [
    { name: "Beginner", value: "beginner" },
    { name: "Intermediate", value: "intermediate" },
    { name: "Advance", value: "advance" },
  ],
};

export const UserDefaultValue: UserDetailsObjectType = {
  name: "",
  contact_number: "",
  country_code: "",
  email: "",
  details: "",
  image: "",
  gender: "",
  city: "",
  dob: "",
  height: "",
  weight: "",
  blood_group: "",
  bmi: "",
  fathers_name: "",
  mothers_name: "",
  aadhar_number: "",
  educational_qualification: "",
  nationality: "",
  state: "",
  address: "",
  previous_serious_injuries: "",
  medical_history: "",
  other_hobbies_and_interest: "",
  asian_ranking: "",
  international_ranking: "",
  national_ranking: "",
  state_ranking: "",
};

export const Rank: string[] = ["A", "B", "C", "D"];
export const SubRank: string[] = ["1", "2", "3", "4"];

export const FORM_CONSTANT = {
  ReqRank: "Rank*",
  ReqSubRank: "Sub Rank*",
  Rank: "Rank",
  SubRank: "Sub Rank",
};

export const getErrorText = (
  contact_number_error: string,
  country_code_error: string
) => {
  let errorMsg: string = "";
  if (contact_number_error && country_code_error) {
    errorMsg = "Code and Contact Number is required";
  } else if (contact_number_error) {
    errorMsg = contact_number_error;
  } else if (country_code_error) {
    errorMsg = country_code_error;
  }
  return errorMsg;
};
