import { useCallback } from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Tab, Tabs } from "Components/Core";
import {
  SelectSports,
  TableComponent,
  ToggleSwitch,
  ButtonIcon,
} from "Components/Shared";
import { SurveyQuestionListComponentProps } from "Interfaces/Pages/SurveyQuestionsInterface";
import {
  ASSIGN_SURVEY_QUESTION,
  getColumns,
  ROLE,
  SESSION_FIELD_MAP,
  COACH_SESSION_TYPES,
  PLAYER_SESSION_TYPES,
} from "Pages/SurveyQuestions/constants";
import { getBatchQuestionColumns } from "Pages/Batches/constants";
import { SPORT, INITIAL_PAGE } from "App/constants";
import { useAppDispatch, useAppSelector } from "App/hooks";
import { setFilterKeys } from "./surveyQuestionListComponentReducer";

const SurveyQuestionListComponent: React.FC<
  SurveyQuestionListComponentProps
> = ({
  setAssignButtonClicked,
  showSportsTab,
  showAssignButton,
  questionsList,
  questionCount,
  tabs,
  onUpdateQuestion,
  onDeactivateQuestion,
  onEditQuestion,
  onDetailQuestion,
}) => {
  const {
      userDetails: { permissions },
    } = useAppSelector((state) => state.loginReducer),
    { filterKeys } = useAppSelector(
      (state) => state.surveyQuestionListComponentReducer
    ),
    { selectedToggleSwitch, selectedUserSession, selectedSportValue } =
      filterKeys,
    dispatch = useAppDispatch();

  const onTabSelect = useCallback(
    (tabName: string | null) => {
      tabName &&
        dispatch(
          setFilterKeys({
            ...filterKeys,
            currentPage: INITIAL_PAGE,
            selectedUserSession: tabName,
          })
        );
    },
    [dispatch, filterKeys]
  );

  const handleSwitchUser = useCallback(() => {
    if (selectedToggleSwitch === ROLE.PLAYER) {
      dispatch(
        setFilterKeys({
          ...filterKeys,
          selectedToggleSwitch: ROLE.COACH,
          selectedUserSession: COACH_SESSION_TYPES[0].name,
          selectedSportValue: "",
          currentPage: INITIAL_PAGE,
        })
      );
    } else {
      dispatch(
        setFilterKeys({
          ...filterKeys,
          selectedToggleSwitch: ROLE.PLAYER,
          selectedUserSession: PLAYER_SESSION_TYPES[0].name,
          selectedSportValue: "",
          currentPage: INITIAL_PAGE,
        })
      );
    }
  }, [selectedToggleSwitch, dispatch, filterKeys]);

  return (
    <>
      <Form className="d-flex align-items-end justify-content-between mb-4 px-4">
        <ToggleSwitch
          textLeft={ROLE.PLAYER_NAME}
          textRight={ROLE.COACH_NAME}
          onClick={handleSwitchUser}
          checked={selectedToggleSwitch === "coach" ? true : false}
        />

        {showSportsTab && (
          <SelectSports
            handleSelect={(event: React.ChangeEvent<HTMLSelectElement>) => {
              const {
                target: { value },
              } = event;
              dispatch(
                setFilterKeys({
                  ...filterKeys,
                  currentPage: INITIAL_PAGE,
                  selectedSportValue: value,
                })
              );
            }}
            selected={+selectedSportValue}
            shouldShowAll={true}
            shouldPassMasterSportId={true}
            title={SPORT}
          />
        )}
      </Form>
      <Tabs
        className="ss-tabs"
        onSelect={onTabSelect}
        activeKey={selectedUserSession}
        defaultValue={selectedUserSession}
      >
        {tabs &&
          tabs.map((tabName: string) => {
            return (
              <Tab
                eventKey={tabName}
                title={
                  tabName +
                  `${
                    questionCount
                      ? ` (${
                          questionCount[selectedToggleSwitch][
                            SESSION_FIELD_MAP[tabName]
                          ]
                        })`
                      : ""
                  }`
                }
                className="ss-tab-content p-0"
                key={tabName}
              >
                {showAssignButton && (
                  <div className="p-2">
                    <ButtonIcon
                      className="btn-outline-secondary fs-sm border-0"
                      onClick={() =>
                        setAssignButtonClicked && setAssignButtonClicked(true)
                      }
                    >
                      {ASSIGN_SURVEY_QUESTION}
                      <FontAwesomeIcon icon={faPlus} className="me-2 px-2" />
                    </ButtonIcon>
                  </div>
                )}
                <div className="ss-page-body d-flex flex-column flex-1 scroll-y">
                  <div className="table-responsive p-2">
                    <TableComponent
                      columns={
                        onEditQuestion && onDeactivateQuestion
                          ? getColumns({
                              onEdit: onEditQuestion,
                              onDelete: onDeactivateQuestion,
                              permissions,
                            })
                          : onUpdateQuestion &&
                            getBatchQuestionColumns({
                              onUpdateQuestion,
                              permissions,
                            })
                      }
                      tableData={questionsList}
                      hideThead={true}
                      onRowClick={onDetailQuestion}
                      isClickAble={true}
                    />
                  </div>
                </div>
              </Tab>
            );
          })}
      </Tabs>
    </>
  );
};

export default SurveyQuestionListComponent;
