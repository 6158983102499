import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import Select from "react-select";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faArrowDown,
  faCalendarAlt,
  faCalendarXmark,
} from "@fortawesome/free-solid-svg-icons";
import { Dropdown, ButtonGroup, ToggleButton, Form } from "Components/Core";
import {
  ButtonIcon,
  SearchControl,
  TableComponent,
  SelectSports,
  PaginationControl,
} from "Components/Shared";
import {
  COACH_SURVEY,
  DATE_PICKER_PLACEHOLDER,
  DOWNLOAD,
  DROPDOWN_COLUMN_MAP,
  DROPDOWN_VALUE_MAP,
  INITIAL_PAGE,
  PLAYER_SURVEY,
  RADIOS,
  RECORDS_PER_PAGE,
  REPORTS_INDEX,
  REPORTS_LIST_MAP,
  SEARCH_PLACEHOLDER_MAP,
} from "./constants";
import { SPORT } from "App/constants";
import {
  PlayerSurveyProps,
  ReportsIndexType,
} from "Interfaces/Pages/ReportsInterface";
import { getReportList } from "App/api";
import { useAppDispatch, useAppSelector } from "App/hooks";
import { handleDownload } from "Utils/utils";
import { setFilterKeys, setFilterList } from "./reportsReducer";
import "./reports.scss";

const Reports = () => {
  const dispatch = useAppDispatch(),
    navigate = useNavigate(),
    { filterList, filterKeys } = useAppSelector(
      (state) => state.reportsReducer
    ),
    {
      numOfDays,
      dropDownValue,
      selectedSportValue,
      searchInput,
      selectedColumn,
    } = filterKeys,
    { selectedTableData } = filterList,
    [currentPage, setCurrentPage] = useState<number>(INITIAL_PAGE),
    [totalPages, setTotalPages] = useState<number>(INITIAL_PAGE),
    [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false),
    [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(true),
    [isInitiallyCalling, setIsInitiallyCalling] = useState<boolean>(false),
    [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
      null,
      null,
    ]),
    [startDate, endDate] = dateRange,
    {
      userDetails: { academy_id },
    } = useAppSelector((state) => state.loginReducer),
    fetchReportList = useCallback(async () => {
      if ((startDate && endDate) || (!startDate && !endDate)) {
        const list = await getReportList({
            academyId: academy_id,
            dropDownValue: dropDownValue,
            selectedSportValue: selectedSportValue,
            numOfDays: numOfDays,
            searchInput: searchInput,
            currentPage: currentPage,
            start_date: startDate,
            end_date: endDate,
          }),
          { total_count } = list,
          data = list[REPORTS_LIST_MAP[dropDownValue]];
        setTotalPages(Math.ceil(total_count / RECORDS_PER_PAGE));
        data && dispatch(setFilterList({ selectedTableData: data }));
      }
    }, [
      dispatch,
      dropDownValue,
      numOfDays,
      searchInput,
      currentPage,
      academy_id,
      selectedSportValue,
      startDate,
      endDate,
    ]),
    handleDropdownSelect = useCallback(
      (eventKey: string | null) => {
        if (eventKey) {
          dispatch(
            setFilterKeys({
              ...filterKeys,
              searchInput: "",
              dropDownValue: eventKey,
              selectedColumn: DROPDOWN_COLUMN_MAP[eventKey],
            })
          );
          setCurrentPage(INITIAL_PAGE);
        }
      },
      [filterKeys, dispatch]
    ),
    handleSportSelect = useCallback(
      (event: React.ChangeEvent<HTMLSelectElement>) => {
        const {
          target: { value },
        } = event;
        dispatch(
          setFilterKeys({
            ...filterKeys,
            selectedSportValue: value,
          })
        );
        setCurrentPage(INITIAL_PAGE);
      },
      [filterKeys, dispatch]
    ),
    handleSearch = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const {
          target: { value },
        } = event;
        dispatch(
          setFilterKeys({
            ...filterKeys,
            searchInput: value,
          })
        );
        setCurrentPage(INITIAL_PAGE);
      },
      [filterKeys, dispatch]
    ),
    handleNumOfDaysSelected = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(
          setFilterKeys({
            ...filterKeys,
            numOfDays: event.currentTarget.value,
          })
        );
        setCurrentPage(INITIAL_PAGE);
      },
      [dispatch, filterKeys]
    ),
    displayDropDownItems = useCallback(() => {
      const dropDownItems = Object.keys(DROPDOWN_VALUE_MAP);
      return dropDownItems.map((index) => (
        <Dropdown.Item eventKey={index} key={index} className="fs-sm p-2">
          {DROPDOWN_VALUE_MAP[index]}
        </Dropdown.Item>
      ));
    }, []),
    // This feature is not in MVP so commented for now
    // const displayDropDownFilterItems = () => {
    //   const dropDownFilterItems = Object.keys(DROPDOWN_FILTER_MAP);
    //   return dropDownFilterItems.map((index) => (
    //     <Dropdown.Item
    //       eventKey={index}
    //       key={index}
    //       className="fs-sm px-2 py-3 d-flex align-items-center gap-2"
    //     >
    //       <span className="fs-xs">{DROPDOWN_FILTER_MAP[index]}</span>
    //       <Select
    //         defaultValue={[colourOptions[2], colourOptions[3]]}
    //         isMulti
    //         name="colors"
    //         options={colourOptions}
    //         className="filter-multi-select flex-1"
    //         classNamePrefix="filter-select"
    //         placeholder={<div className="fs-xs fw-bold text-uppercase">All</div>}
    //       />
    //     </Dropdown.Item>
    //   ));
    // };
    openDatePicker = useCallback(() => {
      setIsDatePickerOpen(!isDatePickerOpen);
      !isCalendarOpen && setIsCalendarOpen(!isCalendarOpen);
      if (startDate) {
        setDateRange([null, null]);
      }
    }, [isDatePickerOpen, isCalendarOpen, startDate]),
    handleChange = useCallback(
      (updateDate: [Date | null, Date | null]) => {
        updateDate && setDateRange(updateDate);
        if (updateDate[0] && updateDate[1]) setIsCalendarOpen(!isCalendarOpen);
      },
      [isCalendarOpen]
    ),
    onTableRowClick = (rowData: PlayerSurveyProps) => {
      if (dropDownValue === PLAYER_SURVEY) {
        navigate(`/reports/player-survey-adherence/${rowData.id}`, {
          state: rowData,
        });
      }
    };

  if (!isInitiallyCalling) {
    dispatch(
      setFilterKeys({
        ...filterKeys,
        selectedColumn: DROPDOWN_COLUMN_MAP[dropDownValue],
      })
    );
    setIsInitiallyCalling(true);
  }

  useEffect(() => {
    fetchReportList();
  }, [fetchReportList]);

  return (
    <div className="ss-page-content d-flex flex-column flex-1 scroll-y bg-white">
      <header className="ss-page-header d-flex align-items-xl-center justify-content-between p-4 flex-column flex-xl-row">
        <div className="d-flex align-items-center gap-3 mb-3 mb-xl-0">
          <h2 className="fs-md mb-0 fw-medium">Reports</h2>
          <Dropdown
            className="ss-dropdown ss-survey-dropdown"
            onSelect={handleDropdownSelect}
          >
            <Dropdown.Toggle
              variant="outline-secondary"
              id="dropdown-basic"
              className="fs-sm fw-medium d-flex align-items-center justify-content-between"
            >
              {DROPDOWN_VALUE_MAP[dropDownValue]}
              <FontAwesomeIcon icon={faAngleDown} className="ms-4" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="p-0">
              {displayDropDownItems()}
            </Dropdown.Menu>
          </Dropdown>
          {/*This feature is not in MVP so commented for now
          <Dropdown className="ss-dropdown ss-filter-dropdown" show={false}>
            <Dropdown.Toggle
              variant="outline-secondary"
              id="dropdown-basic2"
              className="fs-sm fw-medium d-flex align-items-center justify-content-between"
              disabled
            >
              {ADD_FILTER}
              <FontAwesomeIcon icon={faFilter} className="fs-xs ms-3" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="p-0">
              {displayDropDownFilterItems()}
              <Dropdown.Item
                href="#"
                className="fw-medium fs-sm px-2 py-3 filter-btn"
              >
                {APPLY_FILTER}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
          <SelectSports
            className="d-flex align-items-center gap-2"
            selected={selectedSportValue}
            handleSelect={handleSportSelect}
            shouldShowAll={true}
            title={SPORT}
          />
        </div>
        <div className="d-flex align-items-center gap-2">
          {!isDatePickerOpen ? (
            <ButtonGroup className="ss-three-state-toggle">
              {RADIOS.map((radio, idx) => (
                <ToggleButton
                  key={idx}
                  id={`radio-${idx}`}
                  type="radio"
                  variant="outline-secondary"
                  name="radio"
                  className="px-3 py-1"
                  value={radio.value}
                  checked={numOfDays === radio.value}
                  onChange={handleNumOfDaysSelected}
                >
                  {radio.name}
                </ToggleButton>
              ))}
            </ButtonGroup>
          ) : (
            <div className="reports-datepicker-wrap">
              <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                maxDate={new Date()}
                onChange={handleChange}
                placeholderText={DATE_PICKER_PLACEHOLDER}
                className="reports-datepicker"
                open={isCalendarOpen}
                onChangeRaw={(event) => event.preventDefault()}
                showMonthDropdown
              />
            </div>
          )}
          <ButtonIcon className="text-secondary fs-4" onClick={openDatePicker}>
            {!isDatePickerOpen ? (
              <FontAwesomeIcon icon={faCalendarAlt} />
            ) : (
              <FontAwesomeIcon icon={faCalendarXmark} />
            )}
          </ButtonIcon>
        </div>
      </header>
      <hr className="ss-page-seprator mx-4 my-0" />
      <div className="ss-page-body d-flex flex-column flex-1 scroll-y py-4">
        <Form
          className="d-flex align-items-center justify-content-between mb-4 px-4"
          onSubmit={(event: React.ChangeEvent<HTMLFormElement>) =>
            event.preventDefault()
          }
        >
          <SearchControl
            placeholder={SEARCH_PLACEHOLDER_MAP[dropDownValue]}
            label={SEARCH_PLACEHOLDER_MAP[dropDownValue]}
            onChange={handleSearch}
          />
          <ButtonIcon
            className="btn-outline-secondary fs-sm"
            onClick={() =>
              handleDownload({
                academyId: academy_id,
                selectedSportValue: selectedSportValue,
                startDate: startDate,
                endDate: endDate,
                searchInput: searchInput,
                numOfDays: numOfDays,
                dropDownValue: dropDownValue,
              })
            }
          >
            <FontAwesomeIcon icon={faArrowDown} className="me-2" />
            {DOWNLOAD}
          </ButtonIcon>
        </Form>
        {[PLAYER_SURVEY, COACH_SURVEY].includes(dropDownValue) && (
          <div className="progress-bar-status-list-wrap ms-auto mb-3 px-4">
            <div className="progress-bar-status-list d-flex align-items-center gap-5 px-3 py-2">
              {REPORTS_INDEX.map((reportIndex: ReportsIndexType) => {
                return (
                  <div className="progress-bar-status-item d-flex align-items-center gap-2">
                    <span className={reportIndex.className}></span>
                    <p className="mb-0 fs-sm">{reportIndex.label}</p>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <div className="flex-1 scroll-y px-4 mb-3">
          <div className="table-responsive">
            <TableComponent
              columns={selectedColumn}
              tableData={selectedTableData}
              isClickAble={dropDownValue === PLAYER_SURVEY}
              onRowClick={onTableRowClick}
            />
          </div>
        </div>
        <div className="pagination-wrap d-flex justify-content-end px-4">
          <PaginationControl
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
          />
        </div>
      </div>
    </div>
  );
};

export default Reports;
