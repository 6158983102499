import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SurveyQuestionFormComponent } from "Components/Shared";
import { AddQuestion } from "Api/Pages/SurveyQuestionsApi";
import { QuestionDetailsObjectType } from "Interfaces/Pages/SurveyQuestionsInterface";
import {
  QuestionDetails,
  ROUTE_SURVEY_QUESTION,
  ADD_SURVEY_QUESTION_FORM_FIELD,
} from "Pages/SurveyQuestions/constants";
import "./surveyQuestions.scss";

const AddSurveyQuestion = () => {
  const [questionDetails, setQuestionDetails] =
      useState<QuestionDetailsObjectType>({
        ...QuestionDetails,
        survey_question_options: [...QuestionDetails.survey_question_options],
      }),
    navigate = useNavigate();

  const OnAddButtonClick = async () => {
    await AddQuestion({ isEditUser: false, param: questionDetails });
    navigate(ROUTE_SURVEY_QUESTION);
  };
  const onClearButtonClick = () => {
    setQuestionDetails({
      ...JSON.parse(JSON.stringify(QuestionDetails)),
    });
  };

  useEffect(() => {
    onClearButtonClick();
  }, []);

  return (
    <SurveyQuestionFormComponent
      formField={ADD_SURVEY_QUESTION_FORM_FIELD}
      questionDetails={questionDetails}
      setQuestionDetails={setQuestionDetails}
      onClearButtonClick={onClearButtonClick}
      OnAddButtonClick={OnAddButtonClick}
    />
  );
};

export default AddSurveyQuestion;
