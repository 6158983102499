import React from "react";
import { ConfirmBoxBodyPropsType } from "Interfaces/Components/Shared/ConfirmBoxComponentInterface";

const GuidelinesBoxBody: React.FC<ConfirmBoxBodyPropsType> = ({ list }) => {
  return (
    <div>
      <ul>
        {list &&
          list.map((item: string, index: number) => {
            return (
              <li key={index}>
                <p className="fs-sm  mb-0">{item}</p>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default GuidelinesBoxBody;
