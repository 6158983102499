import { faStar } from "@fortawesome/free-solid-svg-icons";
import { ProgressBarComponent, Rating } from "Components/Shared";
import {
  TableColumnInterface,
  BatchPerformanceProps,
  PlayerSurveyProps,
  CoachSurveyProps,
  DropdownValueMapInterface,
  DropdownColumnMapInterface,
  DropdownFilterMapInterface,
  ReportsRouteInterface,
  BatchStatusProps,
  ReportsFilterKey,
  ReportsFilterList,
  SearchPlaceHolderType,
  ReportsIndexType,
  ReportsRadios,
} from "Interfaces/Pages/ReportsInterface";
import { titleCaseConversion } from "Utils/utils";

export const ATTENDANCE_COLUMNS: TableColumnInterface[] = [
  {
    dataField: "player_name",
    title: "Player Name",
  },
  {
    dataField: "sport",
    title: "Sport",
  },
  {
    dataField: "batch_coach_name",
    title: "Coach Name",
  },
  {
    dataField: "batch_name",
    title: "Batch Name",
  },
  {
    dataField: "batch_time",
    title: "Batch Time",
  },
  {
    dataField: "start_date",
    title: "Start Date",
  },
  {
    dataField: "end_date",
    title: "End Date",
  },
  {
    dataField: "survey_activity",
    title: "Survey Activity %",
  },
  {
    dataField: "attendance",
    title: "Attendance %",
  },
];

export const BATCH_PERFORMANCE_COLUMNS: TableColumnInterface[] = [
  {
    title: "Batch Name",
    formatter: (props: BatchPerformanceProps) => {
      if (props && props.name && props.status) {
        return (
          <div
            className={`border-start border-4 ${
              BATCH_STATUS_CSS[props.status]
            }`}
          >
            <span className="ms-2">{props.name}</span>
          </div>
        );
      }
    },
  },
  {
    dataField: "sport",
    title: "Sport",
  },
  {
    dataField: "batch_coach_name",
    title: "Coach Name",
  },
  {
    title: "Status",
    formatter: (props: BatchPerformanceProps) => {
      if (props && props.status) {
        return titleCaseConversion(props.status);
      }
    },
  },
  {
    dataField: "players_count",
    title: "Number of Player",
  },
  {
    dataField: "start_date",
    title: "Start Date",
  },
  {
    dataField: "best_rating_percentage",
    title: "Best Rating",
  },
  {
    dataField: "least_rating_percentage",
    title: "Least Rating",
  },
  {
    dataField: "performance",
    title: "Batch Performance",
    formatter: (props: BatchPerformanceProps) => (
      <>
        <span>{`${props.performance}`}</span>
        <Rating name={faStar} className="fs-sm ms-2 " />
      </>
    ),
  },
];

export const PLAYER_SURVEY_COLUMNS: TableColumnInterface[] = [
  {
    dataField: "player_name",
    title: "Player Name",
  },
  {
    dataField: "batch_coach_name",
    title: "Coach Name",
  },
  {
    dataField: "batch_name",
    title: "Batch Name",
  },
  {
    dataField: "missed_surveys_percentage",
    title: "Missed Survey",
  },
  {
    title: "Morning Survey",
    formatter: (props: PlayerSurveyProps) => {
      if (props && props.morning_surveys) {
        return <ProgressBarComponent surveys={props.morning_surveys} />;
      }
    },
  },
  {
    title: "Pre-Session Survey",
    formatter: (props: PlayerSurveyProps) => {
      if (props && props.pre_surveys) {
        return <ProgressBarComponent surveys={props.pre_surveys} />;
      }
    },
  },
  {
    title: "Post-Session Survey",
    formatter: (props: PlayerSurveyProps) => {
      if (props && props.post_surveys) {
        return <ProgressBarComponent surveys={props.post_surveys} />;
      }
    },
  },
];

export const COACH_SURVEY_COLUMNS: TableColumnInterface[] = [
  {
    dataField: "coach_name",
    title: "Coach Name",
  },
  {
    dataField: "batch_name",
    title: "Batch Name",
  },
  {
    dataField: "missed_surveys_percentage",
    title: "Missed Survey",
  },
  {
    dataField: "surveys",
    title: "Post-Session Survey",
    formatter: (props: CoachSurveyProps) => {
      if (props && props.surveys) {
        return <ProgressBarComponent surveys={props.surveys} />;
      }
    },
  },
];

export const INITIAL_PAGE: number = 1;
export const RECORDS_PER_PAGE: number = 10;
export const INITIAL_NUM_OF_DAYS: string = "15";
export const INITIAL_DROPDOWN_VALUE: string = "attendance";
export const ADD_FILTER: string = "Add Filter";
export const APPLY_FILTER: string = "Apply Filter";
export const DOWNLOAD: string = "Download";
export const SEARCH_PLAYER: string = "Search Player";
export const DATE_PICKER_PLACEHOLDER: string = "Select Date Range";

export const RADIOS: ReportsRadios[] = [
  { name: "15 Days", value: "15" },
  { name: "30 Days", value: "30" },
  { name: "3 Month", value: "90" },
];

export const DROPDOWN_VALUE_MAP: DropdownValueMapInterface = {
  attendance: "Attendance",
  batch: "Batch Performance",
  player: "Player Survey Adherence",
  coach: "Coach Survey Adherence",
};

export const DROPDOWN_COLUMN_MAP: DropdownColumnMapInterface = {
  attendance: ATTENDANCE_COLUMNS,
  batch: BATCH_PERFORMANCE_COLUMNS,
  player: PLAYER_SURVEY_COLUMNS,
  coach: COACH_SURVEY_COLUMNS,
};

export const DROPDOWN_FILTER_MAP: DropdownFilterMapInterface = {
  sport: "Sport",
  batch: "Batches",
};
export const REPORTS_ROUTE: ReportsRouteInterface = {
  attendance: "player_attendance",
  batch: "batch_performance",
  player: "player_survey_adherence",
  coach: "coach_survey_adherence",
};

export const REPORTS_LIST_MAP: ReportsRouteInterface = {
  attendance: "batch_players",
  batch: "batches",
  player: "batch_players",
  coach: "batch_coaches",
};
export const BATCH_STATUS_CSS: BatchStatusProps = {
  active: "border-success",
  inactive: "border-dark",
  cancelled: "border-danger",
};
export const DEFAULT_FILTER_KEY: ReportsFilterKey = {
  numOfDays: INITIAL_NUM_OF_DAYS,
  dropDownValue: INITIAL_DROPDOWN_VALUE,
  selectedSportValue: "",
  searchInput: "",
  selectedColumn: BATCH_PERFORMANCE_COLUMNS,
};
export const DEFAULT_FILTER_LIST: ReportsFilterList = {
  selectedTableData: [],
};

export const SEARCH_PLACEHOLDER_MAP: SearchPlaceHolderType = {
  attendance: "Search Player",
  batch: "Search Batch",
  player: "Search Player",
  coach: "Search Coach",
};

export const PLAYER_SURVEY: string = "player";
export const COACH_SURVEY: string = "coach";

export const REPORTS_INDEX: ReportsIndexType[] = [
  { className: "bg-green", label: "Most Preferred" },
  { className: "bg-green-light", label: "2nd Most Preferred" },
  { className: "bg-yellow", label: "3rd Most Preferred" },
  { className: "bg-brown", label: "Least Preferred" },
  { className: "bg-grey-light", label: "Not Updated" },
];

export const CHART_DATA = [
  { color: "#d66326", name: "Least Preferred", value: 25, weight: 4 },
  { color: "#e4a734", name: "3rd Most Preferred", value: 25, weight: 3 },
  { color: "#33db65", name: "2nd Most Preferred", value: 25, weight: 2 },
  { color: "#18af46", name: "Most Preferred", value: 25, weight: 1 },
];

export const CHART_DATA_WEIGHTAGE: any = {
  most_preferred: 4,
  second_preferred: 3,
  third_preferred: 2,
  least_preferred: 1,
};

export const REPORTS_DOWNLOAD_ROUTE: ReportsRouteInterface = {
  attendance: "player_attendance",
  batch: "batch_performance",
  player: "player_survey",
  coach: "coach_survey",
};
export const TRIGGER_EVENT_DISPLAY_NAME_MAPPING: any = {
  post_session: "Post session",
  pre_session: "Pre session",
  morning: "Morning session",
};
