import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  PaginationControl,
  HeaderComponent,
  SurveyQuestionListComponent,
} from "Components/Shared";
import { getQuestionsList, UpdateQuestion } from "Api/Pages/SurveyQuestionsApi";
import { QuestionDetailsObjectType } from "Interfaces/Pages/SurveyQuestionsInterface";
import {
  ROUTE_SURVEY_QUESTION_CREATE,
  CREATE_NEW_QUESTION,
  SURVEY_QUESTIONS,
  UserSessionMap,
  SESSION_FIELD_MAP,
  ROUTE_SURVEY_QUESTION_UPDATE,
  ROUTE_SURVEY_QUESTION_DETAILS,
} from "Pages/SurveyQuestions/constants";
import {
  CONFIRM,
  QUESTION,
  RECORDS_PER_PAGE,
  deactivateConfirmMessage,
  TEXT_END,
} from "App/constants";
import { isAllowedTo } from "Utils/helper";
import { setConfirmProps } from "App/commonReducer";
import { useAppDispatch, useAppSelector } from "App/hooks";
import { setFilterKeys } from "Components/Shared/SurveyQuestionListComponent/surveyQuestionListComponentReducer";

const SurveyQuestions = () => {
  const navigate = useNavigate(),
    dispatch = useAppDispatch(),
    {
      userDetails: { permissions },
    } = useAppSelector((state) => state.loginReducer),
    { confirmProps } = useAppSelector((state) => state.commonReducer),
    { filterKeys } = useAppSelector(
      (state) => state.surveyQuestionListComponentReducer
    ),
    {
      currentPage,
      totalPages,
      selectedToggleSwitch,
      selectedUserSession,
      selectedSportValue,
    } = filterKeys,
    [questionList, setQuestionList] = useState<QuestionDetailsObjectType[]>([]);

  const fetchQuestionList = useCallback(
    async () => {
      const { survey_questions, total_count } = await getQuestionsList({
        page: currentPage,
        masterSportId: selectedSportValue,
        applicableFor: selectedToggleSwitch,
        triggerEvent: SESSION_FIELD_MAP[selectedUserSession],
      });
      dispatch(
        setFilterKeys({
          ...filterKeys,
          totalPages: Math.ceil(total_count / RECORDS_PER_PAGE),
        })
      );
      setQuestionList(survey_questions);
    },
    // eslint-disable-next-line
    [
      selectedSportValue,
      selectedUserSession,
      selectedToggleSwitch,
      currentPage,
      dispatch,
    ]
  );

  const onAddSurveyQuestion = () => {
    navigate(ROUTE_SURVEY_QUESTION_CREATE);
  };
  const onEditQuestion = (id: number) => {
    navigate(ROUTE_SURVEY_QUESTION_UPDATE(id));
  };
  const onDetailQuestion = (data: QuestionDetailsObjectType) => {
    data.id && navigate(ROUTE_SURVEY_QUESTION_DETAILS(data.id));
  };
  const setCurrentPage = (current: number) => {
    dispatch(setFilterKeys({ ...filterKeys, currentPage: current }));
  };
  const onDeactivateQuestion = async (id: number) => {
      dispatch(
        setConfirmProps({
          ...confirmProps,
          buttonProps: [
            { title: CONFIRM, onClick: () => onConfirmDeactivate(id) },
          ],
          confirmBodyProps: {
            message: [`${deactivateConfirmMessage} ${QUESTION}?`],
          },
          closeButtonClassName: TEXT_END,
          modalType: CONFIRM,
          showConfirm: true,
        })
      );
    },
    onConfirmDeactivate = useCallback(
      async (id: number) => {
        await UpdateQuestion({
          questionId: `${id}`,
          isEditUser: false,
        });
        fetchQuestionList();
      },
      [fetchQuestionList]
    );

  useEffect(() => {
    fetchQuestionList();
  }, [fetchQuestionList]);

  return (
    <div className="ss-page-content d-flex flex-column flex-1 scroll-y bg-white">
      <HeaderComponent
        title={SURVEY_QUESTIONS}
        shouldShowNav={false}
        buttonProps={
          isAllowedTo("SurveyQuestion", "create", permissions)
            ? {
                buttonText: CREATE_NEW_QUESTION,
                onClick: onAddSurveyQuestion,
              }
            : undefined
        }
      />
      <div className="ss-page-body d-flex flex-column flex-1 scroll-y py-4">
        <SurveyQuestionListComponent
          questionsList={questionList}
          showSportsTab={true}
          showAssignButton={false}
          tabs={UserSessionMap[selectedToggleSwitch]}
          onDeactivateQuestion={onDeactivateQuestion}
          onEditQuestion={onEditQuestion}
          onDetailQuestion={onDetailQuestion}
        />
      </div>
      <div className="pagination-wrap d-flex justify-content-end px-4">
        <PaginationControl
          currentPage={currentPage}
          setCurrentPage={(current: number) => setCurrentPage(current)}
          totalPages={totalPages}
        />
      </div>
    </div>
  );
};

export default SurveyQuestions;
