import React from "react";
import { Button } from "Components/Core";
import { ButtonIconProps } from "Interfaces/Components/Shared/ButtonIconInterface";

const ButtonIcon: React.FC<ButtonIconProps> = (props) => {
  const { className, onClick, children, isdisabled } = props;

  return (
    <Button
      variant="link"
      className={`d-flex align-items-center justify-content-center text-decoration-none ${className}`}
      onClick={onClick}
      disabled={isdisabled}
    >
      {children}
    </Button>
  );
};

ButtonIcon.defaultProps = {
  className: "",
};

export default ButtonIcon;
