import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import surveyHistoryReducer from "Components/Shared/SurveyHistory/surveyHistoryReducer";
import loginReducer from "Pages/Login/loginReducer";
import reportsReducer from "Pages/Reports/reportsReducer";
import commonReducer from "App/commonReducer";
import surveyQuestionListComponentReducer from "Components/Shared/SurveyQuestionListComponent/surveyQuestionListComponentReducer";
import academyListComponentReducer from "Components/Shared/Academy/academyListComponentReducer";
import notificationReducer from "Pages/Notifications/notificationReducer";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  loginReducer,
  commonReducer,
  surveyHistoryReducer,
  reportsReducer,
  surveyQuestionListComponentReducer,
  academyListComponentReducer,
  notificationReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
