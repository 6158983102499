import { validatePhoneNumber } from "Utils/utils";
import * as yup from "yup";

const phoneRegExp = /^[\]?[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/;
const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%&])/;

export const addUserNameSchema = yup.object().shape({
  name: yup.string().min(1).max(50).required(),
});

export const addUserContactNumberSchema = yup.object().shape({
  contact_number: yup
    .string()
    .matches(phoneRegExp, "Contact number is not valid")
    .required("Contact number is required"),
});

export const addUserEmailSchema = yup.object().shape({
  email: yup
    .string()
    .email("Email is not valid")
    .max(100)
    .required("Email is required"),
});

export const addUserDetailsSchema = yup.object().shape({
  NameMsg: yup.string().min(1),
  LevelMsg: yup.string().min(1),
  FeeStructureMsg: yup.string().min(1),
  SportMsg: yup.string().min(1),
  StartTimeMsg: yup.string().min(1),
  CoachMsg: yup.string().min(1),
  EndTimeMsg: yup.string().min(1),
});

export const addQuestionSchema = yup.object().shape({
  title: yup
    .string()
    .min(1, "Question must be more than one character")
    .max(150, "Question must be less than 150 character")
    .required("Question is required"),
  applicable_for: yup.string().required("Select Role"),
  trigger_event: yup.string().required("Select Event"),
  master_sport_id: yup.string().when("isGenericQuestion", {
    is: true,
    then: yup.string().required("Select Sport"),
  }),
  survey_question_options: yup.array().of(
    yup.object().shape({
      title: yup.string().max(100).required(),
      preference: yup.string().max(100).required(),
    })
  ),
});

export const optionsSchema = yup.object().shape({
  title: yup
    .string()
    .max(100, "Option must be less than 100 character")
    .required("Option is required"),
  preference: yup.string().max(100).required("Preference is a required"),
});

export const addBatchDetailsSchema = yup.object().shape({
  details: yup.string().max(200),
});

export const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Paswword must be more than 8 characters")
    .max(16, "Paswword must be less than 16 characters")
    .matches(
      passwordRegExp,
      "Password must included one uppercase, one lowercase, one number and one special character"
    ),
  password_confirmation: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password"), null], "Passwords should match"),
  reset_password_token: yup.string().required(),
});

export const userDetailsSchema = yup.object().shape(
  {
    name: yup.string().required("Name is required"),
    country_code: yup.string().nullable(),
    email: yup.string().nullable().email().max(100),
    contact_number: yup
      .string()
      .test(
        "validatePhoneNumber",
        "Contact number is not valid",
        function (contact_number) {
          if (contact_number)
            return validatePhoneNumber(
              this.parent.country_code,
              contact_number
            );
          return true;
        }
      )
      .nullable(),
    player_sports: yup.array().when("coach_sports", {
      is: (coach_sports: Object) => !coach_sports,
      then: yup.array().of(
        yup.object({
          sport_id: yup.string().required(),
          level: yup.string().required(),
        })
      ),
      otherwise: yup.array(),
    }),
    coach_sports: yup.array().when("player_sports", {
      is: (player_sports: Object) => !player_sports,
      then: yup.array().of(
        yup.object({
          sport_id: yup.string().required(),
          level: yup.string().required(),
          rank: yup
            .string()
            .test("validateRank", "Rank is required", function (rank) {
              if (this.parent.sub_rank !== "") {
                if (rank === "") return false;
              }
              return true;
            }),
          sub_rank: yup
            .string()
            .test(
              "validateSubRank",
              "Sub rank is required",
              function (sub_rank) {
                if (this.parent.rank !== "") {
                  if (sub_rank === "") return false;
                }
                return true;
              }
            ),
        })
      ),
      otherwise: yup.array(),
    }),
    aadhar_number: yup
      .string()
      .nullable()
      .test(
        "validateAadharNumber",
        "Enter Valid Aadhar Number",
        function (aadhar_number) {
          if (!aadhar_number) return true;
          if (aadhar_number.length === 12) return true;
          return false;
        }
      ),
  },
  [["player_sports", "coach_sports"]]
);

export const userSportSchema = yup.object().shape({
  sport_id: yup.string().required("Sport is required"),
  level: yup.string().required("Level is required"),
  rank: yup.string().test("validateRank", "Rank is required", function (rank) {
    if (this.parent.sub_rank !== "") {
      if (rank === "") return false;
    }
    return true;
  }),
  sub_rank: yup
    .string()
    .test("validateSubRank", "Sub rank is required", function (sub_rank) {
      if (this.parent.rank !== "") {
        if (sub_rank === "") return false;
      }
      return true;
    }),
});

export const academyDetailsSchema = yup.object().shape(
  {
    name: yup.string().required("Name is required"),
    email: yup.string().email().max(100).required("Email is required"),
    contact_number: yup.string().required("Contact number is required"),
    timezone: yup.string().required("Time zone is required"),
  },
  []
);

export const adminDetailsSchema = yup.object().shape(
  {
    name: yup.string().required("Name is required"),
    email: yup.string().nullable().max(100),
    isEditAdmin: yup.boolean(),
    password: yup.string().when("isEditAdmin", {
      is: (value: boolean) => value === false,
      then: yup.string().email().required("Password is required"),
    }),
    contact_number: yup.string().nullable(),
  },
  []
);
