import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Row, Col, Form } from "Components/Core";
import { HeaderComponent } from "Components/Shared";
import { getQuestionsList } from "Api/Pages/SurveyQuestionsApi";
import {
  QuestionDetailsObjectType,
  SurveyQuestionDetailsComponentPropsType,
} from "Interfaces/Pages/SurveyQuestionsInterface";
import {
  ANSWER,
  ANSWER_OPTION,
  FORM_CONSTANT,
  PREFERENCES,
  QuestionDetails,
  ROLE_TITLE,
  ROUTE_SURVEY_QUESTION_UPDATE,
  SESSION_FIELD_MAP,
  TRIGGER_EVENT_TITLE,
} from "Pages/SurveyQuestions/constants";
import { useAppSelector } from "App/hooks";
import { isAllowedTo } from "Utils/helper";
import { EDIT, QUESTION } from "App/constants";

const SurveyQuestionDetails = ({
  batchQuestionId,
  hideHeader,
}: SurveyQuestionDetailsComponentPropsType) => {
  const [questionsDetails, setQuestionDetails] =
      useState<QuestionDetailsObjectType>(QuestionDetails),
    navigate = useNavigate(),
    {
      userDetails: { permissions },
    } = useAppSelector((state) => state.loginReducer),
    { id } = useParams();

  const questionId = batchQuestionId ? batchQuestionId : id;
  const getQuestionInfo = async (id: string) => {
      const questionsData = await getQuestionsList({ questionId: id });
      setQuestionDetails(questionsData);
    },
    onEditQuestion = () => {
      id && navigate(ROUTE_SURVEY_QUESTION_UPDATE(+id));
    };

  useEffect(() => {
    questionId && getQuestionInfo(questionId);
  }, [id, questionId]);

  return (
    <div className="ss-page-content d-flex flex-column scroll-y flex-1 bg-white">
      {!hideHeader && (
        <>
          <HeaderComponent
            title={FORM_CONSTANT.SurveyQuestionDwtails}
            shouldShowNav={true}
          />
          <hr className="ss-page-seprator mx-4 my-0" />
        </>
      )}
      <div className="ss-page-body d-flex flex-column flex-1 scroll-y">
        <Form className="d-flex flex-column flex-1 scroll-y p-4 gap-4">
          <div className="d-flex align-items-center">
            <p className="fs-sm fw-bold mb-0 ms-1">
              {questionsDetails.master_sport_id
                ? FORM_CONSTANT.specific
                : FORM_CONSTANT.generic}
            </p>
          </div>
          {isAllowedTo(
            FORM_CONSTANT.SurveyQuestion,
            FORM_CONSTANT.Update,
            permissions
          ) && (
            <div className="d-flex align-items-center">
              <p className="fs-sm fw-bold mb-0 ms-1">
                {questionsDetails.is_active
                  ? FORM_CONSTANT.QuestionActive
                  : FORM_CONSTANT.QuestionInActive}
              </p>
            </div>
          )}
          <Row>
            <Col lg={8}>
              <div className="d-flex justify-content-between">
                {questionsDetails?.master_sport?.name && (
                  <div>
                    <label className="fs-xs">Sport</label>
                    <p className="fs-sm fw-bold mb-0">
                      {questionsDetails?.master_sport?.name}
                    </p>
                  </div>
                )}
                <div>
                  <label className="fs-xs">{TRIGGER_EVENT_TITLE}</label>
                  <p className="fs-sm fw-bold mb-0">
                    {SESSION_FIELD_MAP[questionsDetails?.trigger_event]}
                  </p>
                </div>
                <div>
                  <label className="fs-xs">{ROLE_TITLE}</label>
                  <p className="fs-sm fw-bold mb-0">
                    {SESSION_FIELD_MAP[questionsDetails?.applicable_for]}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <div className="border-bottom pb-2 border-2">
            <label className="fs-xs">{QUESTION}</label>
            <p className="fs-sm fw-bold mb-0">{questionsDetails.title}</p>
          </div>

          {questionsDetails.survey_question_options?.map((data, index) => {
            return (
              <Row key={index}>
                <Col lg={5}>
                  <label className="fs-xs">{`${ANSWER_OPTION} ${ANSWER[index]}`}</label>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="fs-sm fw-bold mb-0">{data.title}</p>
                    {isAllowedTo("SurveyQuestion", "update", permissions) && (
                      <label className="fs-sm fw-bold  pill-primary px-2">
                        {PREFERENCES[+data.preference - 1]}
                      </label>
                    )}
                  </div>
                </Col>
              </Row>
            );
          })}
        </Form>
      </div>
      {isAllowedTo(
        FORM_CONSTANT.SurveyQuestion,
        FORM_CONSTANT.Update,
        permissions
      ) && (
        <footer className="ss-page-footer d-flex px-4 py-3">
          <Button
            onClick={onEditQuestion}
            variant="secondary"
            size="sm"
            className="px-4"
          >
            {EDIT}
          </Button>
        </footer>
      )}
    </div>
  );
};

export default SurveyQuestionDetails;
