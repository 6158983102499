import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UsersState } from "Interfaces/Pages/LoginInterface";

const initialState: UsersState = {
  userDetails: { name: "" },
  isLoggedIn: false,
};

export const loginSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    setLoggedInStatus: (state, action: PayloadAction<Boolean>) => {
      state.isLoggedIn = action.payload;
    },
    setUserDetails: (state, action: PayloadAction<any>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes

      state.userDetails = action.payload;
    },
    resetUser: (state) => {
      state.userDetails = {};
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUserDetails, resetUser, setLoggedInStatus } =
  loginSlice.actions;

export default loginSlice.reducer;
