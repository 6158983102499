import { AssetEvaluationCategory } from "Interfaces/AssetTagsInterface";
import {
  AssetSubmissionVideo,
  AssetSubmissionVideoFormData,
} from "Interfaces/Components/Shared/AssetSubmissionVideos";
import { assetTagsReducer } from "Components/Shared/AssetTags/utils";

export const submissionVideoDataToFormData = (
  data: AssetSubmissionVideo
): AssetSubmissionVideoFormData => {
  return {
    id: data.id,
    title: data.title,
    description: data.description,
    master_sport_id: data.master_sport_id,
    graph_attribute_id: data.graph_attribute_id,
    asset_evaluation_category_id: data.asset_evaluation_category_id,
    asset_evaluation_category_title: null,
    is_category_other: false,
    asset_evaluation_sub_category_id: data.asset_evaluation_sub_category_id,
    asset_evaluation_sub_category_title: null,
    is_sub_category_other: false,
    asset_evaluation_tags: data.asset_evaluation_tags.map((tag) => ({
      id: tag.id,
      title: tag.title,
      is_other: false,
    })),
    asset_evaluation_categories: [],
    asset_evaluation_sub_categories: [],
  };
};

export const videoDetailFormReducer = (
  state: AssetSubmissionVideoFormData,
  action: { type: string; payload: any }
): AssetSubmissionVideoFormData => {
  if (action.type === "title" || action.type === "description") {
    return { ...state, [action.type]: action.payload };
  }

  return assetTagsReducer(state, action) as AssetSubmissionVideoFormData;
};

export const getAssetEvaluationTagsFromData = (
  data: AssetEvaluationCategory[],
  categoryId: number | null,
  subCategoryId: number | null
) => {
  if (!categoryId) return [];

  const category = data.find((cat) => categoryId === cat.id);
  if (!category) return [];

  if (!subCategoryId) return category.asset_evaluation_tags;

  const subCategory = category.asset_evaluation_sub_categories.find(
    (subCat) => subCategoryId === subCat.id
  );
  if (!subCategory) return [];

  return subCategory.asset_evaluation_tags;
};
