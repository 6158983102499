import { AssetTagsData } from "Interfaces/AssetTagsInterface";

export const defaultAssetTagsData = (): AssetTagsData => ({
  id: null,
  master_sport_id: null,
  graph_attribute_id: null,
  asset_evaluation_category_id: null,
  asset_evaluation_category_title: null,
  is_category_other: false,
  asset_evaluation_sub_category_id: null,
  asset_evaluation_sub_category_title: null,
  is_sub_category_other: false,
  asset_evaluation_tags: [],
  asset_evaluation_categories: [],
  asset_evaluation_sub_categories: [],
});

export const assetTagsReducer = (
  state: AssetTagsData,
  action: { type: string; payload: any }
): AssetTagsData => {
  if (action.type === "master_sport_id") {
    return {
      ...state,
      master_sport_id: action.payload,

      graph_attribute_id: null,
      asset_evaluation_category_id: null,
      asset_evaluation_category_title: null,
      is_category_other: false,
      asset_evaluation_sub_category_id: null,
      asset_evaluation_sub_category_title: null,
      is_sub_category_other: false,
      asset_evaluation_tags: [],
    };
  }

  if (action.type === "graph_attribute_id") {
    return {
      ...state,
      graph_attribute_id: action.payload,

      asset_evaluation_category_id: null,
      asset_evaluation_category_title: null,
      is_category_other: false,
      asset_evaluation_sub_category_id: null,
      asset_evaluation_sub_category_title: null,
      is_sub_category_other: false,
      asset_evaluation_tags: [],
    };
  }

  if (action.type === "asset_evaluation_category") {
    return {
      ...state,
      ...action.payload,
      asset_evaluation_sub_category_id: null,
      asset_evaluation_sub_category_title: null,
      is_sub_category_other: false,
    };
  }

  if (action.type === "asset_evaluation_sub_category") {
    return {
      ...state,
      ...action.payload,
    };
  }

  if (action.type === "asset_evaluation_category_title") {
    if (action.payload === "") {
      return {
        ...state,
        asset_evaluation_category_title: action.payload,

        asset_evaluation_sub_category_id: null,
        asset_evaluation_sub_category_title: null,
        is_sub_category_other: false,
        asset_evaluation_tags: [],
        asset_evaluation_categories: [],
        asset_evaluation_sub_categories: [],
      };
    }
    return { ...state, asset_evaluation_category_title: action.payload };
  }

  if (action.type === "asset_evaluation_sub_category_title") {
    return { ...state, asset_evaluation_sub_category_title: action.payload };
  }

  if (action.type === "create_asset_evaluation_tag") {
    let maxId = 0;

    for (const tag of state.asset_evaluation_tags) {
      maxId = Math.max(maxId, tag.id);
    }

    return {
      ...state,
      asset_evaluation_tags: [
        ...state.asset_evaluation_tags,
        { id: maxId + 1, title: action.payload, is_other: true },
      ],
    };
  }

  if (action.type === "remove_asset_evaluation_tag") {
    const tags = [...state.asset_evaluation_tags];
    tags.splice(action.payload, 1);

    return {
      ...state,
      asset_evaluation_tags: tags,
    };
  }

  if (action.type === "asset_evaluation_categories") {
    return { ...state, asset_evaluation_categories: action.payload };
  }

  if (action.type === "asset_evaluation_sub_categories") {
    return { ...state, asset_evaluation_sub_categories: action.payload };
  }

  return state;
};
