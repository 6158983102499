export type PromotionalVideoDataType = {
  id: number;
  title: string;
  url: string;
  description: string;
  type: string;
};

export interface IAssetDetails {
  description: string;
  title: string;
  asset: Array<File> | null;
  sport?: string;
  of_type?: ASSET_TYPE_ENUM;
  purpose?: UPLOAD_PURPOSE_ENUM;
}

export enum ASSET_TYPE_ENUM {
  "image" = "image",
  "video" = "video",
}
export enum UPLOAD_PURPOSE_ENUM {
  training = "training",
  featured = "featured",
  drill = "drill",
  public = "public_use",
}

export interface VideoConfigInterface {
  cloudinary: {
    api_key: string;
    cloud_name: string;
  };
  image: {
    allowed_file_formats: Array<string>;
    max_size_bytes: number;
    min_size_bytes: number;
    resolution: {
      height_pixels: number;
      width_pixels: number;
    };
  };

  video: {
    id: number;
    max_duration_seconds: number;
    min_duration_seconds: number;
    max_size_bytes: number;
    min_size_bytes: number;
    codec: string;
    allowed_file_formats: Array<string>;
    resolution: {
      width_pixels: number;
      height_pixels: number;
    };
    aspect_ratio: {
      width: number;
      height: number;
    };
  };
}

export type SizeConfigType = {
  imageMaxSize: number;
  imageMinSize: number;
  videoMaxSize: number;
  videoMinSize: number;
};

export type ResolutionConfigType = {
  imageWidth: number;
  imageHeight: number;
  videoWidth: number;
  videoHeight: number;
};
