import { Form } from "Components/Core";

export const DisplayErrorMessage = (props: any) => {
  const { errorField } = props;
  return (
    <>
      {errorField && (
        <Form.Control.Feedback type="invalid" className="ms-2 d-block">
          {errorField}
        </Form.Control.Feedback>
      )}
    </>
  );
};
