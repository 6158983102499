import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FormControl } from "Components/Core";
import { ButtonIcon } from "Components/Shared";
import { SearchControlProps } from "Interfaces/Components/Shared/SearchControlInterafce";
import { debounce } from "Utils/utils";
import "./searchControl.scss";

const SearchControl: React.FC<SearchControlProps> = ({
  placeholder,
  label,
  onChange,
  className,
}) => {
  return (
    <div
      className={`ss-search-control-wrap d-flex align-items-center border ${className}`}
    >
      <FormControl
        className="ss-search-control border-0 px-3 py-2"
        placeholder={placeholder}
        aria-label={label}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          if (onChange) {
            debounce(() => onChange(event));
          }
        }}
      />
      <ButtonIcon className="fs-sm fs-5 px-3 py-2">
        <FontAwesomeIcon icon={faMagnifyingGlass} />
      </ButtonIcon>
    </div>
  );
};

SearchControl.defaultProps = {
  className: "",
};

export default SearchControl;
