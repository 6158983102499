import axios from "axios";
import {
  AddBatchParam,
  GetMasterParam,
  GetBatchUserParam,
  AssignBatchUserParam,
  getBatchDataParam,
  GetUserDataParam,
} from "Interfaces/AppInterface";
import {
  ACADEMY_BASE_URL,
  SURVEY_QUESTION,
  BASE_URL,
  BATCH_USER,
  DRILLS,
  BatchItemMap,
} from "App/constants";
import { convertDateInto_Y_M_D_format, getAcademyId } from "Utils/utils";

export const addBatch = async ({ param, isEditBatch }: AddBatchParam) => {
  if (isEditBatch) {
    await axios.put(
      `${ACADEMY_BASE_URL}${getAcademyId()}/batches/${param.id}`,
      {
        batch: {
          ...param,
        },
      }
    );
    return [];
  }

  param.start_date = convertDateInto_Y_M_D_format(param.start_date);
  param.end_date = convertDateInto_Y_M_D_format(param.end_date);

  await axios.post(`${ACADEMY_BASE_URL}${getAcademyId()}/batches`, {
    batch: {
      ...param,
    },
  });

  return [];
};

export const getMasterList = async ({
  academyId,
  user,
  batchId,
  searchInput,
  applicable_for,
  sportId,
  page,
  trigger_event,
}: GetMasterParam) => {
  const param: string = `page=${page}&reject_batch_id=${batchId}&order_by=name&order_type=asc&search=${searchInput}`;
  if (user === SURVEY_QUESTION) {
    const { data } = await axios.get(
      `${BASE_URL}survey_questions?&master_sport_id=${sportId}&applicable_for=${applicable_for}&trigger_event=${trigger_event}&${param}`
    );
    return data;
  } else if (user === DRILLS) {
    const { data } = await axios.get(
      `${BASE_URL}drills?master_sport_id=${sportId}&${param}`
    );
    return data;
  } else {
    const { data } = await axios.get(
      `${ACADEMY_BASE_URL}${getAcademyId(academyId)}/${[
        BATCH_USER[user],
      ]}?sport_id=${sportId}&${param}`
    );
    return data;
  }
};

export const getBatchInfo = async (academyId: number, id: number) => {
  const { data } = await axios.get(
    `${ACADEMY_BASE_URL}${getAcademyId(academyId)}/batches/${id}`
  );
  if (data) {
    return data;
  }
  return [];
};

export const getBatchItemList = async ({
  academyId,
  batchId,
  userType,
  page,
  applicable_for,
  trigger_event,
}: GetBatchUserParam) => {
  let response = null;
  if (userType === SURVEY_QUESTION) {
    const queryParams: string = `?page=${page}&applicable_for=${applicable_for}&trigger_event=${trigger_event}`;
    response = await axios.get(
      `${ACADEMY_BASE_URL}${getAcademyId(
        academyId
      )}/batches/${batchId}/survey_questions${queryParams}`
    );
  } else if (userType === DRILLS) {
    response = await axios.get(
      `${ACADEMY_BASE_URL}${getAcademyId(
        academyId
      )}/batches/${batchId}/drills?page=${page}`
    );
  } else {
    response = await axios.get(
      `${ACADEMY_BASE_URL}${getAcademyId(academyId)}/batches/${batchId}/${[
        BATCH_USER[userType],
      ]}?page=${page}`
    );
  }
  const { data } = response;
  return data;
};

export const assignBatchUser = async ({
  user,
  academyId,
  id,
  batchItemSelectedIds,
}: AssignBatchUserParam) => {
  const { data } = await axios.put(
    `${ACADEMY_BASE_URL}${getAcademyId(academyId)}/batches/${id}`,
    {
      batch: {
        [BatchItemMap[user]]: { ...batchItemSelectedIds },
      },
    }
  );
  if (data) {
    return data;
  }
};

export const getBatchData = async ({ id }: getBatchDataParam) => {
  const data: any = await axios.get(
    `${ACADEMY_BASE_URL}${getAcademyId()}/batches/${id}`
  );
  if (data) {
    data.data.batch_coaches = data.data.batch_coaches.map(
      ({ id, coach_id, coach: { name } }: any) => ({
        id,
        coach_id,
        _destroy: false,
        name,
      })
    );

    return data.data;
  }
  return [];
};

export const cancelBatch = async (batchId: number) => {
  await axios.put(
    `${ACADEMY_BASE_URL}${getAcademyId()}/batches/${batchId}/cancel_batch`
  );
};

export const getUserData = async ({
  academyId,
  id,
  userType,
}: GetUserDataParam) => {
  const data: any = await axios.get(
    `${ACADEMY_BASE_URL}${getAcademyId(academyId)}/${[
      BATCH_USER[userType],
    ]}/${id}`
  );
  if (data) {
    return data.data;
  }
  return [];
};
