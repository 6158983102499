import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RatingProps } from "Interfaces/Components/Shared/RatingInterface";

const Rating = (props: RatingProps) => {
  const { className, name } = props;

  return <FontAwesomeIcon icon={name} className={`${className}`} />;
};

Rating.defaultProps = {
  className: "",
};

export default Rating;
