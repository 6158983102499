import axios from "axios";
import { GetSurveyHistoryParam } from "Interfaces/AppInterface";
import {
  ACADEMY_BASE_URL,
  SURVEY_HISTORY_USER,
  TRIGGER_EVENT_MORNING,
} from "App/constants";
import { getAcademyId } from "Utils/utils";

export const getSurveyHistory = async ({
  title,
  academyId,
  id,
  currentPage,
  batchId,
  triggerEvent,
}: GetSurveyHistoryParam) => {
  const params =
    triggerEvent === TRIGGER_EVENT_MORNING ? "" : `&batch_id=${batchId}`;

  const { data } = await axios.get(
    `${ACADEMY_BASE_URL}${getAcademyId(academyId)}/${[
      SURVEY_HISTORY_USER[title],
    ]}/${id}/survey_history?trigger_event=${triggerEvent}&page=${currentPage}${params}`
  );
  if (data) {
    return data;
  }
  return [];
};

export const getSurveyHistoryDetails = async (
  id: number,
  academyId: number
) => {
  const { data } = await axios.get(
    `${ACADEMY_BASE_URL}${getAcademyId(academyId)}/surveys/${id}`
  );
  if (data) {
    return data;
  }
  return [];
};
