import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faCheck,
  faMinus,
  faTimes,
  faAngleLeft,
} from "@fortawesome/free-solid-svg-icons";
import {
  ButtonGroup,
  ToggleButton,
  Form,
  Tabs,
  Tab,
  ListGroup,
  Modal,
  Row,
  Col,
} from "Components/Core";
import {
  ButtonIcon,
  TableComponent,
  PaginationControl,
} from "Components/Shared";
import {
  getSurveyHistory,
  getSurveyHistoryDetails,
} from "Api/Components/SurveyHistoryApi";
import {
  SurveyBatchProps,
  SurveyHistoryObjectInterface,
  SurveyHistoryProps,
} from "Interfaces/Components/Shared/SurveyHistoryInterface";
import {
  COLUMNS,
  COACH_SURVEY,
  BATCH_NAME,
  TRIGGER_EVENT,
  DATE,
  PLAYER_SURVEY,
  Coach,
  NO_DATA_FOUND,
} from "Components/Shared/SurveyHistory/constants";
import { MORNING } from "Components/Shared/UserDetailsComponent/constants";
import { SESSION_FIELD_MAP } from "Pages/SurveyQuestions/constants";
import { COACH, INITIAL_PAGE, PLAYER, RECORDS_PER_PAGE } from "App/constants";
import { useAppDispatch, useAppSelector } from "App/hooks";
import {
  setFilterKeys,
  setAssignedBatch,
  setSurveyHistoryList,
  setSurveyHistoryDetails,
} from "./surveyHistoryReducer";

const SurveyHistory: React.FC<SurveyHistoryProps> = ({
  title,
  TABS,
  batches,
  userId,
}) => {
  const [isInitiallyCalling, setIsInitiallyCalling] = useState(false);
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const {
    userDetails: { academy_id },
  } = useAppSelector((state) => state.loginReducer);
  const { filterList, filterKeys } = useAppSelector(
    (state) => state.surveyHistoryReducer
  );
  const { assignedBatches, surveyHistoryList, surveyHistoryDetails } =
    filterList;
  const { showModal, currentPage, totalPages, selectedBatchId, triggerEvent } =
    filterKeys;

  const onTabSelect = useCallback(
    (tabName: string | null) => {
      tabName &&
        dispatch(
          setFilterKeys({
            ...filterKeys,
            triggerEvent: tabName,
            currentPage: INITIAL_PAGE,
          })
        );
    },
    [dispatch, filterKeys]
  );

  const fetchSurveyHistory = useCallback(async () => {
    const batchId = userId ? id : selectedBatchId;
    const selectedUserId = userId ? userId : id;
    if (selectedUserId && triggerEvent !== "") {
      const data = await getSurveyHistory({
        title: title,
        academyId: academy_id,
        id: selectedUserId,
        currentPage: currentPage,
        batchId,
        triggerEvent: SESSION_FIELD_MAP[triggerEvent],
      });
      const { total_count, surveys } = data;
      dispatch(
        setFilterKeys({
          ...filterKeys,
          totalPages: Math.ceil(total_count / RECORDS_PER_PAGE),
        })
      );
      dispatch(setSurveyHistoryList({ surveyHistoryList: surveys }));
    }
  }, [id, title, academy_id, currentPage, triggerEvent, selectedBatchId]); // eslint-disable-line

  const fetchSurveyHistoryDetails = useCallback(
    async (id) => {
      if (id) {
        const data = await getSurveyHistoryDetails(id, academy_id);
        dispatch(
          setSurveyHistoryDetails({ surveyHistoryDetails: data.survey })
        );
      }
    },
    [academy_id, dispatch]
  );

  const showSurveyHistoryDetails = useCallback(
    (data: SurveyHistoryObjectInterface) => {
      const { id } = data;
      fetchSurveyHistoryDetails(id);
      dispatch(setFilterKeys({ ...filterKeys, showModal: true }));
    },
    [fetchSurveyHistoryDetails, dispatch, filterKeys]
  );
  const hideSurveyHistoryDetails = useCallback(() => {
    dispatch(setFilterKeys({ ...filterKeys, showModal: false }));
  }, [dispatch, filterKeys]);

  const setCurrentPage = (current: number) => {
    dispatch(setFilterKeys({ ...filterKeys, currentPage: current }));
  };

  const findNumOfBatches = useCallback(() => {
    if (batches?.length) {
      const batchCount = batches?.map((batch) => ({
        name: batch.name,
        value: batch.id?.toString(),
        status: batch.status,
      }));
      dispatch(setAssignedBatch({ assignedBatches: batchCount }));
    }
  }, [batches, dispatch]);

  if (!isInitiallyCalling && userId) {
    id &&
      dispatch(
        setFilterKeys({
          ...filterKeys,
          selectedBatchId: id,
          triggerEvent: TABS[0],
        })
      );
    setIsInitiallyCalling(true);
  }
  useEffect(() => {
    if (
      !isInitiallyCalling &&
      triggerEvent === "" &&
      title === PLAYER &&
      [userId, id].includes(undefined) &&
      batches?.length === 0
    ) {
      dispatch(
        setFilterKeys({
          ...filterKeys,
          triggerEvent: TABS[0],
        })
      );
    }
  }, [
    batches,
    id,
    title,
    triggerEvent,
    userId,
    isInitiallyCalling,
    TABS,
    dispatch,
    filterKeys,
  ]);

  useEffect(() => {
    if (!userId) findNumOfBatches();
  }, [userId, findNumOfBatches]);

  useEffect(() => {
    if (
      [selectedBatchId, triggerEvent].includes("") &&
      assignedBatches?.length &&
      !userId
    ) {
      dispatch(
        setFilterKeys({
          ...filterKeys,
          selectedBatchId: assignedBatches[0]?.value,
          triggerEvent: TABS[0],
        })
      );
    }
  }, [
    selectedBatchId,
    triggerEvent,
    assignedBatches,
    dispatch,
    userId,
    TABS,
    filterKeys,
  ]);

  useEffect(() => {
    if (!(!userId && title === COACH && !assignedBatches?.length))
      fetchSurveyHistory();
  }, [userId, assignedBatches.length, title, fetchSurveyHistory]);

  return (
    <>
      <div className="d-flex flex-column h-100 gap-4">
        <Form className="d-flex align-items-center justify-content-between px-4">
          <ButtonGroup className="ss-three-state-toggle scroll-x pb-3 px-1">
            {!userId &&
              assignedBatches &&
              assignedBatches.map((batch: SurveyBatchProps) => (
                <ToggleButton
                  key={batch.value}
                  id={`batch-${batch.value}`}
                  type="radio"
                  variant="outline-secondary"
                  name="batch"
                  className={`px-3 py-1 ${
                    triggerEvent === MORNING ? "opacity-25" : ""
                  }`}
                  value={batch.value}
                  checked={
                    triggerEvent !== MORNING && selectedBatchId === batch.value
                  }
                  disabled={triggerEvent === MORNING}
                  onChange={(event) => {
                    dispatch(
                      setFilterKeys({
                        ...filterKeys,
                        selectedBatchId: event.currentTarget.value,
                        currentPage: INITIAL_PAGE,
                      })
                    );
                  }}
                >
                  <span
                    className={`text-nowrap d-block${
                      triggerEvent === MORNING ? "opacity-25" : ""
                    }`}
                  >
                    {batch.name}
                  </span>
                  {/* <span>{`(${titleCaseConversion(batch.status)})`}</span> */}
                </ToggleButton>
              ))}
          </ButtonGroup>
        </Form>
        <div className="flex-1 scroll-y">
          <Tabs
            className="ss-tabs"
            onSelect={onTabSelect}
            activeKey={triggerEvent === "" ? TABS[0] : triggerEvent}
            defaultValue={triggerEvent}
          >
            {TABS &&
              TABS.map((tabName: string) => {
                return (
                  <Tab
                    eventKey={tabName}
                    title={tabName}
                    className="ss-tab-content p-4"
                    key={tabName}
                  >
                    <ListGroup className="ss-list-group gap-2">
                      {surveyHistoryList.length ? (
                        surveyHistoryList.map(
                          (
                            survey: SurveyHistoryObjectInterface,
                            idx: number
                          ) => (
                            <ListGroup.Item
                              className="bg-dark opaque border-0 px-3 py-2 fs-sm d-flex align-items-center gap-4"
                              onClick={() =>
                                survey.is_attended &&
                                showSurveyHistoryDetails(survey)
                              }
                              key={`${survey.id}_${idx}`}
                            >
                              <span>{survey.date}</span>
                              <div className="flex-1">
                                <FontAwesomeIcon
                                  icon={survey.is_attended ? faCheck : faMinus}
                                  className={
                                    survey.is_attended
                                      ? "text-success fs-5"
                                      : "text-danger fs-5"
                                  }
                                />
                              </div>
                              {survey.is_attended && (
                                <FontAwesomeIcon
                                  icon={faAngleRight}
                                  className="fs-5"
                                />
                              )}
                            </ListGroup.Item>
                          )
                        )
                      ) : (
                        <p className="mb-0 fw-bold text-center">
                          {NO_DATA_FOUND}
                        </p>
                      )}
                    </ListGroup>
                  </Tab>
                );
              })}
          </Tabs>
        </div>
        {totalPages > INITIAL_PAGE && (
          <div className="pagination-wrap d-flex justify-content-end px-4">
            <PaginationControl
              currentPage={currentPage}
              setCurrentPage={(current: number) => setCurrentPage(current)}
              totalPages={totalPages}
            />
          </div>
        )}
      </div>
      <Modal
        className="ss-modal right_modal ss-large-modal"
        show={showModal}
        onHide={hideSurveyHistoryDetails}
      >
        <Modal.Header className="fw-bold fs-sm">
          <div className="d-flex align-items-center gap-2">
            <FontAwesomeIcon
              icon={faAngleLeft}
              className="fs-6"
              role="button"
              onClick={hideSurveyHistoryDetails}
            />
            <span className="fs-5">
              {title === Coach ? COACH_SURVEY : PLAYER_SURVEY}
            </span>
          </div>
          <ButtonIcon className="fs-5" onClick={hideSurveyHistoryDetails}>
            <FontAwesomeIcon icon={faTimes} />
          </ButtonIcon>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column scroll-y gap-4 p-0">
          <div className="d-flex flex-column gap-3 p-4">
            <Row>
              {surveyHistoryDetails?.batch?.name && (
                <Col lg={6}>
                  <div className="d-flex align-items-center gap-3">
                    <label className="fs-sm">{BATCH_NAME}:</label>
                    <p className="fs-6 fw-bold mb-0">
                      {surveyHistoryDetails?.batch?.name}
                    </p>
                  </div>
                </Col>
              )}
              <Col lg={6}>
                <div className="d-flex align-items-center gap-3">
                  <label className="fs-sm">{TRIGGER_EVENT}</label>
                  <p className="fs-6 fw-bold mb-0">
                    {SESSION_FIELD_MAP[surveyHistoryDetails?.trigger_event]}
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className="d-flex align-items-center gap-3">
                  <label className="fs-sm">{DATE}</label>
                  <p className="fs-6 fw-bold mb-0">
                    {surveyHistoryDetails?.date}
                  </p>
                </div>
              </Col>
            </Row>
          </div>
          <div className="d-flex flex-column scroll-y flex-1">
            <div className="table-responsive px-4">
              {surveyHistoryDetails &&
                surveyHistoryDetails?.survey_answers?.length && (
                  <TableComponent
                    columns={COLUMNS}
                    tableData={surveyHistoryDetails?.survey_answers}
                    isClickAble={false}
                  />
                )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SurveyHistory;
