import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DEFAULT_FILTER_KEY } from "./constants";

import {
  AcademyFilterKeysType,
  AcademyListState,
} from "Interfaces/Pages/AcademyInterface";
const initialState: AcademyListState = {
  filterKeys: DEFAULT_FILTER_KEY,
};

export const academyListSlice = createSlice({
  name: "academyList",
  initialState,
  reducers: {
    setFilterKeys: (state, action: PayloadAction<AcademyFilterKeysType>) => {
      state.filterKeys = action.payload;
    },
    resetState: () => initialState,
  },
});

export const { setFilterKeys, resetState } = academyListSlice.actions;
export default academyListSlice.reducer;
