import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UsersState } from "Interfaces/CommonReducerInterface";
import { SelectedTabProps } from "Interfaces/AppInterface";
import { DEFAULT_CONFIRM_PROPS, SELECTED_TAB_OBJECT } from "App/constants";
import { ConfirmBoxComponentPropsType } from "Interfaces/Components/Shared/ConfirmBoxComponentInterface";

export const initialState: UsersState = {
  toastMessage: [],
  showLoader: false,
  errorCode: "",
  confirmProps: DEFAULT_CONFIRM_PROPS,
  selectedTab: SELECTED_TAB_OBJECT,
};
export const commonSlice = createSlice({
  name: "commonSlice",
  initialState,
  reducers: {
    setToastMessage: (state, action: PayloadAction<string[] | any>) => {
      state.toastMessage = action.payload;
    },
    setShowLoader: (state, action: PayloadAction<boolean>) => {
      state.showLoader = action.payload;
    },
    setErrorCode: (state, action: PayloadAction<string>) => {
      state.errorCode = action.payload;
    },
    setConfirmProps: (
      state,
      action: PayloadAction<ConfirmBoxComponentPropsType>
    ) => {
      state.confirmProps = action.payload;
    },
    setSelectedTab: (state, action: PayloadAction<SelectedTabProps>) => {
      state.selectedTab = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setToastMessage,
  setShowLoader,
  setErrorCode,
  setConfirmProps,
  setSelectedTab,
} = commonSlice.actions;

export default commonSlice.reducer;
