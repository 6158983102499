import { useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { Aside } from "Components/Shared";
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from "App/routes";
import { useAppSelector } from "App/hooks";
import { isUserLoggedIn } from "Utils/utils";
import { isAllowedTo } from "Utils/helper";
import { PAGE_NOT_FOUND_ERROR } from "./constants";
import PageNotFound from "Components/Shared/PageNotFound";

export const AppLayout = () => {
  const { errorCode } = useAppSelector((state) => state.commonReducer);
  const navigate = useNavigate();
  useEffect(() => {
    if (+errorCode === PAGE_NOT_FOUND_ERROR) {
      navigate("/page-not-found");
    }
  }, [navigate, errorCode]);

  return (
    <>
      <Routes>
        {PUBLIC_ROUTES.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}

        {PRIVATE_ROUTES.map(({ path, Component, resourceType, action }) => {
          return (
            <Route
              key={path}
              path={path}
              element={ComponentWithSideNav(
                Component,
                path,
                resourceType,
                action
              )}
            />
          );
        })}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
};

export const ComponentWithSideNav = (
  Component: any,
  path: String,
  resourceType: string,
  action: string
) => {
  const {
    userDetails: { permissions },
  } = useAppSelector((state) => state.loginReducer);

  if (isUserLoggedIn()) {
    return (
      <div className="ss-page d-flex flex-1 scroll-y">
        <Aside />
        {isAllowedTo(resourceType, action, permissions) && <Component />}
      </div>
    );
  } else {
    return <Navigate to="/" />;
  }
};
