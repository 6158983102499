import { BatchFormComponent } from "Components/Shared";
import { BatchDetailsDefaultValue } from "Pages/Batches/constants";
import { ADD_BATCH } from "App/constants";

const AddBatch = () => {
  return (
    <BatchFormComponent
      title={ADD_BATCH}
      isEditBatch={false}
      batchData={{ ...BatchDetailsDefaultValue }}
    />
  );
};

export default AddBatch;
