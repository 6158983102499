export const columns = [
  {
    title: "Type",
    dataField: "of_type",
    colWidth: "15%",
  },

  { title: "Sport", dataField: "sport_name", colWidth: "15%" },
  {
    title: "Description",
    dataField: "description",
    colWidth: "25%",
    formatter: (cell: { description?: string }) => {
      return <div className="text-wrap text-break">{cell?.description}</div>;
    },
  },
  {
    title: "Intensity",
    dataField: "rating_points",
    colwidth: "20%",
    formatter: (cell: { rating_points?: number }) =>
      cell.rating_points ? `${cell.rating_points}/10` : "---",
  },
  {
    title: "Comments",
    dataField: "last_comment_text",

    colwidth: "25%",
    formatter: (cell: { last_comment_text?: string }) =>
      cell.last_comment_text ? `${cell.last_comment_text}` : "---",
  },
];
