import { PieChart, Pie, Cell, Legend } from "recharts";
import TextWrapper from "Components/Shared/TextWrapper";
import { EMPTY_LABEL } from "App/constants";

type ChartDataType = Array<{
  color: string;
  name: string;
  value: number;
}>;

interface IProps {
  data: ChartDataType;
  value: number;
}

const PieChartWithNeedle = (props: IProps) => {
  const { data, value } = props;

  const RADIAN = Math.PI / 180;

  const cx = 140;
  const cy = 100;
  const iR = 80;
  const oR = 100;

  const legendFormatter = (value: string) => {
    return (
      <TextWrapper
        tooltipText={value}
        width={120}
        styles={{ marginBottom: "-5px" }}
      >
        {value || EMPTY_LABEL}
      </TextWrapper>
    );
  };

  const needle = (
    value: number,
    data: ChartDataType,
    cx: number,
    cy: number,
    iR: number,
    oR: number,
    color: string
  ) => {
    let total = 0;
    data.forEach((v) => {
      total += v.value;
    });
    const ang = 180.0 * (1 - value / total);
    const length = (iR + 2 * oR) / 3;
    const sin = Math.sin(-RADIAN * ang);
    const cos = Math.cos(-RADIAN * ang);
    const r = 5;
    const x0 = cx + 5;
    const y0 = cy + 5;
    const xba = x0 + r * sin;
    const yba = y0 - r * cos;
    const xbb = x0 - r * sin;
    const ybb = y0 + r * cos;
    const xp = x0 + length * cos;
    const yp = y0 + length * sin;

    return [
      <circle cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
      <path
        d={`M${xba},${yba}L${xbb},${ybb},L${xp},${yp},L${xba},${yba}`}
        stroke="#none"
        fill={color}
      />,
    ];
  };

  return (
    <PieChart
      width={300}
      height={170}
      margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
    >
      <Pie
        isAnimationActive={false}
        dataKey="value"
        startAngle={180}
        endAngle={0}
        data={data}
        cx={cx}
        cy={cy}
        innerRadius={iR}
        outerRadius={oR}
        fill="#8884d8"
        stroke="#fff"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color} />
        ))}
      </Pie>
      {data.length !== 1 && needle(value, data, cx, cy, iR, oR, "#d0d000")}
      <Legend
        layout="horizontal"
        verticalAlign="bottom"
        align="left"
        formatter={legendFormatter}
      />
    </PieChart>
  );
};

export default PieChartWithNeedle;
