import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Button } from "Components/Core";
import { Auth, DisplayErrorMessage } from "Components/Shared";
import { login } from "Api/Pages/LoginApi";
import {
  LoginDetails,
  LoginErrorDetails,
} from "Interfaces/Pages/LoginInterface";
import {
  FORGOT_PASSWORD,
  LOGIN,
  loginErrorValues,
  loginFormValues,
  PASSWORD,
} from "./constants";
import { useAppDispatch } from "App/hooks";
import { loginValidation } from "App/Validations/utils";
import { setLoggedInStatus, setUserDetails } from "./loginReducer";
import { resetState } from "Components/Shared/SurveyQuestionListComponent/surveyQuestionListComponentReducer";
import { resetReportsState } from "Pages/Reports/reportsReducer";
import { EMAIL } from "Pages/ForgotPassword/constant";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [formValues, setFormValues] = useState<LoginDetails>({
    ...loginFormValues,
  });
  const { email, password } = formValues;
  const [formError, setFormError] = useState<LoginErrorDetails>({
    ...loginErrorValues,
  });
  const { emailError, passwordError } = formError;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { id, value },
    } = event;
    setFormValues({ ...formValues, [id]: value });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const isOk = validate();
    if (isOk) {
      const data = await login(formValues);
      if (data) {
        dispatch(setLoggedInStatus(true));
        dispatch(setUserDetails(data.data));
        navigate("/sports");
      }
    }
  };
  const validate = () => {
    const errors = loginValidation(formValues);
    const { emailError, passwordError } = errors;
    setFormError(errors);
    if (emailError !== "" || passwordError !== "") return false;
    return true;
  };
  useEffect(() => {
    const isLoggedIn = localStorage.getItem("access-token");
    isLoggedIn && navigate("/sports");
  }, [navigate]);

  useEffect(() => {
    dispatch(resetState());
  }, []); // eslint-disable-line

  useEffect(() => {
    dispatch(resetReportsState());
  }, []); // eslint-disable-line

  return (
    <>
      <Auth heading="Login">
        <Form onSubmit={handleSubmit} className="login-form" autoComplete="off">
          <Form.Group className="mb-3" controlId="email">
            <Form.Label className="text-white mb-1 fs-sm">{EMAIL}</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Email"
              value={email}
              onChange={handleChange}
              isInvalid={emailError !== ""}
              className="border-primary py-2"
            />
            <DisplayErrorMessage errorField={emailError} />
          </Form.Group>
          <Form.Group className="mb-2" controlId="password">
            <Form.Label className="text-white mb-1 fs-sm">
              {PASSWORD}
            </Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter Password"
              value={password}
              onChange={handleChange}
              isInvalid={passwordError !== ""}
              className="border-primary py-2"
            />
            <DisplayErrorMessage errorField={passwordError} />
          </Form.Group>
          <Form.Group className="text-end mb-4">
            <Link to="forgot-password" className="text-white fs-sm">
              {FORGOT_PASSWORD}
            </Link>
          </Form.Group>
          <Form.Group className="d-grid pt-3">
            <Button
              variant="secondary"
              type="submit"
              className="text-uppercase py-3 fw-bold"
            >
              {LOGIN}
            </Button>
          </Form.Group>
        </Form>
      </Auth>
    </>
  );
};

export default Login;
