import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SurveyQuestionFormComponent } from "Components/Shared";
import { getQuestionsList, UpdateQuestion } from "Api/Pages/SurveyQuestionsApi";
import { QuestionDetailsObjectType } from "Interfaces/Pages/SurveyQuestionsInterface";
import {
  EDIT_SURVEY_QUESTION_FORM_FIELD,
  QuestionDetails,
  ROUTE_SURVEY_QUESTION,
} from "Pages/SurveyQuestions/constants";
import { setToastMessage } from "App/commonReducer";
import { history } from "App";

const EditSurveyQuestion = () => {
  const [questionDetails, setQuestionDetails] =
    useState<QuestionDetailsObjectType>({
      ...QuestionDetails,
    });

  const { id } = useParams(),
    navigate = useNavigate(),
    dispatch = useDispatch();

  const OnAddButtonClick = async () => {
      const { data, status } = await UpdateQuestion({
        isEditUser: true,
        questionId: id,
        param: questionDetails,
      });
      if (status === 422) {
        dispatch(setToastMessage(data));
      } else {
        navigate(ROUTE_SURVEY_QUESTION);
      }
    },
    onCancelButtonClick = () => {
      history.back();
    },
    getQuestionInfo = async (questionId: string) => {
      const questionsData = await getQuestionsList({ questionId });
      if (questionsData.survey_question_options.length === 0) {
        setQuestionDetails({
          ...questionsData,
          survey_question_options: QuestionDetails.survey_question_options,
        });
      } else {
        setQuestionDetails({ ...questionsData });
      }
    };

  useEffect(() => {
    if (id) {
      getQuestionInfo(id);
    }
  }, [id]);

  return (
    <SurveyQuestionFormComponent
      formField={EDIT_SURVEY_QUESTION_FORM_FIELD}
      questionDetails={questionDetails}
      setQuestionDetails={setQuestionDetails}
      onClearButtonClick={onCancelButtonClick}
      OnAddButtonClick={OnAddButtonClick}
      isEditSurveyQuestion={true}
    />
  );
};

export default EditSurveyQuestion;
