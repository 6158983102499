import { useEffect } from "react";
import { useQuery } from "react-query";

import { Form, Col } from "Components/Core";
import { AssetTagsProps } from "Interfaces/AssetTagsInterface";
import { getCategoriesAndTags } from "Api/Components/AssetTagsApi";
import SpecificSkillAndTags from "./SpecificSkillAndTags";
import { getAssetEvaluationTagsFromData } from "../AssetSubmissionVideos/utils";

const SpecificParameter = (props: AssetTagsProps) => {
  const { formData, disabled = false, dispatch } = props;

  const { data } = useQuery({
    queryKey: [
      "asset-tags-categories-tags",
      formData.graph_attribute_id,
      formData.id,
    ],
    queryFn: () =>
      formData.graph_attribute_id
        ? getCategoriesAndTags(formData.graph_attribute_id, formData.id)
        : [],
  });

  useEffect(() => {
    const categoryId = formData.asset_evaluation_category_id;

    if (data && categoryId) {
      let assetEvaluationCategories: { id: number }[] = [];
      let assetEvaluationSubCategories: { id: number }[] = [];

      const category = data.find((cat) => cat.id === categoryId);

      if (
        category &&
        category.asset_evaluation_sub_categories.length === 0 &&
        category.asset_evaluation_tags.length === 0
      ) {
        assetEvaluationCategories = [{ id: categoryId }];
      }

      if (category) {
        const subCategoryId = formData.asset_evaluation_sub_category_id;

        if (subCategoryId) {
          const subCategory = category.asset_evaluation_sub_categories.find(
            (subCat) => subCat.id === subCategoryId
          );

          if (subCategory && subCategory.asset_evaluation_tags.length === 0) {
            assetEvaluationSubCategories = [{ id: subCategoryId }];
          }
        }
      }

      dispatch({
        type: "asset_evaluation_categories",
        payload: assetEvaluationCategories,
      });
      dispatch({
        type: "asset_evaluation_sub_categories",
        payload: assetEvaluationSubCategories,
      });
    }
  }, [
    data,
    dispatch,
    formData.asset_evaluation_category_id,
    formData.asset_evaluation_sub_category_id,
  ]);

  if (!data) {
    return null;
  }

  const handleCategoryChange: React.ChangeEventHandler<HTMLSelectElement> = (
    e
  ) => {
    const value = e.target.value;

    if (value === "OTHER") {
      dispatch({
        type: "asset_evaluation_category",
        payload: {
          is_category_other: true,
          asset_evaluation_category_id: null,
          asset_evaluation_category_title: "",
          asset_evaluation_tags: [],
        },
      });
    } else if (value === "NONE") {
      dispatch({
        type: "asset_evaluation_category",
        payload: {
          is_category_other: false,
          asset_evaluation_category_id: null,
          asset_evaluation_category_title: null,
          asset_evaluation_tags: [],
        },
      });
    } else {
      const categoryId = Number(value);

      dispatch({
        type: "asset_evaluation_category",
        payload: {
          is_category_other: false,
          asset_evaluation_category_id: categoryId,
          asset_evaluation_category_title: null,
          asset_evaluation_tags: getAssetEvaluationTagsFromData(
            data,
            categoryId,
            null
          ),
        },
      });
    }
  };

  const handleOtherCategoryChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (e) => {
    dispatch({
      type: "asset_evaluation_category_title",
      payload: e.target.value,
    });
  };

  const categoryOptions = data.map((category) => (
    <option key={category.id} value={category.id}>
      {category.title}
    </option>
  ));

  return (
    <>
      <Form.Group as={Col} lg={4}>
        <Form.Label>Specific Parameter</Form.Label>
        <Form.Select
          disabled={disabled}
          value={
            formData.asset_evaluation_category_id ||
            (formData.is_category_other ? "OTHER" : "NONE")
          }
          onChange={handleCategoryChange}
        >
          <option value="NONE">No Specific Parameter Selected</option>
          {categoryOptions}
          <option value="OTHER">Other</option>
        </Form.Select>
        {formData.is_category_other && (
          <Form.Control
            placeholder="Other specific parameter"
            value={formData.asset_evaluation_category_title || ""}
            onChange={handleOtherCategoryChange}
          />
        )}
      </Form.Group>

      {((formData.is_category_other &&
        formData.asset_evaluation_category_title !== "") ||
        formData.asset_evaluation_category_id) && (
        <SpecificSkillAndTags
          formData={formData}
          disabled={disabled}
          dispatch={dispatch}
          categoryData={data}
        />
      )}
    </>
  );
};

export default SpecificParameter;
