import DatePicker from "react-datepicker";
import { getCities, getGenders, getBloodGroups } from "Api/CountryCodeApi";
import { Row, Col, Form } from "Components/Core";
import { PlayerDetailsFormPropsType } from "Interfaces/Components/Shared/UserDetailsFormInterface";
import { useCallback, useEffect, useMemo, useState } from "react";
import SearchSelect from "../SearchSelect";
import { convertDateInto_Y_M_D_format } from "Utils/utils";

const PlayerDetailsFrom = (props: PlayerDetailsFormPropsType) => {
  const { userDetails, handleOnChange, setUserDetails, isPlayer } = props;
  const { gender, city, dob, bmi, height, weight, blood_group, country_code } =
    userDetails;

  const [genderList, setGenderList] = useState<string[]>([]);
  const [cityList, setCityList] = useState<string[]>([]);
  const [bloodGroupList, setBloodGroupList] = useState<string[]>([]);

  const fetchGenders = useCallback(async () => {
    const genders = await getGenders();
    setGenderList(genders);
  }, []);

  const fetchCities = useCallback(async () => {
    const cities = await getCities(country_code);
    setCityList(cities);
  }, [country_code]);

  const fetchBloodGroups = useCallback(async () => {
    const bloodGroups = await getBloodGroups();
    setBloodGroupList(bloodGroups);
  }, []);

  useEffect(() => {
    fetchGenders();
    fetchBloodGroups();
  }, [fetchGenders, fetchBloodGroups]);

  useEffect(() => {
    fetchCities();
  }, [country_code, fetchCities]);

  const onGenderChange = (genderSelected: string) => {
    userDetails.gender = genderSelected;
    setUserDetails({ ...userDetails });
  };

  const onCityChange = (citySelected: string) => {
    userDetails.city = citySelected;
    setUserDetails({ ...userDetails });
  };

  const onDOBChange = (date: Date) => {
    if (date) {
      userDetails.dob = convertDateInto_Y_M_D_format(date);
      setUserDetails({ ...userDetails });
    }
  };

  const onBloodGroupChange = (bg: string) => {
    userDetails.blood_group = bg;
    setUserDetails({ ...userDetails });
  };

  const handleHeightChange: React.ChangeEventHandler<HTMLInputElement> = (
    ev
  ) => {
    const heightString = ev.target.value;
    const weightString = weight;

    let heightValue = Number(heightString);
    if (heightValue > 0) {
      userDetails.height = heightString;

      if (heightString && weightString) {
        const weightValue = Number(weightString);

        heightValue /= 100;

        const bmiValue = weightValue / (heightValue * heightValue);
        userDetails.bmi = bmiValue.toFixed(2);
      } else {
        userDetails.bmi = null;
      }
    } else {
      userDetails.height = null;
      userDetails.bmi = null;
    }

    setUserDetails({ ...userDetails });
  };

  const handleWeightChange: React.ChangeEventHandler<HTMLInputElement> = (
    ev
  ) => {
    const heightString = height;
    const weightString = ev.target.value;

    const weightValue = Number(weightString);

    if (weightValue > 0) {
      userDetails.weight = weightString;

      if (heightString && weightString) {
        let heightValue = Number(heightString);
        const weightValue = Number(weightString);

        heightValue /= 100;

        const bmiValue = weightValue / (heightValue * heightValue);
        userDetails.bmi = bmiValue.toFixed(2);
      } else {
        userDetails.bmi = null;
      }
    } else {
      userDetails.weight = null;
      userDetails.bmi = null;
    }

    setUserDetails({ ...userDetails });
  };

  const todaysDate = new Date();

  const selectedDOBDate = useMemo(() => {
    if (!dob) return null;

    const dateArray = dob.split("-");

    const year = parseInt(dateArray[0], 10);
    const month = parseInt(dateArray[1], 10) - 1;
    const day = parseInt(dateArray[2], 10);

    const convertedDate = new Date(year, month, day);
    return convertedDate;
  }, [dob]);

  return (
    <>
      <Row>
        <Form.Group controlId="age" as={Col} lg={4}>
          <Form.Label className="fs-xs">Date of Birth</Form.Label>
          <DatePicker
            selected={selectedDOBDate}
            placeholderText={dob || "Select Date"}
            className="date-picker"
            dateFormat="MM/dd/yyyy"
            maxDate={todaysDate}
            onChange={onDOBChange}
          />
        </Form.Group>
        <Form.Group controlId="city" as={Col} lg={4}>
          <Form.Label className="fs-xs">City</Form.Label>
          <SearchSelect
            optionsList={cityList.map((code: string) => ({
              value: code,
              label: code,
            }))}
            setSelected={onCityChange}
            selected={{
              value: city,
              label: city,
            }}
            placeholder={city || "City"}
          />
        </Form.Group>
      </Row>
      {isPlayer && (
        <Row>
          <Form.Group controlId="blood_group" as={Col} lg={4}>
            <Form.Label className="fs-xs">Blood Group</Form.Label>
            <SearchSelect
              optionsList={bloodGroupList.map((bg: string) => ({
                value: bg,
                label: bg,
              }))}
              setSelected={onBloodGroupChange}
              selected={{
                value: blood_group,
                label: blood_group,
              }}
              placeholder={blood_group || "Blood Group"}
            />
          </Form.Group>
          <Form.Group controlId="gender" as={Col} lg={4}>
            <Form.Label className="fs-xs">Gender</Form.Label>
            <SearchSelect
              optionsList={genderList.map((code: string) => ({
                value: code,
                label: code,
              }))}
              setSelected={onGenderChange}
              selected={{
                value: gender,
                label: gender,
              }}
              placeholder={gender || "Gender"}
            />
          </Form.Group>
        </Row>
      )}
      <Row>
        <Form.Group controlId="height" as={Col} lg={4}>
          <Form.Label className="fs-xs">Height (cm)</Form.Label>
          <Form.Control
            type="number"
            placeholder="Height"
            name="height"
            value={height || ""}
            min={0}
            onChange={handleHeightChange}
          />
        </Form.Group>
        <Form.Group controlId="weight" as={Col} lg={4}>
          <Form.Label className="fs-xs">Weight (kg)</Form.Label>
          <Form.Control
            type="number"
            placeholder="Weight"
            name="weight"
            value={weight || ""}
            min={0}
            onChange={handleWeightChange}
          />
        </Form.Group>
      </Row>
      {isPlayer && (
        <Row>
          <Form.Group controlId="bmi" as={Col} lg={4}>
            <Form.Label className="fs-xs">BMI</Form.Label>
            <Form.Control
              type="number"
              placeholder="BMI"
              name="bmi"
              value={bmi || ""}
              onChange={handleOnChange}
              disabled={true}
            />
          </Form.Group>
        </Row>
      )}
    </>
  );
};

export default PlayerDetailsFrom;
