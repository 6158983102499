import React from "react";
import { ConfirmBoxBodyPropsType } from "Interfaces/Components/Shared/ConfirmBoxComponentInterface";

const ConfirmBoxBody: React.FC<ConfirmBoxBodyPropsType> = ({
  message,
  list,
}) => {
  return (
    <div>
      {message && (
        <>
          {message[0] && (
            <p className="text-center fs-sm fw-bold mb-0">{message[0]}</p>
          )}
          {message[1] && <p className="text-center fs-xs mb-0">{message[1]}</p>}
        </>
      )}
      {list &&
        list?.map((item: string, index: number) => {
          return (
            <p key={index} className="text-center fs-sm fw-bold mb-0">
              {item}
            </p>
          );
        })}
    </div>
  );
};

export default ConfirmBoxBody;
