import React, { useEffect, useState } from "react";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ListGroup } from "Components/Core";
import {
  DrillsDetailsObjectType,
  DrillsListPropsType,
} from "Interfaces/Pages/DrillsInterface";
import { AssignComponentMasterList } from "Interfaces/Pages/BatchesInterface";
import { FILE_TYPE_MAP, FORM_CONSTANT } from "Pages/OLD-Drills/constants";
import { BATCH } from "App/constants";
import { useAppSelector } from "App/hooks";
import { isAllowedTo } from "Utils/helper";

const DrillsListComponent: React.FC<DrillsListPropsType> = ({
  drillsList,
  onDrillClick,
  isAssignList,
  selectedUserIds,
  isRemovable,
}) => {
  const [list, setList] = useState<DrillsDetailsObjectType[]>([]),
    { Height, Width } = FORM_CONSTANT,
    {
      userDetails: { permissions },
    } = useAppSelector((state) => state.loginReducer);

  useEffect(() => {
    setList([]);
    drillsList && setList([...drillsList]);
    list &&
      drillsList &&
      isRemovable &&
      drillsList.forEach((data) => {
        const { drill } = data;
        drill && setList([...list, { ...drill }]);
      });
  }, [drillsList, isRemovable, list]);

  return (
    <>
      <div className={isAssignList ? "" : "flex-1 scroll-y px-4 mb-3"}>
        <ListGroup className="ss-list-group gap-2">
          {list &&
            list.map((value: AssignComponentMasterList, index: number) => {
              const { title, description, id, preview, file_type } = value;
              return (
                <ListGroup.Item
                  className={
                    isAssignList
                      ? "p-3 fs-sm"
                      : "bg-transparent opaque border-0 border-bottom py-3 px-0 fs-sm d-flex justify-content-between align-items-center"
                  }
                  key={index}
                  active={selectedUserIds && selectedUserIds.includes(id)}
                >
                  <div
                    className="d-flex align-items-center gap-3"
                    onClick={() => onDrillClick(value)}
                  >
                    <div className="thumbnail-box position-relative">
                      <img
                        src={preview}
                        width={Width}
                        height={Height}
                        alt={file_type}
                      />
                      {file_type && FILE_TYPE_MAP[file_type] && (
                        <FontAwesomeIcon
                          icon={FILE_TYPE_MAP[file_type]}
                          className="position-absolute fs-5 icon-media"
                        />
                      )}
                    </div>
                    <div>
                      <label className="fs-sm fw-bold">{title}</label>
                      <p className="fs-xs mb-0">{description}</p>
                    </div>
                  </div>
                  {isAllowedTo(BATCH, "update", permissions) && isRemovable && (
                    <div
                      className="pe-4"
                      onClick={(event) => {
                        event.stopPropagation();
                        onDrillClick({
                          data: value,
                          remove: true,
                          replace: false,
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faXmark} />
                    </div>
                  )}
                </ListGroup.Item>
              );
            })}
        </ListGroup>
        <div className="d-flex justify-content-center mt-4 fw-bold">
          {list && !list.length && "No Data Found"}
        </div>
      </div>
    </>
  );
};

export default DrillsListComponent;
