import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DrillsDetailsComponent } from "Components/Shared";
import { getDrillDetails } from "Api/Pages/DrillsApi";
import {
  DrillDetailComponentPropsType,
  DrillsDetailsObjectType,
} from "Interfaces/Pages/DrillsInterface";
import { DrillDetails } from "Pages/OLD-Drills/constants";

const DrillsDetails: React.FC<DrillDetailComponentPropsType> = ({
  batchDrillId,
  hideHeader,
}) => {
  const [drillsDetails, setDrillsDetails] =
      useState<DrillsDetailsObjectType>(DrillDetails),
    { id } = useParams();

  const drillId = batchDrillId ? batchDrillId : id;
  const fetchDrillDetails = useCallback(async (drillId) => {
    const data = await getDrillDetails(drillId);
    setDrillsDetails(data);
  }, []);

  useEffect(() => {
    drillId && fetchDrillDetails(drillId);
  }, [drillId, fetchDrillDetails]);

  return (
    <DrillsDetailsComponent
      drillsDetails={drillsDetails}
      hideHeader={hideHeader}
    />
  );
};

export default DrillsDetails;
