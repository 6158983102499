import axios from "axios";
import {
  GetPlayerListParam,
  DeletePlayerParam,
  AddPlayerParam,
  GetAllPlayersParams,
  GetPlayerEmailReportParams,
  PlayerInfo,
  GetPlayerReportParams,
} from "Interfaces/AppInterface";
import { ACADEMY_BASE_URL } from "App/constants";
import { USER_ATTRIBUTES } from "Pages/Players/constants";
import { convertDateInto_Y_M_D_format, getAcademyId } from "Utils/utils";

export const getPlayerList = async ({
  academyId,
  searchInput,
  selectValue,
  currentPage,
}: GetPlayerListParam) => {
  const queryParams = `page=${currentPage}&sport_id=${selectValue}&search=${searchInput}&order_by=name&order_type=asc`;
  const { data } = await axios.get(
    `${ACADEMY_BASE_URL}${getAcademyId(academyId)}/players?${queryParams}`
  );
  if (data) {
    return data;
  }
  return [];
};

export const deletePlayer = async ({ academyId, id }: DeletePlayerParam) => {
  const { data } = await axios.put(
    `${ACADEMY_BASE_URL}${getAcademyId(academyId)}/players/${id}/deactivate`,
    {
      player: {
        is_active: "false",
      },
    }
  );
  if (data) return data;
};

export const addPlayer = async ({
  academyId,
  param,
  isEditUser,
}: AddPlayerParam) => {
  const user_attributes: any = {};
  USER_ATTRIBUTES.forEach((ele: any) => {
    user_attributes[ele] = param[ele];
    delete param[ele];
  });

  delete param.image;
  if (isEditUser) {
    param.player_sports &&
      param.player_sports.length &&
      param.player_sports.forEach((sportData: any) => {
        delete sportData.sport;
      });
    await axios.put(
      `${ACADEMY_BASE_URL}${getAcademyId(academyId)}/players/${param.id}`,
      {
        player: {
          ...param,
          user_attributes,
        },
      }
    );
    return;
  }
  await axios.post(`${ACADEMY_BASE_URL}${getAcademyId(academyId)}/players`, {
    player: {
      ...param,
      user_attributes,
    },
  });

  return [];
};

export const getAllPlayers = async ({
  academyId,
}: GetAllPlayersParams): Promise<PlayerInfo[]> => {
  const response = await axios.get(
    `${ACADEMY_BASE_URL}${getAcademyId(academyId)}/players/list`
  );

  return response?.data?.players ?? [];
};

export const getPlayerReport = async ({
  academyId,
  playerId,
  startDate,
  endDate,
}: GetPlayerReportParams) => {
  const startDateString = convertDateInto_Y_M_D_format(startDate);
  const endDateString = convertDateInto_Y_M_D_format(endDate);

  const response = await axios.get(
    `${ACADEMY_BASE_URL}${getAcademyId(academyId)}/reports/player_profile.pdf`,
    {
      responseType: "arraybuffer",
      params: {
        start_date: startDateString,
        end_date: endDateString,
        player_ids: playerId,
      },
    }
  );

  return response.data;
};

export const sendEmailPlayerReports = async ({
  academyId,
  playerIds,
  startDate,
  endDate,
  emailAdmin,
}: GetPlayerEmailReportParams) => {
  const startDateString = convertDateInto_Y_M_D_format(startDate);
  const endDateString = convertDateInto_Y_M_D_format(endDate);

  await axios.get(
    `${ACADEMY_BASE_URL}${getAcademyId(academyId)}/reports/player_profile`,
    {
      params: {
        player_ids: playerIds.join(","),
        start_date: startDateString,
        end_date: endDateString,
        email_to: emailAdmin ? "admin" : "players",
      },
    }
  );

  return null;
};
