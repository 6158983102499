import axios from "axios";
import {
  DownloadReportParam,
  GetReportParam,
  PlayerSurveyAdherenceDetailsParam,
} from "Interfaces/Pages/ReportsInterface";
import { ACADEMY_BASE_URL } from "App/constants";
import { REPORTS_DOWNLOAD_ROUTE, REPORTS_ROUTE } from "Pages/Reports/constants";
import { convertDateInto_Y_M_D_format, getAcademyId } from "Utils/utils";

export const getReportList = async ({
  academyId,
  dropDownValue,
  numOfDays,
  selectedSportValue,
  searchInput,
  currentPage,
  start_date,
  end_date,
}: GetReportParam) => {
  let queryParams = `?sport_id=${selectedSportValue}&days=${numOfDays}&page=${currentPage}&search=${searchInput}`;
  if (start_date && end_date)
    queryParams += `&start_date=${convertDateInto_Y_M_D_format(
      start_date
    )}&end_date=${convertDateInto_Y_M_D_format(end_date)}`;

  const { data } = await axios.get(
    `${ACADEMY_BASE_URL}${getAcademyId(academyId)}/reports/${[
      REPORTS_ROUTE[dropDownValue],
    ]}${queryParams}`
  );
  if (data) {
    return data;
  } else {
    return [];
  }
};

export const downloadReport = async ({
  academyId,
  selectedSportValue,
  start_date,
  end_date,
  numOfDays,
  searchInput,
  dropDownValue,
}: DownloadReportParam) => {
  let queryParams = `?sport_id=${selectedSportValue}&days=${numOfDays}&search=${searchInput}&type=${REPORTS_DOWNLOAD_ROUTE[dropDownValue]}`;

  if (start_date && end_date)
    queryParams += `&start_date=${convertDateInto_Y_M_D_format(
      start_date
    )}&end_date=${convertDateInto_Y_M_D_format(end_date)}`;

  const { data } = await axios.get(
    `${ACADEMY_BASE_URL}${getAcademyId(
      academyId
    )}/reports/generate_report${queryParams}`
  );
  return data.generated_file_link;
};

export const getPlayerSurveyAdherenceDetails = async (
  params: PlayerSurveyAdherenceDetailsParam
) => {
  const { academyId, batchId, playerId } = params;
  let queryParams = `?player_id=${playerId}&batch_id=${batchId}`;

  const { data } = await axios.get(
    `${ACADEMY_BASE_URL}${getAcademyId(
      academyId
    )}/reports/player_question_details${queryParams}`
    // `/mocks/playerDetails.json?days=${params.numOfDays}&batch_id=${params.batchId}&start_date=${params.start_date}&end_date=${params.end_date}`
  );
  return data;
};

export const getPlayerSurveyAdherencePDF = async (params: any) => {
  const { academyId, batchId, playerId } = params;
  let queryParams = `?player_id=${playerId}&batch_id=${batchId}`;

  const { data } = await axios.get(
    `${ACADEMY_BASE_URL}${getAcademyId(
      academyId
    )}/reports/player_question_details.pdf${queryParams}`,
    { responseType: "arraybuffer" }
    // `/mocks/playerDetails.json?days=${params.numOfDays}&batch_id=${params.batchId}&start_date=${params.start_date}&end_date=${params.end_date}`
  );
  return data;
};
