import {
  faTrashCan,
  faPencil,
  faCoins,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonIcon } from "Components/Shared";
import {
  CoachColumnInterface,
  CoachObjectType,
  FormConstantType,
} from "Interfaces/Pages/CoachesInterface";
import { isAllowedTo } from "Utils/helper";

export const getCoachColumns = ({
  onEditCoaches,
  onDeleteCoach,
  permissions,
}: CoachColumnInterface) => [
  {
    dataField: "name",
    title: "Coach Name",
  },
  {
    title: "Contact No.",
    formatter: (props: CoachObjectType) =>
      props.country_code && props.contact_number
        ? `${props.country_code} ${props.contact_number}`
        : "-",
  },
  {
    dataField: "email",
    title: "Email ID",
  },

  {
    dataField: "sports_count",
    title: "No. Of Sports",
    formatter: (props: CoachObjectType) => {
      return props.coach_sports.length;
    },
  },
  {
    dataField: "batch_count",
    title: "No. Of Batches",
  },
  {
    dataField: "batch_hours",
    title: "Batch Hours",
  },
  {
    dataField: "skill_coins",
    title: (
      <>
        Skill Coins
        <FontAwesomeIcon
          icon={faCoins}
          style={{ marginLeft: "8px", color: "gold" }}
        />
      </>
    ),
  },
  {
    dataField: "actions",
    title: "Actions",
    formatter: (props: CoachObjectType) => (
      <div className="d-flex" key={props.id}>
        {isAllowedTo("Coach", "update", permissions) && (
          <ButtonIcon
            onClick={(event) => {
              event.stopPropagation();
              onEditCoaches(props.id);
            }}
          >
            <FontAwesomeIcon icon={faPencil} />
          </ButtonIcon>
        )}
        {isAllowedTo("Coach", "deactivate", permissions) && (
          <ButtonIcon
            onClick={(event) => {
              event.stopPropagation();
              onDeleteCoach(props.id);
            }}
          >
            <FontAwesomeIcon icon={faTrashCan} />
          </ButtonIcon>
        )}
      </div>
    ),
  },
];

export const FORM_CONSTANT: FormConstantType = {
  Coaches: "Coaches",
  CreateCoach: "Create New Coach",
  Search: "Search",
  Confirm: "Confirm",
  Ok: "Ok",
  TextCenter: "text-center deactivate-close-btn",
  TextEnd: "text-end",
  ButtonsProps: [
    {
      title: "Confirm",
      onClick: false,
    },
    {
      title: "Ok",
      onClick: false,
    },
  ],
  RouteAddCoach: "/coaches/new",
  RouteEditCoach: (id: number) => {
    return `/coaches/${id}/edit`;
  },
  RouteShowCoach: (id: number) => {
    return `/coaches/${id}`;
  },
};

export const COACH_WARNING: string[] = [
  "You cannot de-activate this Coach",
  "Please first remove Coach from following batches",
];
