import axios from "axios";
import { BulkUploadParam } from "Interfaces/AppInterface";
import { ACADEMY_BASE_URL, BULK_UPLOAD_ROUTE } from "App/constants";
import { getAcademyId } from "Utils/utils";

export const bulkUpload = async ({
  title,
  formData,
  academyId,
}: BulkUploadParam) => {
  await axios.post(
    `${ACADEMY_BASE_URL}${getAcademyId(academyId)}${[
      BULK_UPLOAD_ROUTE[title],
    ]}`,
    formData,
    {
      headers: {
        "content-type": "multipart/form-data",
      },
    }
  );
};

export const getDownloadTemplate = async (academyId: number) => {
  const { data } = await axios.get(
    `${ACADEMY_BASE_URL}${getAcademyId(
      academyId
    )}/players/download_bulk_upload_template`
  );
  return data.generated_file_link;
};
