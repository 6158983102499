import { useMemo } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import { patchSubmissionVideoAction } from "Api/Pages/VideoListApi";
import { Button } from "Components/Core";
import AssetTags from "Components/Shared/AssetTags";
import FileViewPort from "Components/Shared/DrillsDetailsComponent/FileViewPort";
import { AssetSubmissionVideo } from "Interfaces/Components/Shared/AssetSubmissionVideos";
import { submissionVideoDataToFormData } from "../utils";

interface Props {
  data: AssetSubmissionVideo;
  showAssetTags: boolean;
  uploadPurpose: string;
  handleEdit: () => void;
}

const ViewVideoDetails = (props: Props) => {
  const { data, showAssetTags, uploadPurpose, handleEdit } = props;

  const { id, title, description, uploaded_by } = data;

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation({
    mutationFn: patchSubmissionVideoAction,
    onSuccess: () => {
      queryClient.invalidateQueries(["asset-video-submissions"]);

      if (uploadPurpose === "featured") {
        navigate("/featured-videos/submissions");
      } else {
        navigate("/drills/submissions");
      }
    },
  });

  const handleApproved = () => {
    mutate({ videoId: id, action: "approved" });
  };

  const handleRejected = () => {
    mutate({ videoId: id, action: "rejected" });
  };

  const formData = useMemo(() => submissionVideoDataToFormData(data), [data]);

  return (
    <>
      <div className="ss-page-body d-flex flex-column flex-1 scroll-y p-4 gap-4">
        <FileViewPort
          fileType="video/mp4"
          url={data.url}
          preview={data.thumbnail_url}
          videoWrapperClasses="w-50 h-50"
        />

        <div>
          <label className="fs-xs">Title</label>
          <p className="fs-sm fw-bold mb-0">{title}</p>
        </div>
        <div>
          <label className="fs-xs">Description</label>
          <p className="fs-sm fw-bold mb-0">{description || "---"}</p>
        </div>
        <div>
          <label className="fs-xs">Uploaded By</label>
          <p className="fs-sm fw-bold mb-0">{uploaded_by || "---"}</p>
        </div>

        {showAssetTags && (
          <AssetTags disabled formData={formData} dispatch={() => {}} />
        )}
      </div>
      <footer className="ss-page-footer d-flex align-items-center justify-content-between px-4 py-3 gap-3">
        <Button variant="secondary" disabled={isLoading} onClick={handleEdit}>
          Edit
        </Button>
        <div className="d-flex gap-4">
          <Button
            variant="success"
            disabled={isLoading}
            onClick={handleApproved}
          >
            Approve
          </Button>
          <Button
            variant="danger"
            disabled={isLoading}
            onClick={handleRejected}
          >
            Reject
          </Button>
        </div>
        <div></div>
      </footer>
    </>
  );
};

export default ViewVideoDetails;
