import { Table } from "react-bootstrap";
import PaginationControl from "../PaginationControl";

const VirtualTableComponent = ({
  columns,
  tableData,
  onRowClick,
  hideThead,
  hideColWidth,
  isClickAble,
  tableProps,
  paginationProps,
  noDataMessage = "No Data Found",
}: any) => {
  const getPaginationCount = () => {
    if (
      paginationProps &&
      paginationProps.numberOfRowPerPage > 0 &&
      paginationProps.totalCount > 0
    ) {
      return Math.ceil(
        paginationProps.totalCount / paginationProps.numberOfRowPerPage
      );
    }
    return 0;
  };

  return (
    <>
      <Table className="ss-table mb-0">
        {!hideColWidth && (
          <colgroup>
            {columns.map((column: any, index: number) => (
              <col
                key={`col_${column.dataField}_${index}`}
                width={column.colWidth}
              ></col>
            ))}
          </colgroup>
        )}

        {!hideThead && (
          <thead>
            <tr>
              {columns.map((column: any, index: number) => (
                <th
                  className="fw-bold fs-sm"
                  key={`thead_${column.dataField}_${index}`}
                >
                  {column.title}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          {tableData.map((data: any, index: number) => (
            <tr
              role="button"
              key={`tbody_tr_${data.id}_${index}`}
              onClick={() => onRowClick && onRowClick(data)}
              className={isClickAble ? "row-clickable" : ""}
            >
              {columns.map((colum: any, ind: number) => (
                <td
                  className="fs-xs align-middle  overflow-hidden"
                  key={`tbody_td_${data.id}_${index}_${colum.dataField}_${ind}`}
                >
                  {colum.formatter
                    ? colum.formatter(data)
                    : data[colum.dataField]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="d-flex justify-content-center mt-4 fw-bold">
        {!tableData.length && noDataMessage}
      </div>
      {paginationProps && (
        <div className="pagination-wrap d-flex justify-content-end px-4">
          <div className="pagination-wrap d-flex justify-content-end px-4">
            <PaginationControl
              currentPage={paginationProps?.currentPage || 0}
              setCurrentPage={paginationProps?.setPageNumber}
              totalPages={getPaginationCount()}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default VirtualTableComponent;
