import { useCallback, useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tabs, Tab } from "Components/Core";

import AcademyDetails from "./AcademyDetails";
import { getAcademyInfo } from "Api/Pages/AcademyApi";

import { ACADEMY } from "App/constants";
import { history } from "App";
import "../../../Pages/Batches/batch.scss";
import {
  AcademyDetailsObjectType,
  AcademyItemTabsObjectType,
} from "Interfaces/Pages/AcademyInterface";
import { AcademyDefaultValue } from "../Academy/constants";
import { AcademyItemTabs } from "Pages/Academies/constants";

const ShowAcademy = () => {
  const { id } = useParams();

  const [eventKey, setEventKey] = useState<string>(ACADEMY);
  const [academyDetails, setAcademyDetails] =
    useState<AcademyDetailsObjectType>({
      ...AcademyDefaultValue,
    });
  const { name } = academyDetails;

  const fetchAcademyInfo = useCallback(async () => {
    if (id) {
      const details = await getAcademyInfo(+id);
      details && setAcademyDetails(details);
    }
  }, [id]);

  useEffect(() => {
    fetchAcademyInfo();
  }, [fetchAcademyInfo]);

  return (
    <>
      <div className="ss-page-content d-flex flex-column flex-1 scroll-y bg-white">
        <header className="ss-page-header d-flex align-items-center justify-content-between px-3">
          <div className="d-flex align-items-center justify-content-between flex-1">
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faChevronLeft}
                onClick={history.back}
                role="button"
              />
              <h2 className="fs-md mb-0 fw-medium px-2">{name}</h2>
            </div>
          </div>
        </header>
        <hr className="ss-page-seprator mx-4 my-0" />
        <div className="ss-page-body d-flex flex-column flex-1 scroll-y py-4">
          <Tabs
            activeKey={eventKey}
            onSelect={(key: string | null) => {
              key !== null && setEventKey(key);
            }}
            className="ss-tabs"
          >
            {AcademyItemTabs.map(
              (tab: AcademyItemTabsObjectType, index: number) => {
                const { eventKey, title } = tab;
                return (
                  <Tab
                    key={index}
                    eventKey={eventKey}
                    title={title}
                    className="ss-tab-content py-3 px-0 flex-1"
                  >
                    <AcademyDetails
                      eventKey={eventKey}
                      academyDetails={academyDetails}
                    />
                  </Tab>
                );
              }
            )}
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default ShowAcademy;
