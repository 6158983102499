// notificationCountSlice.js
import { createSlice } from "@reduxjs/toolkit";

const notificationCountSlice = createSlice({
  name: "notificationCount",
  initialState: { count: 0 },
  reducers: {
    setNotificationCount: (state, action) => {
      state.count = action.payload;
    },
  },
});

export const { setNotificationCount } = notificationCountSlice.actions;
export default notificationCountSlice.reducer;
