import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ValidationError } from "yup";
import { history } from "App";
import { Button, Form } from "Components/Core";
import { Auth, DisplayErrorMessage } from "Components/Shared";
import { resetPassword } from "Api/Pages/ForgetPasswordApi";
import {
  ResetPasswordErrorObjectType,
  ResetPasswordFormObjectType,
} from "Interfaces/Pages/ForgotPasswordInterface";
import {
  CONFIRM_NEW_PASSWORD,
  FORM_CONSTANT,
  NEW_PASSWORD,
  RESET,
  ResetPasswordDefaultValue,
  ResetPasswordErrorValue,
} from "Pages/ForgotPassword/constant";
import { resetPasswordSchema } from "App/Validations/allSchema";

const ResetPassword = () => {
  const navigate = useNavigate(),
    [searchParams, setSearchParams] = useSearchParams(),
    [formError, setFormError] = useState<ResetPasswordErrorObjectType>({
      ...ResetPasswordErrorValue,
    }),
    [formData, setFormData] = useState<ResetPasswordFormObjectType>(
      ResetPasswordDefaultValue
    );

  const { password_error, password_confirmation_error } = formError,
    { password, password_confirmation } = formData,
    {
      ResetPasswordToken,
      ResetPassword,
      Password,
      ConfirmPassword,
      LoginPath,
      ResetPasswordPath,
      Error,
    } = FORM_CONSTANT;

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const {
      target: { name, value },
    } = event;
    setFormData({ ...formData, [name]: value });
  };

  const handleOnSubmit = useCallback(
    async (event: React.SyntheticEvent) => {
      event.preventDefault();
      const errorObject: ResetPasswordErrorObjectType = {
        ...ResetPasswordErrorValue,
      };
      resetPasswordSchema
        .validate(formData, { abortEarly: false })
        .then(async () => {
          setFormError(errorObject);
          await resetPassword(formData);
          navigate(LoginPath);
        })
        .catch((error) => {
          error instanceof ValidationError &&
            error.inner.forEach((element: ValidationError) => {
              const { path, message } = element;
              errorObject[`${path}${Error}`] = message;
            });
          setFormError(errorObject);
        });
    },
    [formData, LoginPath, Error, navigate]
  );

  useEffect(() => {
    const token = searchParams.get(ResetPasswordToken);
    if (token) {
      setFormData((data) => ({
        ...data,
        reset_password_token: token,
      }));
      history.push(ResetPasswordPath);
    } else {
      navigate(LoginPath);
    }
  }, [
    navigate,
    LoginPath,
    searchParams,
    setSearchParams,
    ResetPasswordPath,
    ResetPasswordToken,
    formData.reset_password_token,
  ]);

  return (
    <>
      <Auth heading={ResetPassword}>
        <Form onSubmit={handleOnSubmit} className="login-form">
          <Form.Group className="mb-3">
            <Form.Label className="text-white mb-1 fs-sm">
              {NEW_PASSWORD}
            </Form.Label>
            <Form.Control
              type={Password}
              onChange={handleOnChange}
              placeholder="Enter password"
              name={Password}
              className="border-primary py-2"
              value={password}
              isInvalid={!!password_error}
            />
            <DisplayErrorMessage errorField={password_error} />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label className="text-white mb-1 fs-sm">
              {CONFIRM_NEW_PASSWORD}
            </Form.Label>
            <Form.Control
              type={Password}
              onChange={handleOnChange}
              placeholder="Enter confirm password"
              name={ConfirmPassword}
              className="border-primary py-2"
              value={password_confirmation}
              isInvalid={!!password_confirmation_error}
            />
            <DisplayErrorMessage errorField={password_confirmation_error} />
          </Form.Group>
          <Form.Group className="d-grid pt-3 mb-3">
            <Button variant="secondary" size="sm" type="submit">
              {RESET}
            </Button>
          </Form.Group>
        </Form>
      </Auth>
    </>
  );
};
export default ResetPassword;
