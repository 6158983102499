import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

interface Props {
  children: any;
  width: number;
  tooltipText?: string;
  styles?: {
    [key: string]: string;
  };
}

const TextWrapper = (props: Props) => {
  const { children, width, styles = {}, tooltipText } = props;
  const placement = "top-start";

  if (!tooltipText) {
    return (
      <span
        style={{
          width: `${width}px`,
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
          display: "inline-block",
          ...styles,
        }}
      >
        {children}
      </span>
    );
  }
  return (
    <OverlayTrigger
      key={placement}
      placement={placement}
      overlay={(props) => (
        <Tooltip id={`tooltip-${placement}`} {...props}>
          {tooltipText}
        </Tooltip>
      )}
    >
      <span
        style={{
          width: `${width}px`,
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
          display: "inline-block",
          ...styles,
        }}
      >
        {children}
      </span>
    </OverlayTrigger>
  );
};

export default TextWrapper;
