import axios from "axios";
import { DrillListApiObjectType } from "Interfaces/AppInterface";
import { BASE_URL } from "App/constants";

export const getDrillDetails = async (id: string) => {
  const { data } = await axios.get(`${BASE_URL}drills/${id}`);
  if (data) {
    return data;
  } else return [];
};

export const getDrillsList = async (param: DrillListApiObjectType) => {
  const { currentPage, searchQuery, selectedSport } = param;
  const { data } = await axios.get(
    `${BASE_URL}drills?page=${currentPage}&master_sport_id=${selectedSport}&search_query=${searchQuery}`
  );
  return data;
};
