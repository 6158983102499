import axios from "axios";
import { BASE_URL } from "App/constants";
import {
  AcademyParamObjectType,
  AddAcademyParam,
} from "Interfaces/AppInterface";

export const getAcademyList: Function = async ({
  page,
  search,
}: AcademyParamObjectType) => {
  const queryParams = `?page=${page}&search=${search}`;
  const { data } = await axios.get(`${BASE_URL}academies${queryParams}`);
  if (data) {
    return data;
  }
  return [];
};

export const getAcademyInfo = async (id: number) => {
  const { data } = await axios.get(`${BASE_URL}academies/${id}`);
  if (data) {
    return data;
  }
  return [];
};

export const addAcademy = async ({
  params,
  isEditAcademy,
}: AddAcademyParam) => {
  if (isEditAcademy) {
    await axios.put(`${BASE_URL}academies/${params["id"]}`, {
      academy: {
        ...params,
      },
    });
    return [];
  }
  await axios.post(`${BASE_URL}academies`, {
    academy: {
      ...params,
    },
  });

  return [];
};
