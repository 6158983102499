import AssetSubmissionVideoDetail from "Components/Shared/AssetSubmissionVideos/AssetSubmissionVideoDetail";
import { useCanApproveSubmissionDrillVideos } from "./utils";

const DrillVideoSubmissionDetail = () => {
  const isAllowed = useCanApproveSubmissionDrillVideos();

  if (!isAllowed) {
    return null;
  }

  return <AssetSubmissionVideoDetail uploadPurpose="drill" />;
};

export default DrillVideoSubmissionDetail;
