import { useMemo } from "react";
import { UsePaginationProps } from "Interfaces/Components/Shared/PaginationControlInterface";

export const usePagination = ({
  currentPage,
  totalPages,
  pageSize,
}: UsePaginationProps) => {
  const { paginationRange, prevDisabled, nextDisabled } = useMemo(() => {
    let prevDisabled: boolean = false;
    let nextDisabled: boolean = false;
    if (currentPage === 1) {
      prevDisabled = true;
    }
    if (currentPage === totalPages) {
      nextDisabled = true;
    }
    return {
      paginationRange: getPaginationGroup(currentPage, pageSize, totalPages),
      prevDisabled,
      nextDisabled,
    };
  }, [totalPages, pageSize, currentPage]);

  return { paginationRange, prevDisabled, nextDisabled };
};

const getPaginationGroup = (
  currentPage: number,
  pageSize: number,
  totalPages: number
) => {
  const start = Math.floor((currentPage - 1) / pageSize) * pageSize;
  let end = start + pageSize;
  if (end > totalPages) {
    end = totalPages;
  }
  return range(start + 1, end);
};

const range = (start: number, end: number) => {
  const range = [];
  for (let i = start; i <= end; i++) {
    range.push(i);
  }
  return range;
};
