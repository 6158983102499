import { SurveysType } from "Interfaces/Pages/ReportsInterface";

export interface ProgressBarComponentProps {
  surveys: SurveysType;
}
export interface ProgressBarMapType {
  [index: string]: string;
  most_prefered: string;
  second_prefered: string;
  third_prefered: string;
  least_prefered: string;
  missed_surveys: string;
}
export const PROGRESS_BAR: ProgressBarMapType = {
  most_prefered: "bg-green",
  second_prefered: "bg-green-light",
  third_prefered: "bg-yellow",
  least_prefered: "bg-brown",
  missed_surveys: "bg-grey-light",
};
