import { PLAYER_SESSION_TYPES, ROLE } from "Pages/SurveyQuestions/constants";
import { SurveyQuestionFilterKeysType } from "Interfaces/Components/Shared/SurveyQuestionListInterface";

export const DEFAULT_FILTER_KEY: SurveyQuestionFilterKeysType = {
  selectedToggleSwitch: ROLE.PLAYER,
  selectedUserSession: PLAYER_SESSION_TYPES[0].name,
  selectedSportValue: "",
  currentPage: 1,
  totalPages: 1,
};
