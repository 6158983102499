import { Player } from "@lottiefiles/react-lottie-player";
import animationData from "./loader-json.json";
import "./loader.scss";

const Loader = (props: any) => {
  const { showLoader } = props;
  return (
    <div
      className="loader-container align-items-center justify-content-center"
      style={{ display: showLoader ? "flex" : "none" }}
    >
      <Player
        autoplay={true}
        loop={true}
        controls={true}
        src={animationData}
        style={{ height: "55%", width: "55%" }}
      />
    </div>
  );
};
export default Loader;
