import { Card } from "Components/Core";
import PieChartWithNeedle from "Components/Shared/Charts/PieChartWithNeedle";
import TextWrapper from "Components/Shared/TextWrapper";
import {
  CHART_DATA_WEIGHTAGE,
  TRIGGER_EVENT_DISPLAY_NAME_MAPPING,
} from "Pages/Reports/constants";
import { getAverageValue } from "../helper";
import { SurveyType } from "../types";

type ChartDataType = Array<{
  color: string;
  name: string;
  value: number;
  weight: number;
}>;

interface IProps {
  // chart needle
  triggerEvent: string;
  title: string;
  surveyData: {
    [key: string]: SurveyType;
  };
  filledSurvey: number;
  totalSurvey: number;
}

const PlayerSurveyDetailsCard = (props: IProps) => {
  const { surveyData, title, triggerEvent, filledSurvey } = props;

  const getChartData = (surveyData: any) => {
    const newChartData: ChartDataType = [];
    for (let key in surveyData) {
      newChartData.push({
        color: surveyData[key].color_code,
        // name: surveyData[key].option,
        name: surveyData[key].option,
        value: 25,
        weight: CHART_DATA_WEIGHTAGE[key],
      });
    }
    return newChartData.sort((a, b) => a.weight - b.weight);
  };

  return (
    <div className="">
      <Card
        style={{
          width: "20rem",
        }}
      >
        <Card.Body>
          <Card.Title>
            <TextWrapper width={288} tooltipText={title}>
              {title}
            </TextWrapper>
          </Card.Title>
          <Card.Subtitle className="mb-2 text-muted reports-card-subtitle">
            {TRIGGER_EVENT_DISPLAY_NAME_MAPPING[triggerEvent] || ""}
          </Card.Subtitle>

          <PieChartWithNeedle
            data={
              filledSurvey === 0
                ? [{ value: 100, color: "#bcbcbc", name: "No data" }]
                : getChartData(surveyData)
            }
            value={getAverageValue(surveyData)}
          />
          <div className="details-precise-box p-2 text-center">
            <span className="details-precise-label">{filledSurvey}</span>
            <span className="ms-1 details-precise-sub-label text-muted">
              Survey Filled
            </span>{" "}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default PlayerSurveyDetailsCard;
