import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ValidationError } from "yup";
import { Location, useLocation, useParams } from "react-router-dom";
import { Button, Row, Col, Form, InputGroup, Accordion } from "Components/Core";
import { getUserData } from "Api/Pages/BatchesApi";
import { addPlayer } from "Api/Pages/PlayersApi";
import { addCoach } from "Api/Pages/CoachesApi";
import {
  getCountryCodeAndNames,
  getEducationalQualifications,
} from "Api/CountryCodeApi";
import {
  UserSport,
  UserDetailFormErrorValue,
  UserSportErrorValue,
  UserSportDefaultValueMap,
  UserDefaultValue,
  getErrorText,
} from "Components/Shared/UserDetailsForm/constant";
import { HeaderComponent, SearchSelect } from "Components/Shared";
import { DisplayErrorMessage } from "Components/Shared/ErrorMessage/DisplayErrorMessage";
import UserSportForm from "Components/Shared/UserDetailsForm/UserSportForm";
import PlayerDemographicForm from "./PlayerDemographicForm";
import PlayerDetailsFrom from "./PlayerDetailForm";
import PlayerPersonalInformationForm from "./PlayerPersonalInformationForm";
import PlayerSportsPerformanceProfileForm from "./SportsPerformanceProfileForm";
import {
  UserDetailFormErrorObjectType,
  UserDetailsFormPropsType,
  UserDetailsObjectType,
  UserSportErrorObjectType,
  UserSportObjectType,
} from "Interfaces/Components/Shared/UserDetailsFormInterface";
import { CANCEL, CLEAR, PLAYER } from "App/constants";
import { history } from "App";
import { useAppSelector } from "App/hooks";
import { userDetailsSchema, userSportSchema } from "App/Validations/allSchema";
import { checkOldAndCurrentData } from "Utils/utils";
import "./userForm.scss";

const UserDetailsForm = () => {
  const location: Location = useLocation(),
    { id } = useParams(),
    { isEditUser, userType } = location.state as UserDetailsFormPropsType,
    [userDetails, setUserDetails] = useState<UserDetailsObjectType>({
      ...UserDefaultValue,
    }),
    userData = useRef(),
    userSportData = useRef<string>(),
    [countryCodesList, setCountryCodesList] = useState<string[]>([]),
    [, setCountryNameList] = useState<string[]>([]),
    [educationalQualificationList, setEducationalQualificationList] = useState<
      string[]
    >([]),
    [sportsDetailList, setSportDetailList] = useState<UserSportObjectType[]>([
      { ...UserSportDefaultValueMap[userType] },
    ]),
    [sportFieldError, setSportFieldError] = useState<
      UserSportErrorObjectType[]
    >([UserSportErrorValue]),
    [fieldError, setFieldError] = useState<UserDetailFormErrorObjectType>(
      UserDetailFormErrorValue
    ),
    {
      userDetails: { academy_id },
    } = useAppSelector((state) => state.loginReducer),
    { name, email, contact_number, country_code, details } = userDetails,
    { name_error, email_error, contact_number_error, country_code_error } =
      fieldError;

  const isPlayer = userType === "Player";

  const fetchUserDetails = useCallback(
    async (id: number) => {
      const data = await getUserData({
        academyId: academy_id,
        id,
        userType: userType,
      });
      userData.current = data;
      data && setUserDetails(data);
      const { coach_sports, player_sports } = data;
      if (coach_sports) {
        setSportDetailList(coach_sports);
        userSportData.current = JSON.stringify(coach_sports);
      }
      if (player_sports) {
        setSportDetailList(player_sports);
        userSportData.current = JSON.stringify(player_sports);
      }
    },
    [setUserDetails, academy_id, userType]
  );

  const validateForm = useCallback(async () => {
    userDetailsSchema
      .validate(userDetails, { abortEarly: false })
      .then(() => setFieldError({ ...UserDetailFormErrorValue }))
      .catch((error) => {
        const errorObject: UserDetailFormErrorObjectType = {
          ...UserDetailFormErrorValue,
        };
        error.inner.forEach((element: ValidationError) => {
          const { path, message } = element;
          errorObject[`${path}_error`] = message;
        });
        setFieldError(errorObject);
      });
    sportsDetailList.forEach((data, index) => {
      userSportSchema
        .validate(data, { abortEarly: false })
        .then(() => {
          delete sportFieldError[index];
        })
        .catch((error) => {
          const errorObject: UserSportErrorObjectType = {
            ...UserSportErrorValue,
          };
          error.inner.forEach((element: ValidationError) => {
            const { path, message } = element;
            errorObject[`${path}_error`] = message;
          });
          sportFieldError[index] = { ...errorObject };
          setSportFieldError([...sportFieldError]);
        });
    });
    const response = await userDetailsSchema.isValid(userDetails);
    return response;
  }, [userDetails, sportsDetailList, sportFieldError]);

  const handleOnSubmit = useCallback(async () => {
    const isValid = await validateForm();

    if (isValid) {
      if (userType === PLAYER) {
        if (!userDetails.email) userDetails.email = null;
        if (!userDetails.contact_number) userDetails.contact_number = null;
        if (!userDetails.country_code) userDetails.country_code = null;
        await addPlayer({
          academyId: academy_id,
          param: { ...userDetails },
          isEditUser,
        });
      } else {
        if (userDetails.email === "") userDetails.email = null;
        if (userDetails.contact_number === "")
          userDetails.contact_number = null;
        await addCoach({
          academyId: academy_id,
          param: { ...userDetails },
          isEditUser,
        });
      }
      history.back();
    }
  }, [userDetails, userType, academy_id, isEditUser, validateForm]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const {
      target: { name, value },
    } = event;
    setUserDetails({ ...userDetails, [name]: value });
  };

  const fetchCountryCodes = useCallback(async () => {
    const { countryCodeList, countryNameList } = await getCountryCodeAndNames(
      academy_id
    );
    setCountryCodesList(countryCodeList);
    setCountryNameList(countryNameList);
  }, [academy_id]);

  const fetchEducationalQualification = useCallback(async () => {
    const educationalQualificationList = await getEducationalQualifications();
    setEducationalQualificationList(educationalQualificationList);
  }, []);

  const onClearForm = () => {
    setUserDetails({ ...UserDefaultValue });
    setFieldError(UserDetailFormErrorValue);
    userType && setSportDetailList([{ ...UserSportDefaultValueMap[userType] }]);
    setSportFieldError([UserSportErrorValue]);
  };

  const onSelectCountryCode = (countryCodeSelected: string) => {
    userDetails.country_code = countryCodeSelected;
    setUserDetails({ ...userDetails });
  };

  useEffect(() => {
    fetchCountryCodes();
    fetchEducationalQualification();
    if (isEditUser && id) {
      fetchUserDetails(+id);
    }
  }, [
    isEditUser,
    id,
    fetchUserDetails,
    fetchCountryCodes,
    fetchEducationalQualification,
  ]);

  useEffect(() => {
    userType &&
      setUserDetails((state) => ({
        ...state,
        [UserSport[userType]]: [...sportsDetailList],
      }));
    if (sportFieldError.length < sportsDetailList.length) {
      setSportFieldError([...sportFieldError, { ...UserSportErrorValue }]);
    }
    if (sportFieldError.length > sportsDetailList.length) {
      setSportFieldError([
        ...sportFieldError.splice(0, sportsDetailList.length),
      ]);
    }
  }, [sportsDetailList, sportFieldError, userType]);

  const countryCodeOptions = useMemo(() => {
    const list = countryCodesList.map((code: string) => ({
      value: code,
      label: code,
    }));

    list.unshift({
      value: "",
      label: "Code",
    });
    return list;
  }, [countryCodesList]);

  return (
    <div className="ss-page-content d-flex flex-column flex-1 scroll-y bg-white">
      <HeaderComponent
        title={
          isEditUser ? `Edit ${userType} Details` : `Create New ${userType}`
        }
      />

      <div className="ss-page-body d-flex flex-column flex-1 scroll-y">
        <Form className="d-flex flex-column flex-1 scroll-y p-4 gap-4">
          <div className="players-details-wrap d-grid gap-4 px-4">
            <Row>
              <Form.Group controlId="name" as={Col} lg={4}>
                <Form.Label className="fs-xs">{`${userType} Name`}*</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Name"
                  value={name}
                  name="name"
                  onChange={handleOnChange}
                  isInvalid={!!name_error}
                />
                <DisplayErrorMessage errorField={name_error} />
              </Form.Group>
              <Form.Group controlId="contact_number" as={Col} lg={4}>
                <div className="d-flex flex-column contact-number-field ">
                  <Form.Label className="fs-xs">Contact Number</Form.Label>
                  <div className="d-flex flex-1 gap-2">
                    <div className="d-flex flex-column">
                      <SearchSelect
                        optionsList={countryCodeOptions}
                        setSelected={onSelectCountryCode}
                        selected={{
                          value: country_code,
                          label: country_code,
                        }}
                        placeholder={country_code || "Code"}
                        errorMessage={country_code_error}
                      />
                    </div>
                    <div className="d-flex flex-1 flex-column">
                      <InputGroup>
                        <Form.Control
                          placeholder="Enter Contact Number"
                          name="contact_number"
                          value={contact_number || ""}
                          onChange={handleOnChange}
                          isInvalid={!!contact_number_error}
                        />
                      </InputGroup>
                      {!!contact_number_error && (
                        <p className="required-text mb-0 ms-2">
                          {getErrorText(contact_number_error, "")}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group controlId="email" as={Col} lg={4}>
                <Form.Label className="fs-xs">Email ID</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter Email ID"
                  name="email"
                  value={email || ""}
                  onChange={handleOnChange}
                  isInvalid={!!email_error}
                />
                <DisplayErrorMessage errorField={email_error} />
              </Form.Group>
              <Form.Group controlId="textarea" as={Col} lg={4}>
                <Form.Label className="fs-xs">{`More Details About ${
                  userType ? userType : "Player"
                }`}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  className="border"
                  placeholder={`More Details About ${userType}`}
                  name="details"
                  value={details}
                  onChange={handleOnChange}
                />
              </Form.Group>
            </Row>
            <PlayerDetailsFrom
              userDetails={userDetails}
              fieldError={fieldError}
              handleOnChange={handleOnChange}
              setUserDetails={setUserDetails}
              isPlayer={isPlayer}
            />
          </div>

          {isPlayer && isEditUser && (
            <Accordion>
              <PlayerDemographicForm
                userDetails={userDetails}
                fieldError={fieldError}
                handleOnChange={handleOnChange}
                setUserDetails={setUserDetails}
                educationalQualificationList={educationalQualificationList}
              />
              <PlayerPersonalInformationForm
                userDetails={userDetails}
                fieldError={fieldError}
                handleOnChange={handleOnChange}
                setUserDetails={setUserDetails}
              />
              <PlayerSportsPerformanceProfileForm
                userDetails={userDetails}
                fieldError={fieldError}
                handleOnChange={handleOnChange}
                setUserDetails={setUserDetails}
              />
            </Accordion>
          )}
          <UserSportForm
            sportsDetailList={sportsDetailList}
            OnAddNewSport={setSportDetailList}
            isEdit={isEditUser}
            errors={sportFieldError}
            userType={userType}
            assignedBatches={userDetails?.academy_batches}
          />
        </Form>
      </div>
      <footer className="ss-page-footer d-flex align-items-center px-4 py-3 gap-3">
        <Button
          variant="outline-secondary"
          size="sm"
          className="px-4"
          onClick={isEditUser ? history.back : onClearForm}
        >
          {isEditUser ? CANCEL : CLEAR}
        </Button>
        <Button
          onClick={handleOnSubmit}
          variant="secondary"
          size="sm"
          className="px-4"
          disabled={
            isEditUser &&
            checkOldAndCurrentData(userData.current, userDetails) &&
            userSportData.current === JSON.stringify(sportsDetailList)
          }
        >
          Save
        </Button>
      </footer>
    </div>
  );
};

export default UserDetailsForm;
