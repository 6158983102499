import { useAppSelector } from "App/hooks";
import { isAllowedTo } from "Utils/helper";

export const useCanApproveSubmissionDrillVideos = () => {
  const {
    userDetails: { permissions, permission_rules },
  } = useAppSelector((state) => state.loginReducer);

  const hasPermission = isAllowedTo("ApprovalAsset", "index", permissions);
  if (!hasPermission) return false;

  if (permission_rules?.index?.ApprovalAsset?.upload_purpose === "drill") {
    return true;
  }

  return false;
};
