import React, { useCallback, useEffect, useState } from "react";
import { Form } from "Components/Core";
import { DisplayErrorMessage } from "Components/Shared/ErrorMessage/DisplayErrorMessage";
import { getMasterSportsList, getSportsList } from "Api/Pages/SportsApi";
import { SelectSportsPropsType } from "Interfaces/Components/Shared/SelectSports";
import { SportObjectType } from "Interfaces/Pages/SportsInterface";
import { useAppSelector } from "App/hooks";

const SelectSports: React.FC<SelectSportsPropsType> = ({
  handleSelect,
  shouldShowAll,
  selected,
  shouldPassMasterSportId,
  className,
  isDisableDropdown,
  placeHolder,
  isRequired,
  SportMsg,
  title,
}) => {
  const [academySportList, setAcademySportList] = useState<SportObjectType[]>(
      []
    ),
    {
      userDetails: { academy_id },
    } = useAppSelector((state) => state.loginReducer);

  const fetchAcademySportList = useCallback(async () => {
      const data = academy_id
        ? await getSportsList(academy_id)
        : await getMasterSportsList();
      setAcademySportList(data);
    }, [academy_id]),
    getActiveId = (sport: SportObjectType) => {
      return shouldPassMasterSportId && academy_id
        ? sport.master_sport_id
        : sport.id;
    };

  useEffect(() => {
    fetchAcademySportList();
  }, [fetchAcademySportList]);

  if (academySportList.length && !selected && !shouldShowAll && !placeHolder) {
    handleSelect({
      target: { value: getActiveId(academySportList[0]) },
    });
  }

  return (
    <Form.Group className={`${className}`}>
      <Form.Label className="fs-sm">{title ? title : "Sport *"}</Form.Label>
      <Form.Select
        className="ss-select-control bg-white"
        aria-label="Sports Category"
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
          handleSelect && handleSelect(event)
        }
        disabled={isDisableDropdown}
        isInvalid={isRequired ? SportMsg !== "" : false}
      >
        {placeHolder && (
          <option value="" key="" disabled selected={selected === ""}>
            {placeHolder}
          </option>
        )}
        {shouldShowAll && (
          <option value="" key="All" selected={selected ? false : true}>
            All
          </option>
        )}
        {academySportList.map((sport, i) => {
          return (
            <option
              value={getActiveId(sport)}
              key={getActiveId(sport)}
              selected={getActiveId(sport) === selected}
            >
              {sport.name}
            </option>
          );
        })}
      </Form.Select>
      {isRequired && <DisplayErrorMessage errorField={SportMsg} />}
    </Form.Group>
  );
};

SelectSports.defaultProps = {
  shouldShowAll: false,
  className: "",
};

export default SelectSports;
