import { useMemo, useReducer } from "react";
import { useMutation, useQueryClient } from "react-query";

import { Form, Button, Col } from "Components/Core";
import FileViewPort from "Components/Shared/DrillsDetailsComponent/FileViewPort";
import AssetTags from "Components/Shared/AssetTags";
import { putSubmissionVideo } from "Api/Pages/VideoListApi";
import { AssetSubmissionVideo } from "Interfaces/Components/Shared/AssetSubmissionVideos";
import {
  submissionVideoDataToFormData,
  videoDetailFormReducer,
} from "../utils";

interface Props {
  data: AssetSubmissionVideo;
  handleCloseEdit: () => void;
}

const VideoDetailsForm = (props: Props) => {
  const { data, handleCloseEdit } = props;
  const queryClient = useQueryClient();

  const initialFromData = useMemo(
    () => submissionVideoDataToFormData(data),
    [data]
  );

  const [formData, dispatch] = useReducer(
    videoDetailFormReducer,
    initialFromData
  );

  const { mutate, isLoading } = useMutation({
    mutationFn: putSubmissionVideo,
    onSuccess: () => {
      queryClient.invalidateQueries(["asset-submission-video-detail", data.id]);
      queryClient.invalidateQueries(["asset-video-submissions"]);
      queryClient.invalidateQueries(["asset-tags-categories-tags"]);
    },
  });

  const handleSave = () => {
    mutate({ videoId: data.id, data: formData });
    handleCloseEdit();
  };

  const handleCancel = () => {
    handleCloseEdit();
  };

  const handleTitleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    dispatch({ type: "title", payload: e.target.value });
  };

  const handleDesctiptionChange: React.ChangeEventHandler<
    HTMLTextAreaElement
  > = (e) => {
    dispatch({ type: "description", payload: e.target.value });
  };

  return (
    <>
      <div className="ss-page-body d-flex flex-column flex-1 scroll-y p-4 gap-4">
        <FileViewPort
          fileType="video/mp4"
          url={data.url}
          preview={data.thumbnail_url}
          videoWrapperClasses="w-50 h-50"
        />

        <Form className="d-flex flex-column gap-4">
          <Form.Group as={Col} lg={4}>
            <Form.Label>Title</Form.Label>
            <Form.Control value={formData.title} onChange={handleTitleChange} />
          </Form.Group>
          <Form.Group as={Col} lg={4}>
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              value={formData.description}
              onChange={handleDesctiptionChange}
            />
          </Form.Group>

          <AssetTags formData={formData} dispatch={dispatch} />
        </Form>
      </div>
      <footer className="ss-page-footer d-flex align-items-center justify-content-start px-4 py-3 gap-3">
        <Button variant="primary" disabled={isLoading} onClick={handleSave}>
          Save
        </Button>
        <Button
          variant="outline-primary"
          disabled={isLoading}
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </footer>
    </>
  );
};

export default VideoDetailsForm;
