import { Button } from "Components/Core";
import {
  GO_TO_HOME,
  NOT_FOUND_404,
  OOPS,
  PAGE_NOT_FOUND_MESSAGE,
} from "./constants";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "App/hooks";
import { setErrorCode } from "App/commonReducer";
import { useNavigate } from "react-router-dom";
import { PAGE_NOT_FOUND_ERROR } from "App/constants";

export const PageNotFound = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { errorCode } = useAppSelector((state) => state.commonReducer);

  useEffect(() => {
    +errorCode === PAGE_NOT_FOUND_ERROR && dispatch(setErrorCode(""));
  }, [dispatch, errorCode]);

  return (
    <div className="error-template d-flex flex-column justify-content-center align-items-center flex-1">
      <h1 className="mt-4 fw-bold">{OOPS}</h1>
      <h2>{NOT_FOUND_404}</h2>
      <div>{PAGE_NOT_FOUND_MESSAGE}</div>
      <Button variant="success" onClick={() => navigate("/")}>
        {GO_TO_HOME}
      </Button>
    </div>
  );
};

export default PageNotFound;
