import AccordionBody from "react-bootstrap/esm/AccordionBody";
import AccordionHeader from "react-bootstrap/esm/AccordionHeader";
import AccordionItem from "react-bootstrap/esm/AccordionItem";
import { Row, Col, Form } from "Components/Core";
import { PlayerSportsPerformanceFormPropsType } from "Interfaces/Components/Shared/UserDetailsFormInterface";

const PlayerSportsPerformanceProfileForm = (
  props: PlayerSportsPerformanceFormPropsType
) => {
  const { userDetails, handleOnChange } = props;
  const {
    international_ranking,
    asian_ranking,
    national_ranking,
    state_ranking,
  } = userDetails;

  return (
    <AccordionItem eventKey="player-sports-performance-form">
      <AccordionHeader>
        <span className="fw-bold">Sports Performance Profile</span>
      </AccordionHeader>
      <AccordionBody>
        <div className="players-details-wrap d-grid gap-4">
          <Row>
            <Form.Group controlId="international_ranking" as={Col} lg={4}>
              <Form.Label className="fs-xs">International Ranking</Form.Label>
              <Form.Control
                placeholder="International Ranking"
                name="international_ranking"
                value={international_ranking}
                onChange={handleOnChange}
              />
            </Form.Group>
            <Form.Group controlId="asian_ranking" as={Col} lg={4}>
              <Form.Label className="fs-xs">Asian Ranking</Form.Label>
              <Form.Control
                placeholder="Asian Ranking"
                name="asian_ranking"
                value={asian_ranking}
                onChange={handleOnChange}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group controlId="national_ranking" as={Col} lg={4}>
              <Form.Label className="fs-xs">National Ranking</Form.Label>
              <Form.Control
                placeholder="National Ranking"
                name="national_ranking"
                value={national_ranking}
                onChange={handleOnChange}
              />
            </Form.Group>
            <Form.Group controlId="state_ranking" as={Col} lg={4}>
              <Form.Label className="fs-xs">State Ranking</Form.Label>
              <Form.Control
                placeholder="State Ranking"
                name="state_ranking"
                value={state_ranking}
                onChange={handleOnChange}
              />
            </Form.Group>
          </Row>
        </div>
      </AccordionBody>
    </AccordionItem>
  );
};

export default PlayerSportsPerformanceProfileForm;
