import { loginErrorValues } from "Pages/Login/constants";
import {
  addUserErrorValues,
  LOGIN_ERROR_MESSAGE,
  ADD_USER_ERROR_MESSAGE,
  addBatchErrorValues,
} from "App/Validations/constants";
import {
  addUserNameSchema,
  addUserContactNumberSchema,
  addUserEmailSchema,
  addUserDetailsSchema,
} from "App/Validations/allSchema";
import { isEmptyOrSpaces } from "Utils/utils";
import { AddBatchErrorObjectType } from "Interfaces/ValidationInterface";
import { LoginDetails } from "Interfaces/Pages/LoginInterface";

export const loginValidation = (formData: LoginDetails) => {
  const { email, password } = formData;
  const { EMAIL_IS_REQUIRED, INVALID_EMAIL_FORMAT, PASSWORD_IS_REQUIRED } =
    LOGIN_ERROR_MESSAGE;
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

  if (!email) {
    loginErrorValues.emailError = EMAIL_IS_REQUIRED;
  } else if (!regex.test(email)) {
    loginErrorValues.emailError = INVALID_EMAIL_FORMAT;
  } else {
    loginErrorValues.emailError = "";
  }
  if (!password) {
    loginErrorValues.passwordError = PASSWORD_IS_REQUIRED;
  } else {
    loginErrorValues.passwordError = "";
  }
  return { ...loginErrorValues };
};

export const validateAddUser = async (formData: any) => {
  const { name, contact_number, email_id, details } = formData;
  const {
    NAME_IS_EMPTY,
    NAME_LIMIT_EXCEEDED,
    CONTACT_NUMBER_IS_EMPTY,
    CONTACT_NUMBER_IS_INVALID,
    EMAIL_IS_EMPTY,
    EMAIL_IS_INVALID,
    EMAIL_LIMIT_EXCEEDED,
    DETAILS_LIMIT_EXCEEDED,
  } = ADD_USER_ERROR_MESSAGE;
  const nameIsValid = await addUserNameSchema.isValid({
    name: name,
  });
  const contactNumberIsValid = await addUserContactNumberSchema.isValid({
    contact_number: contact_number,
  });
  const emailIsValid = await addUserEmailSchema.isValid({
    email: email_id,
  });
  const detailsIsValid = await addUserDetailsSchema.isValid({
    details: details,
  });
  if (!nameIsValid) {
    if (name === "" || name === null) {
      addUserErrorValues.nameError = NAME_IS_EMPTY;
    } else {
      addUserErrorValues.nameError = NAME_LIMIT_EXCEEDED;
    }
  } else if (isEmptyOrSpaces(name)) {
    addUserErrorValues.nameError = NAME_IS_EMPTY;
  } else {
    addUserErrorValues.nameError = "";
  }

  if (!contactNumberIsValid) {
    if (contact_number === "") {
      addUserErrorValues.contact_number_error = CONTACT_NUMBER_IS_EMPTY;
    } else {
      addUserErrorValues.contact_number_error = CONTACT_NUMBER_IS_INVALID;
    }
  } else {
    addUserErrorValues.contact_number_error = "";
  }
  if (!emailIsValid) {
    if (email_id === "") {
      addUserErrorValues.emailError = EMAIL_IS_EMPTY;
    } else if (email_id?.length > 200) {
      addUserErrorValues.emailError = EMAIL_LIMIT_EXCEEDED;
    } else {
      addUserErrorValues.emailError = EMAIL_IS_INVALID;
    }
  } else {
    addUserErrorValues.emailError = "";
  }
  if (!detailsIsValid) {
    addUserErrorValues.detailsError = DETAILS_LIMIT_EXCEEDED;
  } else {
    addUserErrorValues.detailsError = "";
  }
  return { ...addUserErrorValues };
};

export const validateAddBatch = async (obj: AddBatchErrorObjectType) => {
  const validateObject: AddBatchErrorObjectType = { ...addBatchErrorValues };
  const keys = Object.keys(obj);
  for (let i = 0; i < keys.length; i++) {
    await new Promise((resolve) => {
      addUserDetailsSchema.isValid({ [keys[i]]: obj[keys[i]] }).then((res) => {
        if (res) validateObject[keys[i]] = "";
        return resolve(true);
      });
    });
  }
  return validateObject;
};
