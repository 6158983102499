import { Col, Form, Row } from "react-bootstrap";
import { HeaderComponent } from "Components/Shared";
import FileViewPort from "Components/Shared/DrillsDetailsComponent/FileViewPort";
import { DrillsDetailsPropsType } from "Interfaces/Pages/DrillsInterface";
import { FORM_CONSTANT } from "Pages/OLD-Drills/constants";

const DrillsDetailsComponent: React.FC<DrillsDetailsPropsType> = ({
  drillsDetails,
  hideHeader,
}) => {
  const { description, file_type, title, master_sport, file_url, preview } =
      drillsDetails,
    { DrillDetail } = FORM_CONSTANT;
  return (
    <div className="ss-page-content d-flex flex-column flex-1 scroll-y bg-white">
      {!hideHeader && <HeaderComponent title={DrillDetail} />}
      <div className="ss-page-body d-flex flex-column flex-1 scroll-y">
        <Form className="d-flex flex-column flex-1 scroll-y p-4 gap-4">
          <FileViewPort fileType={file_type} url={file_url} preview={preview} />
          <Row>
            <Col lg={4}>
              <label className="fs-xs">Drill Title</label>
              <p className="fs-sm fw-bold mb-0">{title}</p>
            </Col>
            <Col lg={4}>
              <label className="fs-xs">Sport</label>
              <p className="fs-sm fw-bold mb-0">{master_sport}</p>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <label className="fs-xs">More Details About Drill</label>
              <p className="fs-sm fw-bold mb-0">{description}</p>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default DrillsDetailsComponent;
